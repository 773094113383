import { createInjectionState } from '@vueuse/core';
import { checkIntersection } from 'line-intersect';
import { v4 as uuid } from 'uuid';
import { Ref, ref } from 'vue';
import { useContext } from 'vue-context-composition';
import { ILOP, IPositionFixMarker, NM_IN_METER, PositionFixType } from '@signi/common';
import { controlModesCtx } from '../../../contexts/controlModes';
import { messageCtx } from '../../../contexts/messages';
import { viewportCtx } from '../../../contexts/viewport';
import { MeterPoint } from '../../../types';
import { degInRad, meterToLngLat } from '../../../utils/conversions';
import { createLOP, getIntersectionOfBearingAndRange, positionFixAngle } from '../../../utils/positionFixUtils';
import { intersectionWithViewport } from '../../chart/tool/intersectionWithViewport';

const fixIndeterminableMsg = 'Could not determine the position fix based on provided LOPs';
const fixDeterminedPartially = 'Position fix determined based on only two LOPs';

const [useProvideToolsStore, useToolsStore] = createInjectionState((markers: IPositionFixMarker[] = []) => {
  const { viewport, transformToViewport, showInViewport } = useContext(viewportCtx);
  const { setBrowse } = useContext(controlModesCtx);
  const { pushMessage } = useContext(messageCtx);
  const positionFixMarkers: Ref<IPositionFixMarker[]> = ref(markers);
  const editId: Ref<string | undefined> = ref(undefined);
  let active: IPositionFixMarker | undefined = undefined;

  const findMarker = (groupId: string) => positionFixMarkers.value.find((item) => item.id === groupId);
  const showWarning = (text: string) => pushMessage({ text, type: 'warning' });
  const setMarkers = (markers: IPositionFixMarker[]) => (positionFixMarkers.value = markers);

  const createPositionFixMarker = () => {
    const id = uuid();
    editId.value = id;
    positionFixMarkers.value.push({
      id,
      isEditMode: false,
      LOPs: [],
      positionFix: undefined,
      selectedFixType: PositionFixType.Radar,
      showLOPs: false,
      visible: true,
    });
  };

  const updatePositionFix = (groupId: string): boolean => {
    positionFixMarkers.value.forEach((group) => {
      const isMatching = group.id === groupId;
      if (isMatching) {
        group.isEditMode = false;
        group.showLOPs = false;
      }
    });
    return createPositionFix(groupId);
  };

  const toggleShowLops = (groupId: string, isVisible: boolean) => {
    positionFixMarkers.value.forEach((group) => {
      const isMatching = group.id === groupId;
      if (isMatching) {
        group.showLOPs = isVisible;
      }
    });
  };

  const switchToEdit = (groupId: string) => {
    positionFixMarkers.value.forEach((group) => {
      group.isEditMode = false;
      if (group.id === groupId) {
        group.isEditMode = true;
        editId.value = groupId;
        active = JSON.parse(JSON.stringify(group)) as IPositionFixMarker;
      }
    });
  };

  const getLineCoordinates = (lop: ILOP) => {
    const bearingAngleInRad = lop.bearingRelativeValue * degInRad - Math.PI / 2;
    const topLeft = transformToViewport({ x: 0, y: 0 });
    const topRight = transformToViewport({ x: viewport.dimension.width, y: 0 });
    const bottomRight = transformToViewport({ x: viewport.dimension.width, y: viewport.dimension.height });
    const bottomLeft = transformToViewport({ x: 0, y: viewport.dimension.height });
    const intersections = intersectionWithViewport(
      lop.center,
      bearingAngleInRad,
      topLeft,
      topRight,
      bottomRight,
      bottomLeft,
    );
    return [intersections[0], intersections[intersections.length - 1]];
  };

  const getIntersectionOfTwoLops = (lop1: ILOP, lop2: ILOP, warning = true) => {
    if (lop1.type == 'Range') {
      const linePoints = getLineCoordinates(lop2);
      const pts = getIntersectionOfBearingAndRange(lop1, linePoints);
      if (pts.length) {
        return pts[0];
      } else if (warning) {
        showWarning(fixIndeterminableMsg);
      }
    } else if (lop2.type == 'Range') {
      const linePoints = getLineCoordinates(lop1);
      const pts = getIntersectionOfBearingAndRange(lop2, linePoints);
      if (pts.length) {
        return pts[0];
      } else if (warning) {
        showWarning(fixIndeterminableMsg);
      }
    } else {
      let intersectionPt = undefined;
      const [p1, p2] = getLineCoordinates(lop1);
      const [p3, p4] = getLineCoordinates(lop2);
      const inters = checkIntersection(p1.mX, p1.mY, p2.mX, p2.mY, p3.mX, p3.mY, p4.mX, p4.mY);
      if (inters.type == 'intersecting') {
        intersectionPt = { mX: inters.point.x, mY: inters.point.y };
        return intersectionPt;
      } else if (warning) {
        showWarning(fixIndeterminableMsg);
      }
    }
  };

  const createPositionFixForThreeLops = (lops: ILOP[]) => {
    let centroid = undefined;
    const intersections: MeterPoint[] = [];

    lops.forEach((lop: ILOP, index) => {
      const intersection =
        index == 2
          ? getIntersectionOfTwoLops(lops[2], lops[0], false)
          : getIntersectionOfTwoLops(lops[index], lops[index + 1], false);
      if (intersection) {
        intersections.push(intersection);
      }
    });

    if (intersections.length === 3) {
      const centroidX = (intersections[0].mX + intersections[1].mX + intersections[2].mX) / 3;
      const centroidY = (intersections[0].mY + intersections[1].mY + intersections[2].mY) / 3;
      centroid = { mX: centroidX, mY: centroidY };
    } else if (intersections.length === 2) {
      showWarning(fixIndeterminableMsg);
    } else if (intersections.length === 1) {
      centroid = { mX: intersections[0].mX, mY: intersections[0].mY };
      showWarning(fixDeterminedPartially);
    } else {
      showWarning(fixIndeterminableMsg);
    }

    return centroid;
  };

  const createPositionFix = (groupId: string): boolean => {
    const matchingGroup = positionFixMarkers.value.find((grp) => grp.id == groupId);
    if (!matchingGroup) return false;

    matchingGroup.isEditMode = false;
    const lops = matchingGroup.LOPs;
    if (lops.length == 3) {
      matchingGroup.positionFix = createPositionFixForThreeLops(lops);
    } else if (lops.length == 2) {
      matchingGroup.positionFix = getIntersectionOfTwoLops(lops[0], lops[1]);
    }

    return !!matchingGroup.positionFix;
  };

  const deletePositionFix = (groupId: string) => {
    const matchingGroup = positionFixMarkers.value.find((grp) => grp.id == groupId);
    if (!matchingGroup) return;
    matchingGroup.positionFix = undefined;
    matchingGroup.positionFixTimestamp = undefined;
    matchingGroup.isEditMode = false;

    positionFixMarkers.value = positionFixMarkers.value.filter((group) => group.id !== groupId);
  };

  const addBearingRangeToPositionFixMarker = (groupId: string, type: 'Bearing' | 'Range', center: MeterPoint) => {
    positionFixMarkers.value.some((item) => {
      const isMatching = item.id === groupId;
      if (isMatching) {
        item.LOPs.push(createLOP(type, center));
      }
      return isMatching;
    });
  };

  const updateBearingRange = (groupId: string, lopId: string, type: 'Bearing' | 'Range', value: number) => {
    const marker = findMarker(groupId);
    if (!marker) return;

    const lop = marker.LOPs.find((item) => item.id === lopId);
    if (!lop) return;

    const bearing = positionFixAngle(value);
    if (type == 'Bearing') {
      lop.bearing = bearing;
      lop.bearingRelativeValue = bearing;
    } else {
      lop.range = value;
    }
  };

  const onEditStart = (id: string, groupId: string) => {
    editId.value = groupId;
    positionFixMarkers.value.some((group) => {
      const isMatching = group.id === groupId;
      if (isMatching) {
        const lop = group.LOPs.find((LOP) => LOP.id == id);
        if (lop) {
          lop.isSelected = true;
        }
      }
      return isMatching;
    });
  };

  const setPositionToGeo = (id: string, groupId: string) => {
    positionFixMarkers.value.some((group) => {
      const isMatching = group.id === groupId;
      if (isMatching) {
        const lop = group.LOPs.find((LOP) => LOP.id == id);
        if (lop) {
          lop.isSetToOwnShip = true;
        }
      }
      return isMatching;
    });
  };

  const updateCenter = (id: string, groupId: string, center: MeterPoint) => {
    positionFixMarkers.value.some((group) => {
      const isMatching = group.id === groupId;
      if (isMatching) {
        const lop = group.LOPs.find((LOP) => LOP.id == id);
        if (lop) {
          lop.center = center;
          lop.location = meterToLngLat(center);
        }
      }
      return isMatching;
    });
  };

  const updatePositionToOwnShip = (id: string, groupId: string, center: MeterPoint) => {
    positionFixMarkers.value.some((group) => {
      const isMatching = group.id === groupId;
      if (isMatching) {
        const lop = group.LOPs.find((LOP) => LOP.id == id);
        if (lop) {
          lop.center = center;
          lop.isSetToOwnShip = true;
        }
      }
      return isMatching;
    });
  };

  const updateBearingRangeOnOwnShipChange = (shipPosition: MeterPoint) => {
    positionFixMarkers.value.forEach((group) => {
      group.LOPs.forEach((lop) => {
        if (lop.isSetToOwnShip) {
          lop.center = shipPosition;
        }
      });
    });
  };

  const updateAlignHeadingOnOwnShipChange = (heading: number) => {
    const headingValue = parseFloat(heading.toFixed(1));
    positionFixMarkers.value.forEach((group) => {
      group.LOPs.forEach((lop) => {
        if (lop.bearingType == 'RELATIVE') {
          lop.bearingRelativeValue = (lop.bearing ? lop.bearing : 0) + headingValue;
        }
        setIsAlignToHeading(lop, headingValue);
      });
    });
  };

  const updateAlignHeadingOnBearingChange = (lopId: string, heading: number) => {
    positionFixMarkers.value.forEach((group) => {
      group.LOPs.forEach((lop) => {
        if (lop.type == 'Bearing') {
          lop.isSelected = false;
          if (lop.id != lopId) {
            return;
          }
          setIsAlignToHeading(lop, parseFloat(heading.toFixed(1)));
        }
      });
    });
  };

  const updateBearingValue = (
    groupId: string,
    lopId: string,
    value: number,
    heading: number,
    isInputByUser: boolean,
    isDraggedByUser: boolean,
  ) => {
    const marker = findMarker(groupId);
    if (!marker) return;

    const lop = marker.LOPs.find((item) => item.id === lopId);
    if (!lop) return;

    if (lop.bearingType == 'RELATIVE') {
      if (isInputByUser) {
        lop.bearing = value;
        lop.bearingRelativeValue = parseFloat((heading + (lop.bearing ? lop.bearing : 0)).toFixed(1));
      }
      if (isDraggedByUser) {
        lop.bearingRelativeValue = value;
        const relativeToHead = value - heading;
        const relativeVal = relativeToHead > 0 ? relativeToHead : 360 + relativeToHead;
        lop.bearing = parseFloat(relativeVal.toFixed(1));
      }
    } else {
      lop.bearing = lop.bearingRelativeValue = value;
    }

    setIsAlignToHeading(lop, heading);
  };

  const updateRangeValue = (groupId: string, lopId: string, value: number) => {
    const marker = findMarker(groupId);
    if (!marker) return;

    const lop = marker.LOPs.find((item) => item.id === lopId);
    if (!lop) return;

    lop.range = value;
  };

  const updateListVisibility = (visible: boolean) =>
    positionFixMarkers.value.forEach((group) => (group.visible = visible));

  const setIsAlignToHeading = (lop: ILOP, heading: number) =>
    (lop.isAlignToHeading =
      lop.bearingType !== 'RELATIVE' ? lop.bearing === heading : lop.bearingRelativeValue == heading);

  const removeLop = (groupId: string, LOPId: string) => {
    positionFixMarkers.value = positionFixMarkers.value.map((group) => {
      if (group.id === groupId) {
        group.LOPs = group.LOPs.filter((LOP) => LOP.id !== LOPId);
      }
      return group;
    });
  };

  const cancelEditing = (id: string) => {
    positionFixMarkers.value = positionFixMarkers.value.filter((group) => {
      if (group.id === id && active) {
        group.isEditMode = false;
        group.positionFix = {
          mX: active.positionFix?.mX ?? 0,
          mY: active.positionFix?.mY ?? 0,
        };
        group.positionFixTimestamp = active.positionFixTimestamp;
        group.selectedFixType = active.selectedFixType;
        group.showLOPs = active.showLOPs;
        group.visible = active.visible;
        group.LOPs = active.LOPs.map((lop) => {
          const value = lop.type === 'Bearing' ? lop.bearing : lop.range;
          updateBearingRange(group.id, lop.id, lop.type, value!);

          return lop;
        });
      }
      return group.positionFix;
    });
  };

  const updateFixType = (id: string, type: PositionFixType) => {
    const marker = positionFixMarkers.value.find((item) => item.id === id);
    if (marker) {
      marker.selectedFixType = type;
    }
  };

  const updatePositionFixVisibility = (id: string, visibility: boolean) =>
    positionFixMarkers.value.some((group) => {
      const isFound = group.id === id;
      if (isFound) {
        group.visible = visibility;
      }
      return isFound;
    });

  const pinPositionFix = (id: string) => {
    const marker = findMarker(id);
    if (!marker) return;

    editId.value = id;
    setBrowse(true);
    if (marker.visible && marker.positionFix) {
      let rangeInMeter = NM_IN_METER * 5;
      const rangeLOP = marker.LOPs.find((lop) => lop.type === 'Range');

      if (rangeLOP && rangeLOP.range) {
        rangeInMeter = rangeLOP.range * NM_IN_METER * 5;
      }

      showInViewport({
        topLeft: { mX: marker.positionFix.mX - rangeInMeter, mY: marker.positionFix.mY - rangeInMeter },
        bottomRight: { mX: marker.positionFix.mX + rangeInMeter, mY: marker.positionFix.mY + rangeInMeter },
      });
    }
  };

  return {
    addBearingRangeToPositionFixMarker,
    cancelEditing,
    createPositionFix,
    createPositionFixMarker,
    deletePositionFix,
    editId,
    onEditStart,
    pinPositionFix,
    positionFixMarkers,
    removeLop,
    setMarkers,
    setPositionToGeo,
    switchToEdit,
    toggleShowLops,
    updateAlignHeadingOnBearingChange,
    updateAlignHeadingOnOwnShipChange,
    updateBearingRange,
    updateBearingRangeOnOwnShipChange,
    updateBearingValue,
    updateCenter,
    updateFixType,
    updateListVisibility,
    updatePositionFix,
    updatePositionFixVisibility,
    updatePositionToOwnShip,
    updateRangeValue,
  };
});

export { useProvideToolsStore, useToolsStore };
