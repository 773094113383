<template>
  <svg viewBox="0 1 32 32" fill="none">
    <path d="M8 13L16 21L24 13" stroke="#F6F6F6" stroke-linejoin="round" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
