/* eslint-disable no-unused-vars */
import { LngLatPoint, MeterPoint } from '@signi/ecdis/src/lib/types';

export interface EblVrm {
  bearing: number;
  eblVisible: boolean;
  id: string;
  index?: string;
  range: number;
  reference: string;
  trueBearing: boolean;
  vrmVisible: boolean;
}

export interface ParallelIndexLine {
  bearing: number;
  id: string;
  range: number;
  trueBearing: boolean;
  visible: boolean;
}

export interface PositionFixMarker {
  id: string;
  isEditMode: boolean;
  LOPs: LOP[];
  positionFix?: MeterPoint;
  positionFixTimestamp?: string;
  selectedFixType: PositionFixType;
  showLOPs: boolean;
  visible: boolean;
}

export enum PositionFixType {
  Radar = 'Radar',
  Visual = 'Visual',
  DeadReckoning = 'Dead reckoning',
}

export interface LOP {
  bearing?: number;
  bearingRelativeValue: number;
  bearingType: Bearing;
  center: MeterPoint;
  id: string;
  isAlignToHeading: boolean;
  isSelected: boolean;
  isSetToOwnShip: boolean;
  location: LngLatPoint;
  range?: number;
  type: 'Bearing' | 'Range';
}

export type Bearing = 'TRUE' | 'RELATIVE';
