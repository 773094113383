import { v4 as uuid } from 'uuid';
import { computed, reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { MeterPoint } from '../types';
import { persist } from '../utils/persist';

export interface LegacyEblVrm {
  index: string;
  id: string;
  eblVisible: boolean;
  eblValue: number; // Degrees
  vrmValue: number; // NM
  vrmVisible: boolean;
  center: MeterPoint;
  isSetToOwnShip: boolean;
  isAlignToHeading: boolean;
  eblBearing: EblBearing;
  isSelected: boolean;
  eblRelativeValue: number;
}

export type EblBearing = 'TRUE' | 'RELATIVE';

type State = {
  eblVrms: LegacyEblVrm[];
};

export const eblVrmCtx = defineContext(() => {
  const state: State = persist(
    'eblVrm.v1',
    reactive({
      eblVrms: [],
    }),
  );

  const createEblVrm = (center: MeterPoint) => {
    state.eblVrms.push({
      index: getIndexOfEblVrm(),
      id: uuid(),
      eblValue: 0,
      eblVisible: true,
      vrmValue: 1.0,
      vrmVisible: true,
      center,
      isSetToOwnShip: false,
      isAlignToHeading: false,
      eblBearing: 'TRUE',
      isSelected: false,
      eblRelativeValue: 0,
    });
  };

  const getIndexOfEblVrm = () => {
    const eblVrmIndexes = state.eblVrms.map((data) => Number(data.index));
    let index;
    for (index = 1; index <= eblVrmIndexes.length; index++) {
      if (!eblVrmIndexes.includes(index)) {
        break;
      }
    }
    return String(index);
  };

  const findEblVrm = (eblVrmId: string) => state.eblVrms.find((eblVrm) => eblVrm.id === eblVrmId);

  const deleteEblVrm = (eblVrmId: string) => {
    state.eblVrms = state.eblVrms.filter((eblVrm) => eblVrm.id !== eblVrmId);
  };

  const resetEblVrmValue = (eblVrmId: string, heading: number) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.eblValue = 0;
    eblVrm.vrmValue = 1;
    eblVrm.eblRelativeValue = eblVrm.eblBearing === 'RELATIVE' ? parseFloat(heading.toFixed(1)) : 0;
  };

  const toggleEblVisibility = (eblVrmId: string) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.eblVisible = !eblVrm.eblVisible;
  };

  const toggleVrmVisibility = (eblVrmId: string) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.vrmVisible = !eblVrm.vrmVisible;
  };

  const anyVisible = computed(() => state.eblVrms.some((eblVrm) => eblVrm.eblVisible || eblVrm.vrmVisible));

  const canAddNew = computed(() => state.eblVrms.length < 10);

  const toggleAnyVisibility = () => {
    const visibility = !anyVisible.value;
    state.eblVrms.map((data) => {
      data.eblVisible = visibility;
      data.vrmVisible = visibility;
    });
  };

  const updateCenter = (eblVrmId: string, center: MeterPoint) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.center = center;
  };

  // TODO: use computed prop instead of redundant state (eblRelativeValue)
  const updateEblValue = (
    eblVrmId: string,
    value: number,
    heading: number,
    isInputByUser: boolean,
    isDraggedByUser: boolean,
  ) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    if (eblVrm.eblBearing === 'RELATIVE') {
      if (isInputByUser) {
        eblVrm.eblValue = value;
        eblVrm.eblRelativeValue = parseFloat((heading + eblVrm.eblValue).toFixed(1));
      }
      if (isDraggedByUser) {
        eblVrm.eblRelativeValue = value;
        const relativeToHead = value - heading;
        const relativeVal = relativeToHead > 0 ? relativeToHead : 360 + relativeToHead;
        eblVrm.eblValue = parseFloat(relativeVal.toFixed(1));
      }
    } else {
      eblVrm.eblValue = eblVrm.eblRelativeValue = value;
    }
    setIsAlignToHeading(eblVrm, heading);
  };

  const updateVrmValue = (eblVrmId: string, value: number) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.vrmValue = value;
  };

  const updatePositionToOwnShip = (eblVrmId: string, position: MeterPoint) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.isSetToOwnShip = true;
    eblVrm.center = position;
  };

  const setPositionToGeo = (eblVrmId: string) => {
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.isSetToOwnShip = false;
  };
  const onEditStart = (eblVrmId: string) => {
    state.eblVrms.map((eblVrm) => (eblVrm.isSelected = false));
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.isSelected = true;
  };

  const setAlignToHeading = (eblVrmId: string, heading: number) => {
    state.eblVrms.map((eblVrm) => (eblVrm.isSelected = false));
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    eblVrm.isAlignToHeading = !eblVrm.isAlignToHeading;
    setEblAndEblRelativeValue(eblVrm, heading);
  };

  const updateEblVrm = (eblVrm: LegacyEblVrm, heading: number) => {
    const current = findEblVrm(eblVrm.id);
    if (current) {
      Object.assign(current, eblVrm);
    }

    // Recalc RelativeEblValue so that Konva layer is updated correctly
    updateEblValue(eblVrm.id, eblVrm.eblValue, heading, true, false);
  };

  const updateEblVrmOnOwnShipChange = (shipPosition: MeterPoint) => {
    state.eblVrms.map((eblVrm) => {
      if (eblVrm.isSetToOwnShip) eblVrm.center = shipPosition;
    });
  };
  const updateAlignHeadingOnOwnShipChange = (heading: number) => {
    state.eblVrms.map((eblVrm) => {
      const headingValue = parseFloat(heading.toFixed(1));
      if (eblVrm.eblBearing == 'RELATIVE') {
        eblVrm.eblRelativeValue = eblVrm.eblValue + headingValue;
      }
      setIsAlignToHeading(eblVrm, headingValue);
    });
  };
  const updateAlignHeadingOnEblChange = (eblVrmId: string, heading: number) => {
    state.eblVrms.map((eblVrm) => (eblVrm.isSelected = false));
    const eblVrm = findEblVrm(eblVrmId);
    if (!eblVrm) return;
    setIsAlignToHeading(eblVrm, parseFloat(heading.toFixed(1)));
  };

  const setEblBearingValue = (id: string, value: EblBearing, heading: number) => {
    const eblVrm = findEblVrm(id);
    if (!eblVrm) return;
    eblVrm.eblBearing = value;
    setEblAndEblRelativeValue(eblVrm, heading);
    setIsAlignToHeading(eblVrm, heading);
  };

  const setIsAlignToHeading = (eblVrm: LegacyEblVrm, heading: number) => {
    eblVrm.isAlignToHeading =
      eblVrm.eblBearing !== 'RELATIVE' ? eblVrm.eblValue === heading : eblVrm.eblRelativeValue == heading;
  };

  const setEblAndEblRelativeValue = (eblVrm: LegacyEblVrm, heading: number) => {
    const isRelative = eblVrm.eblBearing === 'RELATIVE';
    if (eblVrm.isAlignToHeading) {
      eblVrm.eblValue = isRelative ? 0 : parseFloat(heading.toFixed(1));
    }
    eblVrm.eblRelativeValue =
      eblVrm.eblBearing === 'RELATIVE' ? parseFloat((heading + eblVrm.eblValue).toFixed(1)) : eblVrm.eblValue;
  };
  const setSelectedEblVrm = (id: string) => {
    onEditStart(id);
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };
  const getSelectedEblVrm = () => {
    return state.eblVrms.find((eblVrm) => eblVrm.isSelected);
  };
  return {
    state: readonly(state),
    createEblVrm,
    deleteEblVrm,
    resetEblVrmValue,
    toggleEblVisibility,
    toggleVrmVisibility,
    updateCenter,
    updateEblValue,
    updateVrmValue,
    anyVisible,
    toggleAnyVisibility,
    findEblVrm: (eblVrmId: string) => {
      const eblVrm = findEblVrm(eblVrmId);
      return eblVrm ? readonly(eblVrm) : undefined;
    },
    canAddNew,
    updatePositionToOwnShip,
    setPositionToGeo,
    onEditStart,
    setAlignToHeading,
    updateEblVrmOnOwnShipChange,
    updateAlignHeadingOnOwnShipChange,
    updateAlignHeadingOnEblChange,
    setEblBearingValue,
    setSelectedEblVrm,
    getSelectedEblVrm: () => {
      const eblVrm = getSelectedEblVrm();
      return eblVrm ? readonly(eblVrm) : undefined;
    },
    updateEblVrm,
  };
});
