<template>
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 0V15.5H10" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 16px;
  height: 16px;
}
</style>
