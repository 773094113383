import { computed, watch } from 'vue';
import { SimulationClient } from './generated/Simulation';
import user from './user';
import { fetch } from './utils/http';
import { useAsync } from './utils/useAsync';

// The fallback to process.env is for CommonJS (Storybook)
const env = import.meta.env ?? process.env;

const simulationClient = new SimulationClient(env.VUE_APP_API_BASE_URL, {
  fetch,
});

const sessionId = computed(() => user.state.sessionId);
let timer: number;

export function startHeartbeat(): void {
  timer = window.setInterval(() => {
    if (sessionId.value !== undefined) {
      const { error } = useAsync(() => simulationClient.session_Heartbeat(sessionId.value as string));

      watch(error, (val) => {
        if (val !== undefined) {
          user.resetSession();
          user.setUserError(error?.value?.detail);
          clearInterval(timer);
        }
      });
    }
  }, 30000);
}

export function markAsComplete(): void {
  if (sessionId.value !== undefined) {
    clearInterval(timer);
    simulationClient.session_Completed(sessionId.value);
    user.resetSession();
  }
}
