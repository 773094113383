<template>
  <div class="panDraggablePanel" :style="translateStyle">
    <div @pointerdown="startDrag">
      <slot name="header"></slot>
    </div>
    <slot name="main"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const translateX = ref(0);
    const translateY = ref(0);

    let lastX: number, lastY: number;

    const stopDrag = () => {
      document.onpointermove = null;
      document.onpointerup = null;
    };

    const dragged = (e: PointerEvent) => {
      e.preventDefault();
      const deltaX = lastX - e.clientX;
      const deltaY = lastY - e.clientY;
      translateX.value = Math.round(translateX.value - deltaX);
      translateY.value = Math.round(translateY.value - deltaY);
      lastX = e.clientX;
      lastY = e.clientY;
    };

    const startDrag = (e: PointerEvent) => {
      lastX = e.clientX;
      lastY = e.clientY;
      document.onpointermove = dragged;
      document.onpointerup = stopDrag;
    };

    const translateStyle = computed(() => ({
      transform: `translate(${translateX.value}px, ${translateY.value}px)`,
    }));

    return {
      translateStyle,
      startDrag,
    };
  },
});
</script>

<style>
.panDraggablePanel {
  position: absolute;
}
</style>
