<template>
  <div class="panDropdown" :class="{ open, disabled }" :tabindex="1" @blur="open = false">
    <div class="selected" @click="toggleOpen">
      <slot></slot>
      <icon-arrow v-if="showChevron" class="chevron" :class="{ open }" />
    </div>
    <transition name="fade">
      <div v-show="open" class="scrollbar contents" :style="contentsStyle" @click="open = false">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';

export type DropdownLayout = {
  right: string;
  top: string;
  maxHeight: string;
};

export default defineComponent({
  props: {
    rightAlign: { type: Boolean, default: false },
    maxHeight: { type: String, default: '350px' },
    disabled: { type: Boolean, default: false },
    dropsDownwards: { type: Boolean, default: false },
    showChevron: { type: Boolean, default: true },
    position: { type: Object as PropType<DropdownLayout>, default: undefined },
  },
  setup(props) {
    const open = ref(false);

    const toggleOpen = () => {
      if (!props.disabled) {
        open.value = !open.value;
      }
    };
    const contentsStyle = computed(() => {
      if (!props.position) {
        return {
          maxHeight: props.maxHeight,
          ...(props.dropsDownwards ? { top: '100%' } : { bottom: '100%' }),
          ...(props.rightAlign ? { right: '-1px' } : { left: 0 }),
        };
      } else {
        return props.position;
      }
    });
    return {
      open,
      toggleOpen,
      contentsStyle,
    };
  },
});
</script>

<style scoped>
.panDropdown {
  position: relative;
  outline: none;
}
.panDropdown.open {
  background: black;
}
.panDropdown.disabled .selected ::v-deep(*) {
  color: var(--primary-disabled);
}
.panDropdown.open .selected ::v-deep(*) {
  color: var(--highlight);
}
.selected {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
}
.chevron.open {
  transform: rotate(-180deg);
}
.contents {
  position: absolute;
  background: #26292e;
  min-width: calc(100% + 1px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
