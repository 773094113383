import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { ContextType } from 'vue-context-composition';
import { viewportCtx } from '../../contexts/viewport';

export function addVectorArrowHead(
  endVector: Vector2d,
  isArrowHeadVisible: boolean,
  vectorArrowHeadGroup: Konva.Group,
  rotationAngle: number,
  { transformToAbsolute }: ContextType<typeof viewportCtx>,
  stroke = 'black',
): void {
  const { x, y } = transformToAbsolute({ mX: endVector.x, mY: endVector.y });
  const courseVectorArrowSubgroup = new Konva.Group({
    visible: isArrowHeadVisible,
    rotation: rotationAngle,
    position: { x, y },
  });
  const courseVectorArrow = new Konva.Line({
    points: [0, 10, 0, 0, 10, 0],
    stroke: stroke,
    strokeWidth: 2,
    rotation: 45,
    strokeScaleEnabled: false,
  });
  courseVectorArrowSubgroup.add(courseVectorArrow);
  vectorArrowHeadGroup.add(courseVectorArrowSubgroup);
}
