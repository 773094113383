<template>
  <div class="inlineEditableText" @click="startEdit">
    <div v-show="isEditable" class="textbox">
      <input
        ref="textbox"
        v-model="edited"
        :style="{ fontSize: fontSize + 'px' }"
        type="text"
        :size="size"
        :maxlength="36"
        :placeholder="placeholder"
        @change="changed"
        @blur="stopEdit"
        @keyup.enter="stopEdit"
      />
    </div>
    <ecdis-value
      v-show="!isEditable"
      :style="{ 'font-size': fontSize + 'px', 'white-space': whiteSpace }"
      class="param"
    >
      {{ value }}
    </ecdis-value>
  </div>
</template>

<script lang="ts">
import { Ref, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    paramKey: {
      type: String,
      required: true,
    },
    paramValue: {
      type: String,
      default: '-',
    },
    size: {
      type: Number,
      default: 7,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: Number,
      default: 16,
      required: false,
    },
    whiteSpace: {
      type: String,
      default: 'nowrap',
      required: false,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const textbox: Ref<HTMLInputElement | undefined> = ref();
    const isEditable = ref(false);
    const value = computed(() => props.paramValue || '-');
    const edited = ref();

    const startEdit = () => {
      edited.value = value.value;
      isEditable.value = props.editMode;
      window.setTimeout(() => {
        textbox.value?.focus();
      }, 0);
    };
    const stopEdit = () => (isEditable.value = false);
    const changed = () => emit('changed', { id: props.paramKey, value: `${edited.value}` });

    return { isEditable, value, edited, startEdit, stopEdit, changed, textbox };
  },
});
</script>

<style scoped>
.inlineEditableText {
  display: flex;
  flex: 1;
  align-items: center;
  height: 48px;
}

.param {
  padding: 0 8px;
  user-select: text;
}

input {
  height: 47px;
  background-color: var(--input_background-default);
  color: var(--text_primary);
  border: transparent;
  outline: 1px solid #000000;
  padding-left: 8px;
}

input[type='text']::-webkit-inner-spin-button,
input[type='text']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='text'] {
  -moz-appearance: textfield;
  user-select: text;
}
</style>
