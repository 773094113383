<template>
  <pan-row class="wp head">
    <ecdis-value class="hand text-margin" @click="changeSort('Number')"
      >ID <icon-down v-if="sortParam === 'Number'" :class="{ 'up-arrow': sortOrder === 1 }"
    /></ecdis-value>
    <ecdis-value class="hand" @click="changeSort('Range')"
      >Range <icon-down v-if="sortParam === 'Range'" :class="{ 'up-arrow': sortOrder === 1 }"
    /></ecdis-value>
    <ecdis-value class="hand" @click="changeSort('Bearing')"
      >Bearing <icon-down v-if="sortParam === 'Bearing'" :class="{ 'up-arrow': sortOrder === 1 }"
    /></ecdis-value>
    <ecdis-value class="hand" @click="changeSort('Cpa')"
      >CPA <icon-down v-if="sortParam === 'Cpa'" :class="{ 'up-arrow': sortOrder === 1 }"
    /></ecdis-value>
    <ecdis-value class="hand" @click="changeSort('TCpa')"
      >TCPA <icon-down v-if="sortParam === 'TCpa'" :class="{ 'up-arrow': sortOrder === 1 }"
    /></ecdis-value>
    <div></div>
  </pan-row>
  <pan-scrollable class="target">
    <div v-for="target in targets" :key="target.Number">
      <pan-row>
        <div class="selectedTarget">
          <div
            v-show="isTargetSelected(target.Number)"
            class="active"
            :class="target.DangerState === 'None' ? 'default-active-background' : 'danger-active-background'"
            @click="setSelectedTarget(target.Number)"
          ></div>
        </div>
        <icon-ais-sart v-if="target.IsSart" class="icon" @click="setSelectedTarget(target.Number)"></icon-ais-sart>
        <icon-ais
          v-else-if="target.Origin === 'Ais' && !shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <icon-radar
          v-else-if="target.Origin === 'Radar' && !shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <icon-target-ais-combined
          v-else-if="target.Origin === 'AisCombined' && !shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <icon-radar-combined-sleeping
          v-else-if="target.Origin === 'RadarCombined' && shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <icon-ais-combined-sleeping
          v-else-if="target.Origin === 'AisCombined' && shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <icon-ais-sleeping
          v-else-if="target.Origin === 'Ais' && shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <icon-target-radar-combined
          v-else-if="target.Origin === 'RadarCombined' && !shouldShowSleepingNotation(target)"
          class="icon"
          @click="setSelectedTarget(target.Number)"
        />
        <div class="target-name" @click="setSelectedTarget(target.Number)">
          <ecdis-value>{{ String(target.Number).padStart(3, '0') }}</ecdis-value>
        </div>
        <div class="title" @click="setSelectedTarget(target.Number)">
          <ecdis-value>
            <span :class="getColorStyle(target)">{{ twoDecimalFormatter((target.Range ?? 0) * meterInNm) }}</span
            ><ecdis-unit>NM</ecdis-unit>
          </ecdis-value>
        </div>
        <div class="title" @click="setSelectedTarget(target.Number)">
          <ecdis-value>
            <span :class="getColorStyle(target)">{{ twoDecimalFormatter((target.Bearing ?? 0) * radInDeg) }}</span>
            <ecdis-unit>°</ecdis-unit>
          </ecdis-value>
        </div>
        <div class="title" @click="setSelectedTarget(target.Number)">
          <ecdis-value>
            <span :class="getColorStyle(target)">{{ twoDecimalFormatter((target.Cpa ?? 0) * meterInNm) }}</span>
            <ecdis-unit>NM</ecdis-unit>
          </ecdis-value>
        </div>
        <div class="title" @click="setSelectedTarget(target.Number)">
          <ecdis-value>
            <span :class="getColorStyle(target)">{{ timeFormatter(target.TCpa ?? 0) }}</span>
          </ecdis-value>
        </div>
        <icon-pin class="pin" :disabled="!isGotoIconEnabled(target)" @click="goToTarget(target)" />
        <div class="selected" @click="updateExpanded(target.Number)">
          <slot></slot>
          <icon-arrow class="chevron" :class="{ open: isExpanded(target.Number) }" />
        </div>
      </pan-row>
      <div v-if="isExpanded(target.Number)" :class="{ open: isExpanded(target.Number) }">
        <template v-if="isTargetRadarOrRadarCombined(target.Origin)">
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Latitude</ecdis-unit>
              <ecdis-value>
                {{ formatPositionDegree(target.Latitude * radInDeg, false) }}
                {{ formatUnitLat(target.Latitude) }}
              </ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>Longitude</ecdis-unit>
              <ecdis-value>
                {{ formatPositionDegree(target.Longitude * radInDeg, true) }}
                {{ formatUnitLng(target.Longitude) }}
              </ecdis-value>
            </div>
          </pan-row>
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Course</ecdis-unit>
              <ecdis-value>{{ +twoDecimalFormatter(target.Course * radInDeg) % 360 }}°</ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>SOG</ecdis-unit>
              <ecdis-value>{{ twoDecimalFormatter(target.Speed * mpsInKnots) }} kn</ecdis-value>
            </div>
          </pan-row>
        </template>
        <template v-if="isTargetAisOrAisCombined(target.Origin)">
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Ship name</ecdis-unit>
              <ecdis-value>{{ target.Name }}</ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>Call sign</ecdis-unit>
              <ecdis-value> {{ target.CallSign ? target.CallSign : '-' }}</ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>MMSI</ecdis-unit>
              <ecdis-value> {{ target.MMSI }}</ecdis-value>
            </div>
          </pan-row>
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Latitude</ecdis-unit>
              <ecdis-value>
                {{ formatPositionDegree(target.Latitude * radInDeg, false) }}
                {{ formatUnitLat(target.Latitude) }}
              </ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>Longitude</ecdis-unit>
              <ecdis-value>
                {{ formatPositionDegree(target.Longitude * radInDeg, true) }}
                {{ formatUnitLng(target.Longitude) }}
              </ecdis-value>
            </div>
            <div class="title">
              <ecdis-unit>Postion acc</ecdis-unit>
              <ecdis-value> {{ target.PositionAccuracy }}</ecdis-value>
            </div>
          </pan-row>
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Course</ecdis-unit>
              <ecdis-value>{{ +twoDecimalFormatter(target.Course * radInDeg) % 360 }}°</ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>SOG</ecdis-unit>
              <ecdis-value>{{ twoDecimalFormatter(target.Speed * mpsInKnots) }} kn</ecdis-value>
            </div>
            <div class="title">
              <ecdis-unit>Heading / ROT</ecdis-unit>
              <ecdis-value class="sub">
                {{ twoDecimalFormatter(formatDegrees(target.Heading * radInDeg)) }}° /
                {{ twoDecimalFormatter(target.RateOfTurn * 60 * radInDeg) }}°/min
              </ecdis-value>
            </div>
          </pan-row>
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Ship length</ecdis-unit>
              <ecdis-value>{{ target.ShipLength }}</ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>Ship width</ecdis-unit>
              <ecdis-value>{{ target.ShipWidth }}</ecdis-value>
            </div>
            <div class="title">
              <ecdis-unit>Draught</ecdis-unit>
              <ecdis-value>{{ target.MaxDraught }} </ecdis-value>
            </div>
          </pan-row>
          <pan-row class="row-padding">
            <div class="title row-width">
              <ecdis-unit>Navigation status</ecdis-unit>
              <ecdis-value>{{ target.NavigationalStatus }}</ecdis-value>
            </div>
            <div class="title row-width">
              <ecdis-unit>Destination</ecdis-unit>
              <ecdis-value>{{ target.Destination ? target.Destination : '-' }}</ecdis-value>
            </div>
            <div class="title eta-width">
              <ecdis-unit>ETA (dd-mm hh-mm)</ecdis-unit>
              <ecdis-value>{{ target?.ETA?.replaceAll(/\/|:/g, '-') }} </ecdis-value>
            </div>
          </pan-row>
        </template>
      </div>
    </div>
  </pan-scrollable>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useContext } from 'vue-context-composition';
import { controlModesCtx } from '../../../contexts/controlModes';
import { targetDataCtx } from '../../../contexts/targetData';
import { targetSettingsCtx } from '../../../contexts/targetSettings';
import { viewportCtx } from '../../../contexts/viewport';
import { DangerState, TargetData } from '../../../types';
import { meterInNm, mpsInKnots, radInDeg } from '../../../utils/conversions';
import {
  formatPositionDegree,
  formatUnitLat,
  formatUnitLng,
  timeFormatter,
  twoDecimalFormatter,
} from '../../../utils/formatters';
import { Sort, SortOrder } from '../../../utils/sorter';
import { isTargetAisOrAisCombined, isTargetRadarOrRadarCombined } from '../../../utils/targetUtils';
import { calculateBounds } from '../../../utils/tools';

export default defineComponent({
  emits: ['emitEvent'],
  setup() {
    const { getTargetData, setSelectedTarget, isTargetSelected, updateExpanded, isExpanded } =
      useContext(targetDataCtx);
    const { shouldShowSleepingTargets } = useContext(targetSettingsCtx);
    const { showInViewport, selectRange } = useContext(viewportCtx);
    const { state: controlModesState, setBrowse } = useContext(controlModesCtx);
    const sortOrder = ref(SortOrder.Ascending);
    const sortParam = ref('Range' as keyof TargetData);
    const targets = computed(() => {
      const targetList = getTargetData();
      return Sort(targetList ?? [], sortParam.value, sortOrder.value);
    });
    const goToTarget = (target: TargetData) => {
      if (isGotoIconEnabled(target)) {
        setBrowse(true);
        const bounds = calculateBounds({ lat: target.Latitude, lng: target.Longitude });
        showInViewport(bounds);
        selectRange(1.5);
      }
      setSelectedTarget(target.Number);
    };
    const formatDegrees = (readingInDegrees: number) => {
      return readingInDegrees < 0 ? 360 - Math.abs(readingInDegrees) : readingInDegrees;
    };
    const changeSort = (param: string) => {
      if (sortParam.value === param) {
        sortOrder.value = sortOrder.value === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
      } else {
        sortOrder.value = SortOrder.Ascending;
      }
      sortParam.value = param as keyof TargetData;
    };
    const getColorStyle = (target: TargetData): string => {
      if (target.DangerState === DangerState.None) {
        return 'value-color';
      }
      return 'danger-value-color';
    };
    const isGotoIconEnabled = (target: TargetData): boolean => {
      return shouldShowSleepingTargets()
        ? controlModesState.showTargets
        : controlModesState.showTargets && (!target.Sleeping || (target.Sleeping && (target.ForceAwake ?? false)));
    };
    const shouldShowSleepingNotation = (targetData: TargetData): boolean => {
      return targetData.Sleeping && (!targetData.ForceAwake ?? false);
    };
    return {
      targets,
      twoDecimalFormatter,
      goToTarget,
      formatPositionDegree,
      formatUnitLat,
      formatUnitLng,
      radInDeg,
      mpsInKnots,
      meterInNm,
      setSelectedTarget,
      isTargetSelected,
      formatDegrees,
      changeSort,
      sortParam,
      sortOrder,
      updateExpanded,
      isExpanded,
      getColorStyle,
      timeFormatter,
      isTargetAisOrAisCombined,
      isTargetRadarOrRadarCombined,
      isGotoIconEnabled,
      shouldShowSleepingNotation,
    };
  },
});
</script>
<style scoped>
.row-width {
  width: 33% !important;
}

.value-color {
  color: #4a90e2;
}

.danger-value-color {
  color: rgb(230, 15, 15);
}

.wp > * {
  display: flex;
  align-items: center;
  width: 72px;
  padding-left: 40px;
  color: #a2aab9;
}
.wp > :nth-of-type(2) {
  width: 96px;
  padding-left: 38px;
}
.wp > :nth-of-type(3) {
  width: 108px;
  padding-left: 32px;
}
.wp > :nth-of-type(4) {
  width: 86px;
  padding-left: 16px;
}
.wp > :nth-of-type(5) {
  width: 100px;
  padding-left: 20px;
}

.wp.head {
  flex-shrink: 0;
  background: var(--background_base_main);
}
.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
}

.target {
  background: var(--background_layer_below);
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
}

.title {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 102px;
}

.pin {
  margin: 0 8px;
}

.target-name {
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}
.row-padding {
  padding: 8px 0 8px 8px;
}

.active {
  width: 4px;
  height: 46px;
}

.default-active-background {
  background: var(--button_text-selected);
}

.danger-active-background {
  background: rgb(230, 15, 15);
}

.sub {
  min-width: 168px;
}
.eta-width {
  min-width: 146px;
}
.hand {
  cursor: pointer;
}
.up-arrow {
  transform: rotate(180deg);
}
.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
}
.selected {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chevron.open {
  transform: rotate(-180deg);
}
.selectedTarget {
  width: 5px;
}
.icon {
  width: 42px;
  height: 42px;
}
.text-margin {
  margin-left: 8px;
}
</style>
