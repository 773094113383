import { computed, reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';

interface SafetySetting {
  label: string;
  value: string | number | boolean;
}

type State = {
  settings: Record<string, SafetySetting>;
};

const defaultSettings: Record<string, SafetySetting> = {
  depthShades: { label: 'Depth shades', value: false },
  safetyDepth: { label: 'Safety depth', value: 30 },
  safetyContour: { label: 'Safety contour', value: 30 },
  shallowContour: { label: 'Shallow contour', value: 10 },
  deepContour: { label: 'Deep contour', value: 50 },
};

export const safetySettingCtx = defineContext(() => {
  const state: State = reactive({
    settings: { ...defaultSettings },
  });

  const values = computed(() =>
    Object.entries(state.settings).reduce<Record<string, string | number | boolean>>((acc, [id, setting]) => {
      acc[id] = setting.value;
      return acc;
    }, {}),
  );

  const updateSettingValue = (id: keyof State['settings'], value: string | number | boolean) => {
    state.settings[id].value = value;
  };

  return {
    state: readonly(state),
    updateSettingValue,
    values,
  };
});
