import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { PixelPoint } from '../types';

type State = {
  x: number;
  y: number;
  data: Array<ToolTipData>;
  splitInColumns: boolean;
};
export type ToolTipData = {
  title: string;
  value: string;
  unit?: string;
  isHeader?: boolean;
};
export const toolTipCtx = defineContext(() => {
  const state: State = reactive({
    visible: false,
    x: 0,
    y: 0,
    data: [],
    splitInColumns: false,
  });
  const show = (position: PixelPoint, data: ToolTipData[], splitInColumns = false) => {
    state.x = position.x;
    state.y = position.y;
    state.data = data;
    state.splitInColumns = splitInColumns;
  };
  const hide = () => {
    state.x = 0;
    state.y = 0;
    state.data = [];
  };
  return {
    state: readonly(state),
    show,
    hide,
  };
});
