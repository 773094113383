import { v4 as uuid } from 'uuid';
import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';

type State = {
  messages: Array<Message>;
};

export type Message = {
  id?: string;
  text: string;
  type: 'info' | 'warning';
};

export const messageCtx = defineContext(() => {
  const state: State = reactive({
    messages: [],
  });

  const pushMessage = (msg: Message) => {
    const id = uuid();
    const message = { id, ...msg };
    state.messages = [message, ...state.messages];

    window.setTimeout(() => {
      clearMessage(id);
    }, 6000);
  };
  const clearMessage = (id: string) => (state.messages = state.messages.filter((msg) => msg.id !== id));

  return {
    state: readonly(state),
    pushMessage,
  };
});
