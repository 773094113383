<template>
  <svg viewBox="0 0 32 32" fill="none">
    <path d="M9.5 10.5L21.5 22.5" stroke="#F6F6F6" />
    <path d="M21.5 10.5L9.5 22.5" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
