<template>
  <pan-row class="row-head">
    <div class="left-cell" @click="changeSort('shipName')">
      <pan-static-text class="hand">Ship name</pan-static-text>
      <icon-down v-if="sortParam === 'shipName'" class="hand" :class="{ 'up-arrow': sortOrder === 1 }" />
    </div>
    <div class="right-cell" @click="changeSort('shipId')">
      <pan-static-text class="hand">Ship code</pan-static-text>
      <icon-down v-if="sortParam === 'shipId'" class="hand" :class="{ 'up-arrow': sortOrder === 1 }" />
    </div>
  </pan-row>

  <pan-scrollable v-if="vessels">
    <pan-row
      v-for="vessel in vessels"
      :key="vessel.id"
      class="row-secondary"
      :class="{ active: activeVessel?.shipId === vessel.shipId }"
      :data-test-id="'vessel-' + vessel.id"
      @click="changeActiveVessel(vessel)"
    >
      <div class="left-cell">
        <div :title="vessel.shipName">{{ vessel.shipName }}</div>
      </div>
      <div class="right-cell">
        <span>{{ vessel.shipId }}</span>
      </div>
    </pan-row>
  </pan-scrollable>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useContext } from 'vue-context-composition';
import { vesselCtx } from '../../contexts/vessels';
import { Vessel } from '../../generated/ProductCatalog';
import { Sort, SortOrder } from '../../utils/sorter';

export default defineComponent({
  props: {
    searchText: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { state, loadAllVessels, loadToken, changeActiveVessel } = useContext(vesselCtx);

    const sortOrder = ref(SortOrder.Ascending);
    const sortParam = ref('shipName' as keyof Vessel);
    const vessels = computed(() => {
      const searchText = props.searchText.toLowerCase();
      const filtered = state.vessels?.filter(
        (vessel) =>
          vessel.shipName?.toLowerCase().includes(searchText) || vessel.shipId?.toLowerCase().includes(searchText),
      );
      const sorted = Sort(filtered ?? [], sortParam.value, sortOrder.value);
      if (!state.activeVessel) {
        changeActiveVessel(sorted[0]);
      }
      return sorted;
    });
    const activeVessel = computed(() => state.activeVessel);

    const changeSort = (param: string) => {
      if (sortParam.value === param) {
        sortOrder.value = sortOrder.value === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
      } else {
        sortOrder.value = SortOrder.Ascending;
      }

      sortParam.value = param as keyof Vessel;
    };

    // load vessel data and get token
    loadAllVessels();
    loadToken();

    return {
      vessels,
      activeVessel,
      sortOrder,
      sortParam,
      changeActiveVessel,
      changeSort,
    };
  },
});
</script>

<style scoped>
.row-head {
  width: 100%;
  font-size: 16px;
  background-color: var(--background_base_main);
}

.row-secondary {
  width: 100%;
  font-size: 14px;
  background-color: var(--background_layer_below);
  cursor: pointer;
}

.row-secondary:hover .left-cell,
.row-secondary:hover .right-cell {
  background-color: var(--button_background-selected);
  color: var(--button_text-selected);
}

.active .left-cell,
.active .right-cell {
  background-color: var(--button_background-selected);
  color: var(--button_text-selected);
  cursor: default;
}

.left-cell {
  width: 60%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--text_main);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-cell {
  width: 40%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--text_static);
}

.hand {
  cursor: pointer;
}

.up-arrow {
  transform: rotate(180deg);
}
</style>
