<template>
  <hui-tab-group :default-index="initialTabIndex">
    <hui-tab-list class="flex" :class="{ 'border-b border-background_tile_border': !hasActiveApp }">
      <hui-tab v-if="isExtension" v-slot="{ selected }" title="Targets" @click="setActiveApp('targets')">
        <signi-icon class="tab-icon" :class="{ '!text-feedback_text': selected && hasActiveApp }" name="TargetsIcon" />
      </hui-tab>

      <hui-tab v-slot="{ selected }" title="Routes" @click="setActiveApp('routes')">
        <signi-icon class="tab-icon" :class="{ '!text-feedback_text': selected && hasActiveApp }" name="RoutesIcon" />
      </hui-tab>

      <hui-tab v-slot="{ selected }" title="Tools" @click="setActiveApp('tools')">
        <signi-icon class="tab-icon" :class="{ '!text-feedback_text': selected && hasActiveApp }" name="ToolsIcon" />
      </hui-tab>

      <hui-tab v-slot="{ selected }" title="Mariners notes" @click="setActiveApp('marinersNote')">
        <signi-icon
          class="tab-icon"
          :class="{ '!text-feedback_text': selected && hasActiveApp }"
          name="MarinersNotesIcon"
        />
      </hui-tab>

      <div class="flex-1"></div>

      <hui-tab
        v-slot="{ selected }"
        class="border-l border-background_tile_border"
        title="Safety depths"
        @click="setActiveApp('chart')"
      >
        <signi-icon class="tab-icon" :class="{ '!text-feedback_text': selected && hasActiveApp }" name="SafetyIcon" />
      </hui-tab>

      <hui-tab v-slot="{ selected }" title="Own ship" @click="setActiveApp('ownShip')">
        <signi-icon class="tab-icon" :class="{ '!text-feedback_text': selected && hasActiveApp }" name="ShipIcon" />
      </hui-tab>

      <hui-tab v-if="isExtension" title="Alerts" @click="setActiveApp('alerts')">
        <signi-alert-icon :alerts="alerts" :blink-sync="blinkSync" :muted="alertsMuted" />
      </hui-tab>
    </hui-tab-list>

    <hui-tab-panels
      v-if="hasActiveApp"
      class="flex flex-1 flex-col bg-background_tile"
      :class="{ 'overflow-hidden': scrollPanel }"
    >
      <hui-tab-panel v-if="isExtension" class="top-border flex flex-1 flex-col overflow-hidden">
        <targets-panel @emit-event="$emit('changed', $event)" />
      </hui-tab-panel>
      <hui-tab-panel class="contents">
        <routes-panel class="top-border" @emit-event="$emit('changed', $event)" />
      </hui-tab-panel>
      <hui-tab-panel class="contents">
        <ecdis-tools-panel @markers-updated="$emit('changed', { id: 'positionFixMarkersString', value: $event })" />
      </hui-tab-panel>
      <hui-tab-panel class="top-border flex flex-1 flex-col overflow-hidden">
        <mariners-notes-panel />
      </hui-tab-panel>
      <hui-tab-panel>
        <safety-setting-panel />
      </hui-tab-panel>
      <hui-tab-panel class="flex flex-1 overflow-hidden">
        <own-ship-panel />
      </hui-tab-panel>
      <hui-tab-panel v-if="isExtension" class="flex-1 overflow-hidden">
        <signi-alerts-panel
          :alerts="alerts"
          :blink-sync="blinkSync"
          :muted="alertsMuted"
          @acknowledge="$emit('changed', { id: 'acknowledgeAlert', value: $event })"
          @update:mute="$emit('changed', { id: 'mute', value: $event })"
        />
      </hui-tab-panel>
    </hui-tab-panels>
  </hui-tab-group>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { IAlert, IPositionFixMarker } from '@signi/common';
import { isExtension } from '../../configuration';
import { appsCtx } from '../../contexts/apps';
import { routesCtx } from '../../contexts/routes';
import MarinersNotesPanel from './mariners-notes/MarinersNotesPanel.vue';
import OwnShipPanel from './own-ship-panel/OwnShipPanel.vue';
import RoutesPanel from './routes-panel/RoutesPanel.vue';
import SafetySettingPanel from './SafetySettingPanel.vue';
import TargetsPanel from './targets-panel/TargetsPanel.vue';
import { useToolsStore } from './tools-panel/useToolsStore';

const props = withDefaults(
  defineProps<{
    alerts?: IAlert[];
    alertsMuted?: boolean;
    positionFixMarkers?: IPositionFixMarker[];
  }>(),
  { alerts: () => [], alertsMuted: false, positionFixMarkers: () => [] },
);
defineEmits(['changed']);

const { setMarkers } = useToolsStore()!;

const scrollPanel = computed(() => !appsState.activeApp || ['alerts', 'ownShip'].includes(appsState.activeApp));

const blinkSync = ref(false);
setInterval(() => {
  blinkSync.value = !blinkSync.value;
}, 500);

const { state: routesState, loadRoutes } = useContext(routesCtx);
// load routes when app is initialized
if (!routesState.routes) {
  loadRoutes();
}

const { state: appsState, setActiveApp, hasActiveApp } = useContext(appsCtx);

// Activate tab based on session storage (see appsCtx)
const initialTabIndex = computed(() =>
  appsState.activeApp
    ? (isExtension.value
        ? ['targets', 'routes', 'tools', 'marinersNote', 'chart', 'ownShip', 'alerts']
        : ['routes', 'tools', 'marinersNote', 'chart', 'ownShip'].filter(Boolean)
      ).indexOf(appsState.activeApp)
    : 0,
);

watch(
  () => props.positionFixMarkers,
  (updatedMarkers) => setMarkers(updatedMarkers),
  { deep: true, immediate: true },
);
</script>

<style scoped>
.tab-icon {
  @apply m-3 text-text_main;
}

.top-border {
  /* Temp until we use all panels from @signi/common */
  @apply border-t border-background_tile_border;
}
</style>
