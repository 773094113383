<template>
  <svg viewBox="0 0 32 32" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8027 16L20 8.89034L19.0987 8L11 16L19.0987 24L20 23.1097L12.8027 16Z"
      fill="#EAEAEA"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
