<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3612_168539)">
      <path
        d="M8.80761 18.1924L20.6399 11.6399L13.7574 23.1421L11.2825 20.6673L8.80761 18.1924Z"
        stroke="#A2AAB9"
        stroke-linejoin="round"
      />
      <path d="M19.26 26V24.74L23.572 19.448H19.372V18.3H24.986V19.56L20.59 24.852H25.14V26H19.26Z" fill="#A2AAB9" />
    </g>
    <defs>
      <clipPath id="clip0_3612_168539">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
