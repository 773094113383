import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { routesCtx } from '../../../contexts/routes';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';
import { TurnData } from '../../../generated/ChartServer';
import { Waypoint } from '../../../generated/RouteManagement';
import { degInRad, lngLatToMeter, radInDeg } from '../../../utils/conversions';
import { getScalingFactor } from '../../../utils/mercator';
import { vector } from '../../../utils/vector';

export function addWheelOverLines(
  waypoint: Waypoint,
  wheelOverLineGroup: Konva.Group,
  viewport: ContextType<typeof viewportCtx>,
  route: ContextType<typeof routesCtx>,
  vessel: ContextType<typeof vesselCtx>,
  turnData: TurnData,
  lineColor: string,
): void {
  if (!waypoint || !waypoint.waypointId || !turnData?.startPosition) return;

  const leg = route.findLeg(waypoint.waypointId);
  const prevLeg = route.findPreviousLeg(waypoint.waypointId);

  if (!leg || !leg.toWaypoint || !prevLeg?.course || !vessel.state.activeVessel?.shipTurnAccelerationLength?.value)
    return;

  const start = lngLatToMeter({
    lng: (turnData.startPosition.longitude ?? 0) * radInDeg,
    lat: (turnData.startPosition.latitude ?? 0) * radInDeg,
  });

  const accsLengthVector = vector(0, parseFloat(vessel.state.activeVessel?.shipTurnAccelerationLength?.value)).setAngle(
    Math.PI / 2 - (prevLeg?.course * degInRad + Math.PI),
  );

  const { x: startX, y: startY } = viewport.transformToAbsolute({
    mX: start.mX + accsLengthVector.x * getScalingFactor((turnData.startPosition.latitude ?? 0) * radInDeg),
    mY: start.mY - accsLengthVector.y * getScalingFactor((turnData.startPosition.latitude ?? 0) * radInDeg),
  });

  const length = 80;

  const wolGroup = new Konva.Group({
    x: startX,
    y: startY,
  });
  const line = new Konva.Line({
    points: [0, length / 2, 0, -length / 2],
    stroke: lineColor,
    strokeWidth: 2,
    strokeScaleEnabled: false,
    draggable: false,
    rotation: leg.course,
  });
  wolGroup.add(line);

  wheelOverLineGroup.add(wolGroup);
}
