import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { DisplayGroup } from '../types';
import { persist } from '../utils/persist';

type State = {
  selected: string[];
};

const defaultSelected = [
  'standard',
  'drying',
  'aidstonav',
  'lights',
  'boundaries_and_limits',
  'prohibited',
  'overscale',
  'cautionary_notes',
  'traffic',
  'archipelagic',
  'otherstd',
];

export const displayGroupsCtx = defineContext(() => {
  const state: State = persist(
    'displayGroups.v1',
    reactive({
      selected: [...defaultSelected],
    }),
  );

  const setSelected = (group: DisplayGroup, selected: boolean) => {
    if (group.children) {
      group.children.forEach((group) => setSelected(group, selected));
    }
    if (selected) {
      state.selected.push(group.id);
    } else {
      state.selected = state.selected.filter((id) => id !== group.id);
    }
  };

  const resetSelected = () => {
    state.selected = [...defaultSelected];
  };

  return {
    state: readonly(state),
    setSelected,
    resetSelected,
  };
});
