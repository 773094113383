<template>
  <div class="relative flex h-full w-full" :class="{ extension: isExtension }">
    <div class="sidebar" :class="{ collapsed: !hasActiveApp }">
      <slot name="sidebar" />
    </div>
    <div ref="mainRef" class="relative flex-1 overflow-hidden">
      <ecdis-chart class="chart" />
      <ecdis-context-menu class="absolute" />
      <ecdis-tool-tip />
    </div>
    <pan-toastr class="absolute left-0 right-0" />
    <div class="absolute right-2 top-2">
      <slot name="controlbar" />
    </div>
    <ecdis-chart-loading-indicator class="absolute right-2 bottom-3" />
    <ecdis-chart-object-info-panel class="panel" />
    <ecdis-route-validation-panel class="panel" />
    <ecdis-route-settings-panel class="panel" />
    <ecdis-target-settings-panel class="panel" @emit-event="$emit('changed', $event)" />
    <ecdis-critical-points-panel class="panel" />
    <ecdis-monitoring-panel class="panel" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useContext } from 'vue-context-composition';
import { useProvideToolsStore } from './components/apps-panel/tools-panel/useToolsStore';
import { isExtension } from './configuration';
import { appsCtx } from './contexts/apps';
import { observeDimension } from './observeDimension';

defineEmits(['changed']);

const { hasActiveApp } = useContext(appsCtx);

const mainRef = ref(null);
observeDimension(mainRef);

useProvideToolsStore([]);
</script>

<style scoped>
.sidebar {
  @apply flex w-[484px] flex-col overflow-y-auto border-r border-background_tile bg-background_layer_below;
  .extension & {
    @apply w-[554px];
  }
  &.collapsed {
    @apply absolute left-0 top-0 z-10 h-auto;
  }
}
.panel {
  @apply absolute top-4 left-[calc(1rem_+_484px)];
  .extension & {
    left: calc(1rem + 554px);
  }
}
</style>
