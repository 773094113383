import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { routesCtx } from '../../../contexts/routes';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';
import { TurnData } from '../../../generated/ChartServer';
import { Waypoint } from '../../../generated/RouteManagement';
import { MeterPoint } from '../../../types';
import { degInRad, lngLatToMeter, radInDeg } from '../../../utils/conversions';
import { getScalingFactor } from '../../../utils/mercator';
import { vector } from '../../../utils/vector';
import { intersectionWithViewport } from '../tool/intersectionWithViewport';

export function addWopExtensionLines(
  waypoint: Waypoint,
  wopExtensionLinesGroup: Konva.Group,
  viewportCtxRef: ContextType<typeof viewportCtx>,
  routeCtxRef: ContextType<typeof routesCtx>,
  vesselCtxRef: ContextType<typeof vesselCtx>,
  turnData: TurnData,
  lineColor: string,
): void {
  // if the waypointId OR the turnData's startPosition isn't valid then return
  if (!waypoint?.waypointId || !turnData?.startPosition) return;

  const leg = routeCtxRef.findLeg(waypoint.waypointId);
  const prevLeg = routeCtxRef.findPreviousLeg(waypoint.waypointId);

  // if the current leg has no proceeding waypoint OR the previous leg has no course OR the selected vessel's shipTurnAccelerationLength isn't valid then return
  if (!leg?.toWaypoint || !prevLeg?.course || !vesselCtxRef.state.activeVessel?.shipTurnAccelerationLength?.value)
    return;

  const start = lngLatToMeter({
    lng: (turnData.startPosition.longitude ?? 0) * radInDeg,
    lat: (turnData.startPosition.latitude ?? 0) * radInDeg,
  });

  const accsLengthVector = vector(
    0,
    parseFloat(vesselCtxRef.state.activeVessel?.shipTurnAccelerationLength?.value),
  ).setAngle(Math.PI / 2 - (prevLeg?.course * degInRad + Math.PI));

  const effectStartingMeterPoints = {
    mX: start.mX + accsLengthVector.x * getScalingFactor((turnData.startPosition.latitude ?? 0) * radInDeg),
    mY: start.mY - accsLengthVector.y * getScalingFactor((turnData.startPosition.latitude ?? 0) * radInDeg),
  };

  const absoluteStartingPixelPoints = viewportCtxRef.transformToAbsolute(effectStartingMeterPoints);
  const angleInRad = prevLeg?.course * degInRad - Math.PI / 2;
  const topLeft = viewportCtxRef.transformToViewport({ x: 0, y: 0 });
  const topRight = viewportCtxRef.transformToViewport({ x: viewportCtxRef.viewport.dimension.width, y: 0 });
  const bottomRight = viewportCtxRef.transformToViewport({
    x: viewportCtxRef.viewport.dimension.width,
    y: viewportCtxRef.viewport.dimension.height,
  });
  const bottomLeft = viewportCtxRef.transformToViewport({ x: 0, y: viewportCtxRef.viewport.dimension.height });
  const viewPortIntersection: MeterPoint[] = [];
  /*
    Generate the front, left and right intersection lines to the viewport for the wop line.
  */
  [-90, 0, 90].forEach((deg: number) => {
    intersectionWithViewport(
      effectStartingMeterPoints,
      angleInRad + deg * degInRad,
      topLeft,
      topRight,
      bottomRight,
      bottomLeft,
    ).forEach((item: MeterPoint) => {
      viewPortIntersection.push(item);
    });
  });
  const viewportIntersectionPoints = viewPortIntersection.flatMap((intersection) => {
    const intersectionPixelPoints = viewportCtxRef.transformToAbsolute(intersection);
    return [intersectionPixelPoints.x, intersectionPixelPoints.y] ?? [];
  });
  const line = new Konva.Line({
    points: [absoluteStartingPixelPoints.x, absoluteStartingPixelPoints.y, ...viewportIntersectionPoints],
    stroke: lineColor,
    strokeWidth: 1,
    strokeScaleEnabled: false,
    draggable: false,
  });
  wopExtensionLinesGroup.add(line);
}
