import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { getChartServerClient } from '../api-clients/getChartServerClient';
import { ObjectInfo, Property } from '../generated/ChartServer';
import { MeterPoint } from '../types';
import { meterToLngLat } from '../utils/conversions';
import { EnrichedRoute } from './routes';

export interface MarkerRouteInfo {
  data: Property[];
}

type State = {
  visible: boolean;
  position?: MeterPoint;
  scale?: number;
  objectInfoList?: ObjectInfo[];
  routeInfo?: MarkerRouteInfo;
};

export const objectInfoCtx = defineContext(() => {
  const state: State = reactive({
    visible: false,
    position: undefined,
  });

  const setVisibility = (visible: boolean) => {
    state.visible = visible;
  };

  const setPositionAndScale = async (position: MeterPoint, scale: number) => {
    state.position = position;
    state.objectInfoList = [];

    const geoPositionInDeg = meterToLngLat(position);
    const chartServerClient = await getChartServerClient();
    state.objectInfoList = await chartServerClient.objectInfo({
      position: {
        latitude: geoPositionInDeg.lat,
        longitude: geoPositionInDeg.lng,
      },
      scale,
    });
  };

  const setMarkerRouteInfo = (route: EnrichedRoute | undefined, fromWayPoint: string) => {
    if (!route) state.routeInfo = undefined;
    const leg = route?.legs?.filter((l) => l.fromWaypoint == fromWayPoint)[0];
    if (leg) {
      state.routeInfo = {
        data: [
          {
            title: 'routeName',
            value: route?.name,
          },
          {
            title: 'legNumber',
            value: route?.legs ? (route?.legs?.indexOf(leg) + 1).toString() : undefined,
          },
          {
            title: 'validationResult',
            value: route?.validationStatus,
          },
          {
            title: 'latitude',
            value: state.position?.mX.toString(),
          },
          {
            title: 'longitude',
            value: state.position?.mY.toString(),
          },
          {
            title: 'legCourse',
            value: leg?.course?.toString(),
          },
          {
            title: 'planningSpeed',
            value: leg?.speedPlanned?.toString(),
          },
        ],
      };
    }
  };

  return {
    state: readonly(state),
    setVisibility,
    setPositionAndScale,
    setMarkerRouteInfo,
  };
});
