/* eslint-disable no-console */
import {
  AccountInfo,
  InteractionRequiredAuthError,
  LogLevel,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { readonly, shallowReactive } from 'vue';

type State = {
  authenticated: boolean;
  account?: AccountInfo | null;
  token?: string;
};

const scopes: string[] = ['8419fb37-4d6c-403c-b960-28da08c3a0ed/.default'];

const silentRequest: SilentRequest = {
  scopes: scopes,
};

const redirectRequest: RedirectRequest = {
  scopes: scopes,
};

const popupRequest: PopupRequest = {
  scopes: scopes,
};

function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
  let log;

  switch (level) {
    case LogLevel.Error:
      log = console.error;
      break;
    case LogLevel.Warning:
      log = console.warn;
      break;
    case LogLevel.Info:
      log = console.log;
      break;
    case LogLevel.Verbose:
      log = console.debug;
  }

  if (log && !containsPii) {
    log(message);
  }
}

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: '8419fb37-4d6c-403c-b960-28da08c3a0ed',
    authority: 'https://login.microsoftonline.com/61370aa8-fbdc-4820-8045-14de059130e8',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: loggerCallback,
    },
  },
});

const acquireTokenWithRetry = async (retries = 3) => {
  try {
    if (!msalInstance.getActiveAccount()) {
      msalInstance.loginRedirect();
    }

    return await msalInstance.acquireTokenSilent(silentRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(silentRequest);
    } else if (retries > 0) {
      window.setTimeout(() => {
        return acquireTokenWithRetry(retries - 1);
      }, 1000);
    } else {
      throw error;
    }
  }
};

const state: State = shallowReactive({
  authenticated: msalInstance.getAllAccounts().length > 0,
});

const updateAuthenticated = (): void => {
  state.authenticated = msalInstance.getAllAccounts().length > 0;
};

const acquireToken = async (): Promise<string | undefined> => {
  try {
    const response = await acquireTokenWithRetry(3);
    return response?.tokenType === 'access_token' ? response?.accessToken : response?.idToken;
  } catch (error) {
    state.authenticated = false;
    throw error;
  }
};

const load = async (): Promise<void> => {
  const response = await msalInstance.handleRedirectPromise();

  if (response) {
    msalInstance.setActiveAccount(response.account);
  }

  if (!msalInstance.getActiveAccount()) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  if (!state.authenticated) {
    msalInstance.loginRedirect(redirectRequest);
  }
};

const loadWithPopup = async (): Promise<void> => {
  const response = await msalInstance.loginPopup(popupRequest);

  if (response) {
    msalInstance.setActiveAccount(response.account);
  }

  if (!msalInstance.getActiveAccount()) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
};

export default {
  load,
  loadWithPopup,
  acquireToken,
  updateAuthenticated,
  state: readonly(state),
};
