import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { DeepReadonly } from 'vue';
import { ContextType } from 'vue-context-composition';
import { messageCtx } from '../../../contexts/messages';
import { routesCtx } from '../../../contexts/routes';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';
import { EnrichedWaypoint, LatLon } from '../../../generated/ChartServer';
import { LngLatPoint } from '../../../types';
import {
  calculateBearing,
  calculateDistance,
  degInRad,
  earthRadius,
  lngLatToMeter,
  meterToLngLat,
  radInDeg,
} from '../../../utils/conversions';
import { validateRadius } from '../../../utils/routeUtils';
import { vector } from '../../../utils/vector';
import { colors } from './addRoutes';

export function addTurnpoints(
  wp: DeepReadonly<EnrichedWaypoint>,
  turnRadiusGroup: Konva.Group,
  { transformToAbsolute, transformToViewport, meterToPx }: ContextType<typeof viewportCtx>,
  { updateRadius, editRoute, isActiveWaypoint, setActiveWaypointId }: ContextType<typeof routesCtx>,
  message: ContextType<typeof messageCtx>,
  { getNumericVesselParam }: ContextType<typeof vesselCtx>,
  isMonitored: boolean,
): void {
  const innerTurnRadiusGroup = new Konva.Group();
  if (!wp.turnData) return;

  const start = lngLatToMeter({
    lng: (wp.turnData?.startPosition?.longitude ?? 0) * radInDeg,
    lat: (wp.turnData?.startPosition?.latitude ?? 0) * radInDeg,
  });
  const { x: startX, y: startY } = transformToAbsolute(start);

  const end = lngLatToMeter({
    lng: (wp.turnData?.endPosition?.longitude ?? 0) * radInDeg,
    lat: (wp.turnData?.endPosition?.latitude ?? 0) * radInDeg,
  });
  const { x: endX, y: endY } = transformToAbsolute(end);

  //Using the end of the previous turn as the upper bound for start of turn movement
  const previousWaypoint = editRoute.value?.calculationResult?.waypoints?.find(
    (w) => w.waypointId === editRoute.value?.legs?.find((l) => l.toWaypoint === wp.waypointId)?.fromWaypoint,
  );
  const endOfPreviousTurn = previousWaypoint?.turnData?.endPosition ?? {
    latitude: previousWaypoint?.latitude,
    longitude: previousWaypoint?.longitude,
  };

  const isTurnPointDraggable = wp.waypointId !== undefined && isActiveWaypoint(wp.waypointId);

  const r1 = new Konva.Rect({
    x: startX,
    y: startY,
    width: 14,
    height: 14,
    offsetX: 7,
    offsetY: 7,
    strokeWidth: isMonitored ? 2 : 1,
    stroke: colors.editTrack,
    draggable: isTurnPointDraggable,
    dragBoundFunc: (pos) => dragBoundFunction(pos, { x: startX, y: startY }, endOfPreviousTurn),
  });
  const r2 = new Konva.Rect({
    x: endX,
    y: endY,
    width: 14,
    height: 14,
    offsetX: 7,
    offsetY: 7,
    strokeWidth: isMonitored ? 2 : 1,
    stroke: colors.editTrack,
    draggable: isTurnPointDraggable,
    dragBoundFunc: (pos) => dragBoundFunction(pos, { x: endX, y: endY }, wp.track?.slice(-1)[0]),
  });
  const r3 = new Konva.Rect({
    x: startX,
    y: startY,
    width: 7,
    height: 7,
    offsetX: 3.5,
    offsetY: 3.5,
    strokeWidth: isMonitored ? 2 : 1,
    stroke: colors.editTrack,
    visible: isTurnPointDraggable,
  });
  const r4 = new Konva.Rect({
    x: endX,
    y: endY,
    width: 7,
    height: 7,
    offsetX: 3.5,
    offsetY: 3.5,
    strokeWidth: isMonitored ? 2 : 1,
    stroke: colors.editTrack,
    visible: isTurnPointDraggable,
  });

  innerTurnRadiusGroup.add(r3);
  innerTurnRadiusGroup.add(r4);
  turnRadiusGroup.add(innerTurnRadiusGroup);
  turnRadiusGroup.add(r1);
  turnRadiusGroup.add(r2);

  const stage = turnRadiusGroup.getStage();

  if (stage) {
    r1.on('mousedown', (ev) => {
      if (ev.evt.button === 0 && !isMonitored) setActiveWaypointId(wp.waypointId ?? '');
      ev.cancelBubble = true;
    });
    r2.on('mousedown', (ev) => {
      if (ev.evt.button === 0 && !isMonitored) setActiveWaypointId(wp.waypointId ?? '');
      ev.cancelBubble = true;
    });
    if (wp.turnData.startPosition && wp.turnData.endPosition && isTurnPointDraggable) {
      createTurnPointListeners(r1, stage, wp.turnData.startPosition);
      createTurnPointListeners(r2, stage, wp.turnData.endPosition);
    }
  }

  function dragBoundFunction(pos: Vector2d, turnVector2d: Vector2d, turnLimit?: LatLon): Vector2d {
    //Prevent radius null and turns in exactly the same position
    const minDistance = meterToPx(1);

    const wpM = lngLatToMeter({
      lng: (wp.longitude ?? 0) * radInDeg,
      lat: (wp.latitude ?? 0) * radInDeg,
    });
    const wpAbs = transformToAbsolute(wpM);
    const wpVector = vector(wpAbs.x, wpAbs.y);
    const turnVector = vector(turnVector2d.x, turnVector2d.y);
    const newTurnVector = vector(pos.x, pos.y);

    //Scalar projection
    const wpToTurnPoint = turnVector.subtract(wpVector).setLength(1);
    const turnPointToNewTurnPoint = newTurnVector.subtract(turnVector);
    const magnitude = turnPointToNewTurnPoint.dot(wpToTurnPoint);

    //Do not allow movement past waypoint
    const wpToNewTurnPoint = newTurnVector.subtract(wpVector);
    const outsideWpLimit = wpToNewTurnPoint.dot(wpToTurnPoint) < minDistance;
    if (outsideWpLimit) return wpVector.add(wpToTurnPoint.setLength(minDistance));

    //Do not allow movement past nearest turn point
    let outsideTurnLimit = false;
    if (turnLimit) {
      const turnLimitM = lngLatToMeter({
        lng: (turnLimit?.longitude ?? 0) * radInDeg,
        lat: (turnLimit?.latitude ?? 0) * radInDeg,
      });
      const turnLimitAbs = transformToAbsolute(turnLimitM);
      const turnLimitVector = vector(turnLimitAbs.x, turnLimitAbs.y);
      const turnLimitToNewTurnPoint = newTurnVector.subtract(turnLimitVector);
      outsideTurnLimit = turnLimitToNewTurnPoint.dot(wpToTurnPoint) > -minDistance;
      if (outsideTurnLimit) return turnLimitVector.subtract(wpToTurnPoint.setLength(minDistance));
    }

    const change = wpToTurnPoint.setLength(magnitude);
    return { x: turnVector.x + change.x, y: turnVector.y + change.y };
  }

  function createTurnPointListeners(element: Konva.Rect, stage: Konva.Stage, turnPoint: LatLon) {
    element.on('mouseover', (ev) => {
      stage.container().style.cursor = 'move';
      ev.cancelBubble = true;
    });

    element.on('mousedown touchstart', (ev) => (ev.cancelBubble = true));

    element.on('dragend', () => {
      const pos = element.getPosition();
      if (!pos) return;
      const posM = transformToViewport(pos);
      const posLatLon = meterToLngLat(posM);

      if (wp.waypointId && wp.turnData?.startPosition) {
        // if on dragging the calculated radius returns out to be less than the min ship turn radius then reset the radius to the min ship turn radius.
        updateRadius(
          validateRadius(calculateRadius(posLatLon, turnPoint), getNumericVesselParam('shipMinTurnRadius'), message),
          wp.waypointId,
        );
      }
    });
  }

  function calculateRadius(pos: LngLatPoint, turnPoint: LatLon): number {
    const centrePosition = wp.turnData?.centrePosition;
    if (!centrePosition || wp.longitude == undefined || wp.latitude == undefined) return wp.turnRadius ?? 0;

    const newTurnPointLLP: LngLatPoint = { lng: pos.lng * degInRad, lat: pos.lat * degInRad };
    const waypointLLP: LngLatPoint = { lng: wp.longitude, lat: wp.latitude };
    const turnPointLLP: LngLatPoint = { lng: turnPoint.longitude ?? 0, lat: turnPoint.latitude ?? 0 };
    const turnCentreLLP: LngLatPoint = {
      lng: centrePosition.longitude ?? 0,
      lat: centrePosition.latitude ?? 0,
    };

    const wpToTurn = calculateDistance(waypointLLP, turnPointLLP);
    const wpToCentre = calculateDistance(waypointLLP, turnCentreLLP);
    const wpToCentreBearing = calculateBearing(waypointLLP, turnCentreLLP);
    const turnPointDelta = calculateDistance(waypointLLP, newTurnPointLLP) - wpToTurn;
    const turnPointRelativeDelta = (wpToTurn + turnPointDelta) / wpToTurn;
    const wpToNewCentreDistance = wpToCentre * turnPointRelativeDelta;

    const dLat = Math.cos(wpToCentreBearing) * (wpToNewCentreDistance / earthRadius);
    const dLng =
      (Math.sin(wpToCentreBearing) * (wpToNewCentreDistance / earthRadius)) /
      Math.cos((2 * waypointLLP.lat + dLat) / 2);

    const newTurnCentrePoint: LngLatPoint = {
      lat: waypointLLP.lat + dLat,
      lng: waypointLLP.lng + dLng,
    };

    return calculateDistance(newTurnCentrePoint, newTurnPointLLP);
  }
}
