<template>
  <div class="routesOverview">
    <pan-row class="heading">
      <ecdis-value>ROUTES</ecdis-value>
      <ecdis-input-box class="inputbox" placeholder="SEARCH" @changed="changed" />
      <pan-button :disabled="newRouteDisabled" data-test-id="newRoute" @click="newRoute">NEW</pan-button>
    </pan-row>
    <pan-scrollable>
      <pan-row v-for="route in routes" :key="route.routeId" class="route" @click="openDetail(route)">
        <icon-routes class="routeIcon" />
        <div class="title" :class="isExtension ? 'ecdis-title-width' : 'planning-station-title-width'">
          <ecdis-value class="name">{{ route.name }}</ecdis-value>
          <ecdis-value v-if="editing(route)" class="sub editing">Editing</ecdis-value>
          <ecdis-value v-else class="sub" :class="{ highlight: valid(route) }">{{
            validationStatus(route)
          }}</ecdis-value>
        </div>
        <icon-pin class="pin" @click.stop="goToRoute(route)" />
        <div class="view" :class="{ visible: route.show }" @click.stop="toggleRouteVisibility(route)">
          <icon-view v-if="route.show" />
          <icon-no-view v-else />
        </div>
        <icon-arrow class="chevron" />
      </pan-row>
    </pan-scrollable>
  </div>
</template>

<script lang="ts">
import { DeepReadonly, computed, defineComponent, ref } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../../configuration';
import { messageCtx } from '../../../contexts/messages';
import { EnrichedRoute, routesCtx } from '../../../contexts/routes';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';

export default defineComponent({
  setup() {
    const {
      state,
      toggleRouteVisibility,
      showDetail,
      enrichRoute,
      calculateBounds,
      createRoute,
      setEditId,
      validationStatus,
      valid,
      editing,
    } = useContext(routesCtx);
    const { state: vesselState } = useContext(vesselCtx);
    const { showInViewport } = useContext(viewportCtx);
    const { pushMessage } = useContext(messageCtx);
    const searchText = ref('');

    const routes = computed(() => {
      return state.routes?.filter((route) => route.name?.toLowerCase().includes(searchText.value));
    });

    const showRouteInView = (route: DeepReadonly<EnrichedRoute>) => {
      showInViewport(calculateBounds(route));
    };

    const newRouteDisabled = computed(() => state.editId != undefined);
    const newRoute = () => {
      if (newRouteDisabled.value) return;
      createRoute();
      if (vesselState.isVesselSelected) {
        setEditId(state.detailId);
      } else {
        pushMessage({ text: 'Select a vessel from the Ownship menu before editing the new route', type: 'info' });
      }
    };

    const goToRoute = async (route: DeepReadonly<EnrichedRoute>) => {
      if (!route.waypoints?.length) {
        await enrichRoute(route);
      }
      if (!route.show) {
        toggleRouteVisibility(route);
      }
      if (route.waypoints?.length) {
        showRouteInView(route);
      }
    };

    const openDetail = (route: DeepReadonly<EnrichedRoute>) => {
      if (!route.show) {
        toggleRouteVisibility(route);
      }
      showDetail(route);
    };

    const changed = (text: string) => {
      searchText.value = text.toLowerCase();
    };

    return {
      routes,
      toggleRouteVisibility,
      goToRoute,
      openDetail,
      newRoute,
      newRouteDisabled,
      validationStatus,
      valid,
      editing,
      changed,
      isExtension,
    };
  },
});
</script>

<style scoped>
.routesOverview {
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.heading {
  padding: 8px;
  justify-content: space-between;
}

.route {
  background: var(--background_layer_below);
  padding: 0 12px;
  cursor: pointer;
}
.routeIcon {
  margin-right: 4px;
}
.title {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.planning-station-title-width {
  width: 300px;
}
.ecdis-title-width {
  width: 370px;
}
.title .name,
.title .sub {
  line-height: 16px;
}
.title .sub {
  font-size: 12px;
  color: var(--text_static);
}
.title .sub.highlight {
  color: var(--feedback_text);
}
.title .sub.editing {
  color: var(--alert_warning-active);
}

.title .sub .valid .details {
  width: 90px;
}
.details .distance,
.details .valid {
  font-size: 12px;
  line-height: 16px;
}
.validated .valid {
  color: var(--feedback_indicator_background);
}
.pin {
  margin: 0 8px;
}
.view.visible ::v-deep(*) {
  background: black;
  stroke: var(--feedback_indicator_background);
}
.validated .validate ::v-deep(*) {
  stroke: var(--feedback_indicator_background);
}
.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
  transform: rotate(-90deg);
}
.inputbox {
  width: 300px;
}
</style>
