<template>
  <pan-row class="field">
    <ecdis-label>Route name</ecdis-label>
    <ecdis-value>{{ detailRoute?.name }}</ecdis-value>
  </pan-row>
  <pan-row class="field">
    <ecdis-label>Own ship</ecdis-label>
    <ecdis-value class="vessel">
      {{ selectedVessel?.shipName }}
    </ecdis-value>
  </pan-row>
  <pan-row class="field">
    <ecdis-label>Safety contour</ecdis-label>
    <ecdis-value>{{ safetyContour }} m</ecdis-value>
  </pan-row>
  <pan-row class="field">
    <ecdis-label>Validation period</ecdis-label>
    <ecdis-value>Today</ecdis-value>
  </pan-row>
  <pan-row class="field">
    <ecdis-label>Validation method</ecdis-label>
    <ecdis-value>Unattended</ecdis-value>
  </pan-row>
  <pan-row class="footer">
    <pan-button class="start" @click="doValidation">Start</pan-button>
  </pan-row>
</template>

<script lang="ts">
import { computed, defineComponent, toRef } from 'vue';
import { useContext } from 'vue-context-composition';
import { routesCtx } from '../../contexts/routes';
import { routeValidationCtx } from '../../contexts/routeValidation';
import { safetySettingCtx } from '../../contexts/safetySetting';
import { vesselCtx } from '../../contexts/vessels';

export default defineComponent({
  setup() {
    const { detailRoute } = useContext(routesCtx);
    const { state: safetyState } = useContext(safetySettingCtx);
    const { state } = useContext(vesselCtx);
    const { startValidation } = useContext(routeValidationCtx);
    const selectedVessel = toRef(state, 'selectedVessel');

    const safetyContour = computed(() => safetyState.settings.safetyContour);

    const doValidation = () => {
      if (detailRoute.value) {
        startValidation(
          detailRoute.value,
          safetyContour.value.value as number,
          state.selectedVessel?.shipName,
          state.selectedVessel?.shipId,
        );
      }
    };

    return {
      detailRoute,
      safetyContour,
      selectedVessel,
      doValidation,
    };
  },
});
</script>

<style scoped>
.field {
  padding: 0 12px;
  background: var(--background_layer_below);
}

.field > :nth-of-type(1) {
  width: 180px;
}
.field > :nth-of-type(2) {
  width: 180px;
}

.field .vessel {
  display: flex;
  align-items: center;
}

.footer {
  height: 64px;
  flex-shrink: 0;
  justify-content: center;
  border-bottom: 0;
}
.start {
  width: 94px;
  background: var(--feedback_indicator_background);
}
</style>
