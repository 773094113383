export enum SortOrder {
  Ascending,
  Descending,
}

export function Sort<T, K extends keyof T>(input: T[], prop: K, order: SortOrder): T[] {
  const sorter = (current: T, next: T) => {
    if (current[prop] < next[prop]) {
      return -1;
    } else if (current[prop] > next[prop]) {
      return 1;
    } else {
      return 0;
    }
  };

  const sorted = input.sort(sorter);

  return order === SortOrder.Descending ? sorted.reverse() : sorted;
}
