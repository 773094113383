import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { routesCtx } from '../../../contexts/routes';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';
import { ShapeMetadata } from '../../../types';
import { halfPi, lngLatToMeter, meterToLngLat, radInDeg } from '../../../utils/conversions';
import { hasBadGeometryOrInvalidTurnRadius } from '../../../utils/routeUtils';
import { blinkKonvaSymbol } from '../../../utils/tools';
import { VisibleWaypoint } from './addRoutes';

export function addWaypointMarker(
  wp: VisibleWaypoint,
  waypointMarkerGroup: Konva.Group,
  { transformToAbsolute, transformToViewport }: ContextType<typeof viewportCtx>,
  routes: ContextType<typeof routesCtx>,
  { getNumericVesselParam }: ContextType<typeof vesselCtx>,
): void {
  const point = lngLatToMeter({
    lng: radInDeg * (wp.longitude ?? 0),
    lat: radInDeg * halfPi(wp.latitude ?? 0),
  });
  const { x, y } = transformToAbsolute(point);
  const currentRoute = routes.findRouteByWaypointId(wp.waypointId ?? '');
  const isActiveDraggableWaypoint = wp.active && wp.editable;
  const waypointMarker = new Konva.Circle({
    x,
    y,
    radius: 10,
    stroke: wp.color,
    strokeWidth: 2,
    draggable: isActiveDraggableWaypoint,
    data: <ShapeMetadata>{ uuid: wp.waypointId, type: 'waypoint', showMonitoringToolTipMetaData: wp.monitoring },
  });
  const activeWaypointMarker = new Konva.Circle({
    x,
    y,
    radius: 5,
    stroke: wp.color,
    strokeWidth: 2,
  });
  if (wp.monitoring && wp.active) {
    // When in monitoring mode, the active waypoint should be flashing every 0.5 secs.
    activeWaypointMarker.draggable(false);
    blinkKonvaSymbol(activeWaypointMarker, true);
  } else {
    activeWaypointMarker.visible(wp.active);
    activeWaypointMarker.draggable(true);
  }
  // If a waypoint isn't having enough turn radius (except for the first/last waypoint) or bad geometry then should show crossovers.
  const isCrossoverVisible = hasBadGeometryOrInvalidTurnRadius(
    wp,
    currentRoute,
    getNumericVesselParam('shipMinTurnRadius'),
  );
  for (let i = 0; i < 2; i++) {
    const crossBars = new Konva.Line({
      points: [16, 16, -16, -16],
      absolutePosition: { x, y },
      stroke: wp.color,
      strokeWidth: 1,
      rotation: i * 90,
      visible: isCrossoverVisible,
    });
    waypointMarkerGroup.add(crossBars);
  }
  waypointMarkerGroup.add(activeWaypointMarker);
  waypointMarkerGroup.add(waypointMarker);
  const stage = waypointMarkerGroup.getStage();

  if (!wp.editable || !stage) return;

  waypointMarker.on('mouseover', (ev) => {
    if (isActiveDraggableWaypoint) stage.container().style.cursor = 'move';
    // cancel unset of cursor
    ev.cancelBubble = true;
  });

  waypointMarker.on('mousedown touchstart', (ev) => {
    // cancel dragging of stage
    ev.cancelBubble = true;

    if (!wp.waypointId) return;
    if (ev.evt.button === 0) routes.setActiveWaypointId(wp.waypointId);
    if ((ev.evt as MouseEvent) && ev.evt.ctrlKey) {
      routes.removeWaypoint(wp.waypointId);
    }
  });

  waypointMarker.on('dragend', () => {
    const pos = stage.getPointerPosition();
    if (!pos || !wp.waypointId) return;

    const meterPoint = transformToViewport(pos);
    const latLng = meterToLngLat(meterPoint);
    routes.setWaypointPosition(wp.waypointId, latLng);
  });
}
