<template>
  <span class="label">
    <slot />
  </span>
</template>

<style scoped>
.label {
  color: var(--text_static);
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}
</style>
