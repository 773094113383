import Konva from 'konva';
import { isExtension } from '../../configuration';
import { addChartInfoMarker } from './addChartInfoMarker';
import { addEventListeners } from './addEventListeners';
import { addGraticule } from './addGraticule';
import { addOwnship } from './addOwnship';
import { addRouteValidation } from './addRouteValidation';
import { addTargets } from './addTargets';
import { addMarinersNotes } from './mariners-notes/addMarinersNotes';
import { addNorthArrow } from './north-arrow/addNorthArrow';
import { addRoutes } from './routes/addRoutes';
import { addScaleBar } from './scale-bar/addScaleBar';
import { addBearingRange } from './tool/addBearingRange';
import { addEblVrms } from './tool/addEblVRM';
import { watchUpdateChartImages } from './watchUpdateChartImages';
import { watchViewportDimension } from './watchViewportDimension';
import { watchViewportScaling } from './watchViewportScaling';
import { watchViewportTransforms } from './watchViewportTransforms';

export function createChartCanvas(container: HTMLDivElement): void {
  // create stage, root of all drawable elements
  const stage = new Konva.Stage({ container });

  stage.on('mouseover', () => {
    stage.container().style.cursor = 'default';
  });

  // chart image layer contains all serverside rendered chart images
  const chartImageLayer = new Konva.Layer();
  stage.add(chartImageLayer);

  // top layer contains everything on top of chart image layer
  const transformLayer = new Konva.Layer();
  stage.add(transformLayer);

  // absolute layer doesn't have any transforms applied
  const absoluteLayer = new Konva.Layer();
  stage.add(absoluteLayer);

  addEventListeners(stage);
  watchViewportDimension(stage);
  watchViewportScaling();

  watchViewportTransforms(chartImageLayer);
  watchUpdateChartImages(chartImageLayer);

  watchViewportTransforms(transformLayer);
  if (isExtension.value) {
    addOwnship(transformLayer, absoluteLayer);
  }
  addTargets(transformLayer, absoluteLayer);
  addGraticule(transformLayer);
  addRouteValidation(transformLayer);
  addRoutes(transformLayer, absoluteLayer);
  addChartInfoMarker(absoluteLayer);
  addEblVrms(transformLayer, absoluteLayer);
  addBearingRange(transformLayer, absoluteLayer);
  addScaleBar(absoluteLayer);
  addNorthArrow(absoluteLayer);
  addMarinersNotes(absoluteLayer);
}
