import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { persist } from '../utils/persist';
import { targetConfiguration } from '../utils/targetUtils';
import { UpdateReq } from './routeSettings';

type DisplaySettings = {
  id:
    | 'showLabels'
    | 'pastPosition'
    | 'headingLine'
    | 'pathPrediction'
    | 'minuteMarker'
    | 'showAisTargets'
    | 'showRadarTargets'
    | 'lostTargetAlertsEnabled';
  name: string;
  value: boolean;
  dropDown: DropDownSelection;
};

type OptionalSettings = {
  id: 'dangerousTargets' | 'targetAssociationOn' | 'aisFilteringOn';
  isActive: boolean;
  settings: Array<DetailedSettings>;
};

type DetailedSettings = {
  id:
    | 'sourcePriority'
    | 'combineTargetDistanceThreshold'
    | 'combineTargetCourseThreshold'
    | 'combineTargetSpeedThreshold'
    | 'cpaLimitInNauticalMiles'
    | 'tcpaLimitInMinutes'
    | 'showSleeping'
    | 'aisCpaTcpaFilterOn'
    | 'sleepOutsideRange';
  name: string;
  value: { value: number; unit: string };
  dropDown: DropDownSelection;
};

type TargetSettings = {
  targetSourcesSettings: Array<DisplaySettings>;
  generalSettings: Array<DisplaySettings>;
  aisFiltering: OptionalSettings;
  targetAssociation: OptionalSettings;
  dangerousTargets: OptionalSettings;
};

type DropDownSelection = {
  hasDropDown: boolean;
  value: { value: string; unit: string };
};

type State = {
  visible: boolean;
  settings: TargetSettings;
};

const defaultSettings: TargetSettings = {
  targetSourcesSettings: [
    {
      id: 'showAisTargets',
      name: 'AIS',
      value: true,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
    {
      id: 'showRadarTargets',
      name: 'RADAR',
      value: true,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
  ],
  generalSettings: [
    {
      id: 'showLabels',
      name: 'Show labels',
      value: true,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
    {
      id: 'pastPosition',
      name: 'Past position',
      value: false,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
    {
      id: 'headingLine',
      name: 'Heading line',
      value: true,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
    {
      id: 'pathPrediction',
      name: 'Path prediction',
      value: false,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
    {
      id: 'lostTargetAlertsEnabled',
      name: 'Lost target alerts',
      value: true,
      dropDown: {
        hasDropDown: false,
        value: { value: '0', unit: '' },
      },
    },
    {
      id: 'minuteMarker',
      name: 'Minute marker',
      value: false,
      dropDown: {
        hasDropDown: true,
        value: { value: '3', unit: 'min' },
      },
    },
  ],
  aisFiltering: {
    id: 'aisFilteringOn',
    isActive: true,
    settings: [
      {
        id: 'showSleeping',
        name: 'Show sleeping targets',
        value: { value: 1, unit: '' },
        dropDown: {
          hasDropDown: false,
          value: { value: '0', unit: '' },
        },
      },
      {
        id: 'aisCpaTcpaFilterOn',
        name: 'Sleep outside CPA/TCPA limits',
        value: { value: 1, unit: '' },
        dropDown: {
          hasDropDown: false,
          value: { value: '0', unit: '' },
        },
      },
      {
        id: 'sleepOutsideRange',
        name: 'Sleep outside range',
        value: { value: 1, unit: '' },
        dropDown: {
          hasDropDown: true,
          value: { value: '6', unit: 'NM' },
        },
      },
    ],
  },
  targetAssociation: {
    id: 'targetAssociationOn',
    isActive: true,
    settings: [
      {
        id: 'sourcePriority',
        name: 'Source Priority',
        value: { value: 0, unit: '' },
        dropDown: {
          hasDropDown: true,
          value: { value: 'AIS', unit: '' },
        },
      },
      {
        id: 'combineTargetDistanceThreshold',
        name: 'Distance',
        value: { value: 30, unit: 'm' },
        dropDown: {
          hasDropDown: false,
          value: { value: '0', unit: '' },
        },
      },
      {
        id: 'combineTargetCourseThreshold',
        name: 'Course delta',
        value: { value: 2, unit: 'deg' },
        dropDown: {
          hasDropDown: false,
          value: { value: '0', unit: '' },
        },
      },
      {
        id: 'combineTargetSpeedThreshold',
        name: 'Speed delta',
        value: { value: 1, unit: 'kn' },
        dropDown: {
          hasDropDown: false,
          value: { value: '0', unit: '' },
        },
      },
    ],
  },
  dangerousTargets: {
    id: 'dangerousTargets',
    isActive: false,
    settings: [
      {
        id: 'cpaLimitInNauticalMiles',
        name: 'CPA',
        value: { value: 0, unit: 'NM' },
        dropDown: {
          hasDropDown: false,
          value: { value: 'RADAR', unit: '' },
        },
      },
      {
        id: 'tcpaLimitInMinutes',
        name: 'TCPA',
        value: { value: 0, unit: 'min' },
        dropDown: {
          hasDropDown: false,
          value: { value: '0', unit: '' },
        },
      },
    ],
  },
};

export const targetSettingsCtx = defineContext(() => {
  const state: State = persist(
    'targetDefaultSettings.v1',
    reactive({
      visible: false,
      settings: defaultSettings,
    }),
  );

  const toggleVisibility = () => (state.visible = !state.visible);

  const toggleGeneralSettings = (settingId: string) => {
    const setting = state.settings.generalSettings.find((setting) => setting.id === settingId);
    if (setting) {
      setting.value = !setting.value;
    }
  };

  const toggleTargetSources = (settingId: string) => {
    const setting = state.settings.targetSourcesSettings.find((setting) => setting.id === settingId);
    if (setting) {
      setting.value = !setting.value;
    }
  };

  const getTargetGeneralSettingsValue = (id: string) =>
    state.settings.generalSettings.find((setting) => setting.id === id)?.value;

  const getMinuteMarkInterval = () => {
    const setting = state.settings.generalSettings.find((setting) => setting.id === 'minuteMarker');
    if (setting) return setting.dropDown.value.value;
  };

  const setMinuteMarkInterval = (minuteMarkInterval: string) => {
    const setting = state.settings.generalSettings.find((setting) => setting.id === 'minuteMarker');
    if (setting) setting.dropDown.value.value = minuteMarkInterval;
  };

  const setSleepOutsideRange = (sleepOutsideRange: string) => {
    const setting = state.settings.aisFiltering.settings.find((setting) => setting.id === 'sleepOutsideRange');
    if (setting) setting.dropDown.value.value = sleepOutsideRange;
  };

  const updateRange = ($event: UpdateReq) => {
    const setting =
      state.settings.targetAssociation.settings.find((setting) => setting.id === $event.id) ||
      state.settings.dangerousTargets.settings.find((setting) => setting.id === $event.id);
    if (setting) setting.value.value = +$event.value;
  };

  const toggleAisFilteringSettings = (settingId: string) => {
    const setting = state.settings.aisFiltering.settings.find((setting) => setting.id === settingId);
    if (setting) {
      setting.value.value = setting.value.value > 0 ? 0 : 1;
    }
  };

  const shouldShowSleepingTargets = () => {
    const setting = state.settings.aisFiltering.settings.find((setting) => setting.id === 'showSleeping');
    if (setting && state.settings.aisFiltering.isActive) {
      return Boolean(setting.value.value);
    }
    return false;
  };

  const toggleDangerousTargets = () => {
    state.settings.dangerousTargets.isActive = !state.settings.dangerousTargets.isActive;
  };

  const toggleTargetAssociation = () => {
    state.settings.targetAssociation.isActive = !state.settings.targetAssociation.isActive;
  };

  const getCpaLimitValue = () => {
    const setting = state.settings.dangerousTargets.settings.find(
      (setting) => setting.id === 'cpaLimitInNauticalMiles',
    );
    if (setting) return setting.value.value;
  };

  const getTcpaLimitValue = () => {
    const setting = state.settings.dangerousTargets.settings.find((setting) => setting.id === 'tcpaLimitInMinutes');
    if (setting) return setting.value.value;
  };

  const updateTargetAssociationType = (associationType: string) => {
    const setting = state.settings.targetAssociation.settings.find((setting) => setting.id === 'sourcePriority');
    if (setting) setting.dropDown.value.value = associationType;
  };

  const isAssociatedTargetPreferAis = () => {
    const setting = state.settings.targetAssociation.settings.find((setting) => setting.id === 'sourcePriority');
    if (setting) return setting.dropDown.value.value === 'AIS';
  };

  const toggleAisFiltering = () => {
    state.settings.aisFiltering.isActive = !state.settings.aisFiltering.isActive;
  };

  const getTargetConfigurations = (): string => {
    const thresholdCourseSetting = state.settings.targetAssociation.settings.find(
      (setting) => setting.id === 'combineTargetCourseThreshold',
    );
    const thresholdSpeedSetting = state.settings.targetAssociation.settings.find(
      (setting) => setting.id === 'combineTargetSpeedThreshold',
    );
    const thresholdDistanceSetting = state.settings.targetAssociation.settings.find(
      (setting) => setting.id === 'combineTargetDistanceThreshold',
    );
    const showAisTargets = state.settings.targetSourcesSettings.find((setting) => setting.id === 'showAisTargets');
    const showRadarTargets = state.settings.targetSourcesSettings.find((setting) => setting.id === 'showRadarTargets');
    const allowSleepingOutsideCpaTcpaLimits = state.settings.aisFiltering.settings.find(
      (setting) => setting.id === 'aisCpaTcpaFilterOn',
    );
    const allowSleepingOutsideRange = state.settings.aisFiltering.settings.find(
      (setting) => setting.id === 'sleepOutsideRange',
    );
    const lostTargetAlertsEnabled = state.settings.generalSettings.find(
      (setting) => setting.id === 'lostTargetAlertsEnabled',
    );
    return targetConfiguration(
      getTcpaLimitValue() ?? 0,
      getCpaLimitValue() ?? 0,
      thresholdDistanceSetting?.value?.value ?? 0,
      thresholdCourseSetting?.value?.value ?? 0,
      thresholdSpeedSetting?.value?.value ?? 0,
      isAssociatedTargetPreferAis() ?? true,
      state.settings.targetAssociation.isActive,
      showAisTargets?.value ?? true,
      showRadarTargets?.value ?? true,
      state.settings.dangerousTargets.isActive,
      state.settings.aisFiltering.isActive,
      Boolean(allowSleepingOutsideCpaTcpaLimits?.value?.value) ?? true,
      Boolean(allowSleepingOutsideRange?.value?.value) ?? true,
      allowSleepingOutsideRange?.dropDown?.value.value ? +allowSleepingOutsideRange?.dropDown?.value.value : 6,
      lostTargetAlertsEnabled?.value ?? true,
    );
  };

  return {
    state: readonly(state),
    toggleVisibility,
    toggleGeneralSettings,
    toggleTargetSources,
    getTargetGeneralSettingsValue,
    setMinuteMarkInterval,
    setSleepOutsideRange,
    getMinuteMarkInterval,
    updateRange,
    toggleAisFilteringSettings,
    toggleDangerousTargets,
    toggleTargetAssociation,
    getCpaLimitValue,
    getTcpaLimitValue,
    updateTargetAssociationType,
    isAssociatedTargetPreferAis,
    getTargetConfigurations,
    toggleAisFiltering,
    shouldShowSleepingTargets,
  };
});
