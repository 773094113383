class Vector {
  readonly x: number;
  readonly y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  // return the angle of the vector in radians
  angle(): number {
    return Math.atan2(this.y, this.x);
  }

  // set the direction of the vector in radians
  setAngle(direction: number): Vector {
    const magnitude = this.length();
    const x = Math.cos(direction) * magnitude;
    const y = Math.sin(direction) * magnitude;
    return vector(x, y);
  }

  // get the magnitude of the vector
  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  // set the magnitude of the vector
  setLength(length: number): Vector {
    const direction = this.angle();
    const x = Math.cos(direction) * length;
    const y = Math.sin(direction) * length;
    return vector(x, y);
  }

  // add a vector to this one
  add(v2: Vector): Vector {
    const x = this.x + v2.x;
    const y = this.y + v2.y;
    return vector(x, y);
  }

  // subtract a vector from this one
  subtract(v2: Vector): Vector {
    const x = this.x - v2.x;
    const y = this.y - v2.y;
    return vector(x, y);
  }

  // multiply this vector by the scalar
  multiply(scalar: number): Vector {
    const x = this.x * scalar;
    const y = this.y * scalar;
    return vector(x, y);
  }

  // divide this vector by the scalar
  divide(scalar: number): Vector {
    const x = this.x / scalar;
    const y = this.y / scalar;
    return vector(x, y);
  }

  // return a copy
  clone(): Vector {
    return vector(this.x, this.y);
  }

  toString(): string {
    return `x: ${this.x}, y: ${this.y}`;
  }

  dot(v2: Vector): number {
    return this.x * v2.x + this.y * v2.y;
  }
}

export const vector = (x = 0, y = 0): Vector => new Vector(x, y);
