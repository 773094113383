<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M9.14105 18.1262L20.9733 11.5737L14.0908 23.076L11.6159 20.6011L9.14105 18.1262Z"
      stroke="#A2AAB9"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path d="M19.26 26V24.74L23.572 19.448H19.372V18.3H24.986V19.56L20.59 24.852H25.14V26H19.26Z" fill="#A2AAB9" />
    <path
      d="M17.4008 25.8962C15.4824 26.1821 13.5225 25.8741 11.7844 25.0134C10.0463 24.1528 8.61323 22.7807 7.67783 21.0817C6.74243 19.3826 6.3495 17.4379 6.55183 15.509C6.75417 13.58 7.54208 11.7592 8.80968 10.2912C10.0773 8.8232 11.7638 7.77837 13.6427 7.29711C15.5216 6.81584 17.5028 6.9212 19.32 7.59902C21.1373 8.27684 22.7035 9.49465 23.8083 11.0888C24.913 12.683 25.5034 14.5771 25.5 16.5166"
      stroke="#A2AAB9"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
