import Konva from 'konva';
import { DeepReadonly, computed, watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { criticalPointsSettingCtx } from '../../../contexts/criticalPointsSetting';
import { messageCtx } from '../../../contexts/messages';
import { EnrichedRoute, routesCtx } from '../../../contexts/routes';
import { routeSettingsCtx } from '../../../contexts/routeSettings';
import { toolTipCtx } from '../../../contexts/toolTip';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';
import { DistanceMarker, EnrichedWaypoint } from '../../../generated/ChartServer';
import { IndexLine } from '../../../generated/RouteManagement';
import { meterInNm, nmInMeter } from '../../../utils/conversions';
import { calculateCriticalPointPositions } from '../../../utils/criticalPointUtils';
import { calculateWaypointLabelPositions, calculateWaypointSpeedAndCoursePositions } from '../../../utils/routeUtils';
import { addCriticalPoints } from './addCriticalpoints';
import { addDistanceMarker } from './addDistanceMarkers';
import { addIndexLines } from './addIndexLine';
import { addOfftrackLines } from './addOfftrackLines';
import { addTrackLines } from './addTrackLines';
import { addTurnEdges } from './addTurnEdges';
import { addTurnpoints } from './addTurnpoints';
import { addWaypointCourseLabel } from './addWaypointCourseLabel';
import { addWaypointLabel } from './addWaypointLabel';
import { addWaypointMarker } from './addWaypointMarker';
import { addWaypointPlannedSpeedLabel } from './addWaypointPlannedSpeedLabel';
import { addWheelOverLines } from './addWheelOverLine';
import { addWopExtensionLines } from './addWopExtensionLines';

export const colors = {
  track: 'rgb(255, 132, 60)',
  editTrack: 'rgb(230,15,15)',
};

export type VisibleWaypoint = {
  active: boolean;
  color: string;
  editable: boolean;
  monitoring: boolean;
} & DeepReadonly<EnrichedWaypoint>;

export type DistanceMarkerWithLabel = {
  marker: DistanceMarker;
  label: string;
};

type DisplaySettingsType = {
  monitoring: boolean | undefined;
  editable: boolean | undefined;
};

export function addRoutes(transformLayer: Konva.Layer, absoluteLayer: Konva.Layer): void {
  const criticalPointsSetting = useContext(criticalPointsSettingCtx);
  const toolTip = useContext(toolTipCtx);
  const routes = useContext(routesCtx);
  const { updateDistanceMarkerConfiguration, findLegById } = useContext(routesCtx);
  const { state, editRoute, monitoredRoute } = routes;
  const viewport = useContext(viewportCtx);
  const message = useContext(messageCtx);
  const { viewport: viewportState, pxToMeter, currentRange } = viewport;
  const {
    state: routeSettings,
    getDisplaySettingsValue,
    getGeneralSettingsValue,
    getTagSettingsValue,
    getDistanceMarkerConfiguration,
    updateMarkerDistance,
    getMonitorSettingsValue,
  } = useContext(routeSettingsCtx);
  const vessel = useContext(vesselCtx);

  const edgeGroup = new Konva.Group();
  const distanceMarkerGroup = new Konva.Group();
  const trackGroup = new Konva.Group();
  const xtdGroup = new Konva.Group();
  const indexLinesGroup = new Konva.Group();
  transformLayer.add(edgeGroup, trackGroup, xtdGroup, indexLinesGroup);

  const waypointLabelGroup = new Konva.Group();
  const turnRadiusGroup = new Konva.Group();
  const waypointMarkerGroup = new Konva.Group();
  const waypointPlannedSpeedLabelGroup = new Konva.Group();
  const waypointCourseLabelGroup = new Konva.Group();
  const wheelOverLineGroup = new Konva.Group();
  const wopExtensionLinesGroup = new Konva.Group();
  const criticalPointsGroup = new Konva.Group();

  absoluteLayer.add(
    distanceMarkerGroup,
    waypointLabelGroup,
    turnRadiusGroup,
    waypointMarkerGroup,
    waypointPlannedSpeedLabelGroup,
    waypointCourseLabelGroup,
    wheelOverLineGroup,
    wopExtensionLinesGroup,
    criticalPointsGroup,
  );

  // compute list of all visible waypoints
  const allVisibleWaypoints = computed(
    (): VisibleWaypoint[] =>
      state.routes?.flatMap((route: DeepReadonly<EnrichedRoute>) => {
        if (!route.show) return [];

        const waypoints = route.calculationResult?.waypoints ?? [];
        const editable = route.routeId === state.editId;
        const monitoring = route.routeId === state.monitorId;
        return waypoints.map((wp) => ({
          ...wp,
          active: (editable || monitoring) && route.activeWaypointId === wp.waypointId,
          color: editable || monitoring ? colors.editTrack : colors.track,
          editable,
          monitoring,
        }));
      }) ?? [],
  );

  // compute list of visible editable waypoints
  const editableRouteWaypoints = computed(
    (): DeepReadonly<EnrichedWaypoint[]> =>
      state.routes?.flatMap((route: DeepReadonly<EnrichedRoute>) => {
        const waypoints = route.calculationResult?.waypoints ?? [];
        const editable = route.routeId === state.editId;
        if (!route.show || !editable) return [];

        return waypoints;
      }) ?? [],
  );

  const allIndexLines = computed(
    (): DeepReadonly<IndexLine[]> =>
      state.routes?.flatMap((route: DeepReadonly<EnrichedRoute>) => {
        const indexLines = route.indexLines ?? [];
        const editable = route.routeId === state.editId;
        if (!route.show || !editable) return [];
        return indexLines;
      }) ?? [],
  );
  // compute list of visible monitored waypoints
  const monitoredRouteWaypoints = computed(
    (): DeepReadonly<EnrichedWaypoint[]> =>
      state.routes?.flatMap((route: DeepReadonly<EnrichedRoute>) => {
        const waypoints = route.calculationResult?.waypoints ?? [];
        const monitored = route.routeId === state.monitorId;
        if (!route.show || !monitored) return [];

        return waypoints;
      }) ?? [],
  );

  // compute calculated waypoint label positions for editable/monitored waypoints.
  const editableWaypointLabelPositions = computed(() => wayPointLabelPositions(editableRouteWaypoints.value));

  const monitoredWaypointLabelPositions = computed(() => wayPointLabelPositions(monitoredRouteWaypoints.value));

  const wayPointLabelPositions = (waypoints: DeepReadonly<EnrichedWaypoint[]>) => {
    const distance = pxToMeter(30);
    return calculateWaypointLabelPositions(waypoints, distance);
  };

  const editableWaypointSpeedCourseLabelPositions = computed(() =>
    waypointSpeedCourseLabelPositions(editableRouteWaypoints.value),
  );

  const monitoredWaypointSpeedCourseLabelPositions = computed(() =>
    waypointSpeedCourseLabelPositions(monitoredRouteWaypoints.value),
  );

  const editableWaypointCriticalPointsLabelPositions = computed(() => criticalPointsLabelPositions(editRoute.value));

  const monitoredWaypointCriticalPointsLabelPositions = computed(() =>
    criticalPointsLabelPositions(monitoredRoute.value),
  );

  const waypointSpeedCourseLabelPositions = (waypoints: DeepReadonly<EnrichedWaypoint[]>) => {
    const distance = pxToMeter(50);
    const speedUnit = getGeneralSettingsValue('plannedSpeed')?.unit ?? '-';
    return calculateWaypointSpeedAndCoursePositions(waypoints, speedUnit, 'T', distance);
  };

  const criticalPointsLabelPositions = (route: EnrichedRoute | undefined) => {
    return calculateCriticalPointPositions(allVisibleWaypoints.value, route);
  };

  //compute list of all distance markers
  const allVisibleDistanceMarkersForEditableRoute = computed(() => {
    return editRoute.value && getDisplaySettingsValue('distanceTags')
      ? editRoute.value.calculationResult?.distanceMarkers?.map(
          (marker, index) =>
            <DistanceMarkerWithLabel>{
              marker: marker,
              label: `${Math.round((index + 1) * getDistanceMarkerConfiguration().distance * meterInNm)} NM`,
            },
        )
      : [];
  });

  const allVisibleDistanceMarkersForMonitoredRoute = computed(() => {
    return monitoredRoute.value && getMonitorSettingsValue('distanceTags')
      ? monitoredRoute.value.calculationResult?.distanceMarkers?.map(
          (marker, index) =>
            <DistanceMarkerWithLabel>{
              marker: marker,
              label: `${Math.round((index + 1) * getDistanceMarkerConfiguration().distance * meterInNm)} NM`,
            },
        )
      : [];
  });

  // update transform layer
  watch(
    [
      () => allVisibleWaypoints,
      () => editableRouteWaypoints,
      () => monitoredRouteWaypoints,
      () => routeSettings,
      () => allIndexLines,
    ],
    () => {
      edgeGroup.destroyChildren();
      trackGroup.destroyChildren();
      xtdGroup.destroyChildren();
      indexLinesGroup.destroyChildren();

      const offtrackLimitVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('offtrackLimit'),
        monitoring: getMonitorSettingsValue('offtrackLimit'),
      };
      const indexLinesVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('indexLines'),
        monitoring: getDisplaySettingsValue('indexLines'),
      };

      allVisibleWaypoints.value.forEach((wp) => {
        addTrackLines(wp, trackGroup, routes);
        addTurnEdges(wp, edgeGroup);
      });
      editableRouteWaypoints.value.forEach((wp) => {
        if (offtrackLimitVisible.editable) addOfftrackLines(wp, xtdGroup);
      });
      monitoredRouteWaypoints.value.forEach((wp) => {
        if (offtrackLimitVisible.monitoring) addOfftrackLines(wp, xtdGroup);
      });

      if (indexLinesVisible.editable || indexLinesVisible.monitoring) {
        allIndexLines.value?.forEach((indexline) => {
          if (!indexline.legId) return;
          const leg = findLegById(indexline.legId);
          addIndexLines(indexLinesGroup, viewport, routes, toolTip, indexline, leg?.course ?? 0);
        });
      }
    },
    { deep: true },
  );

  // update absolute layer
  watch(
    [
      () => allVisibleWaypoints,
      () => editableRouteWaypoints,
      () => monitoredRouteWaypoints,
      () => editableWaypointLabelPositions,
      () => monitoredWaypointLabelPositions,
      () => editableWaypointSpeedCourseLabelPositions,
      () => monitoredWaypointSpeedCourseLabelPositions,
      () => editableWaypointCriticalPointsLabelPositions,
      () => monitoredWaypointCriticalPointsLabelPositions,
      () => routeSettings,
      () => viewportState.matrix,
    ],
    () => {
      distanceMarkerGroup.destroyChildren();
      waypointLabelGroup.destroyChildren();
      turnRadiusGroup.destroyChildren();
      waypointMarkerGroup.destroyChildren();
      waypointPlannedSpeedLabelGroup.destroyChildren();
      waypointCourseLabelGroup.destroyChildren();
      wheelOverLineGroup.destroyChildren();
      wopExtensionLinesGroup.destroyChildren();
      criticalPointsGroup.destroyChildren();

      const labelVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('wptName'),
        monitoring: getMonitorSettingsValue('wptName'),
      };
      const turnPointVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('turnPoint'),
        monitoring: getMonitorSettingsValue('turnPoint'),
      };
      const wptCourseVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('nextCourse'),
        monitoring: getMonitorSettingsValue('nextCourse'),
      };
      const wptPlannedSpeedVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('plannedSpeed'),
        monitoring: getMonitorSettingsValue('plannedSpeed'),
      };
      const criticalPointsVisible: DisplaySettingsType = {
        editable: getDisplaySettingsValue('criticalPoints'),
        monitoring: getMonitorSettingsValue('criticalPoints'),
      };

      // add new shapes
      editableWaypointLabelPositions.value.forEach((label) => {
        if (labelVisible.editable) addWaypointLabel(label, waypointLabelGroup, viewport);
      });
      monitoredWaypointLabelPositions.value.forEach((label) => {
        if (labelVisible.monitoring) addWaypointLabel(label, waypointLabelGroup, viewport);
      });
      editableRouteWaypoints.value.forEach((wp) => {
        if (turnPointVisible.editable) addTurnpoints(wp, turnRadiusGroup, viewport, routes, message, vessel, false);
      });
      monitoredRouteWaypoints.value.forEach((wp) => {
        if (turnPointVisible.monitoring) addTurnpoints(wp, turnRadiusGroup, viewport, routes, message, vessel, true);
      });
      editableWaypointSpeedCourseLabelPositions.value.forEach((label) => {
        if (wptPlannedSpeedVisible.editable)
          addWaypointPlannedSpeedLabel(label, waypointPlannedSpeedLabelGroup, viewport);
        if (wptCourseVisible.editable) addWaypointCourseLabel(label, waypointCourseLabelGroup, viewport);
      });
      monitoredWaypointSpeedCourseLabelPositions.value.forEach((label) => {
        if (wptPlannedSpeedVisible.monitoring)
          addWaypointPlannedSpeedLabel(label, waypointPlannedSpeedLabelGroup, viewport);
        if (wptCourseVisible.monitoring) addWaypointCourseLabel(label, waypointCourseLabelGroup, viewport);
      });
      allVisibleWaypoints.value?.forEach((wp) => {
        addWaypointMarker(wp, waypointMarkerGroup, viewport, routes, vessel);
        if (wp.turnData && wp.monitoring)
          addWheelOverLines(wp, wheelOverLineGroup, viewport, routes, vessel, wp.turnData, wp.color);
        if (wp.turnData && getDisplaySettingsValue('wopExtensionLines') && wp.active)
          addWopExtensionLines(wp, wopExtensionLinesGroup, viewport, routes, vessel, wp.turnData, colors.track);
      });
      allVisibleDistanceMarkersForMonitoredRoute.value?.forEach((dm) => {
        addDistanceMarker(dm, viewport, distanceMarkerGroup, true);
      });
      allVisibleDistanceMarkersForEditableRoute.value?.forEach((dm) => {
        addDistanceMarker(dm, viewport, distanceMarkerGroup, false);
      });
      editableWaypointCriticalPointsLabelPositions.value.forEach((label) => {
        if (criticalPointsVisible.editable) {
          addCriticalPoints(
            label,
            criticalPointsGroup,
            viewport,
            routes,
            criticalPointsSetting,
            colors.editTrack,
            false,
            state.editId,
          );
        }
      });
      monitoredWaypointCriticalPointsLabelPositions.value.forEach((label) => {
        if (criticalPointsVisible.monitoring) {
          addCriticalPoints(
            label,
            criticalPointsGroup,
            viewport,
            routes,
            criticalPointsSetting,
            colors.editTrack,
            true,
            state.monitorId,
          );
        }
      });
    },
    { deep: true },
  );

  const distanceMarkerDistance = computed((): number => {
    let distance: number;

    if (currentRange.value > 100) {
      distance = 50;
    } else if (currentRange.value > 40) {
      distance = 20;
    } else if (currentRange.value > 30) {
      distance = 10;
    } else {
      distance = 5;
    }
    return distance;
  });

  watch(
    () => getTagSettingsValue('distanceTags')?.value,
    (changedDistTagSettingValue) => {
      updateDistanceMarkerConfiguration({
        distance: distanceMarkerDistance.value * nmInMeter,
        reversed: changedDistTagSettingValue?.toLowerCase() === 'remaining',
      });
    },
  );
  watch(
    () => distanceMarkerDistance.value,
    (distance: number) => {
      // Updating the context
      updateMarkerDistance(distance);
      // Updating the actual config for re-calculation to happen
      updateDistanceMarkerConfiguration({
        distance: distance * nmInMeter,
        reversed: getTagSettingsValue('distanceTags')?.value.toLowerCase() === 'remaining',
      });
    },
  );
}
