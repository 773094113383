import Konva from 'konva';
import { watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../../configuration';
import { appsCtx } from '../../../contexts/apps';
import { controlModesCtx } from '../../../contexts/controlModes';
import { viewportCtx } from '../../../contexts/viewport';
import { useOwnship } from '../../../global-state/ownship';
import { meterToLngLat, nmInMeter } from '../../../utils/conversions';
import { getScalingFactor } from '../../../utils/mercator';
import { createLegendBottom } from './createLegendBottom';
import { createLegendTop } from './createLegendTop';
import { createScaleBar } from './createScaleBar';

const edgeOffsetX = 10;

export const colors = {
  orange: '#fe8d43',
  grey: '#9c9c9c',
  black: '#000',
};

export function addScaleBar(absoluteLayer: Konva.Layer): void {
  const { hasActiveApp } = useContext(appsCtx);
  const { viewport, meterToPx, chartScaleConformity } = useContext(viewportCtx);
  const { state: controlModesState } = useContext(controlModesCtx);
  const { ownshipLngLatInDeg } = useOwnship();

  const scaleBarsGroup = new Konva.Group();
  absoluteLayer.add(scaleBarsGroup);

  // create different scale bar representations, each 10px in height, centered vertically
  const scaleBar1NM = createScaleBar(colors.orange);
  const scaleBar10NM = createScaleBar(colors.black);
  const scaleBarLegendTop = createLegendTop();
  const scaleBarLegendBottom = createLegendBottom();
  scaleBarsGroup.add(scaleBar1NM, scaleBar10NM, scaleBarLegendTop, scaleBarLegendBottom);

  watchEffect(() => {
    scaleBar1NM.absolutePosition({ x: edgeOffsetX, y: viewport.dimension.height / 2 });
    scaleBar10NM.absolutePosition({ x: edgeOffsetX, y: viewport.dimension.height / 2 });
  });

  watchEffect(() => {
    // show at the right scale level
    const setLegendTop = hasActiveApp.value ? 15 : isExtension.value ? 260 : 70;
    const isVisible = viewport.scaling.scale < 1_000_000;
    scaleBarsGroup.visible(isVisible);
    if (!isVisible) {
      return;
    }

    // show either the 1NM or the 10NM scale bar
    const atScale1NM = viewport.scaling.scale < 80_000;
    scaleBar1NM.visible(atScale1NM);
    scaleBar10NM.visible(!atScale1NM);

    // update the visible scale bar
    const scaleBar = atScale1NM ? scaleBar1NM : scaleBar10NM;

    // determine if scale factor should be based on ownship latitude or center of screen latitude
    const centerLat = meterToLngLat(viewport.center).lat;
    const useOwnshipScale =
      (chartScaleConformity.value > 0.05 || Math.abs(centerLat) > 70) &&
      !controlModesState.browse &&
      ownshipLngLatInDeg.value !== undefined;
    const scaleFactor = getScalingFactor(useOwnshipScale ? ownshipLngLatInDeg.value.lat : centerLat);

    // scale visible bar to 1 or 10 NM
    const scaleBarNM = atScale1NM ? 1 : 10;
    const scaleToPx = meterToPx(scaleBarNM * nmInMeter) * scaleFactor;
    scaleBar.scaleY(scaleToPx / scaleBar.height());

    // update position of legends
    const { y, height } = scaleBar.getClientRect({ skipShadow: true, skipStroke: true });
    scaleBarLegendTop.y(Math.max(setLegendTop, y));
    scaleBarLegendBottom.y(Math.min(viewport.dimension.height - 15, y + height));

    // update texts of legends
    scaleBarLegendTop.text(atScale1NM ? '0.1NM' : '2NM');
    scaleBarLegendBottom.text(useOwnshipScale ? 'at own ship' : 'at center');
  });
}
