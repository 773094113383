<template>
  <button class="panButton" :class="{ active, disabled, primary }" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/** @deprecated use <button class="btn"> instead */
export default defineComponent({
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
  },
  emits: ['click'],
});
</script>

<style scoped>
.panButton {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background: var(--button_background-default);
  border: 1px solid var(--button_border-default);
  border-radius: 2px;
  color: var(--primary);
  padding: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.48);

  font-size: 12px;
  line-height: 16px;
  color: var(--button_text);
  text-transform: uppercase;
  white-space: nowrap;

  cursor: pointer;
  outline: none;
}

.panButton.active {
  border: 1px solid black;
  background: var(--button_background-selected);
  color: var(--feedback_text);
}

.panButton.disabled {
  color: var(--button_text-disabled);
  cursor: default;
}
.panButton.primary {
  background: var(--button_text-selected);
}
</style>
