<template>
  <div class="editableText" @click="startEdit">
    <div v-show="isEditable" class="textbox">
      <input ref="textbox" v-model="edited" type="text" @change="changed" @blur="stopEdit" @keyup.enter="stopEdit" />
    </div>

    <ecdis-value v-show="!isEditable" class="value">
      {{ value }}
    </ecdis-value>
  </div>
</template>

<script lang="ts">
import { Ref, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const textbox: Ref<HTMLInputElement | undefined> = ref();
    const isEditable = ref(false);
    const value = computed(() => props.text);
    const edited = ref();

    const startEdit = () => {
      edited.value = value.value;
      isEditable.value = true;
      window.setTimeout(() => {
        textbox.value?.focus();
      }, 0);
    };
    const stopEdit = () => (isEditable.value = false);
    const changed = () => emit('changed', edited.value);

    return { isEditable, value, edited, startEdit, stopEdit, changed, textbox };
  },
});
</script>

<style scoped>
.editableText {
  display: flex;
  flex: 1;
  align-items: center;
  height: 47px;
  justify-content: flex-end;
}

.value {
  padding: 0 8px;
}

input {
  width: 100%;
  height: 47px;
  background-color: var(--input_background-default);
  color: var(--text_primary);
  font-size: 16px;
  border: transparent;
  outline: 1px solid #000000;
  padding: 8px;
  text-align: right;
}
</style>
