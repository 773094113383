<template>
  <signi-panel-select
    heading="Range"
    :options="enrichedOptions"
    :model-value="modelValue"
    unit="NM"
    @update:model-value="$emit('update:modelValue', $event)"
  ></signi-panel-select>
</template>

<script lang="ts" setup>
/**
 * Component to set the range from a list of dropdown menu options.
 */
import { computed } from 'vue';
import { SelectOption } from '@signi/common';

const props = defineProps<{ modelValue: number; options: SelectOption<number>[] }>();
defineEmits(['update:modelValue']);

const enrichedOptions = computed(() => props.options.map((item) => ({ ...item, label: item.id })));
</script>
