import { rest } from 'msw';

export const handlers = [
  rest.get('/api/mariner-note/myNotes', (_, res, ctx) =>
    res(
      ctx.json([
        {
          id: 'c65be0b1-55fc-4d62-b9a4-d26f4c527adc',
          companyId: 'c49a3b2c-ea36-483f-95ed-aa417d2d09c9',
          userId: 'e292976a-35a4-4865-bda1-60dd6c71313e',
          name: 'My first sailing course',
          description: '',
          notes: [
            {
              id: '3d234045-e6a3-416e-8287-f442dbeafb13',
              type: 'information',
              description: 'Test',
              index: 0,
              points: [{ lat: 1.2922634809258027, lng: -0.5912065689770348 }],
              createdAt: '2022-08-01T09:06:36.2669989+00:00',
              createdBy: 'e292976a-35a4-4865-bda1-60dd6c71313e',
              updatedBy: 'e292976a-35a4-4865-bda1-60dd6c71313e',
              updatedAt: '2022-08-01T09:06:36.267+00:00',
            },
          ],
        },
      ]),
    ),
  ),
];
