import numberFormatter from 'number-format.js';

export const NM_IN_METER = 1852;
export const MS_IN_KNOTS = 3600 / NM_IN_METER;
export const RADIANS_IN_DEGREE = 180 / Math.PI;
export const RADIUS_EARTH_METER = 6371000;
export const METER_IN_NM = 1 / NM_IN_METER;

const formatterFactory = (options?: Intl.NumberFormatOptions) => new Intl.NumberFormat('en-GB', options);
export const speedFormatter = formatterFactory({ maximumFractionDigits: 2, minimumFractionDigits: 2 });
export const degreesFormatter = formatterFactory({
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  minimumIntegerDigits: 3,
});

const longitudeFormatter = formatterFactory({ minimumIntegerDigits: 3 });
const latitudeFormatter = formatterFactory({ minimumIntegerDigits: 2 });
const minutesFormatter = formatterFactory({
  minimumIntegerDigits: 2,
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
});

const degToValue = (degree: number) => Math.floor(Math.abs(degree));
const degToMinutes = (degree: number) => (Math.abs(degree) % 1) * 60;
const radToDegrees = (radians: number) => {
  const degrees = degreesFormatter.format((radians % (Math.PI * 2)) * RADIANS_IN_DEGREE);
  return degrees === '360.0' ? '000.0' : degrees;
};

export const formatPositionDegree = (degree?: number, isLongitude?: boolean): string => {
  if (degree === undefined) return "--° --.---'";

  const absDegree = Math.abs(degree);
  const integer = Math.trunc(absDegree);
  const minutes = (absDegree - integer) * 60;
  const frac1 = twoDigitFormatter(Math.trunc(minutes));
  const frac2 = String(minutes).substring(3, 6);
  const formattedDegree = isLongitude ? threeDigitFormatter(integer) : integer;
  return `${formattedDegree}° ${frac1}.${frac2}’`;
};

export const twoDigitFormatter = (num?: number): string => (num !== undefined ? numberFormatter('00', num) : '--');

export const threeDigitFormatter = (num?: number): string => (num !== undefined ? numberFormatter('000', num) : '---');

export const createFormatter = formatterFactory;

export const formatSpeed = (speedInMs?: number) =>
  speedInMs !== undefined ? speedFormatter.format(speedInMs * MS_IN_KNOTS) : '-.--';

export const formatDistance = (distance?: number) =>
  distance !== undefined ? speedFormatter.format(distance) : '-.--';

export const formatRadToDegrees = (radians?: number) => (radians !== undefined ? radToDegrees(radians) : '---.-');

export const formatEta = (eta?: string): string => (eta ? eta.replaceAll(/\/|:/g, '-') : '-');

export const formatLongitude = (radians?: number) => {
  return radians !== undefined
    ? `${longitudeFormatter.format(degToValue(radians * RADIANS_IN_DEGREE))}° ${minutesFormatter.format(
        degToMinutes(radians * RADIANS_IN_DEGREE),
      )}’`
    : '---° --.---’';
};

export const formatLatitude = (radians?: number) => {
  return radians !== undefined
    ? `${latitudeFormatter.format(degToValue(radians * RADIANS_IN_DEGREE))}° ${minutesFormatter.format(
        degToMinutes(radians * RADIANS_IN_DEGREE),
      )}’`
    : '--° --.---’';
};

export const degToLatDirection = (degree?: number) => (degree === undefined ? '-' : degree < 0 ? 'S' : 'N');
export const degToLongDirection = (degree?: number) => (degree === undefined ? '-' : degree < 0 ? 'W' : 'E');
