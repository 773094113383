const D2R = 0.017453292519943295;
const R2D = 57.29577951308232;
const TWO_PI = Math.PI * 2;
const HALF_PI = Math.PI / 2;
const FORTPI = Math.PI / 4;
const EPSLN = 1.0e-10;
// SPI is slightly greater than Math.PI, so values that exceed the -180..180
// degree range by a tiny amount don't get wrapped. This prevents points that
// have drifted from their original location along the 180th meridian (due to
// floating point error) from changing their sign.
const SPI = 3.14159265359;

const A = 6378137;

function checkCoord(num: number) {
  if (!Number.isFinite(num)) {
    throw TypeError('coordinates must be finite numbers');
  }
}

function sign(x: number) {
  return x < 0 ? -1 : 1;
}

function adjustLon(x: number) {
  return Math.abs(x) <= SPI ? x : x - sign(x) * TWO_PI;
}

export function forward({ lng, lat }: { lng: number; lat: number }): { mX: number; mY: number } {
  checkCoord(lng);
  checkCoord(lat);

  if (lat > 90 || lat < -90) {
    throw Error('Out of bounds');
  }

  const lngRad = lng * D2R;
  const latRad = lat * D2R;

  if (Math.abs(Math.abs(latRad) - HALF_PI) <= EPSLN) {
    throw Error('Lat must be smaller than (90 degrees in radians) - 1.0e-10');
  }

  const mX = A * lngRad;
  const mY = A * Math.log(Math.tan(FORTPI + 0.5 * latRad));
  return { mX, mY };
}

export function inverse({ mX, mY }: { mX: number; mY: number }): { lng: number; lat: number } {
  checkCoord(mX);
  checkCoord(mY);

  const lngRad = adjustLon(mX / A);
  const latRad = HALF_PI - 2 * Math.atan(Math.exp(-mY / A));

  const lng = lngRad * R2D;
  const lat = latRad * R2D;
  return { lng, lat };
}

/**
 * returns the scaling factor needed to
 * represent a shape accurately on mercator map
 * @param lat latitude in degrees
 */
export function getScalingFactor(lat: number): number {
  checkCoord(lat);
  const cos = Math.cos(lat * D2R);

  if (cos < 0.01) {
    throw Error('Out of bounds');
  }
  return 1 / cos;
}

/**
 * Returns the relative difference in scaling factor between two latitudes
 * @param lat1 latitude in degrees
 * @param lat2 latitude in degrees
 */
function calcRelativeScalingDifference(lat1: number, lat2: number): number {
  const scale1 = getScalingFactor(lat1);
  const scale2 = getScalingFactor(lat2);
  return Math.abs(scale1 - scale2) / scale1;
}

export function calcScaleConformity(lat1: number, latitudes: number[]): number {
  return Math.max(...latitudes.map((lat2) => calcRelativeScalingDifference(lat1, lat2)));
}
