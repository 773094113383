import { configuration } from '../configuration';
import { ProductCatalogClient } from '../generated/ProductCatalog';
import { fetch } from '../utils/http';
import { createClientPromise } from './createClientPromise';

export const getProductCatalogClient = (): Promise<ProductCatalogClient> =>
  createClientPromise(
    'ProductCatalogClient',
    () =>
      new ProductCatalogClient(configuration.baseUrls.productCatalog, {
        fetch,
      }),
  );
