import { computed, reactive, readonly } from 'vue';
import { ContextType, defineContext } from 'vue-context-composition';
import { isPlanningStation } from '../configuration';
import { NoteType } from '../generated/Navigation';
import { PixelPoint, ShapeMetadata } from '../types';
import { MarinerNoteHeadings, MarinerNoteIcons, showMarinersNote } from '../utils/marinersNotesUtils';
import { shouldAllowActivateDeactiveSleepingTarget } from '../utils/targetUtils';
import { routesCtx } from './routes';

type State = {
  visible: boolean;
  x: number;
  y: number;
  data: ShapeMetadata;
  editing: boolean;
};

const marinersNotesItems: Array<ContextMenuItem> = [
  {
    name: MarinerNoteHeadings[NoteType.Event],
    separatorRequired: false,
    iconType: MarinerNoteIcons[NoteType.Event],
    action: 'addEventNote',
    available: () => showMarinersNote(NoteType.Event, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.Caution],
    separatorRequired: false,
    iconType: MarinerNoteIcons[NoteType.Caution],
    action: 'addCautionNote',
    available: () => showMarinersNote(NoteType.Caution, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.Information],
    separatorRequired: true,
    iconType: MarinerNoteIcons[NoteType.Information],
    action: 'addInformationNote',
    available: () => showMarinersNote(NoteType.Information, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.DashedLine],
    separatorRequired: false,
    iconType: MarinerNoteIcons[NoteType.DashedLine],
    action: 'addDashedLineNote',
    available: () => showMarinersNote(NoteType.DashedLine, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.SolidLine],
    separatorRequired: false,
    iconType: MarinerNoteIcons[NoteType.SolidLine],
    action: 'addSolidLineNote',
    available: () => showMarinersNote(NoteType.SolidLine, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.SafetyContour],
    separatorRequired: true,
    iconType: MarinerNoteIcons[NoteType.SafetyContour],
    action: 'addSafteyContourNote',
    available: () => showMarinersNote(NoteType.SafetyContour, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.UnfilledArea],
    separatorRequired: false,
    iconType: MarinerNoteIcons[NoteType.UnfilledArea],
    action: 'addUnfilledAreaNote',
    available: () => showMarinersNote(NoteType.UnfilledArea, isPlanningStation.value),
  },
  {
    name: MarinerNoteHeadings[NoteType.FilledArea],
    separatorRequired: false,
    iconType: MarinerNoteIcons[NoteType.FilledArea],
    action: 'addFilledAreaNote',
    available: () => showMarinersNote(NoteType.FilledArea, isPlanningStation.value),
  },
];

const contextMenuItems: Array<ContextMenuItem> = [
  {
    name: 'Zoom in',
    separatorRequired: false,
    iconType: 'ZoomInIcon',
    action: 'zoomIn',
    available: () => true,
  },
  {
    name: 'Zoom out',
    separatorRequired: true,
    iconType: 'ZoomOutIcon',
    action: 'zoomOut',
    available: () => true,
  },
  {
    name: 'Show chart objects',
    separatorRequired: true,
    action: 'ChartObjectInfo',
    available: () => true,
  },
  {
    name: 'Insert waypoint on leg',
    separatorRequired: true,
    action: 'InsertWPT',
    available: (state) => state.editing && state.data.type === 'leg',
    iconType: 'WaypointInsertIcon',
  },
  {
    name: 'Delete waypoint',
    separatorRequired: true,
    action: 'DelWPT',
    available: (state) => state.editing && state.data.type === 'waypoint',
    iconType: 'WaypointRemoveIcon',
  },
  {
    name: 'Add waypoint to route',
    separatorRequired: true,
    action: 'AppendWPT',
    available: (state) => state.editing && state.data.type === undefined,
    iconType: 'WaypointAddIcon',
  },
  {
    name: 'Add index line',
    separatorRequired: true,
    action: 'AddIndexLine',
    available: (state) => state.editing && state.data.type === 'leg',
  },
  {
    name: 'Remove index line',
    separatorRequired: true,
    action: 'RemoveIndexLine',
    available: (state) => state.editing && state.data.type === 'indexLine',
  },
  {
    name: 'Add EBL/VRM',
    separatorRequired: true,
    action: 'AddEblVrm',
    available: (state) => state.data.type !== 'eblVrm',
  },
  {
    name: 'Delete EBL/VRM',
    separatorRequired: true,
    action: 'DeleteEblVrm',
    available: (state) => state.data.type === 'eblVrm',
  },
  {
    name: 'Show EBL/VRM in menu',
    separatorRequired: true,
    action: 'ShowEblVrmInMenu',
    available: (state) => state.data.type === 'eblVrm',
  },
  {
    name: 'Activate',
    separatorRequired: true,
    action: 'ShowActivateSleepingTarget',
    available: (state) =>
      state.data.type === 'target' && shouldAllowActivateDeactiveSleepingTarget(state.data.target, true),
  },
  {
    name: 'De-activate',
    separatorRequired: true,
    action: 'HideActivateSleepingTarget',
    available: (state) =>
      state.data.type === 'target' && shouldAllowActivateDeactiveSleepingTarget(state.data.target, false),
  },
  {
    name: 'Add critical point',
    separatorRequired: true,
    action: 'AddCritialPoint',
    available: (state) => state.editing && state.data.type === 'leg',
  },
  {
    name: 'Show critical points',
    separatorRequired: true,
    action: 'ShowCriticalPoints',
    available: (state) => state.data.type === 'leg',
  },
  {
    name: 'Add mariners notes',
    separatorRequired: true,
    action: 'OpenMarinersNotesList',
    iconType: 'MarinersNotesIcon',
    subList: marinersNotesItems,
    available: () => true,
  },
];

export type ContextMenuItem = {
  name: string;
  separatorRequired: boolean;
  action: ContextMenuAction;
  iconType?: string;
  subList?: ContextMenuItem[];
  available: (state: State) => boolean;
};

type ContextMenuAction =
  | 'none'
  | 'zoomIn'
  | 'zoomOut'
  | 'InsertWPT'
  | 'DelWPT'
  | 'AppendWPT'
  | 'ChartObjectInfo'
  | 'AddIndexLine'
  | 'RemoveIndexLine'
  | 'ShowEblVrmInMenu'
  | 'AddEblVrm'
  | 'DeleteEblVrm'
  | 'ShowActivateSleepingTarget'
  | 'HideActivateSleepingTarget'
  | 'AddCritialPoint'
  | 'ShowCriticalPoints'
  | 'OpenMarinersNotesList'
  | 'addEventNote'
  | 'addCautionNote'
  | 'addInformationNote'
  | 'addDashedLineNote'
  | 'addSolidLineNote'
  | 'addSafteyContourNote'
  | 'addUnfilledAreaNote'
  | 'addFilledAreaNote';

export const contextMenuCtx = defineContext(() => {
  const state: State = reactive({
    visible: false,
    x: 0,
    y: 0,
    data: {},
    editing: false,
  });

  const availableContextMenuItems = computed(() => {
    return contextMenuItems.filter((i) => i.available(state));
  });

  const showContextMenu = (
    pos: PixelPoint,
    data: ShapeMetadata | undefined,
    routesState: ContextType<typeof routesCtx>,
  ) => {
    state.data = data ?? {};
    state.editing = routesState.state.editId != undefined;
    state.x = pos.x;
    state.y = pos.y;
    state.visible = true;
  };

  const hideContextMenu = () => {
    state.visible = false;
    state.data = {};
    state.editing = false;
  };

  const getSubMenuList = (actionType: string) => {
    const matchList = availableContextMenuItems.value.find((item) => {
      return item.action === actionType;
    });
    if (matchList?.subList) {
      return matchList.subList.filter((option) => option.available(state));
    }
    return [];
  };

  return {
    state: readonly(state),
    showContextMenu,
    hideContextMenu,
    availableContextMenuItems,
    getSubMenuList,
  };
});
