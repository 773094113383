import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { viewportCtx } from '../../../contexts/viewport';
import { halfPi, lngLatToMeter, radInDeg } from '../../../utils/conversions';
import { DistanceMarkerWithLabel, colors } from './addRoutes';

export function addDistanceMarker(
  dm: DistanceMarkerWithLabel,
  { transformToAbsolute }: ContextType<typeof viewportCtx>,
  distanceMarkerGroup: Konva.Group,
  isMonitored: boolean,
): void {
  if (!dm.marker.position || !dm.marker.direction) return;

  const point = lngLatToMeter({
    lng: radInDeg * (dm.marker.position?.longitude ?? 0),
    lat: radInDeg * halfPi(dm.marker.position?.latitude ?? 0),
  });
  const halfMarkerLength = isMonitored ? 6 : 5;
  const { x, y } = transformToAbsolute(point);
  const marker = new Konva.Line({
    points: [-halfMarkerLength, 0, 0, 0, halfMarkerLength, 0],
    x: x,
    y: y,
    rotation: (dm.marker.direction ?? 0) * radInDeg,
    stroke: colors.editTrack,
    strokeWidth: isMonitored ? 5 : 3,
    strokeScaleEnabled: false,
  });

  const displacementX = Math.cos(dm.marker.direction) * 40;
  const displacementY = Math.sin(dm.marker.direction) * 20;

  const label = new Konva.Text({
    x: x + displacementX,
    y: y + displacementY,
    width: 100,
    height: 100,
    offsetX: 50,
    offsetY: 50,
    text: dm.label,
    fontSize: 14,
    fontFamily: 'Lato',
    fill: colors.editTrack,
    align: 'center',
    verticalAlign: 'middle',
  });

  distanceMarkerGroup.add(marker);
  distanceMarkerGroup.add(label);
}
