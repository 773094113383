import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { ContextType } from 'vue-context-composition';
import { routesCtx } from '../../../contexts/routes';
import { toolTipCtx } from '../../../contexts/toolTip';
import { viewportCtx } from '../../../contexts/viewport';
import { IndexLine } from '../../../generated/RouteManagement';
import { MeterPoint, ShapeMetadata } from '../../../types';
import { calculateDistance, degInRad, latLonPointToMeter, meterInNm, meterToLngLat } from '../../../utils/conversions';
import { getScalingFactor } from '../../../utils/mercator';
import { vector } from '../../../utils/vector';

export function addIndexLines(
  indexlinesGroup: Konva.Group,
  viewport: ContextType<typeof viewportCtx>,
  route: ContextType<typeof routesCtx>,
  tooltip: ContextType<typeof toolTipCtx>,
  indexLine: IndexLine,
  legCourse: number,
): void {
  if (!indexLine?.legId) return;
  const leg = route.findLegById(indexLine.legId);

  if (!leg?.fromWaypoint || !leg.toWaypoint) return;
  const fromWayPoint = route.findWaypoint(leg.fromWaypoint);

  if (!fromWayPoint?.latitude || !fromWayPoint?.longitude) return;

  const fromWayPointMeter = latLonPointToMeter({
    latitude: (fromWayPoint?.latitude ?? 0) * degInRad,
    longitude: (fromWayPoint?.longitude ?? 0) * degInRad,
  });

  const toWayPoint = route.findWaypoint(leg.toWaypoint);

  const colors = {
    indexLine: 'rgb(255, 132, 60)',
    anchor: 'rgb(255, 132, 60)',
  };

  const stage = indexlinesGroup.getStage();

  const indexlineGroup = new Konva.Group({
    id: indexLine.indexLineId,
    draggable: true,
  });
  indexlineGroup.on('mousedown', (ev) => {
    ev.cancelBubble = true;
  });
  indexlineGroup.on('mouseenter', (ev) => {
    ev.cancelBubble = true;
    if (stage?.container().style) stage.container().style.cursor = 'move';
  });

  indexlineGroup.on('dragend', (ev) => {
    ev.cancelBubble = true;
    updatePosition(ev.target.id());
    tooltip.hide();
  });

  indexlineGroup.on('dragmove', () => {
    showDistanceToolTip();
  });

  const rotatedStartVector = vector(indexLine.offsetMeterX, indexLine.offsetMeterY);
  /*Draw the index line(start point) with the offset to the current Leg From-Way-point position ,
   rotating back to its own angle i.e. angle of Offset from Leg From-Way-point to Index line start position
  */
  const startVector = rotatedStartVector.setAngle(rotatedStartVector.angle() - (90 - (leg.course ?? 0)) * degInRad);
  //Add offset(with rotation) to the Leg From-WayPoit
  const initialPoint = {
    mX: startVector.x + fromWayPointMeter.mX,
    mY: startVector.y + fromWayPointMeter.mY,
  };

  const line = new Konva.Line({
    id: indexLine.indexLineId,
    points: [
      initialPoint.mX,
      initialPoint.mY,
      initialPoint.mX +
        (indexLine.length ?? 0) * Math.cos((90 - legCourse) * degInRad) * getScalingFactor(fromWayPoint.latitude ?? 0),
      initialPoint.mY +
        (indexLine.length ?? 0) * -Math.sin((90 - legCourse) * degInRad) * getScalingFactor(fromWayPoint.latitude ?? 0),
    ],
    stroke: colors.indexLine,
    strokeWidth: 2,

    strokeScaleEnabled: false,
    dash: [10, 4],
    hitStrokeWidth: 20,
    draggable: false,
    data: <ShapeMetadata>{
      type: 'indexLine',
      uuid: indexLine.indexLineId,
    },
  });

  let timeout = 0;
  line.on('mouseover', () => {
    tooltip.hide();
    timeout = window.setTimeout(showDistanceToolTip, 300);
  });
  line.on('mouseout', () => {
    window.clearTimeout(timeout);
    tooltip.hide();
  });

  const achorWH = viewport.pxToMeter(4);
  const startAnchor = new Konva.Circle({
    id: indexLine.indexLineId,
    x: line.points()[0],
    y: line.points()[1],
    radius: achorWH,
    stroke: colors.anchor,
    strokeWidth: 3,
    hitStrokeWidth: 30,
    draggable: true,
    fill: colors.anchor,
    dragBoundFunc: (pos) =>
      dragBoundFunction(
        pos,
        { mX: line.points()[0], mY: line.points()[1] },
        { mX: line.points()[2], mY: line.points()[3] },
      ),
  });
  indexlineGroup.add(startAnchor);

  const endAnchor = new Konva.Circle({
    id: indexLine.indexLineId,
    x: line.points()[2],
    y: line.points()[3],
    radius: achorWH,
    stroke: colors.anchor,
    strokeWidth: 3,
    hitStrokeWidth: 300,
    draggable: true,
    fill: colors.anchor,
    dragBoundFunc: (pos) =>
      dragBoundFunction(
        pos,
        { mX: line.points()[2], mY: line.points()[3] },
        { mX: line.points()[0], mY: line.points()[1] },
      ),
  });
  indexlineGroup.add(endAnchor);

  indexlineGroup.add(line);

  indexlinesGroup.add(indexlineGroup);
  indexlinesGroup.moveToTop();

  startAnchor.on('mouseenter', (ev) => {
    ev.cancelBubble = true;
    ev.target.moveToTop();
    if (stage?.container().style) stage.container().style.cursor = 'crosshair';
  });
  endAnchor.on('mouseenter', (ev) => {
    ev.cancelBubble = true;
    ev.target.moveToTop();
    if (stage?.container().style) stage.container().style.cursor = 'crosshair';
  });
  startAnchor.on('dragmove', (ev) => {
    ev.cancelBubble = true;
    if (stage?.container().style) stage.container().style.cursor = 'crosshair';
    updateLine();
  });
  endAnchor.on('dragmove', (ev) => {
    ev.cancelBubble = true;
    updateLine();
  });
  startAnchor.on('dragend', (ev) => {
    ev.cancelBubble = true;
    updatePosition(ev.target.id());
  });
  endAnchor.on('dragend', (ev) => {
    ev.cancelBubble = true;
    updatePosition(ev.target.id());
  });

  const updateLine = () => {
    const points = [startAnchor.x(), startAnchor.y(), endAnchor.x(), endAnchor.y()];
    line.points(points);
  };
  /* 
    Retsrict resize drag only parallel to the leg
    pos: Drag position of the user
    currentPos: current Position of the anchor dragged by User
    endPos: Other end(anchor) of the index line dragged by User
  */
  const dragBoundFunction = (pos: Vector2d, currentPos: MeterPoint, endPos: MeterPoint): Vector2d => {
    const currentPosAbs = viewport.transformToAbsolute(currentPos);

    const endPosAbs = viewport.transformToAbsolute(endPos);
    const endPosVector = vector(endPosAbs.x, endPosAbs.y);
    const currentPosVector = vector(currentPosAbs.x, currentPosAbs.y);
    const newTurnVector = vector(pos.x, pos.y);

    //Scalar projection
    const dragPos = currentPosVector.subtract(endPosVector).setLength(1);
    const dragPosToNewDragPos = newTurnVector.subtract(currentPosVector);

    const magnitude = dragPosToNewDragPos.dot(dragPos);
    const change = dragPos.setLength(magnitude);

    return { x: currentPosVector.x + change.x, y: currentPosVector.y + change.y };
  };

  const updatePosition = (indexLineId: string) => {
    if (!fromWayPoint?.latitude || !fromWayPoint?.longitude) return;

    const startPosition = viewport.transformToViewport(startAnchor.absolutePosition());
    const endPosition = viewport.transformToViewport(endAnchor.absolutePosition());

    const newStartPosition = meterToLngLat(startPosition);
    const newEndPosition = meterToLngLat(endPosition);

    const startVector = vector(
      startPosition.mX - (fromWayPointMeter.mX ?? 0),
      startPosition.mY - (fromWayPointMeter.mY ?? 0),
    );

    //R
    const rotatedStartVector = startVector.setAngle(startVector.angle() + (90 - (leg.course ?? 0)) * degInRad);

    route.updateIndexLine(
      indexLineId,
      { mX: rotatedStartVector.x, mY: rotatedStartVector.y },
      calculateDistance(
        { lng: (newStartPosition?.lng ?? 0) * degInRad, lat: (newStartPosition?.lat ?? 0) * degInRad },
        { lng: (newEndPosition?.lng ?? 0) * degInRad, lat: (newEndPosition?.lat ?? 0) * degInRad },
      ),
    );
  };

  const showDistanceToolTip = () => {
    const toWayPointMeter = latLonPointToMeter({
      latitude: (toWayPoint?.latitude ?? 0) * degInRad,
      longitude: (toWayPoint?.longitude ?? 0) * degInRad,
    });

    //Leg position vector
    const fromWpVector = vector(fromWayPointMeter.mX, fromWayPointMeter.mY);
    const toWpVector = vector(toWayPointMeter.mX, toWayPointMeter.mY);
    //Index line starting point position vector
    const startPosition = viewport.transformToViewport(startAnchor.absolutePosition());
    const indexLineVector = vector(startPosition.mX, startPosition.mY);

    //Scalar projection FROM and TO waypoint of leg
    const legVector = toWpVector.subtract(fromWpVector).setLength(1);
    const rotatedLegVector = legVector.setAngle(legVector.angle() + Math.PI / 2);
    //Scalar projection of START point of Leg and Index Line START point
    const indexLineToWpVector = indexLineVector.subtract(fromWpVector);

    //Distance of Index line from Leg
    const distance =
      (Math.abs(indexLineToWpVector.dot(rotatedLegVector)) * meterInNm) /
      getScalingFactor(meterToLngLat(viewport.viewport.center).lat);

    //Pointer position for tool tip
    const pointerPosition = stage?.getPointerPosition();
    if (pointerPosition)
      tooltip.show(pointerPosition, [
        { title: 'Index line for leg', value: fromWayPoint?.id?.toString() ?? '', unit: '' },
        { title: 'Range', value: distance.toFixed(2), unit: 'Nm' },
      ]);
  };
}
