<template>
  <signi-panel-select
    heading="Orientation"
    :options="options"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #selected="{ value }">{{ optionLabelsMap[value] }}</template>
    <template #option="{ value }">{{ optionLabelsMap[value] }}</template>
  </signi-panel-select>
</template>

<script setup lang="ts">
import { SelectOption } from '../select/types';

defineProps<{ modelValue?: keyof typeof optionLabelsMap; options: SelectOption<string>[] }>();
defineEmits(['update:modelValue']);

const optionLabelsMap = {
  // RADAR
  HeadUp: 'Head Up',
  NorthUp: 'North Up',
  CourseUp: 'Course Up',

  // ECDIS
  H: 'Head Up',
  N: 'North Up',
  C: 'Course Up',
};
</script>
