<template>
  <pan-row>
    <div class="heading">TARGETS</div>
    <icon-gear
      class="settings"
      data-test-id="targetSettings"
      :class="{ active: isTargetSettingsVisible }"
      @click="toggleVisibility"
    />
  </pan-row>
  <ecdis-targets-overview />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useContext } from 'vue-context-composition';
import { targetSettingsCtx } from '../../../contexts/targetSettings';

export default defineComponent({
  emits: ['emitEvent'],
  setup() {
    const { state: targetSettingsState, toggleVisibility } = useContext(targetSettingsCtx);
    const isTargetSettingsVisible = computed(() => targetSettingsState.visible);
    return {
      toggleVisibility,
      isTargetSettingsVisible,
    };
  },
});
</script>
<style scoped>
.heading {
  color: #ffffff;
  font-size: 16px;
  padding: 8px;
  width: 142px;
}
.settings {
  margin-right: 8px;
  margin-left: 370px;
  cursor: pointer;
}
.settings.active {
  background: var(--button_background-selected);
}
.settings.active ::v-deep(*) {
  stroke: var(--button_text-selected);
}
</style>
