<template>
  <template v-if="isLoading">
    <slot name="loading"></slot>
  </template>
  <template v-else>
    <template v-if="!errorMessage && !userLoggedOut">
      <template v-if="authorized">
        <slot name="authorized"></slot>
      </template>
      <template v-else>
        <slot name="not_authorized"></slot>
      </template>
    </template>
    <template v-else>
      <template v-if="errorMessage">
        <slot name="error" :error-message="errorMessage"></slot>
      </template>
      <template v-else>
        <slot name="logout"></slot>
      </template>
    </template>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, toRef, watch } from 'vue';
import user from '../../user';
import { useAsync } from '../../utils/useAsync';

export default defineComponent({
  setup() {
    const { isLoading, error } = useAsync(user.load);
    const authorized = toRef(user, 'isAuthorized');
    const userLoggedOut = computed(() => user.state.sessionId == undefined);
    watch(error, () => {
      user.setUserError(error.value?.detail ?? 'An unexpected error occured, please refresh the page');
    });
    const errorMessage = user.errorMessage.value;

    return {
      isLoading,
      authorized,
      errorMessage,
      userLoggedOut,
    };
  },
});
</script>
