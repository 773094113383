import {
  CriticalPoint,
  DistanceMarkerConfiguration,
  Leg,
  Projection,
  Route as ValidationRoute,
  Waypoint as ValidationRouteWaypoint,
} from '../generated/ChartServer';
import { GeometryType, Route, Leg as RouteManagementLeg, Waypoint } from '../generated/RouteManagement';
import { degInRad, mpsInKnots, nmInMeter } from './conversions';

export function toValidationRoute(
  route: Route,
  safetyContour = 30,
  distanceMarkerConfiguration?: DistanceMarkerConfiguration,
): ValidationRoute | undefined {
  if (!route.waypoints) return undefined;

  //Explicitly stated fallback values
  const fallbackTurnRadius = 0;
  const fallbackXTD = 0;
  const fallbackSpeed = 0;
  const fallbackLatitude = 0;
  const fallbackLongitude = 0;

  const getLeg = (waypointId: string) => route.legs?.find((leg) => leg.fromWaypoint === waypointId);

  return <ValidationRoute>{
    safetyContour,
    waypoints: route.waypoints.map((w) => {
      const leg = getLeg(w.waypointId ?? '');
      return <ValidationRouteWaypoint>{
        waypointId: w.waypointId,
        latitude: (w.latitude ?? fallbackLatitude) * degInRad,
        longitude: (w.longitude ?? fallbackLongitude) * degInRad,
        turnRadius: (w.radius ?? fallbackTurnRadius) * nmInMeter,
        speed: (leg?.speedPlanned ?? fallbackSpeed) / mpsInKnots,
        leg: <Leg>{
          projection: leg?.geometryType === GeometryType.Orthodrome ? Projection.GreatCircle : Projection.RhumbLine,
          starboardSideXtd: (leg?.starboardXTD ?? fallbackXTD) * nmInMeter,
          portSideXtd: (leg?.portsideXTD ?? fallbackXTD) * nmInMeter,
        },
      };
    }),
    distanceMarkerConfiguration,
    criticalPoints: generateCriticalPointPayload(route.waypoints),
  };
}

function generateCriticalPointPayload(waypoints: Waypoint[]) {
  const criticalPointArray: CriticalPoint[] = [];
  waypoints.forEach((w) => {
    if (w.criticalPoints && w.criticalPoints.length > 0) {
      w.criticalPoints?.forEach((cp) => {
        criticalPointArray.push({
          ...cp,
          waypointId: w.waypointId,
        });
      });
    }
  });
  return criticalPointArray;
}

//Leg is undefined for the final waypoint of a route
export type WaypointLegPair = { waypoint: Waypoint; leg: RouteManagementLeg | undefined };

export function toWaypointLegPairs(route: Route): WaypointLegPair[] | undefined {
  return route.waypoints?.map((w) => {
    return { waypoint: w, leg: route.legs?.find((l) => l.fromWaypoint === w.waypointId) };
  });
}
