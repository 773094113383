import { createGlobalState } from '@vueuse/core';
import { computed, reactive, readonly } from 'vue';
import { Ownship } from '../types';
import { degInRad, lngLatToMeter, meterToLngLat } from '../utils/conversions';
import { vector } from '../utils/vector';

type State = {
  ownship?: Ownship;
};

export const useOwnship = createGlobalState(() => {
  const state: State = reactive({
    ownship: undefined,
  });

  const setOwnship = (ownship: Ownship) => {
    state.ownship = ownship;
  };

  const ownshipMeterPoint = computed(() => {
    if (state.ownship === undefined) return;

    const gpsMeterPoint = lngLatToMeter({ lng: state.ownship.gpsLng, lat: state.ownship.gpsLat });
    const antennaToCcrp = vector(state.ownship.ccrp.x, state.ownship.ccrp.y).subtract(
      vector(state.ownship.gpsAntenna.x, state.ownship.gpsAntenna.y),
    );
    const ccrpCorrection = antennaToCcrp.setAngle(antennaToCcrp.angle() + Math.PI + state.ownship.heading * degInRad);
    return { mX: gpsMeterPoint.mX + ccrpCorrection.x, mY: gpsMeterPoint.mY + ccrpCorrection.y };
  });

  /*
    This is the position of the ship's CCRP
    If you want the ship position, this is what you want to use
    Ownship.state.gpsLng/gpsLat is the sensor data from the gpsAntenna
   */
  const ownshipLngLatInDeg = computed(() =>
    ownshipMeterPoint.value ? meterToLngLat(ownshipMeterPoint.value) : undefined,
  );

  return {
    state: readonly(state),
    setOwnship,
    ownshipMeterPoint,
    ownshipLngLatInDeg,
  };
});
