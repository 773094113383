import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { viewportCtx } from '../../../contexts/viewport';
import { WaypointSpeedCourseLabel } from '../../../utils/routeUtils';
import { colors } from './addRoutes';

export function addWaypointPlannedSpeedLabel(
  { speedMeterPoint, speed }: WaypointSpeedCourseLabel,
  waypointPlannedSpeedLabelGroup: Konva.Group,
  viewport: ContextType<typeof viewportCtx>,
): void {
  const { x, y } = viewport.transformToAbsolute(speedMeterPoint);
  const wptSpeedRect = new Konva.Rect({
    x,
    y,
    width: 44,
    height: 20,
    offsetX: 22,
    offsetY: 10,
    strokeWidth: 1,
    stroke: colors.editTrack,
    strokeScaleEnabled: false,
  });
  const wptSpeedText = new Konva.Text({
    x,
    y,
    width: 50,
    height: 50,
    offsetX: 25,
    offsetY: 25,
    text: speed,
    fontFamily: 'Lato',
    stroke: colors.editTrack,
    align: 'center',
    verticalAlign: 'middle',
    fontSize: 14,
  });
  waypointPlannedSpeedLabelGroup.add(wptSpeedRect);
  waypointPlannedSpeedLabelGroup.add(wptSpeedText);
}
