export enum AlertCategoryData {
  A,
  B,
  NotApplicable = 99,
}

export type AlertData = {
  Id: string;
  Category: AlertCategoryData;
  Description: string;
  Level: AlertLevelData;
  Text: string;
  Active: boolean;
  Acknowledged: boolean;
  Time: string;
  Type: AlertTypeData;
  IsSelected?: boolean;
  SortedIndex?: number;
};

export enum AlertLevelData {
  Caution,
  Warning,
  Alarm,
}

export enum AlertTypeData {
  None,
  PrimaryPosition,
  SecondaryPosition,
  PrimarySog,
  SecondarySog,
  PrimaryStw,
  SecondaryStw,
  PrimaryHeading,
  SecondaryHeading,
  PrimaryCog,
  SecondaryCog,
  PrimaryDepth,
  SecondaryDepth,
  CcrpDeviation,
  CrossTrackLimit,
  MonitoringStopped,
  CriticalPointMonitoring,
}

export type LngLatPoint = {
  lng: number;
  lat: number;
};

export type MeterPoint = {
  mX: number;
  mY: number;
};

export type PixelPoint = {
  x: number;
  y: number;
};

export type PixelDimension = {
  width: number;
  height: number;
};

export type Scaling = {
  // scale
  scale: number;
  // dots per meter
  dpm: number;
};

/*
A two dimensional position in meters relative to the ship
For positioning objects inside the ship
The origin is at the stern / centre line intersection
Y has positive direction ahead
X has positive direction starboard
 /''|''\
/   |   \
|   |   |
|   |   |
|   |   |
|___o___|
 */
export type InternalPosition = {
  x: number;
  y: number;
};

export type Ownship = {
  gpsLng: number;
  gpsLat: number;
  heading: number;
  course: number;
  speedOverGround: number;
  ccrp: InternalPosition;
  gpsAntenna: InternalPosition;
  width: number;
  length: number;
  contour: number[];
  speedThroughWater: number;
  depth: number;
  time: number;
};

export type Rotation = {
  angle: number;
};

export type Viewport = {
  // size of viewport in pixels
  dimension: PixelDimension;
  // center of viewport in meters
  center: MeterPoint;
  // scaling parameters
  scaling: Scaling;
  // rotation parameters
  rotation: Rotation;
  // transform matrix
  matrix: number[];
  // throttled values to watch for chart requests
  throttled: {
    dimension: PixelDimension;
    center: MeterPoint;
    scaling: Scaling;
    rotation: Rotation;
  };
};

export type DisplayGroup = {
  id: string;
  desc: string;
  children?: DisplayGroup[];
};

export enum DangerState {
  None = 'None',
  Alarm = 'Alarm',
  Acknowledged = 'Acknowledged',
}

export type TargetData = {
  Number: number;
  Name: string;
  Latitude: number;
  Longitude: number;
  Course: number;
  Speed: number;
  Status: string;
  Type: string;
  IsReferenceTarget: boolean;
  AcquisitionType: string;
  Origin: string;
  Heading: number;
  RateOfTurn: number;
  IsSart: boolean;
  Cpa: number;
  TCpa: number;
  Range: number;
  Bearing: number;
  CallSign: string;
  MMSI: string;
  ShipLength: string;
  ShipWidth: string;
  Destination: string;
  MaxDraught: string;
  ETA: string;
  NavigationalStatus: string;
  PositionAccuracy: string;
  DangerState: DangerState;
  Sleeping: boolean;
  ForceAwake?: boolean;
};

export type ParamValue = {
  value: string;
  unit: string;
};

export type SensorInfo = {
  sensorId: string;
  available: boolean;
  active: boolean;
};

export type Sensors = {
  positionSensors: SensorInfo[];
  sogSensors: SensorInfo[];
  stwSensors: SensorInfo[];
  headingSensors: SensorInfo[];
  cogSensors: SensorInfo[];
  depthSensors: SensorInfo[];
};

export type ShapeType =
  | 'waypoint'
  | 'leg'
  | 'eblVrm'
  | 'ownship'
  | 'shipOutline'
  | 'indexLine'
  | 'target'
  | 'criticalpoint'
  | 'bearing'
  | 'range';

export type ShapeMetadata = {
  type?: ShapeType;
  uuid?: string;
  target?: TargetData;
  showMonitoringToolTipMetaData?: boolean;
};

export type EtaInfo = {
  WaypointId: string;
  DistanceToWaypoint: number;
  Time: number;
};

export type SailData = {
  ActiveWaypointId: string;
  EtaList: EtaInfo[];
  AccelerationLength: number;
  BearingToTurnPoint: number;
  BearingToWop: number;
  CrossTrackError: number;
  CrossTrackErrorLimitPort: number;
  CrossTrackErrorLimitStarboard: number;
  CrossTrackErrorSpeed: number;
  DistanceToTurnPoint: number;
  DistanceToTurnPointForNextWaypoint: number;
  DistanceToWol: number;
  DistanceToWop: number;
  IsInTurn: boolean;
  IsLastWaypointOnRoute: boolean;
  IsRouteEnded: boolean;
  ProjectedPointLatitude: number;
  ProjectedPointLongitude: number;
  RateOfTurnAtWop: number;
  RemainingSegmentLength: number;
  TangentialBearing: number;
  TimeToWop: number;
  TurnPreWarning: boolean;
  TurnWarning: boolean;
};
