import Konva from 'konva';
import { DeepReadonly } from 'vue';
import { EnrichedWaypoint } from '../../../generated/ChartServer';
import { latLonPointsToPoints } from '../../../utils/conversions';
import { splitAtDateLine } from '../../../utils/routeUtils';
import { colors } from './addRoutes';

export function addOfftrackLines(wp: DeepReadonly<EnrichedWaypoint>, xtdGroup: Konva.Group): void {
  const stbdTracks = wp?.starboardXtdTrack ?? [];
  splitAtDateLine(stbdTracks).forEach((points) => {
    if (!points) return;

    const line = new Konva.Line({
      points: latLonPointsToPoints(points),
      stroke: colors.editTrack,
      strokeWidth: 1,
      strokeScaleEnabled: false,
      dash: [3, 3],
    });
    xtdGroup.add(line);
  });

  const portTracks = wp?.portXtdTrack ?? [];
  splitAtDateLine(portTracks).forEach((points) => {
    if (!points) return;

    const line = new Konva.Line({
      points: latLonPointsToPoints(points),
      stroke: colors.editTrack,
      strokeWidth: 1,
      strokeScaleEnabled: false,
      dash: [3, 3],
    });
    xtdGroup.add(line);
  });
}
