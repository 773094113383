<template>
  <div class="collectionDetail contents">
    <pan-row class="flex">
      <button title="Back to Mariners Collections" @click="emit('reset:collection')">
        <signi-icon name="ChevronLeftIcon" />
      </button>
      <pan-inline-editable-text
        :edit-mode="editCollectionMode"
        param-key="name"
        :param-value="collection.name"
        @changed="emit('update:collectionDescription', collection.id, $event.value)"
      >
      </pan-inline-editable-text>
      <button
        :title="toggleTitle(collection)"
        :class="`mr-2 ${collection.visible ? 'text-feedback_text' : 'text-text_main'}`"
        @click="emit('update:visibilityCollection', collection.id)"
      >
        <signi-icon v-if="collection.visible" name="VisibleIcon"></signi-icon>
        <signi-icon v-else name="HiddenIcon"></signi-icon>
      </button>
      <button title="Delete Collection" class="mr-4" @click="emit('delete:collection', collection.id)">
        <signi-icon name="DeleteIcon"></signi-icon>
      </button>
    </pan-row>
    <pan-scrollable class="h-full">
      <template v-for="note in collection?.notes" :key="note.id">
        <pan-row v-if="(isPlanningStation && note.type != 'event') || isExtension">
          <mariners-note-summary
            v-if="!note.selected"
            class="p-2"
            :note="note"
            @go-to-pin="emit('goToPin', $event)"
            @select:note="emit('select:note', { noteId: $event, collectionId: collection.id })"
          ></mariners-note-summary>
          <mariners-notes-edit-panel
            v-else
            class="w-full"
            :mariners-note="note"
            @go-to-pin="emit('goToPin', $event)"
            @update:description="emit('update:note', { ...note, description: $event })"
            @update:type="emit('update:note', { ...note, type: $event })"
            @delete="emit('delete:marinerNote', note.id)"
          >
          </mariners-notes-edit-panel>
        </pan-row>
      </template>
    </pan-scrollable>
    <pan-row class="borderNone">
      <div class="actions">
        <pan-button class="button uppercase" data-test-id="cancelSaveNotes" @click="emit('refresh:collection')">
          Cancel
        </pan-button>
        <pan-button
          class="button uppercase"
          :primary="!disabled"
          :disabled="disabled"
          data-test-id="saveNotes"
          @click="handler"
        >
          Save
        </pan-button>
      </div>
    </pan-row>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { isExtension, isPlanningStation } from '../../../../configuration';
import { MarinersCollection } from '../../../../contexts/marinersNotes';
import PanScrollable from '../../../../global/pan/PanScrollable.vue';
import MarinersNotesEditPanel from '../notes-edit/NotesEdit.vue';
import MarinersNoteSummary from './../note-summary/NoteSummary.vue';

defineProps<{ collection: MarinersCollection }>();
const emit = defineEmits([
  'reset:collection',
  'refresh:collection',
  'save:collection',
  'goToPin',
  'select:note',
  'update:note',
  'delete:marinerNote',
  'delete:collection',
  'update:collectionDescription',
  'update:visibilityCollection',
]);

const toggleTitle = (collection: MarinersCollection) =>
  'Toggle Visibility to ' + (collection.visible ? 'hide' : 'show');

const editCollectionMode = ref(true);
const disabled = ref(false);
const handler = () => {
  disabled.value = true;
  emit('save:collection');
};
</script>
<style scoped>
.borderNone {
  @apply border-none !important;
}

.header {
  @apply flex flex-row-reverse justify-end;
}

.actions {
  @apply mr-2 flex w-full flex-row justify-end;
}

.button {
  @apply ml-2 w-20;
}

.footer {
  @apply bottom-0 flex h-16 flex-shrink-0 items-center justify-end border-t-2 p-2;
  border-top: 1px solid #000000;
}

.footer .button {
  @apply ml-2 w-20;
}

.collectionDetail {
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
</style>
