import { computed, reactive, readonly } from 'vue';

export enum ApplicationType {
  PlanningStation,
  Extension,
}

type BaseUrls = {
  chartServer?: string;
  routeManagement?: string;
  productCatalog?: string;
  blobStorage?: string;
  api?: string;
};

type Configuration = {
  applicationType?: ApplicationType;
  localAuth?: boolean;
  baseUrls: BaseUrls;
  baseUrlsUpdated: boolean;
};

// The fallback to process.env is for CommonJS (Storybook)
const env = import.meta.env ?? process.env;

const conf: Configuration = reactive({
  applicationType: ApplicationType[env.VUE_APP_APPLICATION_TYPE as keyof typeof ApplicationType],
  localAuth: undefined,
  baseUrls: {
    chartServer: env.VUE_APP_CHART_SERVER_BASE_URL,
    routeManagement: env.VUE_APP_ROUTE_MANAGEMENT_BASE_URL,
    productCatalog: env.VUE_APP_PRODUCT_CATALOG_BASE_URL,
    blobStorage: env.VUE_APP_BLOB_URL,
    api: env.VUE_APP_API_BASE_URL,
  },
  baseUrlsUpdated: false,
});

export const configuration = readonly(conf);

export const isPlanningStation = computed(() => conf.applicationType === ApplicationType.PlanningStation);
export const isExtension = computed(() => conf.applicationType === ApplicationType.Extension);

export const setApplicationType = (type: ApplicationType): void => {
  conf.applicationType = type;
};
export const setLocalAuth = async (localAuth?: boolean): Promise<void> => {
  conf.localAuth = localAuth;
};
export const setBaseUrls = (baseUrls: BaseUrls): void => {
  conf.baseUrls = baseUrls;
  conf.baseUrlsUpdated = true;
};
