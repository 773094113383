<template>
  <div class="toast-container">
    <div v-for="message in messages" :key="message.id" class="toastr" :class="msgClass(message)">
      {{ message.text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRef } from 'vue';
import { useContext } from 'vue-context-composition';
import { Message, messageCtx } from '../../contexts/messages';

export default defineComponent({
  setup() {
    const { state } = useContext(messageCtx);

    const messages = toRef(state, 'messages');

    const msgClass = (msg: Message) => msg.type;

    return { messages, msgClass };
  },
});
</script>

<style scoped>
.toast-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.toastr {
  min-width: 200px;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 4px 0;
  text-align: center;
}

.info {
  background-color: var(--feedback_indicator_background);
  color: var(--text_main);
}

.warning {
  background-color: var(--alert_warning-active);
  color: #000000;
}
</style>
