<template>
  <svg viewBox="0 0 32 32" fill="none">
    <rect opacity="0.01" width="32" height="32" fill="black" />
    <circle cx="15.5" cy="15.5" r="6" stroke="white" />
    <path d="M24 15.5L19 15.5M7 15.5L12 15.5" stroke="#F6F6F6" />
    <path d="M15.5 7L15.5 12M15.5 24L15.5 19" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
