import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { routesCtx } from '../../../contexts/routes';
import { LatLon } from '../../../generated/ChartServer';
import { ShapeMetadata } from '../../../types';
import { latLonPointsToPoints, pipi } from '../../../utils/conversions';
import { splitAtDateLine } from '../../../utils/routeUtils';
import { VisibleWaypoint } from './addRoutes';

export function addTrackLines(
  wp: VisibleWaypoint,
  trackGroup: Konva.Group,
  routes: ContextType<typeof routesCtx>,
): void {
  const tracks = wp?.track?.map((l) => <LatLon>{ latitude: l.latitude, longitude: pipi(l.longitude ?? 0) }) ?? [];
  const splits = splitAtDateLine(tracks);
  splits.forEach((points) => {
    if (!points) return;

    const line = new Konva.Line({
      points: latLonPointsToPoints(points),
      stroke: wp.color,
      strokeWidth: wp.monitoring ? 4 : 2,
      strokeScaleEnabled: false,
      dash: wp.editable ? [] : wp.monitoring ? [15, 5] : [2, 2],
      hitStrokeWidth: wp.editable || wp.monitoring ? 50 : 10,
      data: <ShapeMetadata>{
        type: 'leg',
        uuid: wp.waypointId,
        showMonitoringToolTipMetaData: wp.monitoring,
      },
    });
    trackGroup.add(line);
    const stage = trackGroup.getStage();

    if (!wp.editable || !stage) return;

    line.on('mousedown', (ev) => {
      if (ev.evt.button === 0) routes.setActiveWaypointId(wp.waypointId ?? '');
      ev.cancelBubble = true;
    });
  });
}
