<template>
  <div class="routesDetail">
    <pan-row class="header">
      <icon-back class="back" @click="goBack" />
      <div class="title" :class="isExtension ? 'ecdis-title-width' : 'planning-station-title-width'">
        <pan-inline-editable-text
          :edit-mode="editMode"
          param-key="name"
          :param-value="detailRoute?.name"
          :size="routeNameLimit"
          class="name"
          @changed="updateRouteName"
        >
          {{ detailRoute?.name }}
        </pan-inline-editable-text>

        <ecdis-value v-if="editing(detailRoute)" class="sub editing">Editing</ecdis-value>
        <ecdis-value v-else class="sub" :class="{ highlight: valid(detailRoute) }">{{
          validationStatus(detailRoute)
        }}</ecdis-value>
      </div>
      <div v-if="validating" class="validating">
        <pan-spinner-round :size="24" class="spinner" />
      </div>
      <icon-pin class="pin" @click="goToRoute(detailRoute)" />
      <icon-gear class="settings" :class="{ active: isRouteSettingsVisible }" @click="toggleVisibility" />
    </pan-row>
    <pan-row class="wp head">
      <ecdis-value>WPT</ecdis-value>
      <ecdis-value>CRS</ecdis-value>
      <ecdis-value>RAD</ecdis-value>
      <ecdis-value>DIST</ecdis-value>
      <ecdis-value>SPD</ecdis-value>
      <div></div>
    </pan-row>
    <pan-scrollable>
      <ecdis-waypoint-details
        v-for="(waypointLegPair, index) in waypointLegPairs"
        :key="waypointLegPair.waypoint.waypointId"
        :waypoint-leg-pair="waypointLegPair"
        :first="index === 0"
        :last="index === (waypointLegPairs?.length ?? 0) - 1"
      />
      <template v-if="editMode">
        <div class="help">Edit waypoints in the list above or click on the map to start planning a route</div>
      </template>
    </pan-scrollable>
    <div class="footer">
      <div class="info">
        <ecdis-value class="dist">
          <ecdis-unit>Dist:</ecdis-unit> {{ distanceFormatter(totalDistance) }}<ecdis-unit>NM</ecdis-unit>
        </ecdis-value>
        <ecdis-value>
          <ecdis-unit>Time:</ecdis-unit> {{ timeFormatter(totalTime) }} <ecdis-unit>h</ecdis-unit>
        </ecdis-value>
      </div>
      <template v-if="editMode">
        <pan-button class="button" data-test-id="addWaypoint" @click="addWaypoint">+ ADD WPT</pan-button>
        <pan-button class="button" data-test-id="cancelSaveRoute" @click="discardChanges">Cancel</pan-button>
        <pan-button class="button" :primary="true" :disabled="saving" data-test-id="saveRoute" @click="save">
          Save
        </pan-button>
      </template>
      <template v-else>
        <pan-button
          class="edit button"
          :active="editMode"
          :disabled="editDisabled || monitoring(detailRoute)"
          @click="editDetail"
        >
          Edit
        </pan-button>
        <pan-button
          class="validate button"
          :primary="isExtension ? !validationVisible && !isRouteValidated : !validationVisible"
          :class="{ active: validationVisible }"
          @click="toggleRouteValidationPanel"
        >
          Validate
        </pan-button>
        <pan-button
          v-if="isExtension && !monitoring(detailRoute)"
          class="button"
          :primary="isRouteValidated && !(invalidWpts && invalidWpts.length > 0)"
          :disabled="!canStartMonitoring"
          @click="startMonitoring"
        >
          Monitor
        </pan-button>
        <pan-button
          v-if="monitoring(detailRoute)"
          class="unmonitor-btn"
          :primary="isRouteValidated"
          @click="stopMonitoring"
        >
          Unmonitor
        </pan-button>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../../configuration';
import { contextMenuCtx } from '../../../contexts/contextMenu';
import { criticalPointsSettingCtx } from '../../../contexts/criticalPointsSetting';
import { messageCtx } from '../../../contexts/messages';
import { monitoringCtx } from '../../../contexts/monitoring';
import { EnrichedRoute, routesCtx } from '../../../contexts/routes';
import { routeSettingsCtx } from '../../../contexts/routeSettings';
import { routeValidationCtx } from '../../../contexts/routeValidation';
import { vesselCtx } from '../../../contexts/vessels';
import { viewportCtx } from '../../../contexts/viewport';
import { EnrichedWaypoint } from '../../../generated/ChartServer';
import { meterToLngLat } from '../../../utils/conversions';
import { distanceFormatter, timeFormatter } from '../../../utils/formatters';
import { toWaypointLegPairs } from '../../../utils/routeConversions';
import { hasBadGeometryOrInvalidTurnRadius } from '../../../utils/routeUtils';

export default defineComponent({
  setup() {
    const {
      state,
      closeDetail,
      detailRoute,
      calculateBounds,
      setEditId,
      saveRoute,
      reloadRoute,
      removeRouteFromList,
      appendNewWaypoint,
      valid,
      editing,
      validationStatus,
      isRouteValidatedForVessel,
      monitoring,
      setMonitorId,
    } = useContext(routesCtx);

    const { state: vesselState, getNumericVesselParam } = useContext(vesselCtx);
    const { pushMessage } = useContext(messageCtx);
    const { showInViewport } = useContext(viewportCtx);
    const { hideContextMenu } = useContext(contextMenuCtx);
    const { viewport } = useContext(viewportCtx);
    const { toggleVisibility: toggleMonitoringPanelVisibility } = useContext(monitoringCtx);
    const { hidePanel } = useContext(criticalPointsSettingCtx);

    const {
      state: routeValidationState,
      setVisibility: setValidationVisibility,
      cancelValidation,
    } = useContext(routeValidationCtx);
    const { isValidating: validating, visible: validationVisible } = toRefs(routeValidationState);

    const { state: routeSettingsState, toggleVisibility } = useContext(routeSettingsCtx);
    const isRouteSettingsVisible = computed(() => routeSettingsState.visible);

    const waypoints = computed(() => detailRoute.value?.waypoints);
    const waypointLegPairs = computed(() => (detailRoute.value ? toWaypointLegPairs(detailRoute.value) : []));

    const totalDistance = computed(() => (waypoints.value?.[0] ? waypoints.value[0].remainingDistance : 0));
    const totalTime = computed(() => (waypoints.value?.[0] ? waypoints.value?.slice(-1)[0].travelledTime : 0));
    const editMode = computed(() => state.detailId === state.editId);
    const isRouteValidated = computed(() =>
      isRouteValidatedForVessel(vesselState.selectedVessel?.shipId, detailRoute?.value),
    );

    const invalidWpts = computed(() =>
      detailRoute.value?.calculationResult?.waypoints?.filter((wp) => {
        return hasBadGeometryOrInvalidTurnRadius(wp, detailRoute.value, getNumericVesselParam('shipMinTurnRadius'));
      }),
    );

    const checkGeometry = (): boolean => {
      const invalidWaypoints: Array<EnrichedWaypoint> | undefined = invalidWpts.value;
      if (invalidWaypoints && invalidWaypoints.length > 0) {
        if (invalidWaypoints.length === 1) {
          // Case if there is only one bad waypoint present in current route. Check for the Id under waypoints.
          const wptId = waypoints.value?.find((wp) => wp.waypointId === invalidWaypoints[0].waypointId);
          if (wptId) {
            const warningText = 'Bad geometry at WPT' + wptId.id + ' - Validation not possible';
            pushMessage({ type: 'warning', text: warningText });
            return true;
          }
        } else {
          // Case if there are multiple bad waypoints present in current route.
          pushMessage({ type: 'warning', text: 'Bad geometry at multiple WPTs - Validation not possible' });
          return true;
        }
      }
      return false;
    };

    const toggleRouteValidationPanel = () => {
      const route = detailRoute.value;
      if (route?.waypoints && route.waypoints.length > 1) {
        if (route.transient) {
          pushMessage({ type: 'warning', text: 'Save route before validation' });
          return;
        }
        if (!vesselState.selectedVessel) {
          pushMessage({ type: 'warning', text: 'Select a vessel from the Ownship menu to enable route validation' });
          return;
        }
        // If there are any invalid wpt turns then it should be shown hence setting the relevant toast msg.
        if (checkGeometry()) return;

        if (validationVisible.value) cancelValidation();
        setValidationVisibility(!validationVisible.value);
      } else {
        pushMessage({ type: 'warning', text: 'Could not validate: A route must have at least two waypoints' });
      }
    };
    const routeNameLimit = 36;

    const updateRouteName = (ev: { id: string; value: string }) => {
      const route = detailRoute.value;
      if (ev.value.length && route) {
        route.name = ev.value;
      }
    };

    const goToRoute = (route?: EnrichedRoute) => {
      if (!route) return;
      showInViewport(calculateBounds(route));
    };

    const goBack = () => {
      setValidationVisibility(false);
      closeDetail();
      hideContextMenu();
    };

    const editDisabled = computed(() => validationVisible.value || state.editId !== undefined);

    const editDetail = () => {
      if (editDisabled.value) return;
      if (vesselState.isVesselSelected) {
        setEditId(state.detailId);
        hidePanel();
      } else {
        pushMessage({ type: 'warning', text: 'Select a vessel from the Ownship menu to enable route editing' });
      }
    };

    const addWaypoint = () => {
      const latLon = !detailRoute.value?.waypoints?.length ? meterToLngLat(viewport.center) : undefined;
      appendNewWaypoint(latLon, routeSettingsState.settings.generalSettings);
    };

    const discardChanges = async () => {
      const route = detailRoute.value;
      setEditId();
      hidePanel();
      if (route && route.routeId) {
        if (route.transient) {
          removeRouteFromList();
          goBack();
        } else {
          await reloadRoute(route.routeId);
        }
      }
    };

    const saving = ref(false);

    const save = async () => {
      if (saving.value) return;
      hidePanel();
      const route = detailRoute.value;
      if (route && route.waypoints && route.waypoints.length > 1) {
        saving.value = true;
        await saveRoute().finally(() => (saving.value = false));
        setEditId();
      } else {
        pushMessage({ type: 'warning', text: 'Could not save: A route must have at least two waypoints' });
      }
    };

    const canStartMonitoring = computed(() => isRouteValidated.value && (invalidWpts.value ?? []).length == 0);

    const startMonitoring = () => {
      if (detailRoute.value?.routeId !== undefined) {
        setMonitorId(detailRoute.value?.routeId);
        toggleMonitoringPanelVisibility();
      }
    };

    const stopMonitoring = () => {
      setMonitorId();
      toggleMonitoringPanelVisibility();
    };

    return {
      detailRoute,
      waypointLegPairs,
      goBack,
      toggleRouteValidationPanel,
      validating,
      validationVisible,
      timeFormatter,
      distanceFormatter,
      totalTime,
      totalDistance,
      goToRoute,
      editMode,
      editDisabled,
      editDetail,
      save,
      saving,
      updateRouteName,
      isRouteSettingsVisible,
      toggleVisibility,
      discardChanges,
      routeNameLimit,
      addWaypoint,
      validationStatus,
      editing,
      valid,
      isRouteValidated,
      monitoring,
      isExtension,
      canStartMonitoring,
      startMonitoring,
      stopMonitoring,
      invalidWpts,
    };
  },
});
</script>

<style scoped>
.routesDetail {
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.header {
  padding: 8px;
}
.header .back {
  margin-right: 8px;
}
.header .name {
  flex: 1;
}
.header .validating {
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.validating .spinner {
  margin: 0;
}

.selected {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
}
.chevron.open {
  transform: rotate(-180deg);
}
.back,
.pin {
  cursor: pointer;
}
.title {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.planning-station-title-width {
  width: 400px;
}
.ecdis-title-width {
  width: 440px;
}
.title .name,
.title .sub {
  line-height: 16px;
}
.title .sub {
  font-size: 12px;
  color: var(--text_static);
  padding: 0 8px;
}
.title .sub.highlight {
  color: var(--feedback_text);
}
.title .sub.editing {
  color: var(--alert_warning-active);
}

.title .sub .valid .details {
  width: 90px;
}
.addWaypoint,
.footer {
  padding: 8px;
}

.addWaypoint {
  cursor: pointer;
}

.wp.head {
  flex-shrink: 0;
  background: var(--background_base_main);
}

.wp > * {
  display: flex;
  align-items: center;
}
.wp > :nth-of-type(1) {
  width: 172px;
  padding-left: 36px;
}
.wp > :nth-of-type(2) {
  width: 60px;
}
.wp > :nth-of-type(3) {
  width: 70px;
}
.wp > :nth-of-type(4) {
  width: 75px;
}
.wp > :nth-of-type(5) {
  width: 65px;
}
.wp.head ::v-deep(.value) {
  color: var(--text_static);
}
.wp ::v-deep(.value) {
  font-size: 14px;
}

.add {
  background-color: var(--background_layer_below);
}
.help {
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: var(--text_static);
  font-style: italic;
}
.footer {
  height: 64px;
  flex-shrink: 0;
  padding: 0 16px;
  border-top: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .info {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.footer .info .dist {
  margin-right: 16px;
}
.footer .button {
  width: 80px;
  margin-left: 8px;
}
.footer .unmonitor-btn {
  width: 88px;
  margin-left: 8px;
}

.settings {
  cursor: pointer;
}
.settings.active {
  background: var(--button_background-selected);
}
.settings.active ::v-deep(*) {
  stroke: var(--button_text-selected);
}
</style>
