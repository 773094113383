const globalInterceptors: ((url: RequestInfo, headers: Record<string, string>) => Promise<void>)[] = [];

export const globalIntercept = (
  httpInterceptor: (url: RequestInfo, headers: Record<string, string>) => Promise<void>,
): void => {
  globalInterceptors.push(httpInterceptor);
};

export const fetch = async (
  url: RequestInfo,
  init: RequestInit | undefined,
  interceptors: ((url: RequestInfo, headers: Record<string, string>) => Promise<void>)[] = [],
  attempts = 3,
): Promise<Response> => {
  init = init ?? {};
  const headers = (init?.headers ?? {}) as Record<string, string>;

  for (const interceptor of globalInterceptors) {
    await interceptor(url, headers);
  }

  for (const interceptor of interceptors) {
    await interceptor(url, headers);
  }

  init.headers = headers;

  return window.fetch(url, init).catch((response: Response) => {
    if (response?.status === 500 && attempts > 1) {
      return fetch(url, init, interceptors, attempts - 1);
    } else {
      return Promise.reject(response);
    }
  });
};
