<template>
  <pan-inline-editable-param
    :param-key="rangeId"
    :param-value="toParamValue(rangeValue?.toString(), rangeUnit)"
    @changed="updateRangeDataOnEdit"
  ></pan-inline-editable-param>
  <div class="icon-width">
    <icon-minus :id="'minusButton_' + rangeId" class="icons" @click="decrement(rangeId, value)" />
    <icon-add :id="'plusButton_' + rangeId" class="icons" @click="increment(rangeId, value)" />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';
import { UpdateReq } from '../../contexts/routeSettings';
import { toParamValue } from '../../utils/formatters';

export default defineComponent({
  props: {
    rangeUnit: {
      type: String,
      required: true,
    },
    rangeValue: {
      type: Number,
      required: true,
      default: 0,
    },
    rangeId: {
      type: String,
      required: true,
    },
  },

  emits: ['updateRange'],
  setup(props, { emit }) {
    const value = computed(() => props.rangeValue);

    const updateRangeDataOnEdit = (change: UpdateReq) => {
      const value = +change.value;
      emit('updateRange', { id: change.id, value: value });
    };
    const increment = (id: string, initialValue: number) => {
      const plusRef = document.getElementById('plusButton_' + id);

      //increment by one
      initialValue = initialValue + 1;
      emit('updateRange', { id: props.rangeId, value: initialValue });

      let timer = 0;
      let timerInterval: null | ReturnType<typeof setInterval> = null;

      if (plusRef) {
        plusRef.addEventListener('mousedown', function () {
          timerInterval = setInterval(function () {
            timer += 1;
            if (timer < 1 || timer < 5) {
              initialValue = initialValue + 1;
            } else if (timer > 5 || timer < 15) {
              initialValue = initialValue + 10;
            } else {
              initialValue = initialValue + 100;
            }
            emit('updateRange', { id: id, value: initialValue });
          }, 1000);
        });

        plusRef.addEventListener('mouseup', function () {
          if (timerInterval !== null) {
            clearInterval(timerInterval);
            timer = 0;
          }
        });
      }
    };

    const decrement = (id: string, initialValue: number) => {
      const minusRef = document.getElementById('minusButton_' + id);

      if (initialValue <= 0) {
        initialValue = 0;
      } else {
        //Decrement by 1
        initialValue = initialValue - 1;
        emit('updateRange', { id: id, value: initialValue });

        let timer = 0;
        let timerInterval: null | ReturnType<typeof setInterval> = null;

        if (minusRef) {
          minusRef.addEventListener('mousedown', function () {
            timerInterval = setInterval(function () {
              timer += 1;
              if (timer < 1 || timer < 5) {
                initialValue = initialValue - 1;
              } else if (timer > 5 || timer < 15) {
                initialValue = initialValue - 10;
              } else {
                initialValue = initialValue - 100;
              }
              emit('updateRange', { id: id, value: initialValue });
            }, 1000);
          });

          minusRef.addEventListener('mouseup', function () {
            if (timerInterval !== null) {
              clearInterval(timerInterval);
              timer = 0;
            }
          });
        }
      }
    };

    return {
      toParamValue,
      increment,
      decrement,
      updateRangeDataOnEdit,
      value,
    };
  },
});
</script>
<style scoped>
.icon-width {
  width: 41%;
  display: flex;
  align-items: center;
}
.icons {
  cursor: pointer;
}
</style>
