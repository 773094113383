<template>
  <hui-popover v-slot="{ open }" class="flex flex-col bg-background_layer_below">
    <div>
      <hui-popover-panel
        class="fixed z-10 ml-[-308px] flex max-h-[650px] w-[300px] flex-col overflow-hidden bg-background_layer_below"
      >
        <div class="border-b border-pano_dropdown_border p-4 uppercase text-text_main">Themes</div>
        <div class="overflow-y-auto">
          <div v-for="group in displayGroups" :key="group.desc" class="border-b border-pano_dropdown_border p-4">
            <ul>
              <li class="uppercase">
                <signi-panel-checkbox
                  :indeterminate="indeterminate(group)"
                  :label="group.desc"
                  :model-value="checked(group)"
                  @update:model-value="toggle(group)"
                />
              </li>
              <ul>
                <li v-for="child in group.children" :key="child.id">
                  <signi-panel-checkbox
                    :indeterminate="indeterminate(child)"
                    :label="child.desc"
                    :model-value="checked(child)"
                    @update:model-value="toggle(child)"
                  />
                  <ul>
                    <li v-for="subchild in child.children" :key="subchild.id">
                      <signi-panel-checkbox
                        :indeterminate="indeterminate(subchild)"
                        :label="subchild.desc"
                        :model-value="checked(subchild)"
                        @update:model-value="toggle(subchild)"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </hui-popover-panel>

      <hui-popover-button
        class="w-full px-2 py-1.5 text-text_static hover:bg-button_background-hover focus:bg-button_background-selected focus:outline-none focus-visible:bg-button_background-selected"
        :class="{ 'btn-selected': open }"
      >
        Themes
      </hui-popover-button>
    </div>
  </hui-popover>
</template>

<script lang="ts" setup>
import { displayGroups } from '../../../data/displayGroups';
import { DisplayGroup } from '../../../types';

const props = defineProps<{ selectedGroups: string[] }>();
const emit = defineEmits(['update']);

const checked = (group: DisplayGroup): boolean => {
  const groupSelected = props.selectedGroups.includes(group.id);

  // Check when the group is checked and all (sub)children are checked
  return [...(group.children?.map(checked) ?? []), groupSelected].every(Boolean);
};

const indeterminate = (group: DisplayGroup) => {
  const groupSelected = props.selectedGroups.includes(group.id);
  const list = [...(group.children?.map(checked) ?? []), groupSelected];

  // Indeterminate when there are true and false values
  return list.some(Boolean) && list.some((val) => !val);
};

const toggle = (group: DisplayGroup) => {
  emit('update', { group, value: !checked(group) });
};
</script>

<style scoped>
ul {
  @apply text-sm leading-[1.75] text-text_main;
}
ul ul {
  @apply ml-4;
}
</style>
