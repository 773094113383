import Konva from 'konva';
import { watch, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { controlModesCtx } from '../../contexts/controlModes';
import { displayVectorCtx } from '../../contexts/displayVector';
import { targetDataCtx } from '../../contexts/targetData';
import { targetSettingsCtx } from '../../contexts/targetSettings';
import { viewportCtx } from '../../contexts/viewport';
import { DangerState, ShapeMetadata } from '../../types';
import { lngLatToMeter, radInDeg } from '../../utils/conversions';
import { getScalingFactor } from '../../utils/mercator';
import {
  checkAndFilterAisTargets,
  getTargetLabel,
  isTargetAisOrAisCombined,
  isTargetRadarOrRadarCombined,
} from '../../utils/targetUtils';
import { blinkKonvaSymbol, createTargetMarker, multiples } from '../../utils/tools';
import { vector } from '../../utils/vector';
import { addMinuteMark } from './addMinuteMark';

const colors = {
  target: '#ff4500',
  selectedTarget: '#4A90E2',
  dangerousTarget: 'rgb(230,15,15)',
};

export function addTargets(transformLayer: Konva.Layer, absoluteLayer: Konva.Layer): void {
  const { state, setSelectedTarget, isTargetSelected, getTargetData } = useContext(targetDataCtx);
  const viewportRef = useContext(viewportCtx);
  const { getTargetGeneralSettingsValue, getMinuteMarkInterval, shouldShowSleepingTargets } =
    useContext(targetSettingsCtx);
  const { viewport, transformToAbsolute, pxToMeter } = viewportRef;
  const { state: displayVectorState, displayVectors } = useContext(displayVectorCtx);
  const { state: controlModesState } = useContext(controlModesCtx);
  const radarTargetsParentGroup = new Konva.Group({
    id: 'radarTargetsParentGroup',
  });
  const aisTargetsParentGroup = new Konva.Group({
    id: 'aisTargetsParentGroup',
  });
  const courseParentGroup = new Konva.Group({
    id: 'courseParentGroup',
  });
  transformLayer.add(courseParentGroup);
  absoluteLayer.add(radarTargetsParentGroup, aisTargetsParentGroup);
  radarTargetsParentGroup.visible(controlModesState.showTargets);
  aisTargetsParentGroup.visible(controlModesState.showTargets);
  courseParentGroup.visible(controlModesState.showTargets && displayVectors());

  watchEffect(() => {
    aisTargetsParentGroup.destroyChildren();
    radarTargetsParentGroup.destroyChildren();
    courseParentGroup.destroyChildren();
    for (const target of checkAndFilterAisTargets(getTargetData(), shouldShowSleepingTargets())) {
      const targetColor =
        target.DangerState === DangerState.None && !target.IsSart ? colors.target : colors.dangerousTarget;
      const isActiveDangerous = target.DangerState === DangerState.Alarm;
      const isInactiveSleepingAisTarget = !isActiveDangerous && target.Sleeping && !target.ForceAwake;
      const { mX, mY } = lngLatToMeter({
        lng: radInDeg * target.Longitude,
        lat: radInDeg * target.Latitude,
      });
      const { x: absX, y: absY } = transformToAbsolute({ mX, mY });
      const radarTargetGroup = new Konva.Group({
        id: '' + target.Number,
      });

      const toggleRadarTargets = (showTargets: boolean) => {
        if (showTargets) {
          radarTargetGroup.show();
        } else {
          radarTargetGroup.hide();
        }
      };

      if (isTargetRadarOrRadarCombined(target.Origin)) {
        radarTargetsParentGroup.add(radarTargetGroup);
        toggleRadarTargets(state.showRadarTargets);
        const trackedtargetCircle = new Konva.Circle({
          radius: target.Origin === 'RadarCombined' ? 14 : 4,
          stroke: targetColor,
          strokeWidth: target.Origin === 'RadarCombined' ? 3 : 1.5,
          hitStrokeWidth: 20,
          data: <ShapeMetadata>{ type: 'target', target: target },
        });
        blinkKonvaSymbol(trackedtargetCircle, isActiveDangerous);
        if (target.Origin === 'RadarCombined') {
          const ship = new Konva.Line({
            points: [5, 0, 0, 26, 10, 26],
            stroke: targetColor,
            strokeWidth: 1,
            strokeScaleEnabled: false,
            hitStrokeWidth: 20,
            closed: true,
            offset: {
              x: 5,
              y: 15,
            },
            data: <ShapeMetadata>{ type: 'target', target: target },
          });
          blinkKonvaSymbol(ship, isActiveDangerous);
          radarTargetGroup.add(ship);
        }
        radarTargetGroup.add(trackedtargetCircle);
        radarTargetGroup.position({
          x: absX,
          y: absY,
        });
        radarTargetGroup.rotation(radInDeg * target.Heading);
        trackedtargetCircle.on('mousedown touchstart', (ev) => {
          // cancel dragging of stage
          ev.cancelBubble = true;
          if (ev.evt.button === 0) {
            setSelectedTarget(target.Number);
          }
        });

        // update target position when context state changes
      }

      const shipGroup = new Konva.Group({
        id: '' + target.Number,
      });
      const courseVectorGroup = new Konva.Group({
        id: '' + target.Number,
      });
      const targetNameGroup = new Konva.Group({
        id: '' + target.Number,
      });
      const selectedTargetGroup = new Konva.Group({
        id: '' + target.Number,
      });
      if ((target.Name || target.Number) && !target.IsSart) {
        const formattedLabelName = getTargetLabel(target);
        const targetNumber = new Konva.Text({
          width: 80,
          height: 80,
          offsetX: 40,
          offsetY: 40,
          text: formattedLabelName,
          fontSize: 12,
          fontFamily: 'Lato',
          fill: targetColor,
          align: 'center',
          verticalAlign: 'middle',
          visible: getTargetGeneralSettingsValue('showLabels') ?? false,
          data: <ShapeMetadata>{ type: 'target', target: target },
        });
        blinkKonvaSymbol(targetNumber, isActiveDangerous);
        targetNameGroup.add(targetNumber);
        targetNumber.on('mousedown touchstart', (ev) => {
          // cancel dragging of stage
          ev.cancelBubble = true;
          if (ev.evt.button === 0) {
            setSelectedTarget(target.Number);
          }
        });
      }
      const isHeadingOrCourseAvailable =
        isInactiveSleepingAisTarget || target.IsSart
          ? false
          : Math.abs(target.Heading) >= 0
          ? true
          : Math.abs(target.Course) >= 0
          ? true
          : false;
      const headingLineGroup = new Konva.Group({
        id: '' + target.Number,
      });

      const minuteMarkGroup = new Konva.Group({
        id: '' + target.Number,
      });
      courseParentGroup.add(courseVectorGroup);
      aisTargetsParentGroup.add(shipGroup, targetNameGroup, headingLineGroup, minuteMarkGroup, selectedTargetGroup);

      // add target ship
      if (isTargetAisOrAisCombined(target.Origin)) {
        if (target.IsSart) {
          const sartAis = new Konva.Circle({
            radius: 10,
            stroke: targetColor,
            strokeWidth: 2,
            data: <ShapeMetadata>{ type: 'target', target: target },
          });
          for (let i = 0; i < 2; i++) {
            const crossBars = new Konva.Line({
              points: [7, 7, -7, -7],
              stroke: targetColor,
              strokeWidth: 1,
              rotation: i * 90,
            });
            blinkKonvaSymbol(crossBars, isActiveDangerous);
            shipGroup.add(crossBars);
          }
          blinkKonvaSymbol(sartAis, isActiveDangerous);
          shipGroup.add(sartAis);
          sartAis.on('mousedown touchstart', (ev) => {
            // cancel dragging of stage
            ev.cancelBubble = true;
            if (ev.evt.button === 0) {
              setSelectedTarget(target.Number);
            }
          });
        } else {
          const ship = new Konva.Line({
            points: isInactiveSleepingAisTarget ? [4, 0, 0, 18, 10, 18] : [5, 0, 0, 24, 10, 24],
            stroke: targetColor,
            strokeWidth: isInactiveSleepingAisTarget ? 2 : target.Origin === 'AisCombined' ? 4 : 3,
            strokeScaleEnabled: false,
            hitStrokeWidth: 20,
            dash: isHeadingOrCourseAvailable || isInactiveSleepingAisTarget ? [] : [10, 2],
            closed: true,
            offset: {
              x: 5,
              y: 15,
            },
            data: <ShapeMetadata>{ type: 'target', target: target },
          });
          if (target.Origin === 'AisCombined') {
            const trackedtargetCircle = new Konva.Circle({
              radius: isInactiveSleepingAisTarget ? 10 : 14,
              stroke: targetColor,
              strokeWidth: 1.5,
              hitStrokeWidth: 20,
              data: <ShapeMetadata>{ type: 'target', target: target },
            });
            blinkKonvaSymbol(trackedtargetCircle, isActiveDangerous);
            shipGroup.add(trackedtargetCircle);
          }
          blinkKonvaSymbol(ship, isActiveDangerous);
          shipGroup.add(ship);
          ship.on('mousedown touchstart', (ev) => {
            // cancel dragging of stage
            ev.cancelBubble = true;
            if (ev.evt.button === 0) {
              setSelectedTarget(target.Number);
            }
          });
        }
      }

      const size = 16;
      const stroke =
        target.DangerState === DangerState.None && !target.IsSart ? colors.selectedTarget : colors.dangerousTarget;
      const corners = createTargetMarker(-size, -size, size, stroke);
      const cornerGroup = new Konva.Group();
      const headingLine = new Konva.Line({
        stroke: targetColor,
        strokeWidth: 1,
        strokeScaleEnabled: false,
      });

      cornerGroup.add(...corners.map((corner) => new Konva.Line(corner)));
      selectedTargetGroup.add(cornerGroup);
      blinkKonvaSymbol(headingLine, isActiveDangerous);
      headingLineGroup.add(headingLine);

      const courseVector = new Konva.Line({
        points: [
          0,
          0,
          0,
          -(
            target.Speed *
            (displayVectorState.vectorLengthInMinutes * 60) *
            getScalingFactor(radInDeg * target.Latitude)
          ),
        ],
        stroke: targetColor,
        dash: [10, 5],
        strokeWidth: 3,
        strokeScaleEnabled: false,
      });
      blinkKonvaSymbol(courseVector, isActiveDangerous);
      courseVectorGroup.add(courseVector);
      const courseAngleInRad = target.Course - Math.PI / 2;
      // drawing the target's heading line.
      const angleInRad = target.Heading - Math.PI / 2;
      const endVector = vector().setLength(pxToMeter(70)).setAngle(angleInRad).add(vector(mX, mY));
      const endingPoint = { mX: endVector.x, mY: endVector.y };
      const showHeading =
        isTargetAisOrAisCombined(target.Origin) &&
        isHeadingOrCourseAvailable &&
        (getTargetGeneralSettingsValue('headingLine') ?? false);
      headingLineGroup.visible(showHeading);
      const endPixelPoints = transformToAbsolute(endingPoint);
      // labels to appear always opposite to heading line.
      const hasLargelabelChars = target.Name ? (target.Name.length > 14 ? true : false) : false;
      const relHeading = target.Heading % 3.14;
      const labelLength = !hasLargelabelChars
        ? 40
        : Math.abs(relHeading) >= 0.9 && Math.abs(relHeading) <= 2.4
        ? 50
        : 30;
      const labelVector = vector()
        .setLength(pxToMeter(labelLength))
        .setAngle(angleInRad + Math.PI)
        .add(vector(mX, mY));
      const namePixelPoints = transformToAbsolute({ mX: labelVector.x, mY: labelVector.y });
      targetNameGroup.position({
        x: namePixelPoints.x,
        y: namePixelPoints.y,
      });

      // Heading points for Ais
      const hasRightTurn = target.RateOfTurn * 60 * radInDeg > 2 && target.RateOfTurn * 60 * radInDeg <= 180;
      const hasLeftTurn = target.RateOfTurn * 60 * radInDeg < -2 && target.RateOfTurn * 60 * radInDeg >= -180;
      if (hasRightTurn || hasLeftTurn) {
        // add turn indicator points
        const turnIndicatorAngle = hasRightTurn ? target.Heading + 2 * Math.PI : target.Heading + Math.PI;
        const turnIndicator = vector()
          .setLength(pxToMeter(20))
          .setAngle(turnIndicatorAngle)
          .add(vector(endVector.x, endVector.y));
        const turnIndicatorPoints = transformToAbsolute({ mX: turnIndicator.x, mY: turnIndicator.y });
        headingLine.points([
          absX,
          absY,
          endPixelPoints.x,
          endPixelPoints.y,
          turnIndicatorPoints.x,
          turnIndicatorPoints.y,
        ]);
      } else {
        headingLine.points([absX, absY, endPixelPoints.x, endPixelPoints.y]);
      }

      // Minute mark
      const selectedMinuteMarkInterval = getMinuteMarkInterval();
      const showMinuteMark =
        !target.IsSart &&
        !isInactiveSleepingAisTarget &&
        displayVectors() &&
        (getTargetGeneralSettingsValue('minuteMarker') ?? false);
      if (selectedMinuteMarkInterval !== undefined) {
        const minuteLengthList = multiples(
          parseInt(selectedMinuteMarkInterval),
          displayVectorState.vectorLengthInMinutes,
        );
        minuteMarkGroup.destroyChildren();
        for (const minuteLength of minuteLengthList) {
          const endVector = vector()
            .setLength(target.Speed * (minuteLength * 60) * getScalingFactor(radInDeg * target.Latitude))
            .setAngle(courseAngleInRad)
            .add(vector(mX, mY));
          addMinuteMark(
            endVector,
            showMinuteMark,
            minuteMarkGroup,
            target.Course * radInDeg + viewport.rotation.angle,
            viewportRef,
            isActiveDangerous,
            targetColor,
          );
        }
      }
      // update target position
      shipGroup.position({
        x: absX,
        y: absY,
      });
      courseVectorGroup.position({
        x: mX,
        y: mY,
      });
      selectedTargetGroup.position({
        x: absX,
        y: absY,
      });
      selectedTargetGroup.visible(isTargetSelected(target.Number));
      courseVectorGroup.visible(isHeadingOrCourseAvailable);
      const courseVectorLength =
        target.Speed * (displayVectorState.vectorLengthInMinutes * 60) * getScalingFactor(radInDeg * target.Latitude);
      courseVector.points([0, 0, 0, -courseVectorLength]);
      shipGroup.rotation(radInDeg * target.Heading);
      courseVectorGroup.rotation(radInDeg * target.Course);

      watch(
        () => state.showRadarTargets,
        (showRadarTargets) => {
          toggleRadarTargets(showRadarTargets);
        },
      );

      watch(
        () => controlModesState.showTargets,
        (showTargets) => {
          aisTargetsParentGroup.visible(showTargets);
          radarTargetsParentGroup.visible(showTargets);
          courseParentGroup.visible(showTargets && displayVectors());
        },
      );

      watch(
        () => displayVectors(),
        (showVectors) => {
          courseParentGroup.visible(controlModesState.showTargets && showVectors);
        },
      );
    }
  });
}
