import Konva from 'konva';
import { latLonPointsToPoints } from '../../../utils/conversions';
import { VisibleWaypoint } from './addRoutes';

export function addTurnEdges(wp: VisibleWaypoint, edgeGroup: Konva.Group): void {
  const edges = wp?.edge ?? [];
  if (!edges) return;

  const line = new Konva.Line({
    points: latLonPointsToPoints(edges),
    stroke: wp.color,
    strokeWidth: 1,
    strokeScaleEnabled: false,
    dash: [5, 5],
  });
  edgeGroup.add(line);
}
