<template>
  <svg viewBox="0 0 32 32" fill="none">
    <circle cx="9" cy="22" r="1.5" stroke="#F6F6F6" />
    <circle cx="15" cy="9" r="1.5" stroke="#F6F6F6" />
    <circle cx="24" cy="16" r="1.5" stroke="#F6F6F6" />
    <path d="M8.45258 21C7.48258 18 7.50012 11.5 13.5001 9" stroke="#F6F6F6" />
    <path d="M16.5 8.5C18.5 8.5 19.3 9.30003 20.5 12.5C21.0625 14 22.6667 15.1667 23 15" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 34px;
  height: 44px;
}
</style>
