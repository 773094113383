import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { viewportCtx } from '../../../contexts/viewport';
import { WaypointLabel } from '../../../utils/routeUtils';
import { colors } from './addRoutes';

export function addWaypointLabel(
  { meterPoint, wayPointLabel }: WaypointLabel,
  waypointLabelGroup: Konva.Group,
  { transformToAbsolute }: ContextType<typeof viewportCtx>,
): void {
  const { x, y } = transformToAbsolute(meterPoint);
  // add text in large enough area so it can be horizontally/vertically centered
  const waypointLabelText = new Konva.Text({
    x,
    y,
    width: 100,
    height: 100,
    offsetX: 50,
    offsetY: 50,
    text: wayPointLabel,
    fontSize: 14,
    fontFamily: 'Lato',
    fill: colors.editTrack,
    align: 'center',
    verticalAlign: 'middle',
    listening: false,
  });
  waypointLabelGroup.add(waypointLabelText);
}
