import Konva from 'konva';
import { watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { graticuleCtx } from '../../contexts/graticule';
import { lngLatToMeter } from '../../utils/conversions';

export function addGraticule(layer: Konva.Layer): void {
  const { state: graticuleState } = useContext(graticuleCtx);

  const graticuleGroup = new Konva.Group();
  layer.add(graticuleGroup);

  watchEffect(() => {
    if (graticuleState.show) {
      // add graticule
      for (let lat = -80; lat <= 80; lat += 10) {
        const { mX: startMX, mY: startMY } = lngLatToMeter({ lng: -180, lat });
        const { mX: endMX, mY: endMY } = lngLatToMeter({ lng: 180, lat });
        const line = new Konva.Line({
          points: [startMX, startMY, endMX, endMY],
          stroke: 'white',
          strokeWidth: 1,
          strokeScaleEnabled: false,
        });
        graticuleGroup.add(line);
      }
    } else {
      graticuleGroup.removeChildren();
    }
  });
}
