import { NM_IN_METER } from '../formatters';
import { EblVrm } from './types';

export const createDefaultEblVrm = (
  overrides: Partial<EblVrm> = {},
): Pick<EblVrm, 'bearing' | 'eblVisible' | 'range' | 'reference' | 'trueBearing' | 'vrmVisible'> &
  typeof overrides => ({
  bearing: 0,
  eblVisible: true,
  range: NM_IN_METER,
  reference: 'OwnShip',
  trueBearing: true,
  vrmVisible: true,
  ...overrides,
});
