<template>
  <pan-row class="row" :class="{ active }" @click="toggleOpen">
    <slot name="row-content" />
    <icon-arrow class="chevron" :class="{ open }" />
  </pan-row>

  <div v-if="open" class="contents">
    <slot name="expanded-content" :open="open" />
  </div>

  <slot name="footer" :open="open" />
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
  props: {
    initialOpen: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },
  emits: ['toggled'],
  setup(props, { emit }) {
    const open = ref(props.initialOpen);

    const toggleOpen = () => {
      open.value = !open.value;
      emit('toggled');
    };

    watchEffect(() => {
      open.value = props.initialOpen;
    });

    return {
      open,
      toggleOpen,
    };
  },
});
</script>

<style scoped>
.row {
  background-color: var(--background_layer_below);
  cursor: pointer;
}

.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
}

.chevron.open {
  transform: rotate(-180deg);
}

.contents {
  background: rgba(21, 25, 28, 1);
}
.active {
  background-color: #25282e;
}
</style>
