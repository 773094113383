<template>
  <pan-scrollable class="scrollable">
    <pan-expandable-row v-if="markerRouteInfo">
      <template #row-content>
        <ecdis-value class="value message">Waypoint</ecdis-value>
      </template>
      <template #expanded-content>
        <div v-for="property in markerRouteInfo?.data" :key="property.title" class="details">
          <pan-static-text>{{ property.title }}</pan-static-text>
          <div class="value">{{ property.value }}</div>
        </div>
      </template>
    </pan-expandable-row>
    <pan-expandable-row v-for="objectInfo in enrichedObjectInfoList" :key="objectInfo?.id">
      <template #row-content>
        <ecdis-value class="value message">{{ objectInfo?.className }}</ecdis-value>
        <!-- <icon-pin
          class="pin"
          :class="{ active: objectInfo?.id === highlightedFeatureId }"
          @click.stop="highlightFeature(objectInfo?.id)"
        /> -->
      </template>

      <template #expanded-content>
        <div v-for="property in objectInfo?.properties" :key="property.title" class="details">
          <pan-static-text>{{ property.title }}</pan-static-text>
          <div class="value">{{ property.value }}</div>
        </div>
      </template>
    </pan-expandable-row>
  </pan-scrollable>
</template>

<script lang="ts">
import { DeepReadonly, computed, defineComponent, toRef } from 'vue';
import { useContext } from 'vue-context-composition';
import { objectInfoCtx } from '../../contexts/objectInfo';
import { routeValidationCtx } from '../../contexts/routeValidation';
import { ObjectInfo } from '../../generated/ChartServer';
import { propertySorter } from '../../utils/propertySorter';

export default defineComponent({
  setup() {
    const { state: routeValidationState, setHighlightedFeatureId } = useContext(routeValidationCtx);
    const { state: chartsObjectsInfoState } = useContext(objectInfoCtx);
    const objectInfoList = toRef(chartsObjectsInfoState, 'objectInfoList');
    const markerRouteInfo = toRef(chartsObjectsInfoState, 'routeInfo');
    const validationResults = toRef(routeValidationState, 'validationResults');
    const highlightedFeatureId = toRef(routeValidationState, 'highlightedFeatureId');

    const highlightFeature = (featureId?: string | null) => {
      if (featureId !== null && featureId !== undefined) {
        setHighlightedFeatureId(featureId);
      }
    };

    const propertyTitlesToHide = ['ID', 'wksID', 'Class code'];

    const enrichFeature = (feature: DeepReadonly<ObjectInfo>) => ({
      id: feature.id + '-' + feature.properties?.find((property) => property.title === 'wksID')?.value,
      properties: feature.properties
        ?.filter((property) => !propertyTitlesToHide.includes(property?.title ?? ''))
        .sort(propertySorter),
      className: feature.properties?.find((property) => property.title === 'Class')?.value ?? '-',
    });

    const featureSorter = (f1: { className: string }, f2: { className: string }) => {
      return f1.className < f2.className ? -1 : f1.className > f2.className ? 1 : 0;
    };

    const enrichedObjectInfoList = computed(() => (objectInfoList.value?.map(enrichFeature) ?? []).sort(featureSorter));

    return {
      validationResults,
      enrichedObjectInfoList,
      highlightFeature,
      highlightedFeatureId,
      markerRouteInfo,
    };
  },
});
</script>

<style scoped>
.row {
  background-color: var(--background_layer_below);
  padding: 16px 12px;
  justify-content: space-between;
}

.head {
  background-color: var(--background_tile);
}
.pin {
  cursor: pointer;
}
.pin.active {
  background: #000;
}
.pin.active ::v-deep(*) {
  fill: var(--button_text-selected);
  stroke: var(--button_text-selected);
}

.btn {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  padding: 0;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  padding-left: 12px;
  flex: 1;
}

.value {
  font-size: 14px;
  line-height: 21px;
}

.details {
  padding: 8px 12px;
}
.detail-row {
  width: 50%;
  vertical-align: baseline;
}

.input {
  width: 64px;
  background-color: var(--input_background-default);
  margin: 0 8px;
}

.footer {
  height: 64px;
  flex-shrink: 0;
  justify-content: center;
}
.cancel,
.save {
  width: 94px;
}
.save {
  margin-left: 8px;
  background: var(--feedback_indicator_background);
}
</style>
