<template>
  <div>
    <signi-panel class="mb-1 bg-pano_background_main">
      <ecdis-scale :scale="scale" />
      <ecdis-range-control
        :options="[0.125, 0.25, 0.5, 0.75, 1.5, 3, 6, 12, 24, 48, 96, 126, 256, 506, 1012].map((id) => ({ id }))"
        :model-value="roundedCurrentRange"
        @update:model-value="selectRange"
      />
      <signi-orientation-control
        v-if="showOrientationMode"
        :model-value="orientationMode"
        :options="[{ id: 'H' }, { id: 'N' }]"
        @update:model-value="updateOrientationMode"
      />
      <signi-vector-control
        v-if="showVector"
        :disabled="browse || orientationMode === 'H'"
        :enabled="vectorEnabled"
        :options="[0.5, 1, 3, 6, 12, 24, 48].map((id) => ({ id }))"
        :model-value="vectorValue"
        :secondary-options="[{ id: 'True' }, { id: 'Relative' }]"
        :secondary-value="vectorSecondaryValue"
        @update:enabled="setDisplayLength($event ? 3 : 0)"
        @update:model-value="setDisplayLength"
        @update:secondary-value="setDisplayVectorType"
      />
      <signi-motion-control
        v-if="showMotionMode"
        :model-value="motionMode"
        :options="[{ id: 'TM' }, { id: 'RM' }]"
        @update:model-value="setMotionMode"
      />
    </signi-panel>

    <signi-panel class="bg-pano_background_main">
      <signi-panel-setting v-if="showBrowse && showTarget" primary="Target" small>
        <signi-switch aria-label="Show targets" :model-value="showTargets" @update:model-value="setShowTargets" />
      </signi-panel-setting>
      <signi-panel-setting v-if="showBrowse" primary="Browse" small>
        <signi-switch aria-label="Browse" :model-value="browse" @update:model-value="setBrowse" />
      </signi-panel-setting>

      <div v-if="showBrowse && showCenter" class="flex flex-col items-center bg-background_layer_below px-4 py-1.5">
        <button
          class="btn btn-xs w-full uppercase"
          :class="{ 'btn-selected': isOwnshipCentered }"
          @update:model-value="centerOwnship"
        >
          Center
        </button>
      </div>

      <ecdis-theme-control
        :selected-groups="displayGroupsState.selected"
        @update="setSelected($event.group, $event.value)"
      />

      <div class="flex flex-col items-center bg-background_layer_below px-4 py-1.5">
        <button class="btn btn-xs w-full uppercase" @click="resetSelected">Std Disp</button>
      </div>
    </signi-panel>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { OrientationMode, controlModesCtx } from '../../contexts/controlModes';
import { displayGroupsCtx } from '../../contexts/displayGroups';
import { displayVectorCtx } from '../../contexts/displayVector';
import { viewportCtx } from '../../contexts/viewport';
import { useOwnship } from '../../global-state/ownship';
import { rangeFormatter } from '../../utils/formatters';

defineProps<{
  showOrientationMode?: boolean;
  showMotionMode?: boolean;
  showBrowse?: boolean;
  showCenter?: boolean;
  showTarget?: boolean;
  showVector?: boolean;
}>();

const {
  state: controlModesState,
  setBrowse,
  setOrientationMode,
  setMotionMode,
  setShowTargets,
} = useContext(controlModesCtx);
const { browse, orientationMode, motionMode, showTargets } = toRefs(controlModesState);

const { state: displayVectorState, setDisplayVectorType, setDisplayLength } = useContext(displayVectorCtx);
const { vectorLengthInMinutes: vectorValue, displayVectorType: vectorSecondaryValue } = toRefs(displayVectorState);
const vectorEnabled = computed(() => displayVectorState.vectorLengthInMinutes > 0);

const { currentRange, selectRange, viewport, setCenter } = useContext(viewportCtx);
const roundedCurrentRange = computed(() => +rangeFormatter(currentRange.value));

const { state: displayGroupsState, setSelected, resetSelected } = useContext(displayGroupsCtx);
const { ownshipMeterPoint } = useOwnship();
const scale = computed(() => viewport.scaling.scale);

const isOwnshipCentered = computed(
  () =>
    ownshipMeterPoint.value !== undefined &&
    viewport.center.mX === ownshipMeterPoint.value.mX &&
    viewport.center.mY === ownshipMeterPoint.value.mY,
);
const centerOwnship = () => {
  if (ownshipMeterPoint.value !== undefined) {
    setCenter(ownshipMeterPoint.value, { throttled: false });
  }
};
const updateOrientationMode = (orientationMode: OrientationMode) => {
  setOrientationMode(orientationMode);
  centerOwnship();
};
</script>
