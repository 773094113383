<template>
  <signi-tools-list
    :items="eblVrms"
    title="EBL/VRM"
    @add="createEblVrm(viewport.center)"
    @update:visible="toggleAnyVisibility"
  >
    <template #default="{ item }">
      <signi-ebl-vrm
        :class="{ selected: selectedEblVrmId === item.id }"
        :model-value="item"
        :heading="(ownshipState.ownship?.heading ?? 0) / RADIANS_IN_DEGREE"
        :reference-options="isExtension && [{ id: 'OwnShip' }, { id: 'Geographical' }]"
        @click="onEditStart(item.id)"
        @goto="goToEbLVrm(item.id)"
        @remove="deleteEblVrm(item.id)"
        @update:model-value="onUpdate"
      />
    </template>
  </signi-tools-list>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useContext } from 'vue-context-composition';
import { IEblVrm, METER_IN_NM, NM_IN_METER, RADIANS_IN_DEGREE } from '@signi/common';
import { isExtension } from '../../../configuration';
import { controlModesCtx } from '../../../contexts/controlModes';
import { eblVrmCtx } from '../../../contexts/eblVrm';
import { viewportCtx } from '../../../contexts/viewport';
import { useOwnship } from '../../../global-state/ownship';

const { state: ownshipState } = useOwnship();
const { state, createEblVrm, deleteEblVrm, getSelectedEblVrm, onEditStart, updateEblVrm, toggleAnyVisibility } =
  useContext(eblVrmCtx);
const { setBrowse } = useContext(controlModesCtx);
const { setCenter, showInViewport, viewport } = useContext(viewportCtx);

// Map from LegacyEblVrm (=ctx) to EblVrm (=eblvrm component)
const eblVrms = computed<IEblVrm[]>(() =>
  state.eblVrms.map((e) => ({
    bearing: e.eblValue / RADIANS_IN_DEGREE,
    eblVisible: e.eblVisible,
    id: e.id,
    index: e.index,
    range: e.vrmValue * NM_IN_METER,
    reference: e.isSetToOwnShip ? 'OwnShip' : 'Geographical',
    trueBearing: e.eblBearing === 'TRUE',
    vrmVisible: e.vrmVisible,
  })),
);
const selectedEblVrmId = computed(() => getSelectedEblVrm()?.id);

// Map back from EblVrm (=eblvrm component) to LegacyEblVrm (=ctx)
const onUpdate = (e: IEblVrm) => {
  // Only merge in editable fields
  const currentEblVrm = state.eblVrms.find((eblVrm) => eblVrm.id == e.id)!;

  updateEblVrm(
    {
      ...currentEblVrm,
      eblVisible: e.eblVisible,
      eblValue: e.bearing * RADIANS_IN_DEGREE,
      vrmValue: e.range * METER_IN_NM,
      vrmVisible: e.vrmVisible,
      isSetToOwnShip: e.reference === 'OwnShip',
      eblBearing: e.trueBearing ? 'TRUE' : 'RELATIVE',
    },
    ownshipState.ownship?.heading ?? 0,
  );
};

const goToEbLVrm = (eblVrmId: string) => {
  setBrowse(true);
  const eblVrm = state.eblVrms.find((e) => e.id === eblVrmId);
  if (!eblVrm) return;

  if (eblVrm.vrmVisible) {
    const vrmInMeter = eblVrm.vrmValue * NM_IN_METER * 5;
    const topLeft = { mX: eblVrm.center.mX - vrmInMeter, mY: eblVrm.center.mY - vrmInMeter };
    const bottomRight = { mX: eblVrm.center.mX + vrmInMeter, mY: eblVrm.center.mY + vrmInMeter };
    showInViewport({ topLeft, bottomRight });
  } else {
    setCenter(eblVrm.center, { throttled: false });
  }
};
</script>
