import { configuration } from '../configuration';
import { ChartServerClient } from '../generated/ChartServer';
import { fetch } from '../utils/http';
import { createClientPromise } from './createClientPromise';

export const getChartServerClient = (): Promise<ChartServerClient> =>
  createClientPromise(
    'ChartServerClient',
    () =>
      new ChartServerClient(configuration.baseUrls.chartServer, {
        fetch,
      }),
  );
