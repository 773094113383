<template>
  <div class="collectionDetail contents">
    <signi-panel class="flex">
      <signi-panel-header class="flex grow justify-between leading-8" title="Mariner’s notes">
        <ecdis-input-box class="inputbox" placeholder="SEARCH" @changed="filterCollection" />
        <button title="Create Collection" class="create__collection" @click="emit('create:collection')">New</button>
      </signi-panel-header>
    </signi-panel>
    <pan-scrollable class="h-full">
      <pan-row v-for="collection in collections" :key="collection.id" class="pr-4 pl-4 text-text_main">
        <signi-icon name="FolderIcon" class="mr-2" />
        <p class="grow">
          {{ collection?.name }}
        </p>
        <button
          :title="toggleTitle(collection)"
          :class="`mr-2 ${collection.visible ? 'text-feedback_text' : ''}`"
          @click="emit('update:visibilityCollection', collection.id)"
        >
          <signi-icon v-if="collection.visible" name="VisibleIcon"></signi-icon>
          <signi-icon v-else name="HiddenIcon"></signi-icon>
        </button>
        <button title="Expand Collection" @click="emit('active:collection', collection.id)">
          <signi-icon name="ChevronRightIcon" />
        </button>
      </pan-row>
    </pan-scrollable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { MarinersCollection } from '../../../../../lib/contexts/marinersNotes';

const props = defineProps<{ collections: MarinersCollection[]; isNewEnabled: boolean }>();
const emit = defineEmits(['active:collection', 'create:collection', 'update:visibilityCollection']);

const filterValue = ref('');
const filterCollection = (event: string) => (filterValue.value = event || '').toLocaleLowerCase();
const collections = computed(() =>
  props.collections.filter((collection) => (collection.name || '').toLowerCase().includes(filterValue.value)),
);
const toggleTitle = (collection: MarinersCollection) =>
  'Toggle Visibility to ' + (collection.visible ? 'hide' : 'show');
</script>

<style scoped>
.create__collection {
  @apply flex-initial rounded-sm bg-button_background-default pl-2 pr-2 uppercase;
}
.collectionDetail {
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
</style>
