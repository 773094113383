import Konva from 'konva';
import { v4 as uuid } from 'uuid';
import { ILOP, IPositionFixMarker, PositionFixType } from '@signi/common';
import { MeterPoint, PixelPoint } from '../types';
import { degInRad, meterToLngLat, nmInMeter } from './conversions';

const tooltipFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h23',
};

export const positionFixColors = {
  bearing: 'rgb(255, 132, 60)',
  range: 'rgb(255, 132, 60)',
  positionFix: 'rgb(255, 122, 0)',
};

export const createLOP = (type: 'Bearing' | 'Range', center: MeterPoint): ILOP => {
  return {
    bearing: 0,
    bearingRelativeValue: 0,
    bearingType: 'TRUE',
    center: center,
    id: uuid(),
    isAlignToHeading: false,
    isSelected: false,
    isSetToOwnShip: false,
    location: meterToLngLat(center),
    range: 1,
    type: type,
  };
};

export const createPositionFixOuterCircle = (markersGroup: IPositionFixMarker) => {
  return new Konva.Circle({
    id: 'position_fix_' + markersGroup.id,
    x: markersGroup.positionFix?.mX ? markersGroup.positionFix?.mX : 0,
    y: markersGroup.positionFix?.mY ? markersGroup.positionFix?.mY : 0,
    radius: 12,
    stroke: positionFixColors.positionFix,
    strokeWidth: 1,
  });
};

export const createPositionFixDot = (markersGroup: IPositionFixMarker) => {
  return new Konva.Circle({
    id: 'position_fix_dot_' + markersGroup.id,
    x: markersGroup.positionFix?.mX ? markersGroup.positionFix?.mX : 0,
    y: markersGroup.positionFix?.mY ? markersGroup.positionFix?.mY : 0,
    radius: 1,
    stroke: positionFixColors.positionFix,
    strokeWidth: 2,
  });
};

export const createPositionFixText = () => {
  return new Konva.Text({
    text: getFixTime(tooltipFormatOptions),
    width: 60,
    height: 20,
    offsetX: 30,
    offsetY: 30,
    fontFamily: 'Lato',
    fill: positionFixColors.positionFix,
    align: 'center',
    fontSize: 18,
  });
};

export const createPositionFixTypeText = (selectedFixType: PositionFixType) => {
  return new Konva.Text({
    text: getFixType(selectedFixType),
    width: 50,
    height: 20,
    offsetX: 25,
    offsetY: -20,
    fontFamily: 'Lato',
    fill: positionFixColors.positionFix,
    align: 'center',
    fontSize: 18,
  });
};

export const createPositionFixHorizontalLine = (pixelpts: PixelPoint, id: string) => {
  return new Konva.Line({
    points: [pixelpts.x - 12, pixelpts.y, pixelpts.x + 12, pixelpts.y],
    id: 'position_fix_hline_' + id,
    stroke: positionFixColors.bearing,
    strokeWidth: 1,
  });
};

export const createPositionFixVerticalLine = (pixelpts: PixelPoint, id: string) => {
  return new Konva.Line({
    points: [pixelpts.x - 12, pixelpts.y, pixelpts.x + 12, pixelpts.y],
    id: 'position_fix_hline_' + id,
    stroke: positionFixColors.bearing,
    strokeWidth: 1,
  });
};

export const getFixType = (selectedFixType: PositionFixType) => {
  if (selectedFixType == PositionFixType.Visual) {
    return 'V';
  } else if (selectedFixType == PositionFixType.Radar) {
    return 'R';
  } else {
    return 'DR';
  }
};

export const getFixTime = (formatOptions: Intl.DateTimeFormatOptions) => {
  const timeFormat = new Intl.DateTimeFormat('default', formatOptions);
  return timeFormat.format();
};

export const getIntersectionOfBearingAndRange = (circle: ILOP, linePoints: MeterPoint[]) => {
  const v1x = linePoints[1].mX - linePoints[0].mX;
  const v1y = linePoints[1].mY - linePoints[0].mY;
  const v2x = linePoints[0].mX - circle.center.mX;
  const v2y = linePoints[0].mY - circle.center.mY;
  let b = v1x * v2x + v1y * v2y;
  const c = 2 * (v1x * v1x + v1y * v1y);
  b *= -2;
  const lngLatPos = meterToLngLat(circle.center);
  const radius = ((circle.range ? circle.range : 0) * nmInMeter) / Math.cos(lngLatPos.lat * degInRad);
  const d = Math.sqrt(b * b - 2 * c * (v2x * v2x + v2y * v2y - radius * radius));
  if (isNaN(d)) {
    // no intercept
    return [];
  }
  const u1 = (b - d) / c; // these represent the unit distance of point one and two on the line
  const u2 = (b + d) / c;

  const ret = []; // return array
  if (u1 <= 1 && u1 >= 0) {
    // add point if on the line segment
    ret.push({ mX: linePoints[0].mX + v1x * u1, mY: linePoints[0].mY + v1y * u1 });
  }
  if (u2 <= 1 && u2 >= 0) {
    // second add point if on the line segment
    ret.push({ mX: linePoints[0].mX + v1x * u2, mY: linePoints[0].mY + v1y * u2 });
  }
  return ret;
};

export const positionFixAngle = (degrees: number) => {
  const angle = degrees + 180;
  if (angle === 360) return 0;
  if (angle > 360) return angle - 360;
  return angle;
};
