<template>
  <div v-if="show" class="title">
    <div class="name">{{ name }}</div>
    <div class="company">{{ company }}</div>
  </div>
  <icon-logout v-if="show" class="company" @click="logout" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { markAsComplete, startHeartbeat } from '../../session';
import user from '../../user';

export default defineComponent({
  setup() {
    const show = computed(() => user.state.sessionId !== undefined);
    const name = computed(() => user.state.name ?? '');
    const company = computed(() => user.state.company?.name ?? '');

    startHeartbeat();
    const logout = (): void => {
      markAsComplete();
    };

    return {
      show,
      name,
      company,
      logout,
    };
  },
});
</script>
<style scoped>
.title {
  margin: 0 16px;
}

.name {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text_main);
}

.company {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text_static);
}
</style>
