<template>
  <signi-panel-select
    :disabled="disabled || !enabled"
    heading="Vector"
    :options="options"
    :model-value="modelValue"
    :secondary-options="secondaryOptions"
    :secondary-value="secondaryValue"
    unit="min"
    @update:secondary-value="$emit('update:secondaryValue', $event)"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #selected="{ value }">{{ value || '-' }}</template>
    <template #secondary-option="{ value }">{{ secondaryOptionLabelsMap[value] }}</template>
    <template #general>
      <signi-switch :disabled="disabled" :model-value="enabled" @update:model-value="$emit('update:enabled', $event)" />
    </template>
  </signi-panel-select>
</template>

<script setup lang="ts">
import { SelectOption } from '../select/types';

defineProps<{
  /** Disable the select and toggle controls */
  disabled?: boolean;
  /** Value of the toggle control */
  enabled: boolean;
  /** Value of the select */
  modelValue?: number;
  /** Values to select from (usually number of minutes) */
  options: SelectOption<number>[];
  /* Value of the secondary select */
  secondaryOptions: SelectOption<string>[];
  /** Secondary values to select from (usually True / Relative) */
  secondaryValue: string;
}>();
defineEmits(['update:modelValue', 'update:secondaryValue', 'update:enabled']);

const secondaryOptionLabelsMap = {
  True: 'True',
  Relative: 'Rel',
};
</script>
