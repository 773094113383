import Konva from 'konva';
import { DeepReadonly, watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { getChartServerImageClient } from '../../api-clients/getChartServerImageClient';
import { displayGroupsCtx } from '../../contexts/displayGroups';
import { imageLoadingCtx } from '../../contexts/imageLoading';
import { safetySettingCtx } from '../../contexts/safetySetting';
import { viewportCtx } from '../../contexts/viewport';
import { Viewport } from '../../types';
import { meterToLngLat } from '../../utils/conversions';

const maxImagesInMemory = 10;
const dimensionFactor = 1.5;
let controller: AbortController | undefined;

/**
 * Add a chart image to render on the canvas.
 *
 * @param {Konva.Layer} layer The layer to add images to.
 * @param {Viewport} viewport The viewport parameters that will be requested.
 * @param {string[]} selectedDisplayGroups The display groups that are currently selected.
 */
async function addChartImage(
  layer: Konva.Layer,
  {
    dimension: { width, height },
    center: { mX, mY },
    scaling: { scale, dpm },
    rotation: { angle },
  }: DeepReadonly<Viewport>,
  selectedDisplayGroups: readonly string[],
  { depthShades, safetyDepth, safetyContour, shallowContour, deepContour }: Record<string, string | number | boolean>,
  setLoading: (loading: boolean) => void,
  setError: (loading: boolean) => void,
): Promise<void> {
  setLoading(false);

  // cancel outstanding image request
  if (controller !== undefined) {
    controller.abort();
  }

  // create new abortcontroller
  controller = new AbortController();

  const negRotation = (360 - angle) % 360;
  const { lng, lat } = meterToLngLat({ mX, mY });
  const displayGroups = selectedDisplayGroups.length > 0 ? selectedDisplayGroups.join(',') : 'none';

  // request slightly larger images to accommodate for scrolling, moving etc
  const newWidth = Math.round(width * dimensionFactor);
  const newHeight = Math.round(height * dimensionFactor);
  const rotationCenterX = Math.round(newWidth / 2);
  const rotationCenterY = Math.round(newHeight / 2);

  setLoading(true);
  setError(false);
  // fetch image blob
  const chartServerImageClient = await getChartServerImageClient();
  const res = await chartServerImageClient
    .chart(
      newWidth,
      newHeight,
      lat,
      lng,
      scale,
      dpm,
      negRotation,
      rotationCenterX,
      rotationCenterY,
      displayGroups,
      shallowContour as number,
      safetyContour as number,
      deepContour as number,
      safetyDepth as number,
      depthShades as boolean,
      controller.signal,
    )
    .catch((err: Error) => {
      // throws when controller is aborted, skip it
      // handle failed calls which are not aborted by abort controller
      if (err.name != 'AbortError') {
        setLoading(false);
        setError(true);
      }
    });
  if (!res) {
    return;
  }

  // create image from blob data
  const image = new Image();
  image.src = URL.createObjectURL(res.data);

  // add to layer when loaded
  image.onload = () => {
    setLoading(false);

    // remove oldest chart images when too many
    const chartImageNodes = layer.getChildren();
    if (chartImageNodes.length === maxImagesInMemory) {
      chartImageNodes[0].destroy();
    }

    // need to calculate based on the passed in scaling, not the actual viewport state
    const pxToMeter = (px: number) => (px / dpm) * scale;
    const widthMeters = pxToMeter(newWidth);
    const heightMeters = pxToMeter(newHeight);
    const offsetX = pxToMeter(rotationCenterX);
    const offsetY = pxToMeter(rotationCenterY);
    const x = mX - widthMeters / 2 + offsetX;
    const y = mY - heightMeters / 2 + offsetY;

    // position image on given center
    const chartImage = new Konva.Image({
      image,
      x,
      y,
      offsetX,
      offsetY,
      width: widthMeters,
      height: heightMeters,
      rotation: negRotation,
      opacity: 0,
    });
    layer.add(chartImage);

    // fade in
    new Konva.Tween({
      node: chartImage,
      duration: 0.25,
      opacity: 1,
    }).play();
  };

  // clear abort controller
  controller = undefined;
}

export function watchUpdateChartImages(chartImageLayer: Konva.Layer): void {
  const { viewport } = useContext(viewportCtx);
  const { state: displayGroupsState } = useContext(displayGroupsCtx);
  const { values: safetySettingsValues } = useContext(safetySettingCtx);
  const { setLoading, setError } = useContext(imageLoadingCtx);

  // watch throttled viewport changes and other image state, add new image if changed
  watch(
    [() => viewport.throttled, () => displayGroupsState, () => safetySettingsValues],
    () =>
      addChartImage(
        chartImageLayer,
        viewport,
        displayGroupsState.selected,
        safetySettingsValues.value,
        setLoading,
        setError,
      ),
    { deep: true },
  );
}
