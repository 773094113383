<template>
  <div>
    <div
      v-if="!modelValue.positionFix || modelValue.isEditMode"
      class="border-b border-background_tile_border bg-background_layer_below py-2"
    >
      <div class="w-full px-4">
        <div class="grid-container gap-2">
          <template v-for="(LOP, lopIndex) in modelValue.LOPs" :key="LOP.id">
            <div class="cell-center border-background_tile_border text-sm text-text_static">
              {{ lopIndex + 1 }}
            </div>
            <signi-kpi-input
              v-if="LOP.type == 'Bearing'"
              class="cell-center px-2"
              :decimals="4"
              :formatted-value="positionFixAngle(LOP.bearing ?? 0).toFixed(1)"
              :max="360"
              :model-value="positionFixAngle(LOP.bearing ?? 0)"
              no-max
              unit-preset="deg"
              @update:model-value="$emit('updateBearingRange', props.modelValue.id, LOP.id, 'Bearing', +$event)"
            />
            <signi-kpi-input
              v-if="LOP.type == 'Range'"
              class="cell-center px-2"
              :decimals="4"
              :model-value="LOP.range"
              :formatted-value="LOP.range?.toString()"
              no-max
              unit="NM"
              @update:model-value="$emit('updateBearingRange', props.modelValue.id, LOP.id, 'Range', +$event)"
            />
            <span class="text-text_main">
              {{ formatPositionDegree(LOP.location.lat) }} {{ degToLatDirection(LOP.location.lat) }}
            </span>
            <span class="text-text_main"
              >{{ formatPositionDegree(LOP.location.lng, true) }} {{ degToLongDirection(LOP.location.lng) }}</span
            >
            <button
              class="btn btn-flat btn-xs"
              title="Remove Bearing/Range"
              @click.stop="$emit('removeLop', modelValue.id, LOP.id)"
            >
              <signi-icon name="DeleteIcon" />
            </button>
          </template>
        </div>
      </div>
      <div class="flex w-full gap-2 px-2 py-1">
        <button
          v-show="modelValue.LOPs.length < 3"
          class="btn btn-xs min-w-26 uppercase"
          title="Add bearing"
          @click="$emit('addPositionType', modelValue.id, 'Bearing')"
        >
          Add Bearing
        </button>
        <button
          v-show="modelValue.LOPs.length < 3 && modelValue.LOPs.filter((LOP) => LOP.type == 'Range').length < 1"
          class="btn btn-xs min-w-26 uppercase"
          title="Add range"
          @click="$emit('addPositionType', modelValue.id, 'Range')"
        >
          Add Range
        </button>
      </div>
      <div class="flex h-14 w-full gap-2 px-2 py-1">
        <div class="panel-select-container">
          <div class="ml-2 text-xs uppercase text-text_static">Fix Type</div>
          <signi-panel-select
            class="mr-2"
            position="right"
            :options="typeOptions"
            :model-value="selectedFixType"
            @update:model-value="$emit('updateType', props.modelValue.id, $event)"
          />
        </div>
        <div class="flex-1"></div>
        <button class="btn btn-xs position-action-btn mt-4 uppercase" @click.stop="$emit('cancelEdit', modelValue.id)">
          Cancel
        </button>
        <button
          v-if="!modelValue.isEditMode"
          :disabled="modelValue.LOPs.length < 2"
          class="btn btn-xs position-action-btn mt-4 bg-feedback_text uppercase hover:bg-feedback_text"
          @click.stop="$emit('createPositionFixOnChart', modelValue.id)"
        >
          Create fix
        </button>
        <button
          v-if="modelValue.isEditMode"
          :disabled="modelValue.LOPs.length < 2"
          class="btn btn-xs position-action-btn mt-4 bg-feedback_text uppercase hover:bg-feedback_text"
          @click.stop="$emit('updatePositionFixOnChart', modelValue.id)"
        >
          Done
        </button>
      </div>
    </div>

    <div
      v-if="modelValue.positionFix && !modelValue.isEditMode"
      class="border-b border-background_tile_border bg-background_layer_below py-2"
    >
      <div class="w-full px-4"></div>
      <div class="flex w-full justify-between gap-2 px-2 py-1">
        <div class="panel-select-container">
          <div class="ml-2 text-xs uppercase text-text_static">Fix Type</div>
          <div class="ml-2 text-xs">{{ modelValue.selectedFixType }}</div>
        </div>
        <div class="flex gap-2">
          <button
            :aria-pressed="props.modelValue.visible"
            :class="['btn btn-flat btn-xs', { 'btn-selected': props.modelValue.visible }]"
            title="Toggle visibility"
            @click="$emit('updateVisibility', props.modelValue.id, !props.modelValue.visible)"
          >
            <signi-icon v-if="props.modelValue.visible" name="VisibleIcon" />
            <signi-icon v-else name="HiddenIcon" />
          </button>
          <button class="btn btn-flat btn-xs" title="Pin position fix" @click="$emit('pin', props.modelValue.id)">
            <signi-icon name="TargetpinIcon" />
          </button>
          <button
            class="btn btn-flat btn-xs"
            title="Edit position fix"
            @click.stop="$emit('startEdit', props.modelValue.id)"
          >
            <signi-icon name="EditIcon" />
          </button>
          <button
            class="btn btn-flat btn-xs"
            title="Delete position fix"
            @click.stop="$emit('deletePositionFix', modelValue.id)"
          >
            <signi-icon name="DeleteIcon" />
          </button>
        </div>
      </div>
      <div class="flex w-full justify-between px-4 py-1">
        <span class="text-text_main">
          {{ formatPositionDegree(meterToLngLat(modelValue.positionFix).lat) }}
          {{ degToLatDirection(meterToLngLat(modelValue.positionFix).lat) }}
        </span>
        <span class="text-text_main"
          >{{ formatPositionDegree(meterToLngLat(modelValue.positionFix).lng, true) }}
          {{ degToLongDirection(meterToLngLat(modelValue.positionFix).lng) }}</span
        >
        <div class="my-auto">
          <signi-switch
            :model-value="modelValue.showLOPs"
            @update:model-value="$emit('updateShowLops', props.modelValue.id, $event)"
          />
        </div>
        <div class="text-xs text-text_static">SHOW LOPS</div>

        <span class="ml-4 text-xs text-text_static">
          {{ modelValue.positionFixTimestamp }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SelectOption } from '@signi/common';
import { meterToLngLat } from '../../../../../ecdis/src/lib/utils/conversions';
import { degToLatDirection, degToLongDirection, formatPositionDegree } from '../../formatters';
import { PositionFixMarker, PositionFixType } from '../types';

const props = defineProps<{ modelValue: PositionFixMarker }>();
defineEmits([
  'addPositionType',
  'cancelEdit',
  'createPositionFixOnChart',
  'deletePositionFix',
  'pin',
  'removeLop',
  'startEdit',
  'update:modelValue',
  'updateBearingRange',
  'updatePositionFixOnChart',
  'updateShowLops',
  'updateType',
  'updateVisibility',
]);

const typeOptions: SelectOption<string>[] = Object.values(PositionFixType).map((type) => ({ id: type }));
const selectedFixType = computed(() => props.modelValue.selectedFixType);
const positionFixAngle = (degrees: number) => {
  const angle = degrees + 180;
  if (angle === 360) return 0;
  if (angle > 360) return angle - 360;
  return angle;
};
</script>

<style scoped>
.panel-select-container {
  flex-basis: 11.875rem;
}
.grid-container {
  @apply relative grid items-center;
  grid-template-columns: auto 4fr 3fr 3fr auto;
}

.cell-center {
  @apply flex h-full items-center;
}

.position-action-btn {
  flex-basis: 104px;
}
</style>
