<template>
  <pan-draggable-panel class="vessel-selector-panel">
    <template #header>
      <pan-row class="header">
        <div class="col title-container h-12">
          <ecdis-value>CATALOGUE</ecdis-value>
          <ecdis-input-box placeholder="Search" @changed="changed" />
          <icon-search class="iconSearch" />
        </div>
        <div class="col close-drag-container">
          <icon-grab class="grab-icon" />
          <icon-close class="close-icon" @click="$emit('close')" />
        </div>
      </pan-row>
    </template>

    <template #main>
      <div class="content">
        <div class="col ship-container">
          <vessel-list :search-text="searchText" />
        </div>
        <div class="col ship-container">
          <vessel-detail @close="$emit('close')" />
        </div>
      </div>
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts" setup>
import { onUpdated, ref } from 'vue';
import VesselDetail from './VesselDetail.vue';
import VesselList from './VesselList.vue';

defineEmits(['close']);

const searchText = ref('');

const changed = (text: string) => {
  searchText.value = text;
};

onUpdated(() => changed(''));
</script>

<style scoped>
.vessel-selector-panel {
  width: 784px;
  height: 576px;
  border-radius: 4px;
  background: var(--background_tile);
  top: 10px;
  left: 10px;
  z-index: 800;
  filter: drop-shadow(3px 3px 16px rgba(0, 0, 0, 0.4));
}

.header {
  align-items: unset;
  cursor: move;
}

.iconSearch {
  position: absolute;
  left: calc(50% - 36px);
}

.col {
  width: 50%;
  border-right: 1px solid #000000;
  display: flex;
}
.col:last-child {
  border-right: none;
}

.title-container {
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
}

.close-drag-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grab-icon {
  margin-right: 8px;
  cursor: move;
}

.close-icon {
  margin-right: 8px;
  cursor: pointer;
}

.content {
  height: calc(100% - 48px);
  display: flex;
}

.ship-container {
  flex-direction: column;
}
</style>
