<template>
  <div class="routesPanel">
    <transition :name="showDetail ? 'slideLeft' : 'slideRight'">
      <ecdis-routes-overview v-show="!showDetail" />
    </transition>
    <transition :name="showDetail ? 'slideLeft' : 'slideRight'">
      <ecdis-routes-detail v-show="showDetail" />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { routesCtx } from '../../../contexts/routes';
import { toValidationRoute } from '../../../utils/routeConversions';

export default defineComponent({
  emits: ['emitEvent'],
  setup(_, { emit }) {
    const { state, monitoredRoute } = useContext(routesCtx);
    const { showDetail } = toRefs(state);

    watchEffect(() => {
      if (monitoredRoute.value) {
        emit('emitEvent', { id: 'monitoredRoute', value: JSON.stringify(toValidationRoute(monitoredRoute.value)) });
      } else {
        emit('emitEvent', { id: 'monitoredRoute', value: JSON.stringify('') });
      }
    });

    return {
      showDetail,
    };
  },
});
</script>

<style scoped>
.routesPanel {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.slideLeft-enter-active,
.slideLeft-leave-active,
.slideRight-enter-active,
.slideRight-leave-active {
  transition: transform 0.25s ease-in-out;
}
.slideLeft-enter-from,
.slideRight-leave-to {
  transform: translateX(100%);
}
.slideLeft-leave-to,
.slideRight-enter-from {
  transform: translateX(-100%);
}
</style>
