import Konva from 'konva';
import { DeepReadonly, watch, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { routeValidationCtx } from '../../contexts/routeValidation';
import { viewportCtx } from '../../contexts/viewport';
import { Geometry, GroundingData, LatLon } from '../../generated/ChartServer';
import { lngLatToMeter, radInDeg } from '../../utils/conversions';

export function addRouteValidation(layer: Konva.Layer): void {
  const { state, setActiveIdx, setSelectedFeatureId, setHighlightedFeatureId } = useContext(routeValidationCtx);
  const { pxToMeter } = useContext(viewportCtx);

  const contourGroup = new Konva.Group();
  layer.add(contourGroup);

  const warningGroup = new Konva.Group();
  layer.add(warningGroup);

  const selectGroup = new Konva.Group();
  layer.add(selectGroup);

  const boxGroup = new Konva.Group();
  layer.add(boxGroup);

  const highlightGroup = new Konva.Group();
  layer.add(highlightGroup);

  const highlightPointGroup = new Konva.Group();
  layer.add(highlightPointGroup);

  // TODO move boxes to absolute group
  const updateSizes = () => {
    const size = pxToMeter(20);
    boxGroup.getChildren().forEach((child) =>
      (child as Konva.Rect)
        .size({
          width: size,
          height: size,
        })
        .offset({
          x: size / 2,
          y: size / 2,
        }),
    );
    highlightPointGroup.getChildren().forEach((child) => (child as Konva.Circle).radius(size * 0.25));
  };

  const addCoverage = (coverageAreas: DeepReadonly<LatLon[][] | null | undefined>, isActive: boolean, idx: number) => {
    if (!coverageAreas) return;

    coverageAreas.forEach((coverageArea) => {
      const points = coverageArea.flatMap((point) => {
        const lng = (point.longitude ?? 0) * radInDeg;
        const lat = (point.latitude ?? 0) * radInDeg;
        const { mX, mY } = lngLatToMeter({ lng, lat });
        return [mX, mY];
      });
      if (isActive) {
        selectGroup.add(
          new Konva.Line({
            points,
            stroke: '#202020',
            closed: true,
            fillEnabled: false,
            strokeWidth: 3,
            strokeScaleEnabled: false,
          }),
          new Konva.Line({
            points,
            stroke: '#f9f154',
            closed: true,
            fillEnabled: false,
            strokeWidth: 2,
            strokeScaleEnabled: false,
          }),
        );
      }
      const shape = new Konva.Line({
        points,
        fill: '#00ff4040',
        closed: true,
      });

      shape.on('mouseover', (ev) => {
        layer.getStage().container().style.cursor = 'pointer';
        // cancel unset of cursor
        ev.cancelBubble = true;
      });
      shape.on('click', () => {
        setActiveIdx(idx);
        setSelectedFeatureId('');
      });

      contourGroup.add(shape);
    });
  };

  const findFeature = (featureId?: string | null) => {
    if (!featureId) return;

    return state.validationResults?.flatMap((validationResult) =>
      validationResult.waypointResults?.flatMap((waypointResult) =>
        waypointResult.data?.flatMap((result) =>
          result.warnings?.flatMap((feature) => (feature.id === featureId ? [feature] : [])),
        ),
      ),
    )[0];
  };

  const addGeometry = (geometry: DeepReadonly<Geometry[] | null | undefined>, idx: number) => {
    if (!geometry) return;

    geometry.forEach((polygon) => {
      const feature = findFeature(polygon.id);
      const isRendered = !feature?.properties?.some(
        (property) => property.title === 'Class' && property.value === 'Restricted area',
      );
      const isHighlighted = polygon.id === state.highlightedFeatureId;

      const points = (polygon.points ?? []).flatMap((point) => {
        const lng = (point.longitude ?? 0) * radInDeg;
        const lat = (point.latitude ?? 0) * radInDeg;
        const { mX, mY } = lngLatToMeter({ lng, lat });
        return [mX, mY];
      });
      if (points.length === 2) {
        // one point, render as box
        const [x, y] = points;
        const box1 = new Konva.Rect({
          x,
          y,
          stroke: '#202020',
          strokeWidth: 3,
          strokeScaleEnabled: false,
        });
        const box2 = new Konva.Rect({
          x,
          y,
          stroke: '#f9f154',
          strokeWidth: 2,
          strokeScaleEnabled: false,
        });
        box2.on('mouseover', (ev) => {
          layer.getStage().container().style.cursor = 'pointer';
          // cancel unset of cursor
          ev.cancelBubble = true;
        });
        box2.on('click', () => {
          setActiveIdx(idx);
          setSelectedFeatureId(polygon?.id ?? '');
          setHighlightedFeatureId(polygon?.id ?? '');
        });

        boxGroup.add(box1, box2);
        if (isHighlighted) {
          highlightPointGroup.add(
            new Konva.Circle({
              x,
              y,
              radius: 1,
              fill: '#000',
              stroke: '#f9f154',
              strokeWidth: 2,
              strokeScaleEnabled: false,
            }),
          );
        }
        return;
      }

      const shape = new Konva.Line({
        points,
        fill: isHighlighted ? '#000' : '#ff000080',
        stroke: isHighlighted ? 'white' : 'red',
        closed: true,
        strokeWidth: 2,
        strokeScaleEnabled: false,
      });
      shape.on('mouseover', (ev) => {
        layer.getStage().container().style.cursor = 'pointer';
        // cancel unset of cursor
        ev.cancelBubble = true;
      });
      shape.on('click', () => {
        setActiveIdx(idx);
        setSelectedFeatureId(polygon?.id ?? '');
        setHighlightedFeatureId(polygon?.id ?? '');
      });

      if (isHighlighted) {
        highlightGroup.add(shape);
      } else if (isRendered) {
        warningGroup.add(shape);
      }
    });
  };

  const addFeatures = (groundings: DeepReadonly<GroundingData[] | null | undefined>, idx: number) => {
    if (!groundings) return;

    groundings.forEach((grounding) => {
      addGeometry(grounding.polygons?.warningPolygons, idx);
      addGeometry(grounding.polygons?.groundingPolygons, idx);
    });
  };

  watch(
    [
      () => state.validationResults,
      () => state.activeValidationResultIdx,
      () => state.showDetails,
      () => state.highlightedFeatureId,
    ],
    () => {
      contourGroup.destroyChildren();
      warningGroup.destroyChildren();
      selectGroup.destroyChildren();
      boxGroup.destroyChildren();
      highlightGroup.destroyChildren();
      highlightPointGroup.destroyChildren();

      if (!state.showDetails || !state.validationResults) {
        return;
      }

      state.validationResults.forEach((validationResult, idx) => {
        const isActive = state.activeValidationResultIdx === state.validationResults?.indexOf(validationResult);
        validationResult.waypointResults?.forEach((waypointResult) => {
          addCoverage(waypointResult.coverage, isActive, idx);
          addFeatures(waypointResult.data, idx);
        });
      });

      updateSizes();
    },
    { deep: true },
  );

  watchEffect(updateSizes);
}
