<template>
  <svg viewBox="0 0 32 32" fill="none">
    <path d="M8.5 15.5H23.5" stroke="#F6F6F6" />
    <path d="M16 23V8" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
