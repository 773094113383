import { MaybeElementRef, useResizeObserver } from '@vueuse/core';
import { onMounted } from 'vue';
import { useContext } from 'vue-context-composition';
import { viewportCtx } from './contexts/viewport';

export function observeDimension(element: MaybeElementRef): void {
  const { setDimension } = useContext(viewportCtx);

  onMounted(() => {
    // observe resize and update viewport dimension
    useResizeObserver(element, (entries) => {
      const entry = entries[0];
      const { width, height } = entry.contentRect;
      const componentWidth = Math.floor(width);
      const componentHeight = Math.floor(height);
      if (componentWidth > 0 && componentHeight > 0) {
        setDimension({
          width: componentWidth,
          height: componentHeight,
        });
      }
    });
  });
}
