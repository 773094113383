<template>
  <div v-if="editing" class="bg-button_background-selected">
    <input
      ref="eblInput"
      class="w-full bg-[transparent] text-feedback_text outline-none"
      :class="'text-kpi-' + size"
      min="0"
      :step="10 ** -decimals"
      type="number"
      @blur="confirm"
      @change="onChange"
      @keyup.enter="confirm"
      @keyup.esc="discard"
    />
  </div>
  <div v-else :class="{ 'hover:bg-input_background-default': !disabled }" @click="!disabled && edit()">
    <signi-kpi
      :class="{ 'text-button_text-disabled': disabled }"
      label-alignment="left"
      :size="size"
      :value="formattedValue"
      :unit="unit"
      :unit-preset="unitPreset"
    />
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    decimals?: number;
    disabled?: boolean;
    formattedValue: string;
    modelValue: number;
    max?: number;
    min?: number;
    /** Clamps to 0 when the value is the max value */
    noMax?: boolean;
    size?: 'xxxs' | 'xxs' | 'xs' | 'md' | 'lg';
    unit?: string;
    unitPreset?: 'deg' | 'degCelcius' | 'degPerMin';
  }>(),
  {
    decimals: 0,
    max: Number.POSITIVE_INFINITY,
    min: 0,
    size: 'xxs',
    unit: undefined,
    unitPreset: undefined,
  },
);
const emit = defineEmits(['update:modelValue']);

const eblInput = ref<HTMLInputElement | null>(null);
const editing = ref(false);

const inputMask = (value: number): number => {
  const clamped = Math.min(Math.max(props.min, value), props.max);
  const noMax = clamped === props.max && props.noMax ? 0 : clamped;
  const rounded = Math.round(noMax * 10 ** props.decimals) / 10 ** props.decimals;

  return rounded;
};

const edit = () => {
  editing.value = true;

  nextTick(() => {
    if (eblInput.value) {
      eblInput.value.value = inputMask(props.modelValue).toString();
      eblInput.value.focus();
    }
  });
};

let escPressed = false;
const discard = () => {
  escPressed = true;
  editing.value = false;
};

const confirm = (e: Event) => {
  if (escPressed) {
    escPressed = false;
    return;
  }

  const val = +(e.target as HTMLInputElement).value;
  const validatedVal = inputMask(val);

  emit('update:modelValue', validatedVal);
  editing.value = false;
};

const onChange = (e: Event) => {
  const input = e.target as HTMLInputElement;
  const val = +input.value;
  input.value = inputMask(val).toString();
};
</script>
