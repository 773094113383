<template>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.375 15.125V0.875H12.625V15.125H1.375Z" stroke="#F6F6F6" stroke-linejoin="round" />
    <path d="M3.25 4.625H10.75M3.25 7.625H10.75M3.25 10.625H10.75" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 16px;
  height: 16px;
}
</style>
