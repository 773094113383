import { DeepReadonly } from 'vue';
import { EnrichedRoute } from '../contexts/routes';
import { CriticalPoint, EnrichedCriticalPoint, EnrichedWaypoint, LatLon } from '../generated/ChartServer';
import { MonitoringMode, Waypoint } from '../generated/RouteManagement';
import { LngLatPoint, MeterPoint } from '../types';
import {
  calculateDistance,
  degInRad,
  halfPi,
  latLonPointToMeter,
  lngLatToMeter,
  meterToLngLat,
  pipi,
  radInDeg,
} from './conversions';
import { getScalingFactor } from './mercator';
import { vector } from './vector';

export type CriticalPointsLabel = {
  criticalPointLabelMeterPoint: MeterPoint;
  detail: CriticalPoint;
  displayText: string;
  wpId: string;
};

export type TrackPoint = {
  position: LatLon;
  waypoint: string;
};

export type WpDist = {
  waypoint: string;
  distance: number;
  index: number;
};

export const getRefinedTrackPoints = (
  enrichedRoute: DeepReadonly<EnrichedRoute> | undefined,
): (TrackPoint | undefined)[] => {
  const tracks =
    enrichedRoute?.calculationResult?.waypoints?.flatMap((w) =>
      w.track?.map(
        (l) =>
          <TrackPoint>{
            waypoint: enrichedRoute?.legs?.find((l) => l.fromWaypoint == w.waypointId)?.toWaypoint ?? '',
            position: <LatLon>{ latitude: l?.latitude ?? 0, longitude: pipi(l?.longitude ?? 0) },
          },
      ),
    ) ?? [];
  //Remove very close points that cause issues with the direction of the bounding line
  //These are overlapping points where the legs and turns meet
  //Should perhaps be fixed in the route calculation and not here
  return tracks.filter(
    (point, index, array) =>
      index === 0 ||
      calculateDistance(
        <LngLatPoint>{ lat: point?.position.latitude, lng: point?.position.longitude },
        <LngLatPoint>{ lat: array[index - 1]?.position.latitude, lng: array[index - 1]?.position.longitude },
      ) > 5,
  );
};

export const calculateCriticalPointPositions = (
  enrichedWaypoints: DeepReadonly<EnrichedWaypoint[]>,
  route: DeepReadonly<EnrichedRoute> | undefined,
): CriticalPointsLabel[] => {
  const criticalPointsLabelData: CriticalPointsLabel[] = [];
  route?.calculationResult?.criticalPoints?.forEach((cp: CriticalPoint) => {
    if (cp.waypointId && cp.monitoringMode && cp.persist) {
      const findPreviousLeg = route.legs?.filter((l) => l.toWaypoint === cp.waypointId)[0];
      const prevWpt = enrichedWaypoints.find((item) => item.waypointId === findPreviousLeg?.fromWaypoint);
      const track = filterOverlappingTracks(prevWpt?.track)?.reverse() ?? [];
      const cpLatLng = getCriticalPointPositionOnTrack(track, cp.distanceFromWaypoint);
      const cpInMeter = lngLatToMeter({
        lng: radInDeg * (cpLatLng.lng ?? 0),
        lat: radInDeg * halfPi(cpLatLng.lat ?? 0),
      });
      //  lat long in degrees.

      criticalPointsLabelData.push({
        criticalPointLabelMeterPoint: cpInMeter,
        detail: cp,
        displayText: cp.triggerValue + ' ' + (cp.monitoringMode === MonitoringMode.Distance ? 'Nm' : 'min'),
        wpId: cp.waypointId,
      });
    }
  });

  return criticalPointsLabelData;
};

export const getCriticalPointsForRoute = (route: EnrichedRoute | undefined): CriticalPoint[] => {
  return route?.calculationResult?.criticalPoints ? (route?.calculationResult?.criticalPoints as CriticalPoint[]) : [];
};

export const getStructuredMapDataForCriticalPointsPanel = (
  criticalPoints: DeepReadonly<EnrichedCriticalPoint[]>,
): Map<string, CriticalPoint[]> => {
  const criticalPointsMap = new Map<string, CriticalPoint[]>();
  if (criticalPoints.length > 0) {
    criticalPoints.forEach((cp) => {
      if (cp.waypointId && criticalPointsMap.has(cp.waypointId)) {
        const currCriticalPoints = criticalPointsMap.get(cp.waypointId);
        if (currCriticalPoints) {
          currCriticalPoints.push({ ...cp });
          criticalPointsMap.set(cp.waypointId, currCriticalPoints);
        }
      } else {
        if (cp.waypointId) criticalPointsMap.set(cp.waypointId, [{ ...cp }]);
      }
    });
  }
  return criticalPointsMap;
};

export const allCriticalPointPersisted = (cps: CriticalPoint[]): boolean => {
  return !cps.find((item) => !item.persist);
};

export const updateCriticalPointMapData = (
  criticalPointsMap: Map<string, CriticalPoint[]>,
  criticalPointId: string,
  newValue: string | undefined,
  updateParam: 'message' | 'triggerValue' | 'monitoringMode',
  waypointId: string,
) => {
  if (criticalPointsMap.has(waypointId)) {
    const currCriticalPoints = criticalPointsMap.get(waypointId);
    const criticalPoint = currCriticalPoints?.find((cp) => cp.criticalPointId === criticalPointId);
    if (criticalPoint && currCriticalPoints) {
      switch (updateParam) {
        case 'message':
          criticalPoint.message = newValue;
          break;
        case 'triggerValue':
          criticalPoint.triggerValue = newValue === undefined ? 0 : +newValue;
          break;
        case 'monitoringMode':
          criticalPoint.monitoringMode = newValue === 'min' ? MonitoringMode.Time : MonitoringMode.Distance;
          break;
      }
      criticalPointsMap.set(waypointId, currCriticalPoints);
    }
  }
  return criticalPointsMap;
};

export const getWaypointIndex = (waypoints: DeepReadonly<Waypoint[]> | undefined, waypointId: string): number => {
  const wpIndex = waypoints?.findIndex((x) => x.waypointId === waypointId);
  return wpIndex != undefined && wpIndex !== -1 ? wpIndex + 1 : -1;
};

export const allowCriticalPointsSave = (
  criticalPointsMap: Map<string, CriticalPoint[]>,
  routeWaypoints: DeepReadonly<Waypoint[]> | undefined,
): boolean => {
  if (!routeWaypoints) return false;
  let enableSave = true;
  const waypoints = [...criticalPointsMap.keys()];
  waypoints.map((wpId) => {
    const refWpIndex = getWaypointIndex(routeWaypoints, wpId);
    if (refWpIndex < 1 || refWpIndex > routeWaypoints?.length) {
      enableSave = false;
      return false;
    }
    const criticalPoints = criticalPointsMap.get(wpId);
    criticalPoints?.map((cp) => (enableSave = cp.triggerValue != undefined && cp.triggerValue >= 0));
  });
  return enableSave;
};

export const waypointCanBeDeleted = (
  waypointId: string,
  waypointList: DeepReadonly<Waypoint[]> | undefined,
): boolean => {
  const waypoint = waypointList?.find((w) => w.waypointId === waypointId);
  if (!waypointList || !waypoint || (waypoint.criticalPoints && waypoint.criticalPoints.length > 0)) {
    return false; // return false if current waypoint has critical points
  }

  const isFirstWpt = waypointList[0].waypointId === waypointId;
  if (isFirstWpt) {
    // return false if second waypoint has critical points
    const secondWpt = waypointList[1];
    return !secondWpt || !secondWpt.criticalPoints || !(secondWpt.criticalPoints.length > 0);
  }
  return true;
};

export const getCriticalPointPositionOnTrack = (
  track: DeepReadonly<LatLon[]> | undefined,
  cpDistanceFromWpt: number | undefined,
): LngLatPoint => {
  let pos: LngLatPoint = {
    lat: 0,
    lng: 0,
  };
  let distanceCounter = 0;
  if (track !== undefined && cpDistanceFromWpt !== undefined) {
    track.every((item: LatLon, index: number) => {
      // iterate through each track segment.
      // calculate the distance between subsequent trackpoints on each iteration.
      const distanceBetweenTrackPoints =
        calculateDistance(
          <LngLatPoint>{ lat: item.latitude, lng: item.longitude },
          <LngLatPoint>{ lat: track[index + 1]?.latitude, lng: track[index + 1]?.longitude },
        ) || 0;
      // check if the sum of distance counter & the last trackpoint distance is becoming more than the saved critical point's distance from waypoint OR if its the last trackpoint being iterated in the trackslist.
      if (distanceCounter + distanceBetweenTrackPoints > cpDistanceFromWpt || index === track.length - 1) {
        // This means the criticalPoint resides somewhere between index & (index + 1) of track.
        // Find the current track segment vector.
        const currentTrackSegmentVector = vector(
          latLonPointToMeter({ latitude: item.latitude, longitude: item.longitude }).mX,
          latLonPointToMeter({ latitude: item.latitude, longitude: item.longitude }).mY,
        );
        // Find the next track segment vector.
        const nextTrackSegmentVector = vector(
          latLonPointToMeter({ latitude: track[index + 1]?.latitude, longitude: track[index + 1]?.longitude }).mX,
          latLonPointToMeter({ latitude: track[index + 1]?.latitude, longitude: track[index + 1]?.longitude }).mY,
        );
        const remainingDistFromCurrentTrackSegment = cpDistanceFromWpt - distanceCounter;
        // compute the Cp vector and set its length equal to the remainingDist from current track segment
        const cpVector = currentTrackSegmentVector
          .subtract(nextTrackSegmentVector)
          .setLength(remainingDistFromCurrentTrackSegment * getScalingFactor((item.latitude ?? 0) * radInDeg));
        const finalVectorPoint = currentTrackSegmentVector.subtract(cpVector);
        pos = {
          lat: degInRad * meterToLngLat({ mX: finalVectorPoint.x, mY: finalVectorPoint.y }).lat,
          lng: degInRad * meterToLngLat({ mX: finalVectorPoint.x, mY: finalVectorPoint.y }).lng,
        };
        return false;
      } else {
        // update the distance counter.
        distanceCounter += distanceBetweenTrackPoints;
      }
      return true;
    });
  }
  return pos;
};

export const getDistanceEnrichedTrack = (track: LatLon[] | undefined, cursorMeterPoint: MeterPoint) => {
  let distanceCounter = 0;
  const filteredOutOverlappingCloseTrackPoints = filterOverlappingTracks(track)?.reverse() ?? [];
  return filteredOutOverlappingCloseTrackPoints.map((trackPoint, index) => {
    distanceCounter += calculateDistance(
      <LngLatPoint>{ lng: trackPoint.longitude, lat: trackPoint.latitude },
      <LngLatPoint>{
        lat: filteredOutOverlappingCloseTrackPoints[index + 1]?.latitude ?? 0,
        lng: filteredOutOverlappingCloseTrackPoints[index + 1]?.longitude ?? 0,
      },
    );
    return {
      index: index,
      distanceFromTrackStart: distanceCounter,
      distanceFromCp: calculateDistance(
        <LngLatPoint>{ lat: trackPoint.latitude ?? 0, lng: trackPoint.longitude ?? 0 },
        <LngLatPoint>{
          lat: meterToLngLat(cursorMeterPoint).lat * degInRad,
          lng: meterToLngLat(cursorMeterPoint).lng * degInRad,
        },
      ),
      latitude: trackPoint.latitude ?? 0,
      longitude: trackPoint.longitude ?? 0,
    };
  });
};

export const filterOverlappingTracks = (tracks: readonly LatLon[] | undefined) => {
  return tracks?.filter(
    (point, index, array) =>
      index === 0 ||
      calculateDistance(
        <LngLatPoint>{ lat: point?.latitude, lng: point?.longitude },
        <LngLatPoint>{ lat: array[index - 1]?.latitude, lng: array[index - 1]?.longitude },
      ) > 5,
  );
};
