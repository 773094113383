import { configuration } from '../configuration';
import { RouteManagementClient } from '../generated/RouteManagement';
import { fetch } from '../utils/http';
import { createClientPromise } from './createClientPromise';

export const getRouteManagementClient = (): Promise<RouteManagementClient> =>
  createClientPromise(
    'RouteManagementClient',
    () =>
      new RouteManagementClient(configuration.baseUrls.routeManagement, {
        fetch,
      }),
  );
