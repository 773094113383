import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { MeterPoint } from '../types';

type State = {
  absoluteMeterPoint?: MeterPoint;
};

export const cursorCtx = defineContext(() => {
  const state: State = reactive({});

  const setAbsoluteMeterPoint = (point: MeterPoint) => {
    state.absoluteMeterPoint = point;
  };

  return {
    state: readonly(state),
    setAbsoluteMeterPoint,
  };
});
