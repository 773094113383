<template>
  <ecdis-app-authentication>
    <div class="planningStation" @contextmenu.prevent>
      <ecdis-top-bar class="topBar" />
      <ecdis-app-authorization>
        <template #authorized>
          <ecdis-root-view class="root">
            <template #sidebar>
              <ecdis-apps-panel></ecdis-apps-panel>
            </template>
            <template #controlbar>
              <ecdis-chart-control-bar class="w-[180px]" />
            </template>
          </ecdis-root-view>
        </template>
        <template #not_authorized>
          <div class="root message">
            <span> Sorry, you are not authorized to use the K-Sim planning station </span>
          </div>
        </template>
        <template #loading>
          <div class="root message">
            <span> Loading, please wait... </span>
          </div>
        </template>
        <template #error="slotProps">
          <div class="root message">
            <span> {{ slotProps.errorMessage }} </span>
          </div>
        </template>
        <template #logout>
          <div class="root message">
            <span> You have been successfully logged out </span>
          </div>
        </template>
      </ecdis-app-authorization>
    </div>
  </ecdis-app-authentication>
</template>

<script lang="ts">
import { defineComponent, toRef, watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { marinersNotesCtx, routesCtx, viewportCtx } from '@signi/ecdis';

export default defineComponent({
  setup() {
    const { state, showDetail, enrichRoute, calculateBounds } = useContext(routesCtx);
    const { fetchMarinersNotesCollection } = useContext(marinersNotesCtx);
    const routes = toRef(state, 'routes');
    const { showInViewport } = useContext(viewportCtx);

    fetchMarinersNotesCollection();

    const stopWatcher = watch(routes, async () => {
      if (routes.value) {
        // routes are loaded
        const urlParamRouteId = new URLSearchParams(window.location.search).get('routeid');
        const route = routes.value.find((route) => route.routeId === urlParamRouteId);

        if (route) {
          showDetail(route);
          await enrichRoute(route);

          showInViewport(calculateBounds(route));
        }

        stopWatcher();
      }
    });
  },
});
</script>

<style scoped>
.planningStation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topBar {
  height: 56px;
  flex-shrink: 0;
}

.root {
  height: calc(100vh - 56px);
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text_static);
  font-size: 48px;
}
</style>
