import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { nmInMeter } from '../utils/conversions';
import { persist } from '../utils/persist';
import { limit } from '../utils/tools';

type RouteSettings = {
  generalSettings: Array<GeneralSettings>;
  editDisplaySettings: Array<DisplaySettings>;
  monitorDisplaySettings?: Array<DisplaySettings>;
  tagSettings: Array<TagSettings>;
};

export type GeneralSettings = {
  id: 'plannedSpeed' | 'radius' | 'offtrackLimit';
  name: string;
  value: { value: string; unit: string };
};

type DisplaySettings = {
  id:
    | 'wptName'
    | 'plannedSpeed'
    | 'nextCourse'
    | 'turnPoint'
    | 'offtrackLimit'
    | 'distanceTags'
    | 'criticalPoints'
    | 'indexLines'
    | 'wopExtensionLines';
  name: string;
  value: boolean;
};

type TagSettings = {
  id: 'routeDistanceTags' | 'distanceTags';
  name: string;
  value: { value: string; unit: string };
  isSelectable: boolean;
};

export type UpdateReq = {
  id: string;
  value: string;
};

type State = {
  visible: boolean;
  settings: RouteSettings;
  distanceBwMarkers: number;
};

const defaultSettings: RouteSettings = {
  generalSettings: [
    { id: 'plannedSpeed', name: 'Planned speed', value: { value: '', unit: 'Kn' } },
    { id: 'radius', name: 'Radius', value: { value: '', unit: 'NM' } },
    { id: 'offtrackLimit', name: 'Offtrack limit', value: { value: '0.05', unit: 'NM' } },
  ],
  monitorDisplaySettings: [
    { id: 'wptName', name: 'WPT name', value: true },
    { id: 'plannedSpeed', name: 'Planned speed', value: false },
    { id: 'nextCourse', name: 'Next course', value: true },
    { id: 'turnPoint', name: 'Turn point', value: false },
    { id: 'offtrackLimit', name: 'Offtrack limit', value: true },
    { id: 'distanceTags', name: 'Distance tags', value: false },
    { id: 'criticalPoints', name: 'Critical points', value: false },
    { id: 'indexLines', name: 'Index lines', value: true },
  ],
  editDisplaySettings: [
    { id: 'wptName', name: 'WPT name', value: true },
    { id: 'plannedSpeed', name: 'Planned speed', value: false },
    { id: 'nextCourse', name: 'Next course', value: false },
    { id: 'turnPoint', name: 'Turn point', value: true },
    { id: 'offtrackLimit', name: 'Offtrack limit', value: true },
    { id: 'distanceTags', name: 'Distance tags', value: false },
    { id: 'criticalPoints', name: 'Critical points', value: false },
    { id: 'indexLines', name: 'Index lines', value: true },
    { id: 'wopExtensionLines', name: 'WOP extension lines', value: false },
  ],
  tagSettings: [
    { id: 'routeDistanceTags', name: 'Min distance tags', value: { value: '10', unit: 'NM' }, isSelectable: false },
    { id: 'distanceTags', name: 'Distance tags', value: { value: 'Travelled', unit: '' }, isSelectable: true },
  ],
};

export const routeSettingsCtx = defineContext(() => {
  const state: State = persist(
    'defaultSettings.v1',
    reactive({
      visible: false,
      settings: defaultSettings,
      distanceBwMarkers: 10,
    }),
  );

  const toggleVisibility = () => (state.visible = !state.visible);
  const updateGeneralSettings = (change: UpdateReq) => {
    state.settings.generalSettings.every((param) => {
      if (param.id === change.id && param.value) {
        param.value.value = change.value;
        return false;
      }
      return true;
    });
  };
  const toggleEditDisplaySettings = (settingId: string) => {
    const setting = state.settings.editDisplaySettings.find((setting) => setting.id === settingId);
    if (setting) {
      setting.value = !setting.value;
    }
  };
  const updateTagSettings = (change: UpdateReq) => {
    state.settings.tagSettings.every((param) => {
      if (param.id === change.id && param.value) {
        if (param.id === 'routeDistanceTags') {
          const value = limit(1, 100, Math.round(parseFloat(change.value)));
          param.value.value = value.toString();
          state.distanceBwMarkers = value;
        } else {
          param.value.value = change.value;
        }
        return false;
      }
      return true;
    });
  };

  const getDisplaySettingsValue = (id: string) =>
    state.settings.editDisplaySettings.find((setting) => setting.id === id)?.value;

  const getGeneralSettingsValue = (id: string) =>
    state.settings.generalSettings.find((setting) => setting.id === id)?.value;

  const getTagSettingsValue = (id: string) => state.settings.tagSettings.find((setting) => setting.id === id)?.value;

  const getDistanceMarkerConfiguration = () => {
    return {
      distance: state.distanceBwMarkers * nmInMeter,
      reversed: getTagSettingsValue('distanceTags')?.value.toLowerCase() === 'remaining',
    };
  };

  const toggleMonitorDisplaySettings = (settingId: string) => {
    const setting = state.settings.monitorDisplaySettings?.find((setting) => setting.id === settingId);
    if (setting) {
      setting.value = !setting.value;
    }
  };

  const getMonitorSettingsValue = (id: string) =>
    state.settings.monitorDisplaySettings?.find((setting) => setting.id === id)?.value;

  const updateMarkerDistance = (distance: number) => (state.distanceBwMarkers = distance);

  return {
    state: readonly(state),
    toggleVisibility,
    updateGeneralSettings,
    toggleEditDisplaySettings,
    updateTagSettings,
    getDisplaySettingsValue,
    getGeneralSettingsValue,
    getTagSettingsValue,
    getDistanceMarkerConfiguration,
    updateMarkerDistance,
    toggleMonitorDisplaySettings,
    getMonitorSettingsValue,
  };
});
