<template>
  <pan-row class="row">
    <pan-static-text>Waypoint ({{ validationResults?.length }})</pan-static-text>
    <div class="controls">
      <pan-button class="btn" @click="setActiveIdx(0)">
        <icon-first-arrow />
      </pan-button>
      <pan-button class="btn" @click="setActiveIdx((activeIdx ?? 0) - 1)">
        <icon-left-arrow />
      </pan-button>
      <ecdis-editable-text
        :text="String((activeIdx ?? 0) + 1)"
        class="input"
        @changed="setActiveIdx(Number($event) - 1)"
      />
      <pan-button class="btn" @click="setActiveIdx((activeIdx ?? 0) + 1)">
        <icon-right-arrow />
      </pan-button>
      <pan-button class="btn" @click="setActiveIdx((validationResults?.length ?? 1) - 1)">
        <icon-last-arrow />
      </pan-button>
    </div>
  </pan-row>

  <pan-scrollable class="scrollable">
    <pan-row class="row head">
      <pan-static-text>Groundings &amp; hazards ({{ activeGroundings.length }})</pan-static-text>
    </pan-row>

    <pan-expandable-row
      v-for="activeGrounding in activeGroundings"
      :key="activeGrounding?.id"
      :initial-open="activeGrounding?.id === selectedFeatureId"
    >
      <template #row-content>
        <ecdis-value class="value message">{{ activeGrounding?.className }}</ecdis-value>
        <icon-pin
          class="pin"
          :class="{ active: activeGrounding?.id === highlightedFeatureId }"
          @click.stop="highlightFeature(activeGrounding?.id)"
        />
      </template>

      <template v-if="activeGrounding?.properties" #expanded-content>
        <div v-for="property in activeGrounding?.properties" :key="property.title" class="details">
          <pan-static-text>{{ property?.title }}</pan-static-text>
          <ecdis-value>{{ property?.value }}</ecdis-value>
        </div>
      </template>
    </pan-expandable-row>

    <pan-row class="row head">
      <pan-static-text>Warnings ({{ activeWarnings.length }})</pan-static-text>
    </pan-row>

    <pan-expandable-row
      v-for="activeWarning in activeWarnings"
      :key="activeWarning?.id"
      :initial-open="activeWarning?.id === selectedFeatureId"
    >
      <template #row-content>
        <ecdis-value class="value message">{{ activeWarning?.className }}</ecdis-value>
        <icon-pin
          class="pin"
          :class="{ active: activeWarning?.id === highlightedFeatureId }"
          @click.stop="highlightFeature(activeWarning?.id)"
        />
      </template>

      <template v-if="activeWarning?.properties" #expanded-content>
        <div v-for="properties in activeWarning?.properties" :key="properties.title" class="details">
          <pan-static-text>{{ properties.title }}</pan-static-text>
          <ecdis-value class="value">{{ properties.value }}</ecdis-value>
        </div>
      </template>
    </pan-expandable-row>
  </pan-scrollable>

  <pan-row class="footer">
    <pan-button class="cancel" @click="onCancel">Cancel</pan-button>
    <pan-button class="save" :disabled="validating || saving" @click="saveValidation">Save</pan-button>
  </pan-row>
</template>

<script lang="ts">
import { DeepReadonly, computed, defineComponent, ref, toRef } from 'vue';
import { useContext } from 'vue-context-composition';
import { messageCtx } from '../../contexts/messages';
import { routesCtx } from '../../contexts/routes';
import { routeValidationCtx } from '../../contexts/routeValidation';
import { Feature } from '../../generated/ChartServer';
import { RouteValidationStatus, ValidationStatusWithShipDetails } from '../../generated/RouteManagement';
import { propertySorter } from '../../utils/propertySorter';

type EnrichedFeature = (DeepReadonly<Feature> & { className: string }) | undefined;

export default defineComponent({
  setup() {
    const { state, setActiveIdx, cancelValidation, setVisibility, setHighlightedFeatureId } =
      useContext(routeValidationCtx);
    const { saveValidation: saveValidationStatus } = useContext(routesCtx);
    const { pushMessage } = useContext(messageCtx);

    const validationResults = toRef(state, 'validationResults');
    const activeIdx = toRef(state, 'activeValidationResultIdx');
    const validating = toRef(state, 'isValidating');
    const highlightedFeatureId = toRef(state, 'highlightedFeatureId');
    const selectedFeatureId = toRef(state, 'selectedFeatureId');

    const activeValidationResult = computed(() => validationResults.value?.[activeIdx.value ?? 0]);

    const makeUnique = (features: EnrichedFeature[]) => {
      const uniqueFeatures: Record<string, EnrichedFeature> = {};
      features.forEach((feature) => {
        uniqueFeatures[feature?.id ?? ''] = feature;
      });
      return Object.values(uniqueFeatures);
    };

    const propertyTitlesToHide = [
      'Alert group ID',
      'Alert priority',
      'Dataset valid coverage',
      'ID',
      'Feature warning',
      'Class code',
      'Scale minimum',
      'Exposition of sounding',
      'Feature reference',
    ];

    const enrichFeature = (grounding: DeepReadonly<Feature>) => ({
      id: grounding.id,
      properties: grounding.properties
        ?.filter((property) => !propertyTitlesToHide.includes(property?.title ?? ''))
        .sort(propertySorter),
      className: grounding.properties?.find((property) => property.title === 'Class')?.value ?? '-',
    });

    const activeGroundings = computed(() =>
      makeUnique(
        activeValidationResult.value?.waypointResults?.flatMap((waypointResult) =>
          waypointResult.data?.flatMap((result) => result.groundings?.map(enrichFeature)),
        ) ?? [],
      ),
    );

    const activeWarnings = computed(() =>
      makeUnique(
        activeValidationResult.value?.waypointResults?.flatMap((waypointResult) =>
          waypointResult.data?.flatMap((result) => result.warnings?.map(enrichFeature)),
        ) ?? [],
      ),
    );

    const saving = ref(false);

    const saveValidation = () => {
      saving.value = true;
      const hasGroundings = validationResults.value?.some((v) =>
        v.waypointResults?.some((w) => w.data?.some((d) => d.groundings?.length)),
      );
      const hasWarnings = validationResults.value?.some((v) =>
        v.waypointResults?.some((w) => w.data?.some((d) => d.warnings?.length)),
      );

      const validationStatus = hasGroundings
        ? hasWarnings
          ? RouteValidationStatus.EncValidatedWithGroundingsAndWarnings
          : RouteValidationStatus.EncValidatedWithGroundings
        : hasWarnings
        ? RouteValidationStatus.EncValidatedWithWarnings
        : RouteValidationStatus.EncValidatedOK;

      const validationStatusShipDetails: ValidationStatusWithShipDetails = {
        shipName: state.selectedShipName,
        shipCode: state.selectedShipCode,
      };
      saveValidationStatus(validationStatus, validationStatusShipDetails)
        .then(() => {
          pushMessage({ type: 'info', text: 'Validation saved successfully' });
        })
        .finally(() => (saving.value = false));
      cancelValidation();
      setVisibility(false);
    };

    const onCancel = () => {
      if (validating.value) {
        pushMessage({ type: 'info', text: 'Validation cancelled' });
      } else {
        pushMessage({ type: 'info', text: 'Validation was not saved' });
      }

      cancelValidation();
    };

    const highlightFeature = (featureId?: string | null) => {
      setHighlightedFeatureId(featureId ?? '');
    };

    return {
      validationResults,
      activeValidationResult,
      activeGroundings,
      activeWarnings,
      setActiveIdx,
      activeIdx,
      cancelValidation,
      saveValidation,
      saving,
      validating,
      onCancel,
      highlightedFeatureId,
      highlightFeature,
      selectedFeatureId,
    };
  },
});
</script>

<style scoped>
.row {
  background-color: var(--background_layer_below);
  padding: 16px 12px;
  justify-content: space-between;
}

.head {
  background-color: var(--background_tile);
}
.pin {
  cursor: pointer;
}
.pin.active {
  background: #000;
}
.pin.active ::v-deep(*) {
  fill: var(--button_text-selected);
  stroke: var(--button_text-selected);
}

.btn {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  padding: 0;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  padding-left: 12px;
  flex: 1;
}

.value {
  font-size: 14px;
  line-height: 21px;
}

.details {
  padding: 8px 12px;
}
.detail-row {
  width: 50%;
  vertical-align: baseline;
}

.input {
  width: 64px;
  background-color: var(--input_background-default);
  margin: 0 8px;
}

.footer {
  height: 64px;
  flex-shrink: 0;
  justify-content: center;
}
.cancel,
.save {
  width: 94px;
}
.save {
  margin-left: 8px;
  background: var(--feedback_indicator_background);
}
</style>
