import Konva from 'konva';
import { toRef, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { displayVectorCtx } from '../../contexts/displayVector';
import { ownshipSettingsCtx } from '../../contexts/ownshipSettings';
import { viewportCtx } from '../../contexts/viewport';
import { useOwnship } from '../../global-state/ownship';
import { ShapeMetadata } from '../../types';
import { degInRad } from '../../utils/conversions';
import { getScalingFactor } from '../../utils/mercator';
import { multiples } from '../../utils/tools';
import { vector } from '../../utils/vector';
import { addMinuteMark } from './addMinuteMark';
import { addVectorArrowHead } from './addVectorArrowHead';
import { intersectionWithViewport } from './tool/intersectionWithViewport';

export function addOwnship(transformLayer: Konva.Layer, absoluteLayer: Konva.Layer): void {
  const ownship = useOwnship();
  const { state, ownshipMeterPoint, ownshipLngLatInDeg } = ownship;
  const viewportRef = useContext(viewportCtx);
  const { viewport, currentRange, transformToAbsolute, transformToViewport, pxToMeter } = viewportRef;
  const { state: displayVectorState, isTrueVector, displayVectors } = useContext(displayVectorCtx);
  const { state: ownshipSettingsState } = useContext(ownshipSettingsCtx);
  const ownshipSettings = toRef(ownshipSettingsState, 'settings');

  const shipContourGroup = new Konva.Group();
  const courseGroup = new Konva.Group();
  const beamLineGroup = new Konva.Group();
  const headingLineGroup = new Konva.Group();
  const shipMarkerGroup = new Konva.Group();
  const minuteMarkGroup = new Konva.Group();
  const vectorArrowHeadGroup = new Konva.Group();
  transformLayer.add(shipContourGroup, courseGroup);
  absoluteLayer.add(shipMarkerGroup, beamLineGroup, headingLineGroup, vectorArrowHeadGroup, minuteMarkGroup);

  // add ownship
  const shipContour = new Konva.Line({
    stroke: 'black',
    strokeWidth: 5,
    strokeScaleEnabled: false,
    closed: true,
    data: <ShapeMetadata>{
      type: 'ownship',
    },
  });
  shipContourGroup.add(shipContour);

  const shipMarkerCircle = new Konva.Circle({
    radius: 12,
    stroke: 'black',
    strokeWidth: 3,
    data: <ShapeMetadata>{
      type: 'shipOutline',
    },
  });
  const shipMarkerInnerCircle = new Konva.Circle({
    radius: 6,
    stroke: 'black',
    strokeWidth: 3,
    data: <ShapeMetadata>{
      type: 'shipOutline',
    },
  });
  shipMarkerGroup.add(shipMarkerCircle, shipMarkerInnerCircle);
  const courseVector = new Konva.Line({
    stroke: 'black',
    strokeWidth: 3,
    dash: [10, 5],
    strokeScaleEnabled: false,
  });
  courseGroup.add(courseVector);

  const headingLine = new Konva.Line({
    stroke: 'black',
    strokeWidth: 1,
    strokeScaleEnabled: false,
  });
  headingLineGroup.add(headingLine);

  const beamLine = new Konva.Line({
    points: [-15, 0, 0, 0, 15, 0],
    stroke: 'black',
    strokeWidth: 1,
    strokeScaleEnabled: false,
  });
  beamLineGroup.add(beamLine);

  // update ownship position when context state changes
  watchEffect(() => {
    if (ownshipMeterPoint.value === undefined || ownshipLngLatInDeg.value === undefined) return;

    const { mX, mY } = ownshipMeterPoint.value;
    const { x: absX, y: absY } = transformToAbsolute(ownshipMeterPoint.value);
    const scale = getScalingFactor(ownshipLngLatInDeg.value.lat);

    shipContourGroup.scale({ x: scale, y: scale });
    shipContourGroup.position({
      x: mX,
      y: mY,
    });
    courseGroup.position({
      x: mX,
      y: mY,
    });
    shipMarkerGroup.position({
      x: absX,
      y: absY,
    });
    beamLineGroup.position({
      x: absX,
      y: absY,
    });
  });

  watchEffect(() => {
    if (state.ownship === undefined || ownshipLngLatInDeg.value === undefined) return;

    shipContourGroup.rotation(state.ownship.heading);
    shipContour.offset({
      x: state.ownship.ccrp.x,
      y: state.ownship.length / 2 - state.ownship.ccrp.y,
    });
    shipContour.points([...state.ownship.contour]);

    beamLineGroup.rotation(state.ownship.heading + viewport.rotation.angle);
    courseGroup.rotation(state.ownship.course);
    courseVector.points([
      0,
      0,
      0,
      -state.ownship.speedOverGround *
        (displayVectorState.vectorLengthInMinutes * 60) *
        getScalingFactor(ownshipLngLatInDeg.value.lat),
    ]);

    courseGroup.visible(isTrueVector() && displayVectors());
    beamLineGroup.visible(ownshipSettings.value.headingLine);
    headingLineGroup.visible(ownshipSettings.value.headingLine);

    const showContour = state.ownship.contour.length > 8 && currentRange.value < 1;
    shipContourGroup.visible(showContour && ownshipSettings.value.trueScaleOutline); // shipContour representation of ownship
    shipMarkerGroup.visible(!showContour || !ownshipSettings.value.trueScaleOutline); // Circular representation of ownship
  });

  watchEffect(() => {
    if (state.ownship === undefined || ownshipMeterPoint.value === undefined) return;

    // Check for intersection to draw a heading line intersecting the viewport.
    const absoluteShipPixelPoints = transformToAbsolute(ownshipMeterPoint.value);
    const angleInRad = state.ownship.heading * degInRad - Math.PI / 2;
    const topLeft = transformToViewport({ x: 0, y: 0 });
    const topRight = transformToViewport({ x: viewport.dimension.width, y: 0 });
    const bottomRight = transformToViewport({ x: viewport.dimension.width, y: viewport.dimension.height });
    const bottomLeft = transformToViewport({ x: 0, y: viewport.dimension.height });
    const intersections = intersectionWithViewport(
      ownshipMeterPoint.value,
      angleInRad,
      topLeft,
      topRight,
      bottomRight,
      bottomLeft,
    );
    const intersectionPoints = intersections.flatMap((intersection) => {
      const intersectionPixelPoints = transformToAbsolute(intersection);
      return [intersectionPixelPoints.x, intersectionPixelPoints.y] ?? [];
    });
    headingLine.points([absoluteShipPixelPoints.x, absoluteShipPixelPoints.y, ...intersectionPoints]);
  });

  watchEffect(() => {
    if (state.ownship === undefined || ownshipMeterPoint.value === undefined || ownshipLngLatInDeg.value === undefined)
      return;

    const { mX, mY } = ownshipMeterPoint.value;
    const selectedMinuteMarkInterval = ownshipSettings.value.minuteMarksInterval;
    const courseAngleInRad = state.ownship.course * degInRad - Math.PI / 2;

    // Minute marks
    const showMinuteMark = isTrueVector() && displayVectors() && ownshipSettings.value.showMinuteMarks;
    if (selectedMinuteMarkInterval !== undefined) {
      const minuteLengthList = multiples(selectedMinuteMarkInterval, displayVectorState.vectorLengthInMinutes);
      minuteMarkGroup.destroyChildren();
      for (const minuteLength of minuteLengthList) {
        const endVector = vector()
          .setLength(
            state.ownship.speedOverGround * (minuteLength * 60) * getScalingFactor(ownshipLngLatInDeg.value.lat),
          )
          .setAngle(courseAngleInRad)
          .add(vector(mX, mY));
        addMinuteMark(
          endVector,
          showMinuteMark,
          minuteMarkGroup,
          state.ownship.course + viewport.rotation.angle,
          viewportRef,
          false,
        );
      }
    }

    // Course Arrow Head
    vectorArrowHeadGroup.destroyChildren();
    if (state.ownship.speedOverGround) {
      const vectorArrowHeadList = [];
      const vectorArrowHead1 = vector()
        .setLength(
          state.ownship.speedOverGround *
            (displayVectorState.vectorLengthInMinutes * 60) *
            getScalingFactor(ownshipLngLatInDeg.value.lat),
        )
        .setAngle(courseAngleInRad)
        .add(vector(mX, mY));
      const showVectorArrowHead = isTrueVector() && displayVectors();
      const vectorArrowHead2 = vector().setLength(pxToMeter(5)).setAngle(courseAngleInRad).add(vectorArrowHead1);
      vectorArrowHeadList.push(vectorArrowHead1, vectorArrowHead2);
      for (const vectorArrowHead of vectorArrowHeadList)
        addVectorArrowHead(
          vectorArrowHead,
          showVectorArrowHead,
          vectorArrowHeadGroup,
          state.ownship.course + viewport.rotation.angle,
          viewportRef,
        );
    }
  });
}
