import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import { Vector2d } from 'konva/lib/types';
import { watch, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { LegacyEblVrm, eblVrmCtx } from '../../../contexts/eblVrm';
import { toolTipCtx } from '../../../contexts/toolTip';
import { viewportCtx } from '../../../contexts/viewport';
import { useOwnship } from '../../../global-state/ownship';
import { ShapeMetadata } from '../../../types';
import { degInRad, meterToLngLat, nmInMeter } from '../../../utils/conversions';
import { twoDecimalFormatter } from '../../../utils/formatters';
import { angle, distance } from '../../../utils/tools';
import { intersectionWithViewport } from './intersectionWithViewport';

const hitWidth = 20;

export function addEblVrms(transformLayer: Konva.Layer, absoluteLayer: Konva.Layer): void {
  const {
    state,
    updateCenter,
    updateVrmValue,
    updateEblValue,
    onEditStart,
    updatePositionToOwnShip,
    setPositionToGeo,
    updateEblVrmOnOwnShipChange,
    updateAlignHeadingOnOwnShipChange,
    updateAlignHeadingOnEblChange,
  } = useContext(eblVrmCtx);
  const { viewport, transformToAbsolute, transformToViewport } = useContext(viewportCtx);
  const tooltip = useContext(toolTipCtx);
  const { ownshipMeterPoint, state: ownshipState } = useOwnship();

  const colors = {
    ebl: 'rgb(255, 132, 60)',
    vrm: 'rgb(255, 132, 60)',
  };
  const mainGroupIdPrefix = 'main-group_';

  tooltip.hide();

  const eblVrmsGroup = new Konva.Group();
  transformLayer.add(eblVrmsGroup);

  const absoluteGroup = new Konva.Group();
  absoluteLayer.add(absoluteGroup);

  const stage = transformLayer.getStage();

  const addEblStageListeners = (eblVrm: LegacyEblVrm) => {
    onEditStart(eblVrm.id);
    stage.off('mousemove.ebl touchmove.ebl');
    stage.on('mousemove.ebl touchmove.ebl', () => {
      const position = stage.getPointerPosition();
      if (!position) return;

      const point = transformToViewport(position);
      const rotation = angle(eblVrm.center, point);
      const value = parseFloat(rotation.toFixed(1));
      updateEblValue(eblVrm.id, value, ownshipState.ownship?.heading ?? 0, false, true);
      updateToolTip(position, eblVrm);
      updateAlignHeadingOnEblChange(eblVrm.id, ownshipState.ownship?.heading ?? 0);
    });
    stage.on('mouseup touchend', () => {
      stage.off('mousemove.ebl touchmove.ebl');
      tooltip.hide();
    });
  };

  const addVrmStageListeners = (eblVrm: LegacyEblVrm) => {
    onEditStart(eblVrm.id);
    stage.off('mousemove.vrm touchmove.vrm');
    stage.on('mousemove.vrm touchmove.vrm', () => {
      const position = stage.getPointerPosition();
      if (!position) return;

      const point = transformToViewport(position);
      const lngLatPos = meterToLngLat(eblVrm.center);
      const radius = (distance(eblVrm.center, point) / nmInMeter) * Math.cos(lngLatPos.lat * degInRad);
      updateVrmValue(eblVrm.id, parseFloat(radius.toFixed(2)));
      updateToolTip(position, eblVrm);
    });
    stage.on('mouseup touchend', () => {
      stage.off('mousemove.vrm touchmove.vrm');
      tooltip.hide();
    });
  };

  //Tooltip to show ebl/vrm value while changing
  const updateToolTip = (position: Vector2d, eblVrm: LegacyEblVrm) => {
    tooltip.show(position, [
      {
        title: 'EBL',
        value: eblVrm.eblValue.toString(),
        unit: '°',
      },
      {
        title: 'VRM',
        value: twoDecimalFormatter(eblVrm.vrmValue),
        unit: 'NM',
      },
    ]);
  };

  const drawEblVrms = () => {
    const allExistingGroupIds: string[] = [];
    state.eblVrms.forEach((eblVrm) => {
      const mainId = `${mainGroupIdPrefix}${eblVrm.id}`;
      allExistingGroupIds.push(mainId);
      const existingGroup = eblVrmsGroup.findOne('#' + mainId);
      if (existingGroup !== undefined) {
        return;
      }

      const eblVrmGroup = new Konva.Group({
        id: mainId,
      });
      eblVrmsGroup.add(eblVrmGroup);

      const eblVrmAbsoluteGroup = new Konva.Group({
        id: mainId,
      });
      absoluteGroup.add(eblVrmAbsoluteGroup);

      // update transform group
      const eblLine = new Konva.Line({
        // line will be exactly drawn in viewport, so no offset needed
        points: [],
        id: eblVrm.id,
        stroke: colors.vrm,
        strokeWidth: 3,
        dash: [5, 3],
        strokeScaleEnabled: false,
        hitStrokeWidth: hitWidth,
      });
      eblLine.on('mouseenter', function (ev) {
        stage.container().style.cursor = 'crosshair';
        ev.cancelBubble = true;
      });
      eblLine.on('mousedown touchstart', (ev) => {
        ev.cancelBubble = true;
        addEblStageListeners(eblVrm);
      });
      eblVrmGroup.add(eblLine);

      watchEffect(() => {
        // only draw the intersection of the EBL and the viewport to improve performance
        const eblAngleInRad = eblVrm.eblRelativeValue * degInRad - Math.PI / 2;
        const topLeft = transformToViewport({ x: 0, y: 0 });
        const topRight = transformToViewport({ x: viewport.dimension.width, y: 0 });
        const bottomRight = transformToViewport({ x: viewport.dimension.width, y: viewport.dimension.height });
        const bottomLeft = transformToViewport({ x: 0, y: viewport.dimension.height });
        const intersections = intersectionWithViewport(
          eblVrm.center,
          eblAngleInRad,
          topLeft,
          topRight,
          bottomRight,
          bottomLeft,
        );
        const points = intersections.flatMap((intersection) => [intersection.mX, intersection.mY]) ?? [];
        eblLine.points(points);
      });

      watchEffect(() => {
        eblLine.visible(eblVrm.eblVisible);
      });

      const vrmCircle = new Konva.Circle({
        x: eblVrm.center.mX,
        y: eblVrm.center.mY,
        id: eblVrm.id,
        radius: 0,
        draggable: false,
        fillEnabled: false,
        stroke: colors.ebl,
        strokeWidth: 3,
        strokeScaleEnabled: false,
        dash: [5, 3],
        hitStrokeWidth: hitWidth,
      });
      vrmCircle.on('mouseenter', function (ev) {
        stage.container().style.cursor = 'crosshair';
        ev.cancelBubble = true;
      });
      vrmCircle.on('mousedown touchstart', (ev) => {
        ev.cancelBubble = true;
        const position = stage.getPointerPosition();
        if (eblLine.intersects(position)) {
          addEblStageListeners(eblVrm);
        }
        addVrmStageListeners(eblVrm);
      });
      eblVrmGroup.add(vrmCircle);

      // watch for changes, shapes are not redrawn
      watchEffect(() => {
        const lngLatPos = meterToLngLat(eblVrm.center);
        const radius = (eblVrm.vrmValue * nmInMeter) / Math.cos(lngLatPos.lat * degInRad);
        vrmCircle.radius(radius);
      });

      watchEffect(() => {
        vrmCircle.visible(eblVrm.vrmVisible);
      });

      watchEffect(() => {
        const position = { x: eblVrm.center.mX, y: eblVrm.center.mY };
        vrmCircle.position(position);
      });

      // update absolute group
      const eblText = new Konva.Text({
        text: eblVrm.index,
        width: 50,
        height: 20,
        offsetX: 25,
        offsetY: -10,
        fontFamily: 'Lato',
        fill: colors.ebl,
        align: 'center',
        fontSize: 18,
      });
      eblVrmAbsoluteGroup.add(eblText);

      const vrmCircleDot = new Konva.Circle({
        id: eblVrm.id,
        radius: 3,
        fill: colors.ebl,
        hitFunc: function (context) {
          context.beginPath();
          context.arc(0, 0, hitWidth, 0, Math.PI * 2, true);
          context.closePath();
          context.fillStrokeShape(vrmCircleDot);
        },
        draggable: true,
        data: <ShapeMetadata>{ type: 'eblVrm', uuid: eblVrm.id },
      });
      eblVrmAbsoluteGroup.add(vrmCircleDot);

      let timeout = 0;

      vrmCircleDot.on('mouseenter', function (ev) {
        stage.container().style.cursor = 'crosshair';
        ev.cancelBubble = true;
        const position = stage.getPointerPosition();
        if (!position) return;
        timeout = window.setTimeout(updateToolTip, 300, position, eblVrm);
      });
      vrmCircleDot.on('mouseout', () => {
        window.clearTimeout(timeout);
        tooltip.hide();
      });

      vrmCircleDot.on('mousedown touchstart', (ev) => {
        ev.cancelBubble = true;
        onEditStart(eblVrm.id);
        setPositionToGeo(eblVrm.id);
      });
      vrmCircleDot.on('dragmove', () => {
        tooltip.hide();
        const pos = vrmCircleDot.getAbsolutePosition();
        updateCenter(eblVrm.id, transformToViewport(pos));
      });
      vrmCircleDot.on('dragend', (ev) => {
        const shapes = stage.getAllIntersections(ev.currentTarget.position());
        const lockToOwnship = shapes.some((item: Shape) => {
          const data = <ShapeMetadata>item.getAttr('data');
          if (data?.type === 'shipOutline' || data?.type === 'ownship') {
            return true;
          }
        });
        if (lockToOwnship && ownshipMeterPoint.value !== undefined) {
          updatePositionToOwnShip(eblVrm.id, ownshipMeterPoint.value);
        } else {
          setPositionToGeo(eblVrm.id);
        }
      });
      // watch for center & viewport changes
      watch(
        [() => eblVrm.center, () => viewport.matrix],
        () => {
          const position = transformToAbsolute(eblVrm.center);
          eblText.position(position);
          vrmCircleDot.position(position);
        },
        { deep: true, immediate: true },
      );

      watchEffect(() => {
        const visible = eblVrm.eblVisible || eblVrm.vrmVisible;
        eblText.visible(visible);
        vrmCircleDot.visible(visible);
      });
    });

    eblVrmsGroup.getChildren().forEach((node) => {
      if (!allExistingGroupIds.includes(node.id())) {
        node.destroy();
      }
    });
    absoluteGroup.getChildren().forEach((node) => {
      if (!allExistingGroupIds.includes(node.id())) {
        node.destroy();
      }
    });
  };

  watch([() => state.eblVrms], drawEblVrms, { deep: true, immediate: true });

  watch(
    ownshipMeterPoint,
    (ownshipMeterPoint) => {
      if (ownshipMeterPoint !== undefined) {
        updateEblVrmOnOwnShipChange(ownshipMeterPoint);
      }
    },
    { deep: true, immediate: true },
  );

  watch(
    () => ownshipState.ownship?.heading,
    (ownshipHeading) => {
      if (ownshipHeading !== undefined) {
        updateAlignHeadingOnOwnShipChange(ownshipHeading);
      }
    },
    { immediate: true },
  );
}
