<template>
  <div class="vue-simple-spinner" :style="spinnerStyle"></div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 32,
    },
    lineSize: {
      type: Number,
      default: 4,
    },
    lineBgColor: {
      type: String,
      default: 'black',
    },
    lineFgColor: {
      type: String,
      default: '#4A90E2',
    },
    speed: {
      type: Number,
      default: 1.5,
    },
  },
  setup(props) {
    const spinnerStyle = computed(() => {
      return {
        'border-radius': '100%',
        border: props.lineSize + 'px solid ' + props.lineBgColor,
        'border-top': props.lineSize + 'px solid ' + props.lineFgColor,
        width: props.size + 'px',
        height: props.size + 'px',
        animation: 'vue-simple-spinner-spin ' + props.speed + 's linear infinite',
      };
    });
    return {
      spinnerStyle,
    };
  },
});
</script>

<style>
.vue-simple-spinner {
  transition: all 0.3s linear;
}
@keyframes vue-simple-spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
