<template>
  <div class="displaySetting">
    <pan-row class="col-layout display-header">
      <pan-static-text class="name-header" :class="{ 'name-ecdis-header': isExtension }">Name</pan-static-text>
      <pan-static-text>Edited</pan-static-text>
      <pan-static-text v-if="isExtension">Monitored</pan-static-text>
    </pan-row>

    <div v-for="(setting, index) in settings.editDisplaySettings" :key="index" class="col-layout">
      <pan-static-text class="setting-name" :class="{ 'name-ecdis-width': isExtension }">
        {{ setting.name }}
      </pan-static-text>
      <signi-panel-checkbox :model-value="setting.value" @update:model-value="toggleEditDisplaySettings(setting.id)" />
      <template v-if="isExtension && settings.monitorDisplaySettings">
        <signi-panel-checkbox
          v-if="settings.monitorDisplaySettings[index]"
          :model-value="
            settings.monitorDisplaySettings[index].name === setting.name && settings.monitorDisplaySettings[index].value
          "
          @update:model-value="toggleMonitorDisplaySettings(setting.id)"
        />
        <signi-panel-checkbox v-else class="opacity-0" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../configuration';
import { routeSettingsCtx } from '../../contexts/routeSettings';

export default defineComponent({
  setup() {
    const { state, toggleEditDisplaySettings, toggleMonitorDisplaySettings } = useContext(routeSettingsCtx);

    const { settings } = toRefs(state);

    return {
      settings,
      toggleEditDisplaySettings,
      toggleMonitorDisplaySettings,
      isExtension,
    };
  },
});
</script>

<style scoped>
.displaySetting {
  border-bottom: 1px solid #000000;
}

.col-layout {
  display: flex;
  justify-content: space-between;
  padding: 4px 13px;
}

.display-header {
  background-color: var(--background_base_main);
}
.setting-name {
  display: flex;
  flex-direction: column;
  min-width: 96px;
}
.name-ecdis-width {
  min-width: 127px;
}

.name-header {
  min-width: 128px;
}
.name-ecdis-header {
  min-width: 158px;
}
</style>
