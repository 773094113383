import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import { Vector2d } from 'konva/lib/types';
import { watch, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { ILOP } from '@signi/common';
import { toolTipCtx } from '../../../contexts/toolTip';
import { viewportCtx } from '../../../contexts/viewport';
import { useOwnship } from '../../../global-state/ownship';
import { ShapeMetadata } from '../../../types';
import { degInRad, meterToLngLat, nmInMeter } from '../../../utils/conversions';
import { twoDecimalFormatter } from '../../../utils/formatters';
import {
  createPositionFixDot,
  createPositionFixHorizontalLine,
  createPositionFixOuterCircle,
  createPositionFixText,
  createPositionFixTypeText,
  createPositionFixVerticalLine,
  getFixTime,
  positionFixAngle,
  positionFixColors,
} from '../../../utils/positionFixUtils';
import { angle, distance } from '../../../utils/tools';
import { useToolsStore } from '../../apps-panel/tools-panel/useToolsStore';
import { intersectionWithViewport } from './intersectionWithViewport';

const hitWidth = 20;

const formatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h23',
};

export function addBearingRange(transformLayer: Konva.Layer, absoluteLayer: Konva.Layer): void {
  const {
    onEditStart,
    positionFixMarkers,
    setPositionToGeo,
    updateCenter,
    updatePositionToOwnShip,
    updateBearingRangeOnOwnShipChange,
    updateAlignHeadingOnOwnShipChange,
    updateBearingValue,
    updateRangeValue,
    updateAlignHeadingOnBearingChange,
  } = useToolsStore()!;

  const { viewport, transformToAbsolute, transformToViewport } = useContext(viewportCtx);
  const tooltip = useContext(toolTipCtx);
  const { ownshipMeterPoint, state: ownshipState } = useOwnship();

  const mainBearingGroupIdPrefix = 'main-bearing-group_';
  const mainRangeGroupIdPrefix = 'main-range-group_';
  const mainPositionFixGroupIdPrefix = 'main-position-fix-group_';

  tooltip.hide();

  const transformGroup = new Konva.Group();
  transformLayer.add(transformGroup);

  const absoluteGroup = new Konva.Group();
  absoluteLayer.add(absoluteGroup);

  const stage = transformLayer.getStage();

  const addBearingStageListeners = (lop: ILOP, groupId: string) => {
    onEditStart(lop.id, groupId);
    stage.off('mousemove.bearing touchmove.bearing');
    stage.on('mousemove.bearing touchmove.bearing', () => {
      const position = stage.getPointerPosition();
      if (!position) return;

      const point = transformToViewport(position);
      const rotation = angle(lop.center, point);
      const value = parseFloat(rotation.toFixed(1));
      const heading = ownshipState.ownship?.heading ?? 0;
      if (lop.bearingType == 'RELATIVE') {
        lop.bearingRelativeValue = value;
        const relativeToHead = value - heading;
        const relativeVal = relativeToHead > 0 ? relativeToHead : 360 + relativeToHead;
        lop.bearing = parseFloat(relativeVal.toFixed(1));
      } else {
        lop.bearing = lop.bearingRelativeValue = value;
      }
      lop.isAlignToHeading =
        lop.bearingType !== 'RELATIVE' ? lop.bearing === heading : lop.bearingRelativeValue == heading;

      updateBearingValue(groupId, lop.id, value, heading ?? 0, false, true);
      updateToolTip(position, lop);
      updateAlignHeadingOnBearingChange(lop.id, heading ?? 0);
    });
    stage.on('mouseup touchend', () => {
      stage.off('mousemove.bearing touchmove.bearing');
      tooltip.hide();
    });
  };

  const addRangeStageListeners = (lop: ILOP, groupId: string) => {
    onEditStart(lop.id, groupId);
    stage.off('mousemove.range touchmove.range');
    stage.on('mousemove.range touchmove.range', () => {
      const position = stage.getPointerPosition();
      if (!position) return;

      const point = transformToViewport(position);
      const lngLatPos = meterToLngLat(lop.center);
      const radius = (distance(lop.center, point) / nmInMeter) * Math.cos(lngLatPos.lat * degInRad);
      const rangeValue = parseFloat(radius.toFixed(2));
      lop.range = rangeValue;
      updateRangeValue(groupId, lop.id, rangeValue);
      updateToolTip(position, lop);
    });
    stage.on('mouseup touchend', () => {
      stage.off('mousemove.range touchmove.range');
      tooltip.hide();
    });
  };

  const updateToolTip = (position: Vector2d, lop: ILOP) => {
    if (lop.type == 'Bearing') {
      tooltip.show(position, [
        {
          title: 'Bearing',
          value: positionFixAngle(lop.bearing ?? 0).toFixed(1) || '',
          unit: '°',
        },
      ]);
    } else {
      tooltip.show(position, [
        {
          title: 'Range',
          value: twoDecimalFormatter(lop.range),
          unit: 'NM',
        },
      ]);
    }
  };

  const drawPositionFix = (allExistingPositionFixGroupIds: string[]) => {
    positionFixMarkers.value.forEach((markersGroup) => {
      if (markersGroup.positionFix) {
        const mainId = `${mainPositionFixGroupIdPrefix}${markersGroup.id}`;
        allExistingPositionFixGroupIds.push(mainId);

        const existingGroup = absoluteGroup.findOne('#' + mainId);
        if (existingGroup !== undefined) {
          existingGroup.destroy();
        }

        const positionFixTransformGroup = new Konva.Group({
          id: mainId,
        });
        transformGroup.add(positionFixTransformGroup);

        const positionFixAbsoluteGroup = new Konva.Group({
          id: mainId,
        });
        absoluteGroup.add(positionFixAbsoluteGroup);

        const fixX = markersGroup ? markersGroup.positionFix.mX : 0;
        const fixY = markersGroup ? markersGroup.positionFix.mY : 0;
        const pixelpts = transformToAbsolute({ mX: fixX, mY: fixY });

        const positionFixOuterCircle = createPositionFixOuterCircle(markersGroup);
        const positionFixDot = createPositionFixDot(markersGroup);
        const positionFixText = createPositionFixText();
        markersGroup.positionFixTimestamp = getFixTime(formatOptions);
        const positionFixTypeText = createPositionFixTypeText(markersGroup.selectedFixType);
        const positionFixHorizontalLine = createPositionFixHorizontalLine(pixelpts, markersGroup.id);
        const positionFixVerticalLine = createPositionFixVerticalLine(pixelpts, markersGroup.id);

        positionFixAbsoluteGroup.add(positionFixHorizontalLine);
        positionFixAbsoluteGroup.add(positionFixVerticalLine);
        positionFixAbsoluteGroup.add(positionFixOuterCircle);
        positionFixAbsoluteGroup.add(positionFixDot);
        positionFixAbsoluteGroup.add(positionFixText);
        positionFixAbsoluteGroup.add(positionFixTypeText);

        watch(
          [() => markersGroup.positionFix, () => viewport.matrix],
          () => {
            const position = transformToAbsolute({
              mX: markersGroup.positionFix?.mX ? markersGroup.positionFix?.mX : 0,
              mY: markersGroup.positionFix?.mY ? markersGroup.positionFix?.mY : 0,
            });
            positionFixOuterCircle.position(position);
            positionFixDot.position(position);
            positionFixText.position(position);
            positionFixTypeText.position(position);
            positionFixHorizontalLine.points([position.x - 12, position.y, position.x + 12, position.y]);
            positionFixVerticalLine.points([position.x, position.y - 12, position.x, position.y + 12]);
          },
          { deep: true, immediate: true },
        );

        watchEffect(() => {
          const visibility = markersGroup.visible;
          positionFixOuterCircle.visible(visibility);
          positionFixDot.visible(visibility);
          positionFixText.visible(visibility);
          positionFixTypeText.visible(visibility);
          positionFixHorizontalLine.visible(visibility);
          positionFixVerticalLine.visible(visibility);
        });
      }
    });
  };

  const drawBearings = (allExistingBearingGroupIds: string[]) => {
    positionFixMarkers.value.forEach((markersGroup) => {
      markersGroup.LOPs.forEach((lop, lopIndex) => {
        if (lop.type == 'Bearing') {
          const mainId = `${mainBearingGroupIdPrefix}${markersGroup.id}${lop.id}`;
          allExistingBearingGroupIds.push(mainId);

          const existingGroup = transformGroup.findOne('#' + mainId);
          if (existingGroup !== undefined) {
            existingGroup.destroy();
          }

          const bearingTransformGroup = new Konva.Group({
            id: mainId,
          });
          transformGroup.add(bearingTransformGroup);

          const bearingAbsoluteGroup = new Konva.Group({
            id: mainId,
          });
          absoluteGroup.add(bearingAbsoluteGroup);

          // update transform group
          const bearingLine = new Konva.Line({
            // line will be exactly drawn in viewport, so no offset needed
            points: [],
            id: lop.id,
            stroke: positionFixColors.bearing,
            strokeWidth: 3,
            dash: [5, 3],
            strokeScaleEnabled: false,
            hitStrokeWidth: hitWidth,
          });
          bearingLine.on('mouseenter', function (ev) {
            stage.container().style.cursor = 'crosshair';
            ev.cancelBubble = true;
          });
          bearingLine.on('mousedown touchstart', (ev) => {
            ev.cancelBubble = true;
            addBearingStageListeners(lop, markersGroup.id);
          });
          bearingLine.on('dragmove', () => {
            tooltip.hide();
            const pos = bearingLine.getAbsolutePosition();
            updateCenter(lop.id, markersGroup.id, transformToViewport(pos));
          });
          bearingTransformGroup.add(bearingLine);

          watchEffect(() => {
            // only draw the intersection of the bearing and the viewport to improve performance
            const bearingAngleInRad = lop.bearingRelativeValue * degInRad - Math.PI / 2;
            const topLeft = transformToViewport({ x: 0, y: 0 });
            const topRight = transformToViewport({ x: viewport.dimension.width, y: 0 });
            const bottomRight = transformToViewport({ x: viewport.dimension.width, y: viewport.dimension.height });
            const bottomLeft = transformToViewport({ x: 0, y: viewport.dimension.height });
            const intersections = intersectionWithViewport(
              lop.center,
              bearingAngleInRad,
              topLeft,
              topRight,
              bottomRight,
              bottomLeft,
            );
            const points = intersections.flatMap((intersection) => [intersection.mX, intersection.mY]) ?? [];
            bearingLine.points(points);
          });

          // update absolute group
          const bearingText = new Konva.Text({
            text: (lopIndex + 1).toString(),
            width: 50,
            height: 20,
            offsetX: 25,
            offsetY: -10,
            fontFamily: 'Lato',
            fill: positionFixColors.bearing,
            align: 'center',
            fontSize: 18,
          });
          bearingAbsoluteGroup.add(bearingText);

          const bearingDot = new Konva.Circle({
            id: lop.id,
            radius: 3,
            fill: positionFixColors.bearing,
            hitFunc: function (context) {
              context.beginPath();
              context.arc(0, 0, hitWidth, 0, Math.PI * 2, true);
              context.closePath();
              context.fillStrokeShape(bearingDot);
            },
            draggable: true,
            data: <ShapeMetadata>{ type: 'bearing', uuid: lop.id },
          });
          bearingAbsoluteGroup.add(bearingDot);

          let timeout = 0;

          bearingDot.on('mouseenter', function (ev) {
            stage.container().style.cursor = 'crosshair';
            ev.cancelBubble = true;
            const position = stage.getPointerPosition();
            if (!position) return;
            timeout = window.setTimeout(updateToolTip, 300, position, lop);
          });
          bearingDot.on('mouseout', () => {
            window.clearTimeout(timeout);
            tooltip.hide();
          });

          bearingDot.on('mousedown touchstart', (ev) => {
            ev.cancelBubble = true;
            onEditStart(lop.id, markersGroup.id);
            setPositionToGeo(lop.id, markersGroup.id);
          });
          bearingDot.on('dragmove', () => {
            tooltip.hide();
            const pos = bearingDot.getAbsolutePosition();
            updateCenter(lop.id, markersGroup.id, transformToViewport(pos));
          });
          bearingDot.on('dragend', (ev) => {
            const shapes = stage.getAllIntersections(ev.currentTarget.position());
            const lockToOwnship = shapes.some((item: Shape) => {
              const data = <ShapeMetadata>item.getAttr('data');
              if (data?.type === 'shipOutline' || data?.type === 'ownship') {
                return true;
              }
            });
            if (lockToOwnship && ownshipMeterPoint.value !== undefined) {
              updatePositionToOwnShip(lop.id, markersGroup.id, ownshipMeterPoint.value);
            } else {
              setPositionToGeo(lop.id, markersGroup.id);
            }
          });

          // watch for center & viewport changes
          watch(
            [() => lop.center, () => viewport.matrix],
            () => {
              const position = transformToAbsolute(lop.center);
              bearingText.position(position);
              bearingDot.position(position);
            },
            { deep: true, immediate: true },
          );

          watchEffect(() => {
            const visibility =
              markersGroup.visible &&
              (markersGroup.positionFix === undefined || markersGroup.isEditMode || markersGroup.showLOPs);
            bearingText.visible(visibility);
            bearingLine.visible(visibility);
            bearingDot.visible(visibility);

            const opacity = markersGroup.positionFix != undefined ? 0.4 : 1;
            bearingText.opacity(opacity);
            bearingLine.opacity(opacity);
            bearingDot.opacity(opacity);
          });
        }
      });
    });
  };

  const drawRanges = (allExistingRangeGroupIds: string[]) => {
    positionFixMarkers.value.forEach((markersGroup) => {
      markersGroup.LOPs.forEach((lop, lopIndex) => {
        if (lop.type == 'Range') {
          const mainId = `${mainRangeGroupIdPrefix}${markersGroup.id}${lop.id}`;
          allExistingRangeGroupIds.push(mainId);

          const existingGroup = transformGroup.findOne('#' + mainId);
          if (existingGroup !== undefined) {
            existingGroup.destroy();
          }

          const rangeTransformGroup = new Konva.Group({
            id: mainId,
          });
          transformGroup.add(rangeTransformGroup);

          const rangeAbsoluteGroup = new Konva.Group({
            id: mainId,
          });
          absoluteGroup.add(rangeAbsoluteGroup);

          const rangeCircle = new Konva.Circle({
            x: lop.center.mX,
            y: lop.center.mY,
            id: lop.id,
            radius: 0,
            draggable: false,
            fillEnabled: false,
            stroke: positionFixColors.range,
            strokeWidth: 3,
            strokeScaleEnabled: false,
            dash: [5, 3],
            hitStrokeWidth: hitWidth,
          });

          rangeCircle.on('mouseenter', function (ev) {
            stage.container().style.cursor = 'crosshair';
            ev.cancelBubble = true;
          });
          rangeCircle.on('mousedown touchstart', (ev) => {
            ev.cancelBubble = true;
            addRangeStageListeners(lop, markersGroup.id);
          });
          rangeTransformGroup.add(rangeCircle);

          // watch for changes, shapes are not redrawn
          watchEffect(() => {
            const lngLatPos = meterToLngLat(lop.center);
            const radius = ((lop.range ? lop.range : 0) * nmInMeter) / Math.cos(lngLatPos.lat * degInRad);
            rangeCircle.radius(radius);
          });

          watchEffect(() => {
            const position = { x: lop.center.mX, y: lop.center.mY };
            rangeCircle.position(position);
          });

          const rangeText = new Konva.Text({
            text: (lopIndex + 1).toString(),
            width: 50,
            height: 20,
            offsetX: 25,
            offsetY: -10,
            fontFamily: 'Lato',
            fill: positionFixColors.range,
            align: 'center',
            fontSize: 18,
          });
          rangeAbsoluteGroup.add(rangeText);

          const rangeCircleDot = new Konva.Circle({
            id: lop.id,
            radius: 3,
            fill: positionFixColors.range,
            hitFunc: function (context) {
              context.beginPath();
              context.arc(0, 0, hitWidth, 0, Math.PI * 2, true);
              context.closePath();
              context.fillStrokeShape(rangeCircleDot);
            },
            draggable: true,
            data: <ShapeMetadata>{ type: 'range', uuid: lop.id },
          });
          rangeAbsoluteGroup.add(rangeCircleDot);

          let timeout = 0;

          rangeCircleDot.on('mouseenter', function (ev) {
            stage.container().style.cursor = 'crosshair';
            ev.cancelBubble = true;
            const position = stage.getPointerPosition();
            if (!position) return;
            timeout = window.setTimeout(updateToolTip, 300, position, lop);
          });
          rangeCircleDot.on('mouseout', () => {
            window.clearTimeout(timeout);
            tooltip.hide();
          });

          rangeCircleDot.on('mousedown touchstart', (ev) => {
            ev.cancelBubble = true;
            onEditStart(lop.id, markersGroup.id);
            setPositionToGeo(lop.id, markersGroup.id);
          });
          rangeCircleDot.on('dragmove', () => {
            tooltip.hide();
            const pos = rangeCircleDot.getAbsolutePosition();
            updateCenter(lop.id, markersGroup.id, transformToViewport(pos));
          });
          rangeCircleDot.on('dragend', (ev) => {
            const shapes = stage.getAllIntersections(ev.currentTarget.position());
            const lockToOwnship = shapes.some((item: Shape) => {
              const data = <ShapeMetadata>item.getAttr('data');
              if (data?.type === 'shipOutline' || data?.type === 'ownship') {
                return true;
              }
            });
            if (lockToOwnship && ownshipMeterPoint.value !== undefined) {
              updatePositionToOwnShip(lop.id, markersGroup.id, ownshipMeterPoint.value);
            } else {
              setPositionToGeo(lop.id, markersGroup.id);
            }
          });
          // watch for center & viewport changes
          watch(
            [() => lop.center, () => viewport.matrix],
            () => {
              const position = transformToAbsolute(lop.center);
              rangeCircleDot.position(position);
              rangeText.position(position);
            },
            { deep: true, immediate: true },
          );

          watchEffect(() => {
            const visibility =
              markersGroup.visible &&
              (markersGroup.positionFix === undefined || markersGroup.isEditMode || markersGroup.showLOPs);
            rangeCircleDot.visible(visibility);
            rangeText.visible(visibility);
            rangeCircle.visible(visibility);

            const opacity = markersGroup.positionFix != undefined ? 0.4 : 1;
            rangeCircleDot.opacity(opacity);
            rangeText.opacity(opacity);
            rangeCircle.opacity(opacity);
          });
        }
      });
    });
  };

  const updateChart = () => {
    const allExistingGroupIds: string[] = [];
    drawBearings(allExistingGroupIds);
    drawRanges(allExistingGroupIds);
    drawPositionFix(allExistingGroupIds);

    transformGroup.getChildren().forEach((node) => {
      if (!allExistingGroupIds.includes(node.id())) {
        node.destroy();
      }
    });
    absoluteGroup.getChildren().forEach((node) => {
      if (!allExistingGroupIds.includes(node.id())) {
        node.destroy();
      }
    });
  };

  watch(() => positionFixMarkers, updateChart, { deep: true, immediate: true });

  watch(
    ownshipMeterPoint,
    (ownshipMeterPoint) => {
      if (ownshipMeterPoint !== undefined) {
        updateBearingRangeOnOwnShipChange(ownshipMeterPoint);
      }
    },
    { deep: true, immediate: true },
  );

  watch(
    () => ownshipState.ownship?.heading,
    (ownshipHeading) => {
      if (ownshipHeading !== undefined) {
        updateAlignHeadingOnOwnShipChange(ownshipHeading);
      }
    },
    { immediate: true },
  );
}
