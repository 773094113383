<template>
  <signi-panel-header title="Safety depth" />

  <div
    class="grid auto-rows-auto gap-y-px rounded border-b border-background_tile_border bg-background_tile_border text-sm text-text_main"
  >
    <signi-panel-setting primary="Depth shades">
      <div class="flex-1">
        <signi-select
          cell
          class="-my-3 -mx-2"
          :options="[{ id: 'Two' }, { id: 'Four' }]"
          :model-value="settings.depthShades.value ? 'Four' : 'Two'"
          @update:model-value="updateSettingValue('depthShades', $event === 'Four')"
        />
      </div>
    </signi-panel-setting>

    <signi-panel-setting primary="Safety depth" :disabled="false">
      <signi-kpi-input
        class="input flex-1"
        :formatted-value="settings.safetyDepth.value.toString()"
        :min="0"
        :model-value="settings.safetyDepth.value"
        size="xxxs"
        unit="m"
        @update:model-value="updateSettingValue('safetyDepth', $event)"
      />
    </signi-panel-setting>

    <signi-panel-setting primary="Safety contour" :disabled="false">
      <signi-kpi-input
        class="input flex-1"
        :formatted-value="settings.safetyContour.value.toString()"
        :min="0"
        :model-value="settings.safetyContour.value"
        size="xxxs"
        unit="m"
        @update:model-value="updateSafetyContour($event)"
      />
    </signi-panel-setting>

    <signi-panel-setting primary="Shallow contour" :disabled="false">
      <signi-kpi-input
        v-if="settings.depthShades.value"
        class="input flex-1"
        :formatted-value="settings.shallowContour.value.toString()"
        :model-value="settings.shallowContour.value"
        :max="settings.safetyContour.value"
        :min="0"
        size="xxxs"
        unit="m"
        @update:model-value="updateSettingValue('shallowContour', $event)"
      />
      <div v-else class="flex-1 text-button_text-disabled">{{ settings.shallowContour.value }} m</div>
    </signi-panel-setting>

    <signi-panel-setting primary="Deep contour" :disabled="false">
      <signi-kpi-input
        v-if="settings.depthShades.value"
        class="input flex-1"
        :formatted-value="settings.deepContour.value.toString()"
        :min="settings.safetyContour.value"
        :model-value="settings.deepContour.value"
        size="xxxs"
        unit="m"
        @update:model-value="updateSettingValue('deepContour', $event)"
      />
      <div v-else class="flex-1 text-button_text-disabled">{{ settings.deepContour.value }} m</div>
    </signi-panel-setting>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from 'vue';
import { useContext } from 'vue-context-composition';
import { safetySettingCtx } from '../../contexts/safetySetting';

const { state, updateSettingValue } = useContext(safetySettingCtx);
const settings = toRef(state, 'settings');

const updateSafetyContour = (value: number) => {
  updateSettingValue('safetyContour', value);

  if (value > settings.value.deepContour.value) {
    updateSettingValue('deepContour', value);
  }
  if (value < settings.value.shallowContour.value) {
    updateSettingValue('shallowContour', value);
  }
};
</script>

<style scoped>
.input {
  @apply -mx-2 -my-3 block px-2 py-3;
}
</style>
