<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M9.14105 18.1262L20.9733 11.5737L14.0908 23.076L11.6159 20.6011L9.14105 18.1262Z"
      stroke="#A2AAB9"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <circle cx="16" cy="16.5" r="9.5" transform="rotate(45 16 16.5)" stroke="#A2AAB9" />
  </svg>
</template>
<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
