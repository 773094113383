<template>
  <ul v-if="visible" id="context-menu" ref="contextMenuRef" :style="setOrientation" @contextmenu.prevent>
    <ecdis-context-menu-list
      v-for="item in availableContextMenuItems"
      :key="item.name"
      :is-separator-required="item.separatorRequired"
      :class="showSubMenu && item?.subList && item?.subList?.length > 0 ? 'active' : ''"
      :disabled="item.iconType === 'MarinersNotesIcon' ? notesLoading : false"
      @click="optionClick(item)"
    >
      <signi-icon v-if="item.iconType" :name="item.iconType" class="mr-1 h-5" />
      <p class="ml-1 grow">
        {{ item?.name }}
      </p>
      <signi-icon v-if="item.iconType === 'MarinersNotesIcon'" name="ChevronRightIcon" class="h-5"></signi-icon>
    </ecdis-context-menu-list>

    <template v-if="showSubMenu">
      <ul id="subCategoryMenu">
        <signi-panel-label primary="" class="pl-2 pt-2 pb-1">Collection:</signi-panel-label>
        <signi-select
          :options="colletionNameList"
          class="m-2 flex-1 rounded-sm bg-button_text-disabled"
          :model-value="selectedDropdownValue"
          @update:model-value="setSelectedCollectionOnDropdown($event)"
        >
          <template #option="{ value }">
            <signi-icon v-if="value === 'Create Collection'" name="CreateCollectionIcon" class="mr-2" />
            {{ value }}
          </template>
        </signi-select>
        <ecdis-context-menu-list
          v-for="item in availableSubMenuList"
          :key="item.name"
          :is-separator-required="item.separatorRequired"
          class="pl-1"
          @click="optionClick(item)"
        >
          <signi-icon :name="item.iconType" :class="'mr-1 h-5'" />
          <p class="grow">
            {{ item?.name }}
          </p>
        </ecdis-context-menu-list>
      </ul>
    </template>
  </ul>
</template>

<script lang="ts">
import { Ref, UnwrapRef, computed, defineComponent, onUpdated, ref, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { appsCtx } from '../../contexts/apps';
import { ContextMenuItem, contextMenuCtx } from '../../contexts/contextMenu';
import { controlModesCtx } from '../../contexts/controlModes';
import { criticalPointsSettingCtx } from '../../contexts/criticalPointsSetting';
import { eblVrmCtx } from '../../contexts/eblVrm';
import { marinersNotesCtx } from '../../contexts/marinersNotes';
import { messageCtx } from '../../contexts/messages';
import { objectInfoCtx } from '../../contexts/objectInfo';
import { routesCtx } from '../../contexts/routes';
import { routeSettingsCtx } from '../../contexts/routeSettings';
import { targetDataCtx } from '../../contexts/targetData';
import { viewportCtx } from '../../contexts/viewport';
import { NoteType } from '../../generated/Navigation';
import { useOwnship } from '../../global-state/ownship';
import { meterToLngLat } from '../../utils/conversions';

export default defineComponent({
  setup() {
    const unitZoomFactor = 125;
    const contextMenuRef = ref<HTMLElement | null>();
    const { ownshipLngLatInDeg } = useOwnship();
    const { setActiveApp, state: appState } = useContext(appsCtx);
    const { state, hideContextMenu, availableContextMenuItems, getSubMenuList } = useContext(contextMenuCtx);
    const { state: controlModesState } = useContext(controlModesCtx);
    const { showPanel, setRouteInfo } = useContext(criticalPointsSettingCtx);
    const {
      canDeleteWaypoint,
      removeWaypoint,
      appendNewWaypoint,
      insertNewWaypoint,
      findRouteByWaypointId,
      addNewIndexLine,
      removeIndexLine,
      insertNewCriticalpoint,
    } = useContext(routesCtx);
    const { viewport, setZoom, transformToViewport } = useContext(viewportCtx);
    const { state: routeSettings } = useContext(routeSettingsCtx);
    const { activateSleepingTarget } = useContext(targetDataCtx);
    const { pushMessage } = useContext(messageCtx);
    const {
      setMarkerRouteInfo: setMarkerRouteInfo,
      setVisibility: setChartOjectInfoPanelVisibility,
      setPositionAndScale: setMarkerPosition,
    } = useContext(objectInfoCtx);
    const { createEblVrm, deleteEblVrm, setSelectedEblVrm } = useContext(eblVrmCtx);
    const {
      createMarinersNote,
      state: marinersNoteState,
      getAllCollection,
      setSelectedCollectionOnDropdown,
    } = useContext(marinersNotesCtx);

    const availableSubMenuList: Ref<Array<ContextMenuItem>> = ref([]);
    const showSubMenu: Ref<boolean> = ref(false);
    const showCollectionDropdown = ref(false);

    const { visible } = toRefs(state);
    const notesLoading = computed(() => marinersNoteState.marinersNotesLoading);

    const colletionNameList = computed(() => {
      const values = getAllCollection().map(({ id, name }) => ({ label: name, id }));
      const newCollection = { label: 'Create Collection', id: undefined };
      return [newCollection, ...values];
    });

    const selectedDropdownValue = computed(() => {
      const collection = colletionNameList.value.find(({ id }) => id === marinersNoteState.lastSelectedCollection);

      return collection?.label;
    });

    const contextMenuWindowObject: ContextMenuWindowObject = {
      parentOffsetWidth: 0,
      parentOffsetHeight: 0,
      contextMenuWidth: 0,
      contextMenuHeight: 0,
    };
    onUpdated(() => {
      if (contextMenuRef?.value && contextMenuRef.value.parentElement) {
        contextMenuWindowObject.parentOffsetWidth = contextMenuRef.value.parentElement.offsetWidth;
        contextMenuWindowObject.parentOffsetHeight = contextMenuRef.value.parentElement.offsetHeight;
        contextMenuWindowObject.contextMenuWidth = contextMenuRef.value.offsetWidth;
        contextMenuWindowObject.contextMenuHeight = contextMenuRef.value.offsetHeight;
      }

      if (!visible.value) {
        showSubMenu.value = false;
      }
    });
    const optionClick = (ctxItem: UnwrapRef<ContextMenuItem>) => {
      let keepMenuOpen = false;
      if (!ctxItem.subList) {
        showSubMenu.value = false;
      }
      const meterPoint = transformToViewport(state);

      // choose context menu operation based on click events
      switch (ctxItem.action) {
        case 'zoomIn':
          setZoom(true, state, unitZoomFactor, controlModesState.browse);
          keepMenuOpen = true;
          break;
        case 'zoomOut':
          setZoom(false, state, unitZoomFactor, controlModesState.browse);
          keepMenuOpen = true;
          break;
        case 'DelWPT':
          if (state.data.uuid) {
            if (canDeleteWaypoint(state.data.uuid)) {
              removeWaypoint(state.data.uuid);
              return;
            }
            pushMessage({
              type: 'warning',
              text: 'Cannot delete WP because of a Critical Point.',
            });
          }
          break;
        case 'AppendWPT':
          appendNewWaypoint(meterToLngLat(meterPoint), routeSettings.settings.generalSettings);
          break;
        case 'InsertWPT':
          if (state.data.uuid) {
            insertNewWaypoint(state.data.uuid, meterToLngLat(meterPoint), routeSettings.settings.generalSettings);
          }
          break;
        case 'ChartObjectInfo':
          setMarkerPosition(meterPoint, viewport.scaling.scale);
          if (state.data.uuid && state.data.type && ['waypoint', 'leg'].includes(state.data.type)) {
            //Both waypoint and leg are currently using waypointId as uuid
            const routeInfo = findRouteByWaypointId(state.data.uuid);
            setMarkerRouteInfo(routeInfo, state.data.uuid);
          }
          setChartOjectInfoPanelVisibility(true);
          break;
        case 'AddEblVrm':
          createEblVrm(transformToViewport({ x: state.x, y: state.y }));
          break;
        case 'DeleteEblVrm':
          if (state.data.uuid) deleteEblVrm(state.data.uuid);
          break;
        case 'AddIndexLine':
          if (state.editing && state.data.uuid && state.data.type === 'leg') addNewIndexLine(state.data.uuid);
          break;
        case 'RemoveIndexLine':
          if (state.editing && state.data.uuid && state.data.type === 'indexLine') removeIndexLine(state.data.uuid);
          break;
        case 'ShowEblVrmInMenu':
          if (state.data.uuid && state.data.type === 'eblVrm') {
            if (appState.activeApp !== 'tools') setActiveApp('tools');
            setSelectedEblVrm(state.data.uuid);
          }
          break;
        case 'ShowActivateSleepingTarget':
        case 'HideActivateSleepingTarget':
          if (state.data.type === 'target' && state.data.target?.Sleeping) {
            activateSleepingTarget(state.data.target.Number);
          }
          break;
        case 'AddCritialPoint':
          if (state.editing && state.data.uuid && state.data.type === 'leg') {
            const routeInfo = findRouteByWaypointId(state.data.uuid);
            if (routeInfo?.routeId && routeInfo.name) {
              setRouteInfo(routeInfo.routeId, routeInfo.name, false);
              showPanel();
              insertNewCriticalpoint(state.data.uuid, meterPoint);
            }
          }
          break;
        case 'ShowCriticalPoints':
          if (state.data.type && ['waypoint', 'leg'].includes(state.data.type) && state.data.uuid) {
            const routeInfo = findRouteByWaypointId(state.data.uuid);
            if (routeInfo?.routeId && routeInfo.name) {
              setRouteInfo(routeInfo.routeId, routeInfo.name, !state.editing);
              showPanel();
            }
          }
          break;
        case 'OpenMarinersNotesList':
          {
            keepMenuOpen = true;
            showCollectionDropdown.value = true;
            const subList = [...getSubMenuList('OpenMarinersNotesList')];
            if (subList?.length > 0 && !showSubMenu.value) {
              availableSubMenuList.value = subList;
              showSubMenu.value = true;
            } else {
              showSubMenu.value = false;
            }
          }
          break;
        case 'addSafteyContourNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.SafetyContour);
          break;
        case 'addSolidLineNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.SolidLine);
          break;
        case 'addDashedLineNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.DashedLine);
          break;
        case 'addUnfilledAreaNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.UnfilledArea);
          break;
        case 'addFilledAreaNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.FilledArea);
          break;
        case 'addInformationNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.Information);
          break;
        case 'addCautionNote':
          createMarinersNote(meterToLngLat(meterPoint), NoteType.Caution);
          break;
        case 'addEventNote': {
          if (ownshipLngLatInDeg.value !== undefined) {
            createMarinersNote(ownshipLngLatInDeg.value, NoteType.Event);
          }
        }
      }
      if (!keepMenuOpen) {
        availableSubMenuList.value = [];
        hideContextMenu();
      }
    };
    const setOrientation = computed(() => ({
      top:
        contextMenuWindowObject?.contextMenuHeight + state.y >= contextMenuWindowObject?.parentOffsetHeight
          ? state.y - contextMenuWindowObject?.contextMenuHeight + 'px'
          : state.y + 'px',
      left:
        contextMenuWindowObject?.contextMenuWidth + state.x >= contextMenuWindowObject?.parentOffsetWidth
          ? state.x - contextMenuWindowObject?.contextMenuWidth + 'px'
          : state.x + 'px',
    }));

    return {
      visible,
      availableContextMenuItems,
      availableSubMenuList,
      showSubMenu,
      optionClick,
      setOrientation,
      contextMenuRef,
      notesLoading,
      colletionNameList,
      selectedDropdownValue,
      setSelectedCollectionOnDropdown,
    };
  },
});
type ContextMenuWindowObject = {
  parentOffsetWidth: number;
  parentOffsetHeight: number;
  contextMenuWidth: number;
  contextMenuHeight: number;
};
</script>

<style scoped>
#context-menu {
  @apply z-[999] m-0 w-44 list-none bg-button_background-default p-0 text-xs;
}

#subCategoryMenu {
  width: 200px;
  @apply absolute !top-[80%] ml-[103%]  bg-button_background-default text-xs;
}

.m-1 {
  @apply mr-2;
}

.adjustWptIcon {
  @apply ml-[-0.125rem] mr-3;
}

/* .chevron {
  @apply mr-[-24px] w-[89px] origin-center rotate-[-90deg] space-x-1.5;
} */
.chevron {
  @apply origin-center rotate-[-90deg];
}

.active {
  @apply cursor-pointer bg-button_background-selected text-button_text-selected;
}
</style>
