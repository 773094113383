<template>
  <div ref="containerRef"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { createChartCanvas } from './createChartCanvas';

export default defineComponent({
  setup() {
    const containerRef = ref<HTMLDivElement | null>(null);

    onMounted(() => {
      createChartCanvas(containerRef.value as HTMLDivElement);
    });

    return {
      containerRef,
    };
  },
});
</script>
