import Konva from 'konva';
import { colors } from './addScaleBar';

export function createScaleBar(oddColor: string): Konva.Group {
  const group = new Konva.Group({
    offsetY: 5,
    height: 10,
  });
  for (let i = 0; i < 10; i++) {
    group.add(
      new Konva.Line({
        points: [0, i, 0, i + 1],
        stroke: i % 2 === 0 ? colors.grey : oddColor,
        strokeWidth: 3,
      }),
    );
  }
  return group;
}
