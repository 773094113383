<template>
  <div v-if="loading || error" class="loading-icon">
    <ecdis-kongsberg-loading-indicator class="scaled floating spinner" :start="0.5" :progress="0.5" :error="error" />
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { imageLoadingCtx } from '../../contexts/imageLoading';

export default defineComponent({
  setup() {
    const { state } = useContext(imageLoadingCtx);
    return toRefs(state);
  },
});
</script>

<style scoped>
.loading-icon {
  width: 70px;
  height: 70px;
}
</style>
