<template>
  <pan-draggable-panel v-if="visible" class="chartObjectInfoPanel">
    <template #header>
      <pan-row class="header">
        <ecdis-value class="title">Chart Object Information</ecdis-value>
        <icon-grab class="grab" />
        <icon-close @pointerdown="closePanel" />
      </pan-row>
    </template>
    <template #main>
      <ecdis-chart-object-info-details />
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { objectInfoCtx } from '../../contexts/objectInfo';

export default defineComponent({
  setup() {
    const { state, setVisibility } = useContext(objectInfoCtx);
    const { visible } = toRefs(state);

    const closePanel = () => {
      setVisibility(false);
    };

    return {
      visible,
      closePanel,
    };
  },
});
</script>

<style scoped>
.chartObjectInfoPanel {
  width: 360px;
  height: 528px;
  background: var(--background_tile);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.header,
.field {
  padding: 0 12px;
}

.title {
  flex: 1;
  font-size: 16px;
  text-transform: uppercase;
}
.grab {
  margin-right: 8px;
}
</style>
