<template>
  <div class="value" :class="{ active }">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    active: { type: Boolean, default: false },
  },
});
</script>
<style scoped>
.value {
  color: var(--text_main);
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active {
  color: var(--button_text-selected);
}
</style>
