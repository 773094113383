<template>
  <div class="grid-container" :class="{ reference: referenceOptions }">
    <div class="cell-center row-span-2 justify-center border-r border-background_tile_border text-sm text-text_static">
      {{ modelValue.index ?? modelValue.id }}
    </div>
    <div class="px-2 py-1">
      <button
        :aria-pressed="modelValue.eblVisible"
        :class="['btn btn-flat btn-xs mr-2 inline-block', { 'btn-selected': modelValue.eblVisible }]"
        title="Toggle visibility"
        @click="$emit('update:modelValue', { ...modelValue, eblVisible: !modelValue.eblVisible })"
      >
        <signi-icon
          :key="modelValue.eblVisible.toString()"
          :name="modelValue.eblVisible ? 'VisibleIcon' : 'HiddenIcon'"
        />
      </button>
      <span class="text-sm text-text_static">EBL</span>
    </div>
    <signi-kpi-input
      class="cell-center px-2"
      :decimals="1"
      :disabled="!modelValue.eblVisible"
      :formatted-value="formatRadToDegrees(modelValue.bearing)"
      :max="360"
      :model-value="modelValue.bearing * RADIANS_IN_DEGREE"
      no-max
      unit-preset="deg"
      @update:model-value="$emit('update:modelValue', { ...modelValue, bearing: $event / RADIANS_IN_DEGREE })"
    />
    <div v-if="referenceOptions">
      <signi-select
        cell
        :options="[{ id: 'TRUE' }, { id: 'REL' }]"
        :model-value="modelValue.trueBearing ? 'TRUE' : 'REL'"
        @update:model-value="onUpdateTrueBearing"
      />
    </div>
    <div v-if="referenceOptions" class="pr-1 pl-2">
      <button
        :aria-pressed="eblAligned"
        :class="['btn btn-flat btn-xs', { 'btn-selected': eblAligned }]"
        title="Align EBL bearing with own ship heading"
        @click="onAlignClick"
      >
        <signi-icon name="AlignIcon" />
      </button>
    </div>
    <div
      class="cell-center gap-x-2 border-l border-background_tile_border px-2"
      :class="{ 'row-span-2': !referenceOptions }"
    >
      <button class="btn btn-flat btn-xs" title="Reset to default parameters" @click="onReset">
        <signi-icon name="ResetIcon" />
      </button>
      <button class="btn btn-flat btn-xs" title="Go to EBL/VRM" @click="$emit('goto')">
        <signi-icon name="TargetpinIcon" />
      </button>
      <button class="btn btn-flat btn-xs" title="Remove EBL/VRM" @click.stop="$emit('remove')">
        <signi-icon name="DeleteIcon" />
      </button>
    </div>

    <!-- Second row -->
    <div class="cell-center border-t border-background_tile_border px-2 py-1">
      <button
        :aria-pressed="modelValue.vrmVisible"
        :class="['btn btn-flat btn-xs mr-2 inline-block', { 'btn-selected': modelValue.vrmVisible }]"
        title="Toggle visibility"
        @click="$emit('update:modelValue', { ...modelValue, vrmVisible: !modelValue.vrmVisible })"
      >
        <signi-icon
          :key="modelValue.vrmVisible.toString()"
          :name="modelValue.vrmVisible ? 'VisibleIcon' : 'HiddenIcon'"
        />
      </button>
      <span class="text-sm text-text_static">VRM</span>
    </div>
    <signi-kpi-input
      class="cell-center border-t border-background_tile_border px-2"
      :decimals="2"
      :disabled="!modelValue.vrmVisible"
      :formatted-value="formatRange(modelValue.range)"
      :model-value="modelValue.range / NM_IN_METER"
      no-max
      unit="NM"
      @update:model-value="$emit('update:modelValue', { ...modelValue, range: $event * NM_IN_METER })"
    />
    <div v-if="referenceOptions" class="col-span-2 h-full border-t border-background_tile_border"></div>
    <div v-if="referenceOptions" class="cell-center justify-end border-l border-background_tile_border">
      <signi-select
        :options="referenceOptions"
        :model-value="modelValue.reference"
        @update:model-value="onUpdateReference"
      >
        <template #option="{ value }">
          <signi-icon :key="value" :name="referenceIcons[value]" />
        </template>
        <template #default="{ value }">
          <signi-icon :key="value" :name="referenceIcons[value]" />
          <span class="sr-only">{{ value }}</span>
        </template>
      </signi-select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { NM_IN_METER, RADIANS_IN_DEGREE, createFormatter, formatRadToDegrees } from '../../formatters';
import { SelectOption } from '../../select/types';
import { EblVrm } from '../types';
import { createDefaultEblVrm } from '../util';

const props = defineProps<{
  /** The ship heading will be used to align the bearing if there is a true bearing  */
  heading?: number;
  modelValue: EblVrm;
  /** If this value is defined, 3 extra controls will be rendered. In Planning Station this value is undefined */
  referenceOptions?: SelectOption[];
}>();

const emit = defineEmits(['goto', 'remove', 'update:modelValue']);

const rangeFormatter = createFormatter({
  minimumIntegerDigits: 3,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const formatRange = (distanceInM?: number) =>
  distanceInM !== undefined ? rangeFormatter.format(distanceInM / NM_IN_METER) : '-.--';

const eblAligned = computed(() => {
  return props.modelValue.trueBearing
    ? formatRadToDegrees(props.modelValue.bearing) === formatRadToDegrees(props.heading)
    : formatRadToDegrees(props.modelValue.bearing) === '000.0';
});

const onUpdateTrueBearing = ($event: string) => {
  emit('update:modelValue', { ...props.modelValue, trueBearing: 'TRUE' === $event });
};

const onAlignClick = () => {
  // When the primary heading sensor is lost the heading value is undefined or stale, but we switch automatically to relative bearing so this is no problem
  emit('update:modelValue', { ...props.modelValue, bearing: props.modelValue.trueBearing ? props.heading : 0 });
};

const onReset = () => {
  emit('update:modelValue', { ...props.modelValue, ...createDefaultEblVrm() });
};

const onUpdateReference = (reference: string) => {
  emit('update:modelValue', { ...props.modelValue, reference });
};

const referenceIcons = {
  Geographical: 'GlobeIcon',
  OwnShip: 'CCRPIcon',
};
</script>

<style scoped>
.grid-container {
  @apply relative grid items-center border-t border-l border-r border-background_tile_border bg-background_layer_below first:border-t-0;
  grid-template-columns: 2.5rem auto 1fr auto;
  grid-template-rows: 1fr 1fr;

  &.reference {
    grid-template-columns: 2.5rem auto 1fr 5.75rem auto auto;
    grid-template-rows: 1fr 1fr;
  }
}

.selected::before {
  @apply absolute h-full w-1 bg-feedback_indicator_background content-[''];
}

.cell-center {
  @apply flex h-full items-center;
}
</style>
