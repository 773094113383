import { checkIntersection } from 'line-intersect';
import { MeterPoint } from '../../../types';
import { vector } from '../../../utils/vector';

export function intersectionWithViewport(
  startingPoint: MeterPoint,
  angle: number,
  viewportTopLeft: MeterPoint,
  viewportTopRight: MeterPoint,
  viewportBottomRight: MeterPoint,
  viewportBottomLeft: MeterPoint,
): MeterPoint[] {
  const intersections: MeterPoint[] = [];

  // calculate far end point
  const endVector = vector()
    .setLength(Number.MAX_SAFE_INTEGER / 2)
    .setAngle(angle)
    .add(vector(startingPoint.mX, startingPoint.mY));
  const endingPoint = { mX: endVector.x, mY: endVector.y };

  const topIntersection = checkIntersection(
    startingPoint.mX,
    startingPoint.mY,
    endingPoint.mX,
    endingPoint.mY,
    viewportTopLeft.mX,
    viewportTopLeft.mY,
    viewportTopRight.mX,
    viewportTopRight.mY,
  );
  if (topIntersection.type === 'intersecting') {
    intersections.push({ mX: topIntersection.point.x, mY: topIntersection.point.y });
  }
  const rightIntersection = checkIntersection(
    startingPoint.mX,
    startingPoint.mY,
    endingPoint.mX,
    endingPoint.mY,
    viewportTopRight.mX,
    viewportTopRight.mY,
    viewportBottomRight.mX,
    viewportBottomRight.mY,
  );
  if (rightIntersection.type === 'intersecting') {
    intersections.push({ mX: rightIntersection.point.x, mY: rightIntersection.point.y });
  }
  const bottomIntersection = checkIntersection(
    startingPoint.mX,
    startingPoint.mY,
    endingPoint.mX,
    endingPoint.mY,
    viewportBottomRight.mX,
    viewportBottomRight.mY,
    viewportBottomLeft.mX,
    viewportBottomLeft.mY,
  );
  if (bottomIntersection.type === 'intersecting') {
    intersections.push({ mX: bottomIntersection.point.x, mY: bottomIntersection.point.y });
  }
  const leftIntersection = checkIntersection(
    startingPoint.mX,
    startingPoint.mY,
    endingPoint.mX,
    endingPoint.mY,
    viewportBottomLeft.mX,
    viewportBottomLeft.mY,
    viewportTopLeft.mX,
    viewportTopLeft.mY,
  );
  if (leftIntersection.type === 'intersecting') {
    intersections.push({ mX: leftIntersection.point.x, mY: leftIntersection.point.y });
  }
  if (intersections.length === 1) {
    // only one intersection is only possible when the starting point was inside the viewport, add it
    intersections.unshift({ ...startingPoint });
  }
  return intersections;
}
