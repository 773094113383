<template>
  <svg viewBox="0 0 16 16" fill="none">
    <path d="M12 6H4L8 10.5L12 6Z" fill="#A2AAB9" stroke="#A2AAB9" stroke-linejoin="round" />
  </svg>
</template>

<style scoped>
svg {
  width: 16px;
  height: 16px;
}
</style>
