<template>
  <div class="contents">
    <slot />
  </div>
</template>

<style scoped>
.contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 4px;
}
</style>
