<template>
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M16 23.0001C19.9494 23.0001 23.5071 20.4548 26 16.0001C23.5071 11.5454 19.9494 9.00012 16 9.00012C12.0507 9.00012 8.49297 11.5454 6.00004 16.0001C8.49297 20.4548 12.0507 23.0001 16 23.0001Z"
      stroke="#F6F6F6"
      stroke-linejoin="round"
    />
    <path
      d="M16 12.5001C17.933 12.5001 19.5 14.0671 19.5 16.0001C19.5 17.9331 17.933 19.5001 16 19.5001C14.067 19.5001 12.5 17.9331 12.5 16.0001C12.5 14.0671 14.067 12.5001 16 12.5001Z"
      stroke="#F6F6F6"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
