<template>
  <div>
    <hui-disclosure v-slot="{ open }" :default-open="true">
      <signi-panel-header :title="title" class="flex items-center gap-x-2 !px-2 text-button_text">
        <div class="flex-1" />
        <button
          :aria-pressed="visible"
          :class="['btn btn-flat btn-xs', { 'btn-selected': visible }]"
          title="Toggle visibility"
          @click="$emit('update:visible', !visible)"
        >
          <signi-icon :key="visible.toString()" :name="visible ? 'VisibleIcon' : 'HiddenIcon'" />
        </button>
        <button class="btn btn-flat btn-xs" title="Add" @click="onAdd">
          <signi-icon name="PlusIcon" />
        </button>
        <hui-disclosure-button
          ref="disclosureButton"
          :class="['flex-shrink-0 p-1 transition-transform', { 'rotate-180 transform': open }]"
        >
          <signi-icon name="ChevronDownIcon" />
        </hui-disclosure-button>
      </signi-panel-header>
      <hui-disclosure-panel>
        <slot v-for="(item, i) of sortedItems" :key="item.id" :item="item" :index="i"> </slot>
      </hui-disclosure-panel>
    </hui-disclosure>
  </div>
</template>

<script lang="ts" setup>
import { DisclosureButton } from '@headlessui/vue';
import { computed, ref } from 'vue';
import { EblVrm, ParallelIndexLine, PositionFixMarker } from '../types';

const props = defineProps<{ items: (EblVrm | ParallelIndexLine | PositionFixMarker)[]; title: string }>();
const emit = defineEmits(['add', 'update:visible']);

const disclosureButton = ref<typeof DisclosureButton | null>(null);

const sortedItems = computed(() => [...props.items].sort((a, b) => (+a.id < +b.id ? -1 : +a.id > +b.id ? 1 : 0)));
const visible = computed(() =>
  props.items.some((item) => ('visible' in item ? item.visible : item.eblVisible || item.vrmVisible)),
);

const onAdd = () => {
  // Expand disclosure
  if (disclosureButton.value && disclosureButton.value.$el.getAttribute('aria-expanded') === 'false') {
    disclosureButton.value?.$el.click();
  }

  const index = sortedItems.value.findIndex((item, i) => i + 1 !== +item.id);
  emit('add', ~index ? (index + 1).toString() : (sortedItems.value.length + 1).toString());
};
</script>
