import { configuration } from '../configuration';
import { ChartServerClient } from '../generated/ChartServer';
import { fetch } from '../utils/http';
import { createClientPromise } from './createClientPromise';

export const getChartServerImageClient = (): Promise<ChartServerClient> =>
  createClientPromise(
    'ChartServerImageClient',
    () =>
      new ChartServerClient(configuration.baseUrls.chartServer, {
        fetch: (url, init) =>
          fetch(url, init, [
            async (_, headers) => {
              headers['Accept'] = 'image/jpeg';
            },
          ]),
      }),
  );
