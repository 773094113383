import numberFormatter from 'number-format.js';
import { GeometryType, RouteValidationStatus } from '../generated/RouteManagement';
import { ParamValue } from '../types';

const intlDateFormatter = new Intl.DateTimeFormat('en');

export const dateFormatter = (date?: Date | string): string =>
  date !== undefined ? intlDateFormatter.format(new Date(date)) : '';

const intlDateTimeFormatter = new Intl.DateTimeFormat('en', {
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h23',
  timeZone: 'UTC',
});

export const dateTimeFormatter = (date?: Date | string): string =>
  date !== undefined ? intlDateTimeFormatter.format(new Date(date)) : '';

export const rangeFormatter = (range: number): string => {
  if (range < 0.1995) {
    return numberFormatter('0.000', range);
  } else if (range < 0.995) {
    return numberFormatter('0.00', range);
  } else if (range < 9.95) {
    return numberFormatter('0.0', range);
  }
  return numberFormatter('#', range);
};

export const timeFormatter = (totalSeconds?: number): string => {
  if (totalSeconds === undefined) return '--:--:--';

  const sign = Math.sign(totalSeconds);
  const roundedTotal = Math.abs(Math.round(totalSeconds));
  const hours: number = Math.floor(roundedTotal / 3600);
  const minutes: number = Math.floor((roundedTotal - hours * 3600) / 60);
  const seconds: number = roundedTotal - hours * 3600 - minutes * 60;
  return (
    (sign < 0 ? '-' : '') +
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')
  );
};

const degToDecimalMinutes = (degree: number) => {
  const absDeg = Math.abs(degree);
  const floorDeg = Math.floor(absDeg);
  const minutes = numberFormatter('0.###', (absDeg - floorDeg) * 60);
  return `${floorDeg}° ${minutes}'`;
};

export const latitudeToDecimalMinutesFormatter = (latitude?: number): string => {
  if (latitude === undefined) return "---° --.---'";
  const direction = latitude >= 0 ? 'N' : 'S';
  return `${degToDecimalMinutes(latitude)} ${direction}`;
};

export const longitudeToDecimalMinutesFormatter = (longitude?: number): string => {
  if (longitude === undefined) return "---° --.---'";
  const direction = longitude >= 0 ? 'E' : 'W';
  return `${degToDecimalMinutes(longitude)} ${direction}`;
};

export const scaleFormatter = (scale: number): string => numberFormatter('#,###.', scale).replace(/,/g, '.');

export const distanceFormatter = (distance?: number): string =>
  distance !== undefined ? numberFormatter('0.#', distance) : '-.-';

export const courseFormatter = (courseInDeg?: number): string =>
  courseInDeg !== undefined ? numberFormatter('000.0', courseInDeg) : '---.-';

export const speedFormatter = (speedInKnots?: number): string =>
  speedInKnots !== undefined ? numberFormatter('0.0', speedInKnots) : '-.-';

export const twoDecimalFormatter = (num?: number): string =>
  num !== undefined ? numberFormatter('0.00', num) : '-.--';

export const courseToTrueNorthFormatter = (courseInDeg?: number): string =>
  courseInDeg !== undefined ? numberFormatter('000', courseInDeg) : '---';

export const legModeToStringFormatter = (legMode: GeometryType | undefined): string => {
  if (legMode == GeometryType.Orthodrome) {
    return 'Great circle';
  }
  return 'Rhumb line';
};

const validationStatusAbbreviations = {
  notValidated: 'Not validated',
  encValidatedOK: 'EOK',
  encValidatedWithWarnings: 'EW',
  encValidatedWithGroundings: 'EG',
  encValidatedWithGroundingsAndWarnings: 'EGW',
  nonENCValidatedOK: 'NOK',
  nonENCValidatedWithWarnings: 'NW',
  nonENCValidatedWithGroundings: 'NG',
  nonENCValidatedWithGroundingsAndWarnings: 'NGW',
};

export const validationAbbrFormatter = (status?: RouteValidationStatus): string => {
  if (!status) return validationStatusAbbreviations.notValidated;
  return validationStatusAbbreviations[status] ?? validationStatusAbbreviations.notValidated;
};

export const toParamValue = (value: string, unit = ''): ParamValue => {
  return { value, unit } as ParamValue;
};

export const oneDecimalFormatter = (num?: number): string =>
  num !== undefined ? numberFormatter('000.0', num) : '---.-';

export const twoDigitFormatter = (num?: number): string => (num !== undefined ? numberFormatter('00', num) : '--');

export const threeDigitFormatter = (num?: number): string => (num !== undefined ? numberFormatter('000', num) : '---');

export const formatPositionDegree = (degree?: number, isLongitude?: boolean): string => {
  if (degree === undefined) return "--° --.---'";

  const absDegree = Math.abs(degree);
  const integer = Math.trunc(absDegree);
  const minutes = (absDegree - integer) * 60;
  const frac1 = twoDigitFormatter(Math.trunc(minutes));
  const frac2 = String(minutes).substring(3, 6);
  const formattedDegree = isLongitude ? threeDigitFormatter(integer) : integer;
  return `${formattedDegree}° ${frac1}.${frac2}’`;
};

export const formatSecToHHMMSS = (totalSeconds?: number): string => {
  if (totalSeconds === undefined) return '--:--:--';
  const roundedTotal = Math.round(totalSeconds);
  const hours: number = Math.floor(roundedTotal / 3600);
  const minutes: number = Math.floor((roundedTotal - hours * 3600) / 60);
  const seconds: number = roundedTotal - hours * 3600 - minutes * 60;
  return (
    hours.toString().padStart(2, '0') +
    'h ' +
    minutes.toString().padStart(2, '0') +
    'min ' +
    seconds.toString().padStart(2, '0') +
    's'
  );
};

export const formatUnitLat = (degree?: number): string => (degree !== undefined ? (degree < 0 ? 'S' : 'N') : '-');
export const formatUnitLng = (degree?: number): string => (degree !== undefined ? (degree < 0 ? 'W' : 'E') : '-');
export const oneDecimalDigitFormatter = (num?: number): string =>
  num !== undefined ? numberFormatter('0.0', num) : '-.-';
