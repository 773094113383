import Konva from 'konva';
import { watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { viewportCtx } from '../../contexts/viewport';

export function watchViewportDimension(stage: Konva.Stage): void {
  const { viewport } = useContext(viewportCtx);

  // update stage dimension when viewport dimension changes
  watchEffect(() => {
    stage.width(viewport.dimension.width);
    stage.height(viewport.dimension.height);
  });
}
