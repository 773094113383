<template>
  <div class="mariner__panel">
    <transition :name="activeCollection ? 'slideLeft' : 'slideRight'">
      <collection-overview
        v-if="!activeCollection"
        :collections="collections"
        :is-new-enabled="isEditing"
        @active:collection="setActiveMarinersNotesCollectionId($event)"
        @create:collection="createAndSetActiveCollection"
        @update:visibility-collection="updateCollectionVisibility($event)"
      >
      </collection-overview>
    </transition>
    <transition :name="activeCollection ? 'slideLeft' : 'slideRight'">
      <collection
        v-if="activeCollection"
        :collection="activeCollection"
        @update:collection-description="updateCollection"
        @reset:collection="resetCollection"
        @refresh:collection="fetchMarinersNotesCollection"
        @save:collection="handleSaveCollection"
        @select:note="selectMarinersNote($event.noteId, $event.collectionId)"
        @go-to-pin="goToPin($event)"
        @update:note="updateMarinersNote($event, activeCollection?.id)"
        @delete:mariner-note="deleteNoteFromActiveCollection($event)"
        @update:visibility-collection="updateCollectionVisibility($event)"
        @delete:collection="deleteCollection"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../../../lib/configuration';
import { controlModesCtx } from '../../../../lib/contexts/controlModes';
import { viewportCtx } from '../../../../lib/contexts/viewport';
import { Point } from '../../../../lib/generated/Navigation';
import { calculateBounds } from '../../../../lib/utils/tools';
import { marinersNotesCtx } from '../../../contexts/marinersNotes';
import CollectionOverview from './collection-overview/CollectionOverview.vue';
import Collection from './collection/Collection.vue';

const {
  getActiveCollection,
  getAllCollection,
  setActiveMarinersNotesCollectionId,
  persistMarinersNotesCollection,
  updateMarinersNote,
  deleteNoteFromActiveCollection,
  createAndSetActiveCollection,
  updateMarinerCollectionName,
  state,
  fetchMarinersNotesCollection,
  updateCollectionVisibility,
  removeUnSavedCollection,
  deleteCollection,
  selectMarinersNote,
} = useContext(marinersNotesCtx);
const { showInViewport } = useContext(viewportCtx);
const { setBrowse } = useContext(controlModesCtx);

const collections = computed(() => getAllCollection());
const activeCollection = computed(() => getActiveCollection());
const isEditing = computed(() => state.marinersNotesLoading);

const resetCollection = (collectionId: string) => {
  setActiveMarinersNotesCollectionId(collectionId);
  if (collectionId === undefined) {
    removeUnSavedCollection();
  }
};
const handleSaveCollection = () => persistMarinersNotesCollection();
const updateCollection = (collectionId: string | undefined, description: string) =>
  updateMarinerCollectionName(collectionId, description);
const goToPin = (point: Point) => {
  const { lat, lng } = point;

  if (lat === undefined || lng === undefined) return;

  if (isExtension) setBrowse(true);
  const bounds = calculateBounds({ lat, lng });

  showInViewport(bounds, 0.75, false);
};
</script>
<style scoped>
.mariner__panel {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.slideLeft-enter-active,
.slideLeft-leave-active,
.slideRight-enter-active,
.slideRight-leave-active {
  transition: transform 0.25s ease-in-out;
}
.slideLeft-enter-from,
.slideRight-leave-to {
  transform: translateX(100%);
}
.slideLeft-leave-to,
.slideRight-enter-from {
  transform: translateX(-100%);
}
</style>
