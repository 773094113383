<template>
  <svg viewBox="0 0 32 32" fill="none">
    <path d="M12.5 11.5V10.5H13.5V11.5H12.5Z" fill="#F6F6F6" stroke="#F6F6F6" stroke-linejoin="round" />
    <path d="M12.5 17.5V16.5H13.5V17.5H12.5Z" fill="#F6F6F6" stroke="#F6F6F6" stroke-linejoin="round" />
    <path d="M12.5 23.5V22.5H13.5V23.5H12.5Z" fill="#F6F6F6" stroke="#F6F6F6" stroke-linejoin="round" />
    <path d="M18.5 11.5V10.5H19.5V11.5H18.5Z" fill="#F6F6F6" stroke="#F6F6F6" stroke-linejoin="round" />
    <path d="M18.5 17.5V16.5H19.5V17.5H18.5Z" fill="#F6F6F6" stroke="#F6F6F6" stroke-linejoin="round" />
    <path d="M18.5 23.5V22.5H19.5V23.5H18.5Z" fill="#F6F6F6" stroke="#F6F6F6" stroke-linejoin="round" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
