import { v4 as uuid } from 'uuid';
import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { configuration } from '../configuration';
import { NavigationClient, Note, NoteCollection, NoteType, Point } from '../generated/Navigation';
import { LngLatPoint } from '../types';
import { degInRad } from '../utils/conversions';
import { fetch } from '../utils/http';
import { lineTypeNotes } from '../utils/marinersNotesUtils';

export type MarinersNote = Note & {
  selected?: boolean;
  type: NoteType;
  points: Point[];
  visible?: boolean;
};

export type MarinersCollection = NoteCollection & {
  notes: MarinersNote[];
  visible?: boolean;
};

export type MarinersCollectionState = {
  collections: MarinersCollection[];
  activeCollectionId?: string;
  activeMarinersNoteId?: string;
  marinersNotesLoading: boolean;
  lastSelectedCollection?: string;
};

const apiClient = new NavigationClient(configuration.baseUrls.api, {
  fetch,
});

const defaultCollection = () => ({
  notes: [],
  id: undefined,
  description: '',
  name: 'New Collection',
});

export const marinersNotesCtx = defineContext(() => {
  const state: MarinersCollectionState = reactive({
    activeCollectionId: undefined,
    activeMarinersNoteId: undefined,
    collections: [],
    marinersNotesLoading: true,
    lastSelectedCollection: undefined,
  });

  const fetchMarinersNotesCollection = async () => {
    try {
      const collections = await apiClient.userNotes().then((values) => {
        state.marinersNotesLoading = false;
        return values.map(({ notes, ...collection }) => ({
          notes: (notes || []).map((note) => ({ ...note, selected: false })),
          ...collection,
        })) as MarinersCollection[];
      });

      setMarinersCollections(collections);
      state.marinersNotesLoading = false;
    } catch (error) {
      console.log('error', error);
    }
  };
  const setMarinersCollections = (collections: MarinersCollection[]) => (state.collections = collections);

  const generateName = () => {
    const collectionNames = new Set(state.collections.map(({ name }) => name));
    let contender = 'New Collection';
    let validName = false;
    let index = 0;

    while (!validName) {
      const normalizedContender = index++ === 0 ? contender : `New Collection ${index}`;
      if (!collectionNames.has(normalizedContender)) {
        validName = true;
        contender = normalizedContender;
      }
    }

    return contender;
  };

  const createNewCollection = () => {
    const collection = { ...defaultCollection(), name: generateName() };

    state.collections.push(collection);
  };

  const createAndSetActiveCollection = () => {
    createNewCollection();
    state.activeCollectionId = undefined;
  };

  const persistMarinersNotesCollection = async () => {
    const activeCollection = getActiveCollection();
    state.activeMarinersNoteId = '';
    state.marinersNotesLoading = true;

    try {
      const response = await apiClient.createOrUpdateNotes(activeCollection).then((updateCollection) => {
        state.marinersNotesLoading = false;

        return {
          ...updateCollection,
          notes: (updateCollection.notes || []).map((note) => ({ ...note, selected: false })),
        } as MarinersCollection;
      });

      updateActiveCollection(response, activeCollection?.id);
      setActiveMarinersNotesCollectionId(undefined);
    } catch (error) {
      state.marinersNotesLoading = true;
    }
  };

  const updateActiveCollection = (updatedCollection: MarinersCollection, collectionId?: string) => {
    const collections = state.collections.map((collection) => {
      if (collection.id === collectionId) return updatedCollection;

      return collection;
    });

    setMarinersCollections(collections);
  };

  const getMarinersNotesByCollectionId = (collectionId: string | undefined): MarinersNote[] =>
    state.collections.find((collection) => collection.id === collectionId)?.notes || [];

  const createMarinersNote = (point: LngLatPoint, noteType: NoteType, collectionId = state.lastSelectedCollection) => {
    const id = uuid();
    const collection = getCollection(collectionId);

    if (!collection) {
      createNewCollection();
    }

    const currentCollectionNotes = getMarinersNotesByCollectionId(collectionId);

    currentCollectionNotes.push({
      id: id,
      description: '',
      points: [{ lat: point.lat * degInRad, lng: point.lng * degInRad }],
      type: noteType,
      selected: true,
      updatedAt: new Date(),
    });

    selectMarinersNote(id, collectionId);
  };

  const selectMarinersNote = (noteId?: string, collectionId?: string) => {
    unselectedAllMarinersNotes();
    selectMarinerNoteInCollection(noteId, collectionId);
  };

  const selectMarinerNoteInCollection = (noteId?: string, collectionId?: string) => {
    const collection = getCollection(collectionId);

    if (!collection) return;

    collection.notes = collection.notes.map((note: MarinersNote) => ({ ...note, selected: noteId === note.id }));
  };

  const unselectedAllMarinersNotes = () => {
    const collections = state.collections.map((collection: MarinersCollection) => ({
      ...collection,
      notes: collection.notes.map((note: MarinersNote) => ({ ...note, selected: false })),
    }));

    setMarinersCollections(collections);
  };

  const setActiveMarinersNotesCollectionId = (collectionId?: string) => (state.activeCollectionId = collectionId);

  const getActiveMarinersCollectionId = () => state.activeCollectionId;

  const getActiveCollection = () => getCollection(state.activeCollectionId);

  const getCollection = (collectionId: string | undefined) => state.collections.find(({ id }) => id === collectionId);

  const updatePointPosition = (
    marinersNoteId: string,
    point: LngLatPoint,
    index: number,
    collectionId = state.activeCollectionId,
  ) => {
    const activeNote = getMarinersNoteById(marinersNoteId, collectionId);

    if (activeNote && isPointAvailable(activeNote.points)) {
      activeNote.points[index] = { lat: point.lat * degInRad, lng: point.lng * degInRad };
    }
  };

  const isPointAvailable = (points?: Point[]): boolean => !!points;

  const updateMarinersNote = (note: MarinersNote, collectionId?: string) => {
    const { id, type, description } = note;
    updateNoteType(id, type, collectionId);
    updateNoteDescription(id, description, collectionId);
  };

  const updateMarinerCollectionName = (collectionId?: string, description?: string) => {
    const collection = getCollection(collectionId);

    if (collection) collection.name = description;
  };

  const updateMarinersNotesPoints = (point: LngLatPoint, id?: string, collectionId = state.activeCollectionId) => {
    const activeNote = getMarinersNoteById(id, collectionId);
    if (!(activeNote && isPointAvailable(activeNote.points))) return;

    activeNote.points.push({ lat: point.lat * degInRad, lng: point.lng * degInRad });
  };

  const getMarinersNoteById = (id?: string, collectionId?: string) => {
    return getMarinersNotesByCollectionId(collectionId).find((notes) => notes.id === id);
  };

  const getActiveMarinersNotesCollection = () => {
    return getMarinersNotesByCollectionId(state.activeCollectionId);
  };

  const getAllCollection = () => state.collections;

  const updateNoteType = (noteId: string | undefined, newType: NoteType, collectionId = state.activeCollectionId) => {
    const currentNote = getMarinersNoteById(noteId || '', collectionId);
    if (!currentNote) return;
    currentNote.type = newType;
    currentNote.updatedBy = undefined; // BE needs it undefined to figure out its the update of existing Note during this POST call scenario.
  };

  const updateNoteDescription = (noteId?: string, newDesc?: string, collectionId = state.activeCollectionId) => {
    const currentNote = getMarinersNoteById(noteId || '', collectionId);
    if (!currentNote) return;
    currentNote.description = newDesc;
    currentNote.updatedBy = undefined; // BE needs it undefined to figure out its the update of existing Note during this POST call scenario.
  };

  const deleteNoteFromActiveCollection = (noteId: string) => {
    const activeCollection = getActiveCollection();
    if (!activeCollection) return;
    const existingNotes = [...activeCollection.notes];
    existingNotes?.splice(
      existingNotes?.findIndex((l) => l.id === noteId),
      1,
    );
    if (activeCollection && activeCollection.notes.length) {
      activeCollection.notes = [...existingNotes];
    }
  };

  const isActiveNoteLineNote = () => {
    const { activeCollectionId, activeMarinersNoteId } = state;
    const { type } = getMarinersNoteById(activeMarinersNoteId, activeCollectionId) || {};

    return lineTypeNotes.some((notetype) => notetype === type);
  };

  const updateCollectionVisibility = (collectionId?: string) => {
    const collection = getCollection(collectionId);

    if (collection) {
      collection.visible = !collection.visible;
    }
  };

  const removeUnSavedCollection = () => {
    state.collections = state.collections.filter(({ id }) => id !== undefined);
  };

  const deleteCollection = async (collectionId?: string) => {
    if (!collectionId) {
      removeUnSavedCollection();
      return;
    }

    try {
      await apiClient.deleteCollection(collectionId).then(() => {
        return fetchMarinersNotesCollection();
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const visibleCollectionNotes = () => {
    return state.collections.filter(({ visible }) => visible);
  };

  const setSelectedCollectionOnDropdown = (collectionId: string) => {
    state.lastSelectedCollection = collectionId;
  };

  const getSelectedMarinersNote = () => {
    let note = undefined;
    const { collections } = state;
    let collectionId = undefined;

    for (let index = 0; index < collections.length; index++) {
      const { id, notes } = collections[index];
      collectionId = id;
      note = notes.find(({ selected }: MarinersNote) => selected);

      if (note) break;
    }

    return { note, collectionId };
  };

  return {
    state: readonly(state),
    createMarinersNote,
    fetchMarinersNotesCollection,
    persistMarinersNotesCollection,
    getMarinersNotesByCollectionId,
    updatePointPosition,
    updateMarinersNotesPoints,
    getActiveMarinersCollectionId,
    setActiveMarinersNotesCollectionId,
    getActiveMarinersNotesCollection,
    updateNoteType,
    updateNoteDescription,
    deleteNoteFromActiveCollection,
    isActiveNoteLineNote,
    getActiveCollection,
    getAllCollection,
    createNewCollection,
    updateMarinersNote,
    updateMarinerCollectionName,
    updateCollectionVisibility,
    removeUnSavedCollection,
    deleteCollection,
    visibleCollectionNotes,
    setSelectedCollectionOnDropdown,
    selectMarinersNote,
    unselectedAllMarinersNotes,
    createAndSetActiveCollection,
    getSelectedMarinersNote,
    setMarinersCollections,
  };
});
