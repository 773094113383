<template>
  <pan-draggable-panel v-if="visible" class="targetSettingsPanel">
    <template #header>
      <signi-panel-header title="Target sources" class="flex justify-between text-text_static">
        <button class="text-button_text" title="Close" @click="toggleVisibility">
          <signi-icon class="h-6 w-6" name="CloseIcon" />
        </button>
      </signi-panel-header>

      <div class="checkBox targetSource">
        <div v-for="(setting, index) in settings.targetSourcesSettings" :key="index" class="grid grid-cols-2 p-2">
          <signi-panel-checkbox
            :label="setting.name"
            :model-value="setting.value"
            @update:model-value="toggleTargetSourceandUpdate(setting.id)"
          />

          <span class="static-text targetCount">
            <span class="targetText">Targets:</span> {{ getTargetsCountBySource(setting.id) }}
          </span>
        </div>
      </div>
      <signi-panel-header title="General" class="text-text_static" />
      <div class="checkBox">
        <div
          v-for="(setting, index) in settings.generalSettings"
          :key="index"
          class="data-items"
          :class="{ 'data-items-width': !setting?.dropDown?.hasDropDown }"
        >
          <signi-panel-checkbox
            :label="setting.name"
            :model-value="setting.value"
            @update:model-value="toggleGeneralSettingsAndUpdate(setting.id)"
          />
          <pan-dropdown
            v-if="setting?.dropDown?.hasDropDown"
            class="dropdown"
            :drops-downwards="true"
            :disabled="!setting.value"
            :right-align="true"
          >
            <ecdis-value>
              {{ setting.dropDown.value.value }} <ecdis-unit>{{ setting.dropDown.value.unit }}</ecdis-unit>
            </ecdis-value>
            <template #content>
              <ecdis-option-list
                v-slot="{ value }"
                :param-key="setting.id"
                :values="['1', '3', '6', '12', '24']"
                :current-value="setting.dropDown.value.value"
                @selected="setMinuteMarkInterval($event.value)"
              >
                <ecdis-value>
                  {{ value }} <ecdis-unit>{{ setting.dropDown.value.unit }}</ecdis-unit>
                </ecdis-value>
              </ecdis-option-list>
            </template>
          </pan-dropdown>
        </div>
      </div>

      <signi-panel-header title="AIS filtering" class="switch-header">
        <signi-switch
          class="mx-2"
          :model-value="settings.aisFiltering.isActive"
          @update:model-value="toggleAisFilteringAndUpdate(settings.aisFiltering.id)"
        />
      </signi-panel-header>
      <div>
        <div v-for="(setting, index) in settings.aisFiltering.settings" :key="index" class="data-items">
          <signi-panel-checkbox
            :label="setting.name"
            :model-value="setting.value.value === 1"
            @update:model-value="toggleAisFilteringAndUpdate(setting.id)"
          />

          <pan-dropdown
            v-if="setting?.dropDown?.hasDropDown"
            class="dropdown"
            :drops-downwards="true"
            :disabled="!setting.value.value"
            :right-align="true"
          >
            <ecdis-value>
              {{ setting.dropDown.value.value }} <ecdis-unit>{{ setting.dropDown.value.unit }}</ecdis-unit>
            </ecdis-value>
            <template #content>
              <ecdis-option-list
                v-slot="{ value }"
                :param-key="setting.id"
                :values="['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']"
                :current-value="setting.dropDown.value.value"
                @selected="setSleepOutsideRangeValueAndUpdate($event.value)"
              >
                <ecdis-value>
                  {{ value }} <ecdis-unit>{{ setting.dropDown.value.unit }}</ecdis-unit>
                </ecdis-value>
              </ecdis-option-list>
            </template>
          </pan-dropdown>
        </div>
      </div>
      <signi-panel-header title="Target association" class="switch-header">
        <signi-switch
          class="mx-2"
          :model-value="settings.targetAssociation.isActive"
          @update:model-value="setTargetAssociation()"
        />
      </signi-panel-header>
      <pan-row v-for="(setting, index) in settings.targetAssociation.settings" :key="index">
        <div class="cell">
          <pan-static-text>{{ setting.name }}</pan-static-text>
        </div>
        <div class="cell right">
          <pan-dropdown
            v-if="setting?.dropDown?.hasDropDown"
            class="target-dropdown"
            :drops-downwards="true"
            :disabled="!setting.value"
          >
            <ecdis-value>
              {{ setting.dropDown.value.value }} <ecdis-unit>{{ setting.dropDown.value.unit }}</ecdis-unit>
            </ecdis-value>
            <template #content>
              <ecdis-option-list
                v-slot="{ value }"
                :param-key="setting.id"
                :values="['RADAR', 'AIS']"
                :current-value="setting.dropDown.value.value"
                @selected="setTargetAssociationType($event.value)"
              >
                <ecdis-value>
                  {{ value }} <ecdis-unit>{{ setting.dropDown.value.unit }}</ecdis-unit>
                </ecdis-value>
              </ecdis-option-list>
            </template>
          </pan-dropdown>
          <div v-else class="editable-text">
            <pan-range-editor
              :range-id="setting.id"
              :range-unit="setting.value.unit"
              :range-value="setting.value.value"
              @update-range="updateTargetData"
            >
            </pan-range-editor>
          </div>
        </div>
      </pan-row>

      <signi-panel-header title="Dangerous targets" class="switch-header">
        <signi-switch
          class="mx-2"
          :model-value="settings.dangerousTargets.isActive"
          @update:model-value="toggleDangerousTargetsAndUpdate()"
        />
      </signi-panel-header>
      <pan-row v-for="(setting, index) in settings.dangerousTargets.settings" :key="index">
        <div class="cell">
          <pan-static-text>{{ setting.name }}</pan-static-text>
        </div>
        <div class="cell right">
          <div class="editable-text">
            <pan-range-editor
              :range-id="setting.id"
              :range-unit="setting.value.unit"
              :range-value="setting.value.value"
              @update-range="updateTargetData"
            >
            </pan-range-editor>
          </div>
        </div>
      </pan-row>
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { UpdateReq } from '../../contexts/routeSettings';
import { targetDataCtx } from '../../contexts/targetData';
import { targetSettingsCtx } from '../../contexts/targetSettings';

export default defineComponent({
  emits: ['emitEvent'],
  setup(_, { emit }) {
    const {
      state: targetSettingsState,
      toggleGeneralSettings,
      toggleTargetSources,
      toggleVisibility,
      setMinuteMarkInterval,
      setSleepOutsideRange,
      updateRange,
      toggleAisFilteringSettings,
      toggleDangerousTargets,
      toggleTargetAssociation,
      updateTargetAssociationType,
      getTargetConfigurations,
      toggleAisFiltering,
    } = useContext(targetSettingsCtx);
    const { getTargetsCountBySource, clearactivateSleepingTarget } = useContext(targetDataCtx);

    const { visible, settings } = toRefs(targetSettingsState);

    const updateTargetData = (change: UpdateReq) => {
      updateRange(change);
      emitTargetConfiguration();
    };

    const toggleTargetSourceandUpdate = (id: string) => {
      toggleTargetSources(id);
      emitTargetConfiguration();
    };

    const toggleDangerousTargetsAndUpdate = () => {
      toggleDangerousTargets();
      emitTargetConfiguration();
    };

    const setTargetAssociationType = (value: string) => {
      updateTargetAssociationType(value);
      emitTargetConfiguration();
    };

    const setTargetAssociation = () => {
      toggleTargetAssociation();
      emitTargetConfiguration();
    };

    const emitTargetConfiguration = () => {
      emit('emitEvent', { id: 'targetConfiguration', value: getTargetConfigurations() });
    };

    const setSleepOutsideRangeValueAndUpdate = (range: string) => {
      setSleepOutsideRange(range);
      emitTargetConfiguration();
    };

    const toggleAisFilteringAndUpdate = (settingId: string) => {
      if (!settingId) {
        return;
      } else if (settingId === 'aisFilteringOn') {
        toggleAisFiltering();
        clearactivateSleepingTarget();
      } else {
        toggleAisFilteringSettings(settingId);
      }
      emitTargetConfiguration();
    };

    const toggleGeneralSettingsAndUpdate = (settingId: string) => {
      toggleGeneralSettings(settingId);
      emitTargetConfiguration();
    };

    emitTargetConfiguration();

    return {
      visible,
      settings,
      getTargetsCountBySource,
      toggleGeneralSettingsAndUpdate,
      toggleVisibility,
      setMinuteMarkInterval,
      updateTargetData,
      toggleAisFilteringSettings,
      toggleDangerousTargetsAndUpdate,
      toggleTargetSourceandUpdate,
      setTargetAssociation,
      setTargetAssociationType,
      toggleAisFilteringAndUpdate,
      setSleepOutsideRangeValueAndUpdate,
    };
  },
});
</script>

<style scoped>
.targetSettingsPanel {
  padding: 0px !important;
  width: 320px;
  background: var(--background_layer_below);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  top: 20px;
}
.switch-header {
  @apply flex items-center justify-between text-text_static;
}
.checkBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.data-items-width {
  width: 50%;
}
.data-items {
  display: flex;
  padding: 8px;
}

.dropdown {
  margin: -8px 0 0 45px;
}
.target {
  justify-content: space-between;
}
.target-items {
  padding-left: 23px;
}
.cell {
  width: 40%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 8px 11px;
}

.right {
  padding: 0;
  width: 60%;
}
.target-dropdown {
  margin-left: 5%;
  padding-right: 7%;
  width: 100%;
}
.editable-text {
  width: 100%;
  display: flex;
}
.checkBox .static-text,
.data-items .static-text {
  color: #f6f6f6;
  line-height: 16px;
  padding-left: 6px;
}
.targetSource-close {
  margin-left: 120px;
}
.targetSource {
  display: block;
}
.targetText {
  color: #a2aab9;
}
.targetCount {
  padding-left: 88px;
}
</style>
