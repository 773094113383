<template>
  <span class="unit">
    <slot />
  </span>
</template>

<style scoped>
.unit {
  color: var(--text_static);
}
</style>
