<template>
  <div class="list-row">
    <pan-static-text>{{ name }}</pan-static-text>
    <ecdis-value>
      {{ value }} <ecdis-unit>{{ unit }}</ecdis-unit>
    </ecdis-value>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    paramName: {
      type: String,
      default: '',
    },
    paramValue: {
      type: String,
      default: '',
    },
    paramUnit: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const name = computed(() => props.paramName);
    const value = computed(() => props.paramValue);
    const unit = computed(() => props.paramUnit);

    return { name, value, unit };
  },
});
</script>

<style scoped>
.list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 16px;
}
</style>
