<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6L6 12M6 12L12 18M6 12L18 12" stroke="#F6F6F6" stroke-linejoin="round" />
  </svg>
</template>

<style scoped>
svg {
  width: 24px;
  height: 24px;
}
</style>
