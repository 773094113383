<template>
  <div class="cell">
    <signi-icon
      :key="alertIcon"
      :aria-label="alert.level"
      :name="alertIcon"
      :class="{
        invisible: !blinkActive,
        'text-alert_alarm-active': alert.level === AlertLevel.Alarm && (!alert.acknowledged || alert.active),
        'text-alert_warning-active': alert.level === AlertLevel.Warning && (!alert.acknowledged || alert.active),
        'text-alert_alarm-inactive': alert.level === AlertLevel.Alarm && alert.acknowledged && !alert.active,
        'text-alert_warning-inactive': alert.level === AlertLevel.Warning && alert.acknowledged && !alert.active,
        'text-alert_caution': alert.level === AlertLevel.Caution,
      }"
      role="img"
    />
  </div>

  <div class="cell overflow-hidden">
    <button
      v-show="!alert.acknowledged && alert.level != AlertLevel.Caution"
      class="btn mr-2 inline-flex"
      @click="$emit('acknowledge')"
    >
      Ack
    </button>
    <span class="truncate" :title="alert.text"> {{ alert.text }} </span>
  </div>

  <div class="cell">{{ time }}</div>

  <hui-popover class="cell">
    <hui-popover-button title="Show alert details">
      <signi-icon name="InfoIcon" title="InfoIcon" />
    </hui-popover-button>
    <hui-popover-panel>
      <signi-alert-details :alert="alert" class="details-popover" />
    </hui-popover-panel>
  </hui-popover>
</template>

<script setup lang="ts">
import { formatInTimeZone } from 'date-fns-tz';
import { computed } from 'vue';
import { Alert, AlertLevel } from '../types';

defineEmits(['acknowledge']);

const props = defineProps<{ alert: Alert; blinkSync: boolean }>();

const alertIcon = computed(() => {
  const { active, acknowledged } = props.alert;

  if (props.alert.level === AlertLevel.Caution) {
    return 'CautionIcon';
  } else if (!active && !acknowledged) {
    return 'WarningRectifiedIcon';
  }
  return 'WarningIcon';
});

const shouldBlink = computed(
  () => !props.alert.acknowledged && props.alert.level != AlertLevel.Caution && props.alert.active,
);
const blinkActive = computed(() => !shouldBlink.value || (shouldBlink.value && props.blinkSync));
const time = computed(() =>
  props.alert.timestamp ? formatInTimeZone(props.alert.timestamp, 'UTC', 'HH:mm:ss') : '--:--:--',
);
</script>

<style scoped>
.cell {
  @apply flex h-12 items-center border-b border-background_tile_border border-opacity-50 bg-background_layer_below px-2 text-sm text-text_main;
  &:nth-child(4n + 1) {
    @apply pl-3;
  }
  &:nth-child(4n) {
    @apply pr-3;
  }
}
button {
  @apply h-6 items-center text-sm;
}
.details-popover {
  @apply fixed z-10 ml-6 -mt-6 w-[22.5rem];
}
</style>
