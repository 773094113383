import { computed, reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { persist } from '../utils/persist';

export type App = 'targets' | 'routes' | 'tools' | 'chart' | 'ownShip' | 'alerts' | 'marinersNote';

type State = {
  activeApp?: App;
};

export const appsCtx = defineContext(() => {
  const state: State = persist('appsState.v1', reactive({ activeApp: undefined }));
  const hasActiveApp = computed(() => !!state.activeApp);
  const setActiveApp = (app: App) => {
    state.activeApp = state.activeApp === app ? undefined : app;
  };

  return {
    hasActiveApp,
    setActiveApp,
    state: readonly(state),
  };
});
