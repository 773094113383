<template>
  <div class="panScrollable">
    <div class="scrollbar contents">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.panScrollable {
  flex: 1;
  position: relative;
}
.contents {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
