<template>
  <div class="flex flex-1 flex-col border-t border-background_tile_border">
    <div class="flex flex-1 flex-col overflow-auto">
      <signi-panel-header title="Own ship" />

      <signi-panel class="bg-background_tile_border text-sm text-text_main">
        <signi-panel-setting primary="Own ship" class="px-3 !py-1">
          <div class="flex flex-1 items-center justify-between">
            <div>
              <p class="text-text_main">{{ ownshipInfo?.name }}</p>
              <p class="text-text_static">{{ ownshipInfo?.id }}</p>
            </div>

            <hui-disclosure v-if="isPlanningStation" v-slot="{ close, open }">
              <div class="flex">
                <hui-disclosure-button
                  class="btn btn-xs uppercase"
                  data-test-id="ownShipChange"
                  :class="{ 'btn-selected': open }"
                >
                  Change
                </hui-disclosure-button>

                <signi-modal-transition>
                  <hui-disclosure-panel class="vessel-selector-panel">
                    <ecdis-vessel-selector-panel @close="close" />
                  </hui-disclosure-panel>
                </signi-modal-transition>
              </div>
            </hui-disclosure>

            <hui-disclosure v-if="!isPlanningStation" v-slot="{ close, open }">
              <div class="flex">
                <hui-disclosure-button
                  class="btn btn-flat btn-xs uppercase"
                  data-test-id="ownShipSetting"
                  :class="{ 'btn-selected': open }"
                  title="Settings"
                >
                  <signi-icon name="SettingsIcon" />
                </hui-disclosure-button>

                <signi-modal-transition>
                  <hui-disclosure-panel class="settings-panel">
                    <ecdis-ownship-settings-panel
                      :settings="settings"
                      @close="close"
                      @update:setting="settings[$event.key] = $event.value"
                    />
                  </hui-disclosure-panel>
                </signi-modal-transition>
              </div>
            </hui-disclosure>
          </div>
        </signi-panel-setting>
      </signi-panel>

      <signi-panel-header title="Ship parameters" />

      <signi-panel v-if="ownshipInfo" class="bg-background_tile_border text-sm text-text_main">
        <signi-panel-setting primary="Ship Length" class="!rounded-none px-3">
          <div class="flex-1">{{ ownshipInfo.length }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="Ship Width" class="px-3">
          <div class="flex-1">{{ ownshipInfo.width }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="Max Ship Draught" class="px-3">
          <div class="flex-1">{{ ownshipInfo.draught }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="CCRP from Stern" class="px-3">
          <div class="flex-1">{{ ownshipInfo.ccrpY }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="CCRP stb. of Centreline" class="px-3">
          <div class="flex-1">{{ ownshipInfo.ccrpX }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="Pivot Point from Stern" class="px-3">
          <div class="flex-1">{{ ownshipInfo.pivotPositionXFromStern }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="Pivot Point stb. of Centre" class="px-3">
          <div class="flex-1">{{ ownshipInfo.pivotPositionYFromStern }} <span class="text-text_static">m</span></div>
        </signi-panel-setting>

        <signi-panel-setting primary="Min. Ship Turn Radius" class="px-3">
          <div class="flex-1">
            <signi-kpi-input
              class="cell-center -mx-2 -my-3 block px-2 py-3"
              :decimals="1"
              :formatted-value="ownshipInfo.minTurnRadius"
              :model-value="+ownshipInfo.minTurnRadius"
              size="xxxs"
              unit="NM"
              @update:model-value="updateParam({ id: 'shipMinTurnRadius', value: ($event / METER_IN_NM).toString() })"
            />
          </div>
        </signi-panel-setting>

        <signi-panel-setting primary="Max. Ship Turn Rate" class="px-3">
          <div class="flex-1">
            <signi-kpi-input
              class="cell-center -mx-2 -my-3 block px-2 py-3"
              :decimals="1"
              :formatted-value="ownshipInfo.vesselMaxRateOfTurn"
              :model-value="+ownshipInfo.vesselMaxRateOfTurn"
              size="xxxs"
              unit-preset="degPerMin"
              @update:model-value="updateParam({ id: 'shipMaxTurnRate', value: $event.toString() })"
            />
          </div>
        </signi-panel-setting>

        <signi-panel-setting primary="Turn Acceleration Length" class="!rounded-none px-3">
          <div class="flex-1">
            <signi-kpi-input
              class="cell-center -mx-2 -my-3 block px-2 py-3"
              :decimals="1"
              :formatted-value="ownshipInfo.turnAccelerationLength"
              :max="360"
              :model-value="+ownshipInfo.turnAccelerationLength"
              no-max
              size="xxxs"
              unit="m"
              @update:model-value="updateParam({ id: 'shipTurnAccelerationLength', value: $event.toString() })"
            />
          </div>
        </signi-panel-setting>
      </signi-panel>
    </div>

    <div class="flex w-full justify-center gap-x-2 border-t border-background_tile_border py-3">
      <button class="btn btn-xs uppercase" @click="resetChanges">Reset values</button>
      <button
        v-if="isPlanningStation"
        id="cancelBtn"
        class="btn btn-xs uppercase"
        :disabled="!isViewDirty"
        @click="clearUncommittedChanges"
      >
        Cancel
      </button>
      <button class="btn btn-xs uppercase" :disabled="!isViewDirty" @click="applyChanges">Apply</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { METER_IN_NM, RADIANS_IN_DEGREE, createFormatter, formatDistance, formatRadToDegrees } from '@signi/common';
import { isPlanningStation } from '../../../configuration';
import { ownshipSettingsCtx } from '../../../contexts/ownshipSettings';
import { vesselCtx } from '../../../contexts/vessels';
import { Vessel } from '../../../generated/ProductCatalog';

const {
  state,
  updateParam,
  clearUncommittedChanges,
  matchVesselAndResetIsViewDirty,
  resetParamValues,
  toggleRefetchShipValues,
  setIsViewDirty,
} = useContext(vesselCtx);

const { state: ownshipSettingsState } = useContext(ownshipSettingsCtx);

const oneDecimalDigitFormatter = createFormatter({
  maximumFractionDigits: 1,
  minimumIntegerDigits: 1,
  minimumFractionDigits: 1,
});

const { settings } = toRefs(ownshipSettingsState);

// Mapping from API data model to OwnShipInfo data model
const ownshipInfo = computed(() => {
  if (!state.selectedVesselParams) {
    return undefined;
  }

  const formatValue = (val?: string | number, formatter: (value: number) => string = oneDecimalDigitFormatter.format) =>
    val !== undefined ? formatter(+val) : '-';

  const info = state.selectedVesselParams.reduce<Vessel>((acc, p) => {
    acc[p.id] = p.value;
    return acc;
  }, {});

  // '-' or '0.0' or '123.45'
  const vesselMaxRateOfTurn =
    info.shipMaxTurnRate?.value === undefined || info.shipMaxTurnRate?.value === null
      ? '-'
      : +info.shipMaxTurnRate.value === 0
      ? '0.0'
      : // TODO: is info.shipMaxTurnRate.value really DEG? We have to normalize this
        // For now convert value back to RAD so that we can use formatter
        formatRadToDegrees(+info.shipMaxTurnRate.value / RADIANS_IN_DEGREE);

  return {
    ccrpX: formatValue(info.conningPositionXFromStern?.value),
    ccrpY: formatValue(info.conningPositionYFromStern?.value),
    draught: formatValue(info.shipDraught?.value),
    id: state.selectedVessel?.shipId,
    length: formatValue(info.shipLength?.value),
    minTurnRadius: formatValue(
      info.shipMinTurnRadius?.value ? +info.shipMinTurnRadius.value * METER_IN_NM : undefined,
      formatDistance,
    ),
    name: state.selectedVessel?.shipName,
    pivotPositionXFromStern: formatValue(info.pivotPositionXFromStern?.value),
    pivotPositionYFromStern: formatValue(info.pivotPositionYFromStern?.value),
    turnAccelerationLength: formatValue(info.shipTurnAccelerationLength?.value),
    vesselMaxRateOfTurn,
    width: formatValue(info.shipWidth?.value),
  };
});

const applyChanges = () => {
  if (isPlanningStation.value) {
    matchVesselAndResetIsViewDirty();
  } else {
    setIsViewDirty(false);
  }
};

const resetChanges = () => {
  if (isPlanningStation.value) {
    resetParamValues();
  } else {
    toggleRefetchShipValues();
  }
};

const isViewDirty = computed(() => state.isViewDirty);
</script>

<style>
.vessel-selector-panel {
  @apply fixed z-10 -mt-1;
  margin-left: calc(34px + 1rem);
}
.settings-panel {
  @apply fixed z-10;
  margin-left: calc(5px + 3.5rem);
}
</style>
