<template>
  <div
    class="inline-block p-3"
    :class="{
      'bg-alert_alarm-active': topPriorityAlert?.level === AlertLevel.Alarm,
      'bg-alert_warning-active': topPriorityAlert?.level === AlertLevel.Warning,
      'bg-alert_caution': topPriorityAlert?.level === AlertLevel.Caution,
    }"
  >
    <signi-icon
      :key="alertIcon"
      :class="['text-alert_text', { invisible: !blinkActive }]"
      :name="alertIcon"
      role="img"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, toRef, watchEffect } from 'vue';
import { useActiveAlerts } from '../alerts-helper';
import { Alert, AlertLevel } from '../types';

const props = defineProps<{ alerts: Alert[]; blinkSync: boolean; muted: boolean }>();
const activeAlerts = useActiveAlerts(toRef(props, 'alerts'));
const topPriorityAlert = computed(() => activeAlerts.value && activeAlerts.value[0]);
const soundEvent = new MessageEvent('message', { data: { name: 'playSystemSound' }, origin: window.origin });

const shouldBlink = computed(
  () =>
    topPriorityAlert.value &&
    !topPriorityAlert.value.acknowledged &&
    topPriorityAlert.value.active &&
    topPriorityAlert.value.level != AlertLevel.Caution,
);
const blinkActive = computed(() => !shouldBlink.value || (shouldBlink.value && props.blinkSync));

const alertIcon = computed(() =>
  topPriorityAlert.value?.level === AlertLevel.Caution ? 'CautionIcon' : 'WarningIcon',
);

watchEffect(() => {
  if (!props.muted && activeAlerts.value.length > 0 && blinkActive.value) {
    window.parent.dispatchEvent(soundEvent);
  }
});
</script>
