<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M6.5 25.5V6.5H25.5V25.5H6.5Z" stroke="#A2AAB9" stroke-linejoin="round" />
    <path
      d="M22.5 16C22.5 19.5899 19.5899 22.5 16 22.5C12.4101 22.5 9.5 19.5899 9.5 16C9.5 12.4101 12.4101 9.5 16 9.5C19.5899 9.5 22.5 12.4101 22.5 16Z"
      stroke="#A2AAB9"
      stroke-linejoin="round"
    />
    <path
      d="M19.5 16C19.5 17.933 17.933 19.5 16 19.5C14.067 19.5 12.5 17.933 12.5 16C12.5 14.067 14.067 12.5 16 12.5C17.933 12.5 19.5 14.067 19.5 16Z"
      stroke="#A2AAB9"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 15.5001V9.51904C18.9873 9.70825 21.0832 11.2973 22.0018 13.5001L16.5 15.5001Z"
      fill="#A2AAB9"
      stroke="#A2AAB9"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
