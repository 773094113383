<template>
  <pan-draggable-panel v-if="visible" class="monitoring-panel" :class="{ open }">
    <template #header>
      <div class="header">
        <div class="indicator">
          <div class="title">Monitoring</div>
        </div>
        <div class="info">
          <div class="route-name">{{ monitoredRoute?.name }}</div>
          <div class="route-eta">
            <ecdis-unit>ETA</ecdis-unit> {{ dateTimeFormatter(dateForEtaInfo(lastWaypointEtaInfo)) }} ({{
              distanceFormatter(finalRemainingDistance)
            }}
            <ecdis-unit>NM</ecdis-unit>)
          </div>
        </div>
        <icon-pin v-if="monitoredRoute" class="pin" @click.stop="goToRoute" />
        <icon-arrow class="chevron" :class="{ open }" @click="toggleOpen" />
      </div>
    </template>
    <template #main>
      <div v-show="open">
        <div class="top">
          <div class="column">
            <ecdis-label>DTG</ecdis-label>
            <ecdis-value>
              {{
                distanceFormatter(
                  (isInTurn
                    ? nextWaypointEtaInfo?.DistanceToWaypoint ?? 0
                    : activeWaypointEtaInfo?.DistanceToWaypoint ?? 0) * meterInNm,
                )
              }}<ecdis-unit> NM</ecdis-unit>
            </ecdis-value>
          </div>
          <div class="column">
            <ecdis-label>TTG</ecdis-label>
            <ecdis-value>
              {{ timeFormatter(isInTurn ? nextWaypointEtaInfo?.Time ?? 0 : activeWaypointEtaInfo?.Time ?? 0) }}
            </ecdis-value>
          </div>
          <div class="column">
            <ecdis-label>TRC CRS</ecdis-label>
            <ecdis-value> {{ courseFormatter(activeLeg?.course) }}<ecdis-unit> °</ecdis-unit></ecdis-value>
          </div>
          <div class="column">
            <ecdis-label>PL SPD</ecdis-label>
            <ecdis-value> {{ speedFormatter(activeLeg?.speedPlanned) }}<ecdis-unit> Kn</ecdis-unit></ecdis-value>
          </div>
        </div>
        <template v-if="!isFirstWaypointActive">
          <div class="head">
            <pan-static-text>Current leg to WPT {{ activeWaypoint?.id }}</pan-static-text>
          </div>
          <pan-row class="col-layout">
            <div>
              <pan-static-text>Crosstrack stbd</pan-static-text>
              <ecdis-value>{{ twoDecimalFormatter(stbdXTD) }} <ecdis-unit>m</ecdis-unit></ecdis-value>
            </div>
            <div>
              <pan-static-text>Crosstrack port</pan-static-text>
              <ecdis-value>{{ twoDecimalFormatter(portXTD) }} <ecdis-unit>m</ecdis-unit></ecdis-value>
            </div>
          </pan-row>
          <pan-row class="col-layout">
            <div>
              <pan-static-text>Offtrack stbd</pan-static-text>
              <ecdis-value>{{ twoDecimalFormatter(stbdOfftrack) }} <ecdis-unit>m</ecdis-unit></ecdis-value>
            </div>
            <div>
              <pan-static-text>Offtrack port</pan-static-text>
              <ecdis-value>{{ twoDecimalFormatter(portOfftrack) }} <ecdis-unit>m</ecdis-unit></ecdis-value>
            </div>
          </pan-row>
        </template>

        <template v-if="!isFirstWaypointActive && !isLastWaypointActive">
          <div class="head">
            <pan-static-text>
              {{ isInTurn ? 'In turn on' : 'Next turn on' }} WPT
              {{ activeWaypoint?.id }}
            </pan-static-text>
          </div>
          <pan-row class="col-layout">
            <div>
              <pan-static-text>Radius</pan-static-text>
              <ecdis-value>{{ twoDecimalFormatter(activeWaypoint?.radius) }} <ecdis-unit>NM</ecdis-unit></ecdis-value>
            </div>
            <div v-if="!isInTurn">
              <pan-static-text>BWOP</pan-static-text>
              <ecdis-value>{{ courseFormatter(bearingWOP) }} <ecdis-unit>°</ecdis-unit></ecdis-value>
            </div>
            <template v-else>
              <div>
                <pan-static-text>DEOT</pan-static-text>
                <ecdis-value>{{ distanceFormatter(remainingSegmentLength) }} <ecdis-unit>NM</ecdis-unit></ecdis-value>
              </div>
            </template>
          </pan-row>
          <div class="head">
            <pan-static-text>Next leg to WPT {{ nextWaypoint?.id }}</pan-static-text>
          </div>
          <pan-row class="col-layout">
            <div>
              <pan-static-text>Course</pan-static-text>
              <ecdis-value>{{ courseFormatter(nextLeg?.course) }} <ecdis-unit>°</ecdis-unit></ecdis-value>
            </div>
            <div>
              <pan-static-text>Planned speed</pan-static-text>
              <ecdis-value>{{ speedFormatter(nextLeg?.speedPlanned) }} <ecdis-unit>Kn</ecdis-unit></ecdis-value>
            </div>
          </pan-row>
          <pan-row class="col-layout">
            <div>
              <pan-static-text>DWOL</pan-static-text>
              <ecdis-value>{{ distanceFormatter(distanceWOL) }} <ecdis-unit>NM</ecdis-unit></ecdis-value>
            </div>
            <div>
              <pan-static-text>ETA</pan-static-text>
              <ecdis-value>{{ dateTimeFormatter(dateForEtaInfo(nextWaypointEtaInfo)) }}</ecdis-value>
            </div>
          </pan-row>
        </template>
        <div class="footer">
          <pan-button class="button" @click="stopMonitoring">Stop</pan-button>
        </div>
      </div>
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { monitoringCtx } from '../../contexts/monitoring';
import { routesCtx } from '../../contexts/routes';
import { viewportCtx } from '../../contexts/viewport';
import { useOwnship } from '../../global-state/ownship';
import { EtaInfo } from '../../types';
import { meterInNm, mod2pi, nmInMeter, radInDeg } from '../../utils/conversions';
import {
  courseFormatter,
  dateTimeFormatter,
  distanceFormatter,
  speedFormatter,
  timeFormatter,
  twoDecimalFormatter,
} from '../../utils/formatters';

export default defineComponent({
  setup() {
    const { state: monitoringState, toggleVisibility } = useContext(monitoringCtx);
    const { setMonitorId, monitoredRoute, calculateBounds } = useContext(routesCtx);
    const { state: ownshipState } = useOwnship();
    const { showInViewport } = useContext(viewportCtx);

    const open = ref(true);

    const toggleOpen = () => {
      open.value = !open.value;
    };

    const { visible, sailData } = toRefs(monitoringState);
    const ownship = toRef(ownshipState, 'ownship');

    const waypoints = computed(() => monitoredRoute.value?.waypoints);
    const legs = computed(() => monitoredRoute.value?.legs);

    const activeWaypointId = computed(() => sailData.value?.ActiveWaypointId);
    const nextWaypointId = computed(() => {
      const activeIdx = (waypoints.value ?? []).findIndex((w) => w.waypointId === activeWaypointId.value);
      return activeIdx > -1 ? waypoints.value?.[activeIdx + 1]?.waypointId : undefined;
    });

    const findWaypoint = (waypointId: string | undefined) =>
      waypointId ? waypoints.value?.find((w) => w.waypointId === waypointId) : undefined;

    const findLegToWaypoint = (waypointId: string | undefined) =>
      waypointId ? legs.value?.find((l) => l.toWaypoint === waypointId) : undefined;

    const findEtaForWaypoint = (waypointId: string | undefined) =>
      waypointId ? sailData.value?.EtaList?.find((eta) => eta.WaypointId === waypointId) : undefined;

    const isFirstWaypointActive = computed(() => waypoints.value?.[0]?.waypointId === activeWaypointId.value);
    const isLastWaypointActive = computed(
      () => waypoints.value?.[waypoints.value.length - 1]?.waypointId === activeWaypointId.value,
    );
    const isInTurn = computed(() => sailData.value?.IsInTurn ?? false);

    const activeWaypoint = computed(() => findWaypoint(activeWaypointId.value));
    const activeLeg = computed(() => findLegToWaypoint(activeWaypointId.value));

    const nextWaypoint = computed(() => findWaypoint(nextWaypointId.value));
    const nextLeg = computed(() => findLegToWaypoint(nextWaypointId.value));

    const activeWaypointEtaInfo = computed(() => findEtaForWaypoint(activeWaypointId.value));
    const nextWaypointEtaInfo = computed(() => findEtaForWaypoint(nextWaypointId.value));

    const lastWaypointEtaInfo = computed(() => {
      const etaList = sailData.value?.EtaList;
      return etaList?.[etaList.length - 1];
    });

    // Represents distance to start of turn while on a leg, and end of turn while in a turn
    const remainingSegmentLength = computed(() => (sailData.value?.RemainingSegmentLength ?? 0) * meterInNm);

    const finalRemainingDistance = computed(() => (lastWaypointEtaInfo.value?.DistanceToWaypoint ?? 0) * meterInNm);

    const bearingWOP = computed(() =>
      sailData.value?.BearingToWop ? mod2pi(sailData.value?.BearingToWop) * radInDeg : undefined,
    );
    const distanceWOL = computed(() =>
      sailData.value?.DistanceToWol ? sailData.value?.DistanceToWol * meterInNm : undefined,
    );
    const stbdXTD = computed(() => (activeLeg.value?.starboardXTD ?? 0) * nmInMeter);
    const portXTD = computed(() => (activeLeg.value?.portsideXTD ?? 0) * nmInMeter);

    const crossTrackError = computed(() => sailData.value?.CrossTrackError ?? 0);
    const stbdOfftrack = computed(() => (crossTrackError.value > 0 ? crossTrackError.value : 0));
    const portOfftrack = computed(() => (crossTrackError.value < 0 ? -crossTrackError.value : 0));

    const dateForEtaInfo = (eta?: EtaInfo) => {
      if (ownship.value === undefined || eta === undefined || eta.Time === undefined) return undefined;
      return new Date(ownship.value.time + eta.Time * 1000);
    };

    const goToRoute = () => {
      if (!monitoredRoute.value) return;
      showInViewport(calculateBounds(monitoredRoute.value));
    };

    const stopMonitoring = () => {
      setMonitorId();
      toggleVisibility();
    };

    return {
      isFirstWaypointActive,
      isLastWaypointActive,
      isInTurn,
      activeWaypoint,
      activeLeg,
      nextWaypoint,
      nextLeg,
      activeWaypointEtaInfo,
      nextWaypointEtaInfo,
      lastWaypointEtaInfo,
      remainingSegmentLength,
      finalRemainingDistance,
      visible,
      monitoredRoute,
      bearingWOP,
      distanceWOL,
      stbdXTD,
      portXTD,
      stbdOfftrack,
      portOfftrack,
      open,
      toggleOpen,
      goToRoute,
      stopMonitoring,
      dateTimeFormatter,
      timeFormatter,
      distanceFormatter,
      courseFormatter,
      speedFormatter,
      twoDecimalFormatter,
      dateForEtaInfo,
      meterInNm,
    };
  },
});
</script>

<style scoped>
.monitoring-panel {
  width: 360px;
  background: var(--background_layer_below);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  min-height: 48px;
}

.chevron {
  margin-left: auto;
  will-change: transform;
  transition-property: transform;
  transition-duration: 100ms;
  cursor: pointer;
}

.chevron.open {
  transform: rotate(-180deg);
}

.indicator {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 0 12px;
  background: var(--feedback_indicator_background);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.open .indicator {
  border-bottom-left-radius: 0;
}

.info {
  margin-left: 8px;
  flex: 1;
}

.top {
  display: flex;
  padding: 12px 0;
}

.top ::v-deep(.label) {
  margin-bottom: 6px;
}

.top ::v-deep(.value) {
  font-size: 24px;
  color: var(--feedback_indicator_background);
}

.top ::v-deep(.value .unit) {
  font-size: 12px;
  vertical-align: middle;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.route-name {
  font-size: 14px;
}
.route-eta {
  font-size: 12px;
}

.title {
  font-size: 14px;
  flex: 1;
  text-transform: uppercase;
}

.close {
  cursor: pointer;
}

.col-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.head {
  font-size: 14px;
  padding: 2px 12px;
  background: var(--background_tile);
}

.col-layout > * {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 4px 12px;
}

.footer {
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0 !important;
}

.footer .button {
  width: 104px;
}
</style>
