import Konva from 'konva';
import { throttle } from 'lodash';
import { computed, reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { MeterPoint, PixelDimension, PixelPoint, Rotation, Scaling, Viewport } from '../types';
import { halfMaxMX, lngLatToMeter, meterToLngLat, nmInMeter } from '../utils/conversions';
import { calcScaleConformity, getScalingFactor } from '../utils/mercator';
import { persist } from '../utils/persist';

const minScale = 1000;
export const timing = { throttleMs: 2000 };

export type Bounds = {
  topLeft: MeterPoint;
  bottomRight: MeterPoint;
};

type SetOptions = {
  throttled: boolean;
};

const defaultSetOptions: SetOptions = {
  throttled: true,
};

export const viewportCtx = defineContext(() => {
  const dimension = {
    width: 500,
    height: 500,
  };
  const center = lngLatToMeter({
    lng: 10.509788,
    lat: 59.425664,
  });
  const scaling = {
    scale: 46000000,
    dpm: 3780,
  };
  const rotation = {
    angle: 0,
  };
  const viewport: Viewport = persist(
    'viewport.v2',
    reactive({
      dimension: { ...dimension },
      center: { ...center },
      scaling: { ...scaling },
      rotation: { ...rotation },
      matrix: [], // updated by watcher,
      throttled: {
        dimension: { ...dimension },
        center: { ...center },
        scaling: { ...scaling },
        rotation: { ...rotation },
      },
    }),
    sessionStorage,
  );

  const constrainScale = (scale: number) => {
    const maxScale = (2 * halfMaxMX * viewport.scaling.dpm) / viewport.dimension.width;
    return Math.max(minScale, Math.min(scale, maxScale));
  };

  // store throttled viewport updates every 2s
  const throttledUpdate = throttle(
    ({ dimension, center, scaling, rotation }: Viewport) => {
      viewport.throttled = {
        dimension: { ...dimension },
        center: { ...center },
        scaling: { ...scaling },
        rotation: { ...rotation },
      };
    },
    timing.throttleMs,
    { leading: false },
  );

  const setDimension = (dimension: PixelDimension, { throttled }: SetOptions = defaultSetOptions) => {
    viewport.dimension = dimension;

    if (throttled) {
      throttledUpdate(viewport);
    } else {
      viewport.throttled.dimension = { ...viewport.dimension };
    }
  };

  const setCenter = (center: MeterPoint, { throttled }: SetOptions = defaultSetOptions) => {
    viewport.center = center;

    if (throttled) {
      throttledUpdate(viewport);
    } else {
      viewport.throttled.center = { ...viewport.center };
    }
  };

  const setScaling = (scaling: Scaling, { throttled }: SetOptions = defaultSetOptions) => {
    viewport.scaling = { ...scaling, scale: constrainScale(scaling.scale) };

    if (throttled) {
      throttledUpdate(viewport);
    } else {
      viewport.throttled.scaling = { ...viewport.scaling };
    }
  };

  const setScale = (scale: number, { throttled }: SetOptions = defaultSetOptions) => {
    viewport.scaling.scale = constrainScale(scale);

    if (throttled) {
      throttledUpdate(viewport);
    } else {
      viewport.throttled.scaling.scale = viewport.scaling.scale;
    }
  };

  const setRotation = (rotation: Rotation, { throttled }: SetOptions = defaultSetOptions) => {
    viewport.rotation = rotation;

    if (throttled) {
      throttledUpdate(viewport);
    } else {
      viewport.throttled.rotation = { ...viewport.rotation };
    }
  };

  const setMatrix = (matrix: number[]) => {
    viewport.matrix = matrix;
  };

  const transformToAbsolute = ({ mX, mY }: MeterPoint): PixelPoint => {
    const { x, y } = new Konva.Transform(viewport.matrix).point({ x: mX, y: mY });
    return { x, y };
  };

  const transformToViewport = ({ x, y }: PixelPoint): MeterPoint => {
    const { x: mX, y: mY } = new Konva.Transform(viewport.matrix).invert().point({ x, y });
    return { mX, mY };
  };

  const pxToMeter = (px: number) => (px / viewport.scaling.dpm) * viewport.scaling.scale;

  const meterToPx = (m: number) => (m / viewport.scaling.scale) * viewport.scaling.dpm;

  const viewportMinSize = computed(() => Math.min(viewport.dimension.width, viewport.dimension.height));
  const viewportMaxSize = computed(() => Math.max(viewport.dimension.width, viewport.dimension.height));

  const chartScaleConformity = computed(() => {
    const centerLat = meterToLngLat(viewport.center).lat;
    const corners = [
      { x: 0, y: 0 },
      { x: 0, y: viewport.dimension.height },
      { x: viewport.dimension.width, y: 0 },
      { x: viewport.dimension.width, y: viewport.dimension.height },
    ];
    const latitudes = corners.map((c) => meterToLngLat(transformToViewport(c)).lat);
    return calcScaleConformity(centerLat, latitudes);
  });

  const currentRange = computed(() => {
    const scalingFactor = getScalingFactor(meterToLngLat(viewport.center).lat);
    return pxToMeter(viewportMinSize.value) / (2 * nmInMeter * scalingFactor);
  });

  const selectRange = (range: number) => {
    const scalingFactor = getScalingFactor(meterToLngLat(viewport.center).lat);
    const scale = (2 * scalingFactor * range * nmInMeter * viewport.scaling.dpm) / viewportMinSize.value;
    setScale(scale, { throttled: false });
  };

  const setZoom = (
    zoomIn: boolean,
    point: PixelPoint,
    unitZoomFactor: number,
    isControlModesStateBrowseEnabled: boolean,
  ) => {
    const maxZoomFactor = 1.1;
    if (zoomIn && viewport.scaling.scale > 1000) {
      const adjustedZoomFactor = 1 + Math.min(Math.pow(unitZoomFactor * 0.1, 4), maxZoomFactor - 1);
      if (adjustedZoomFactor < 0.001) return;
      // zoom in
      setScale(viewport.scaling.scale / adjustedZoomFactor);
      // when browse is active, zoom in to pointer position
      if (isControlModesStateBrowseEnabled) {
        centerOnPoint(adjustedZoomFactor - 1, point);
      }
    } else if (!zoomIn && viewport.scaling.scale < 78897689) {
      const adjustedZoomFactor = 1 + Math.min(Math.pow(-unitZoomFactor * 0.1, 4), maxZoomFactor - 1);
      if (adjustedZoomFactor < 0.001) return;
      // when browse is active, zoom out from pointer position
      if (isControlModesStateBrowseEnabled) {
        centerOnPoint(-(adjustedZoomFactor - 1), point);
      }
      // zoom out
      setScale(viewport.scaling.scale * adjustedZoomFactor);
    }
  };

  const centerOnPoint = (displacementFactor: number, point: PixelPoint) => {
    if (point === null) {
      return;
    }

    const eventOffsetMeterPoint = transformToViewport(point);
    const centerViewportMeterPoint = transformToViewport({
      x: viewport.dimension.width / 2,
      y: viewport.dimension.height / 2,
    });
    const delta = {
      mX: centerViewportMeterPoint.mX - eventOffsetMeterPoint.mX,
      mY: centerViewportMeterPoint.mY - eventOffsetMeterPoint.mY,
    };

    // update center position to zoom exactly to hovered point
    setCenter({
      mX: viewport.center.mX - delta.mX * displacementFactor,
      mY: viewport.center.mY - delta.mY * displacementFactor,
    });
  };

  const showInViewport = ({ topLeft, bottomRight }: Bounds, marginFactor = 0.75, shouldScale = true) => {
    // set center
    const centerMX = (topLeft.mX + bottomRight.mX) / 2;
    const centerMY = (topLeft.mY + bottomRight.mY) / 2;
    setCenter({ mX: centerMX, mY: centerMY }, { throttled: false });

    if (shouldScale) {
      // scale to fit
      const widthPx = (bottomRight.mX - topLeft.mX) * viewport.scaling.dpm;
      const heightPx = (bottomRight.mY - topLeft.mY) * viewport.scaling.dpm;
      const widthScale = widthPx / (viewport.dimension.width * marginFactor);
      const heightScale = heightPx / (viewport.dimension.height * marginFactor);
      setScale(Math.max(widthScale, heightScale), { throttled: false });
    }
  };

  return {
    viewport: readonly(viewport),
    setDimension,
    setCenter,
    setScaling,
    setScale,
    setRotation,
    setMatrix,
    pxToMeter,
    meterToPx,
    transformToAbsolute,
    transformToViewport,
    viewportMinSize,
    viewportMaxSize,
    chartScaleConformity,
    currentRange,
    selectRange,
    setZoom,
    centerOnPoint,
    showInViewport,
  };
});
