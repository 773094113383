import { Ref, computed } from 'vue';
import { Alert, AlertLevel } from './types';

const mapLevel = { Caution: 1, Warning: 2, Alarm: 3 };

// An alert belongs to history category only if its Inactive AND its either of type Caution Or is Acknowledged,
const isHistoricalAlert = (alert: Alert) => {
  return (alert.level === AlertLevel.Caution || alert.acknowledged) && !alert.active;
};

export const useActiveAlerts = (alerts: Ref<Alert[]>) =>
  computed(() =>
    alerts.value
      .filter((alert) => !isHistoricalAlert(alert))
      .sort(
        (a, b) =>
          Number(b.active) - Number(a.active) ||
          Number(a.acknowledged) - Number(b.acknowledged) ||
          (mapLevel[b.level] < mapLevel[a.level] ? -1 : mapLevel[b.level] > mapLevel[a.level] ? 1 : 0) ||
          (a.category < b.category ? -1 : a.category > b.category ? 1 : 0) ||
          b.timestamp.localeCompare(a.timestamp),
      ),
  );

export const useHistoricalAlerts = (alerts: Ref<Alert[]>) =>
  computed(() =>
    alerts.value.filter((alert) => isHistoricalAlert(alert)).sort((a, b) => b.timestamp.localeCompare(a.timestamp)),
  );
