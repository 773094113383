import Konva from 'konva';
import { watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../../configuration';
import { appsCtx } from '../../../contexts/apps';
import { viewportCtx } from '../../../contexts/viewport';

const orangeColor = '#fe8d43';

function createNorthArrow() {
  const group = new Konva.Group();
  group.add(
    new Konva.Arrow({
      points: [0, 50, 0, 0],
      pointerWidth: 6,
      offsetY: 25,
      strokeWidth: 2,
      fill: orangeColor,
      stroke: orangeColor,
    }),
  );
  group.add(
    new Konva.Text({
      text: 'N',
      x: -9,
      y: -5,
      fontFamily: 'Lato',
      fontSize: 24,
      fontStyle: 'bold',
      fill: orangeColor,
    }),
  );
  return group;
}

export function addNorthArrow(absoluteLayer: Konva.Layer): void {
  const { viewport } = useContext(viewportCtx);
  const { hasActiveApp } = useContext(appsCtx);

  const northArrow = createNorthArrow();
  absoluteLayer.add(northArrow);

  watchEffect(() => {
    // update arrow rotation
    northArrow.rotation(viewport.rotation.angle);
    northArrow.position({ x: 60, y: isExtension.value && !hasActiveApp.value ? 290 : 90 });
  });
}
