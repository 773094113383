import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { persist } from '../utils/persist';

export type OrientationMode = 'C' | 'H' | 'N';

export type MotionMode = 'TM' | 'RM';

type State = {
  browse: boolean;
  orientationMode: OrientationMode;
  motionMode: MotionMode;
  showTargets: boolean;
};

export const controlModesCtx = defineContext(() => {
  const state: State = persist(
    'controlModesState.v1',
    reactive({
      browse: false,
      orientationMode: 'N',
      motionMode: 'TM',
      showTargets: false,
    }),
  );

  const setShowTargets = (showTargets: boolean) => {
    state.showTargets = showTargets;
  };

  const setBrowse = (browse: boolean) => {
    state.browse = browse;
  };

  const setOrientationMode = (orientationMode: OrientationMode) => {
    state.orientationMode = orientationMode;
  };

  const setMotionMode = (motionMode: MotionMode) => {
    state.motionMode = motionMode;
  };

  return {
    state: readonly(state),
    setBrowse,
    setOrientationMode,
    setMotionMode,
    setShowTargets,
  };
});
