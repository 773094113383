import { DisplayGroup } from '../types';

export const displayGroups: DisplayGroup[] = [
  {
    id: 'standard',
    desc: 'Standard',
    children: [
      { id: 'drying', desc: 'Drying line' },
      {
        id: 'aidstonav',
        desc: 'Buoys, beacons, aids to navigation',
        children: [{ id: 'lights', desc: 'Lights' }],
      },
      { id: 'boundaries_and_limits', desc: 'Boundaries and limits' },
      { id: 'prohibited', desc: 'Prohibited and restricted areas' },
      { id: 'overscale', desc: 'Chart scale boundary' },
      { id: 'cautionary_notes', desc: 'Cautionary notes' },
      { id: 'traffic', desc: "Ships' routing systems and ferry routes" },
      { id: 'archipelagic', desc: 'Archipelagic sea lanes' },
      { id: 'otherstd', desc: 'Miscellaneous (Standard)' },
    ],
  },
  {
    id: 'other',
    desc: 'Other',
    children: [
      { id: 'soundings', desc: 'Spot soundings' },
      { id: 'cables', desc: 'Submarine cables and pipelines' },
      { id: 'obstructions', desc: 'All isolated dangers' },
      { id: 'magnetic_variation', desc: 'Magnetic variation' },
      { id: 'depth_contours', desc: 'Depth contours' },
      { id: 'seabed', desc: 'Seabed' },
      { id: 'currents', desc: 'Tidal' },
      { id: 'other_areas', desc: 'Miscellaneous (Other)' },
    ],
  },
  {
    //TODO: align id with Navtor
    id: 'text',
    desc: 'Text',
    children: [
      { id: 'important_text', desc: 'Important text' },
      { id: 'names', desc: 'Names' },
      { id: 'light_description', desc: 'Light description' },
      { id: 'other_text', desc: 'Other text' },
    ],
  },
];
