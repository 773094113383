import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { SailData } from '../types';

type State = {
  visible: boolean;
  sailData: SailData | undefined;
};

export const monitoringCtx = defineContext(() => {
  const state: State = reactive({
    visible: false,
    sailData: undefined,
  });

  const setSailData = (sailData?: SailData) => (state.sailData = sailData);

  const toggleVisibility = () => (state.visible = !state.visible);
  const show = () => (state.visible = true);
  const hide = () => (state.visible = false);

  return {
    state: readonly(state),
    setSailData,
    toggleVisibility,
    show,
    hide,
  };
});
