import { watchEffect } from 'vue';
import authentication from '../authentication';
import { configuration, isExtension, isPlanningStation } from '../configuration';

const clients: Record<string, unknown> = {};

function isReady(): boolean {
  // bail out when planning station is not logged in yet
  if (isPlanningStation.value && !authentication.state.authenticated) return false;

  // bail out when extension...
  if (isExtension.value) {
    // ... hasn't gotten the localAuth prop yet
    if (configuration.localAuth === undefined) return false;

    // ... is running without simulated auth, but hasn't gotten baseUrl props yet
    if (configuration.localAuth === false && !configuration.baseUrlsUpdated) return false;

    //  ... is running with simulated auth, but hasn't gotten baseUrl props yet or is not yet authenticated
    if (configuration.localAuth === true && (!configuration.baseUrlsUpdated || !authentication.state.authenticated))
      return false;
  }

  return true;
}

export const createClientPromise = <C>(clientName: string, createClient: () => C): Promise<C> =>
  new Promise((resolve) => {
    if (clients[clientName] !== undefined) {
      resolve(clients[clientName] as C);
      return;
    }

    watchEffect(() => {
      if (isReady()) {
        clients[clientName] = createClient();
        resolve(clients[clientName] as C);
      }
    });
  });
