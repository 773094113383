<template>
  <pan-draggable-panel v-if="visible" class="routeValidationPanel" :style="{ height: showDetails ? '700px' : '350px' }">
    <template #header>
      <pan-row class="header">
        <ecdis-value class="title">Validate route</ecdis-value>
        <icon-grab class="grab" />
        <icon-close @pointerdown="closePanel" />
      </pan-row>
    </template>
    <template #main>
      <component :is="activeComponent" />
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { routeValidationCtx } from '../../contexts/routeValidation';
import RouteToBeValidated from './RouteToBeValidated.vue';
import RouteValidationDetails from './RouteValidationDetails.vue';

export default defineComponent({
  components: {
    RouteToBeValidated,
    RouteValidationDetails,
  },
  setup() {
    const { state: routeValidationState, setVisibility, cancelValidation } = useContext(routeValidationCtx);
    const { visible, showDetails } = toRefs(routeValidationState);
    const activeComponent = computed(() => (showDetails.value ? RouteValidationDetails : RouteToBeValidated));

    const closePanel = () => {
      cancelValidation();
      setVisibility(false);
    };

    return {
      visible,
      closePanel,
      showDetails,
      activeComponent,
    };
  },
});
</script>

<style scoped>
.routeValidationPanel {
  width: 360px;
  background: var(--background_tile);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.header,
.field {
  padding: 0 12px;
}

.title {
  flex: 1;
  font-size: 16px;
  text-transform: uppercase;
}
.grab {
  margin-right: 8px;
}
</style>
