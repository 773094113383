import '@signi/common/assets/index.css';
import '@signi/common/assets/palettes.css';
import '@signi/common/../public/palette-dusk.css'; // TODO: support palette switching
import '@signi/ecdis/assets/globalStyles.css';

import { createApp } from 'vue';
import { authentication, globalIntercept, provideContexts } from '@signi/ecdis';
import App from './App.vue';

window.addEventListener('load', async () => {
  await authentication.load();

  globalIntercept(async (_: RequestInfo, headers: Record<string, string>) => {
    const token = await authentication.acquireToken();

    if (token !== undefined) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  });

  if (localStorage.getItem('enableMock')) {
    const { worker } = await import('./mocks/browser');
    worker.start({ onUnhandledRequest: 'bypass' });
  }

  const app = createApp(App);
  provideContexts(app);
  app.mount('#planning-station');
});
