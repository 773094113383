<template>
  <li class="context-menu-item" :class="{ 'bottom-separator': isSeparatorRequired, disabled: disabled }">
    <slot></slot>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isSeparatorRequired: { type: Boolean, default: true },
    disabled: { type: Boolean },
  },
});
</script>

<style scoped>
.context-menu-item {
  display: flex;
  padding: 11px 0px 11px 11px;
  height: 32px;
  align-items: center;
}
.context-menu-item:hover {
  cursor: pointer;
  background-color: var(--button_background-selected);
  color: var(--button_text-selected);
}
.bottom-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.disabled {
  @apply pointer-events-none opacity-60;
}
</style>
