import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';

type State = {
  show: boolean;
};

export const graticuleCtx = defineContext(() => {
  const state: State = reactive({
    show: false,
  });

  const setShow = (show: boolean) => {
    state.show = show;
  };

  return {
    state: readonly(state),
    setShow,
  };
});
