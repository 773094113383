<template>
  <pan-expandable-row :active="isActiveWaypoint" @toggled="onToggle">
    <template #row-content>
      <div v-if="isActiveWaypoint" class="active"></div>
      <div class="wp" :class="{ inactive: !isActiveWaypoint }">
        <div>
          <icon-waypoint />
          <div class="title">
            <ecdis-value class="summary">WPT {{ waypoint.id }}</ecdis-value>
            <pan-static-text class="data-values">{{ waypoint.name }}</pan-static-text>
          </div>
        </div>
        <ecdis-value class="summary data-values">
          {{ courseFormatter(!last ? leg?.course : undefined) }}
          <ecdis-unit>°</ecdis-unit>
        </ecdis-value>
        <ecdis-value class="summary data-values">
          {{ twoDecimalFormatter(!first && !last && waypoint.radius !== undefined ? waypoint.radius : undefined) }}
          <ecdis-unit>{{ radiusUnit }}</ecdis-unit>
        </ecdis-value>
        <ecdis-value class="summary data-values">
          {{ distanceFormatter(!last ? waypoint.legDistance : undefined) }}
          <ecdis-unit>NM</ecdis-unit>
        </ecdis-value>
        <ecdis-value class="summary data-values">
          {{ speedFormatter(!last ? leg?.speedPlanned : undefined) }}
          <ecdis-unit>{{ speedUnit }}</ecdis-unit>
        </ecdis-value>
      </div>
    </template>

    <template #expanded-content>
      <div class="contents" :class="{ disabled }">
        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Name</pan-static-text>
            <pan-inline-editable-text
              class="tab-values"
              :font-size="dataDetailsFontSize"
              :edit-mode="editMode"
              param-key="name"
              :param-value="waypoint.name"
              @changed="updated"
            />
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Radius</pan-static-text>
            <div v-if="first || last" class="tab-values">
              <ecdis-value>
                -
                <ecdis-unit>{{ radiusUnit }}</ecdis-unit>
              </ecdis-value>
            </div>
            <pan-inline-editable-param
              v-if="!first && !last"
              class="tab-values"
              :font-size="dataDetailsFontSize"
              :edit-mode="editMode"
              param-key="radius"
              :param-value="
                toParamValue(
                  twoDecimalFormatter(waypoint.radius !== undefined ? waypoint.radius : undefined),
                  radiusUnit,
                )
              "
              @changed="updated"
            />
          </div>
        </pan-row>

        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Lat</pan-static-text>
            <pan-inline-editable-text
              class="tab-values"
              :edit-mode="editMode"
              :font-size="dataDetailsFontSize"
              param-key="latitude"
              :param-value="toParamValue(latitudeToDecimalMinutesFormatter(waypoint.latitude)).value"
              @changed="updated"
            />
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Long</pan-static-text>
            <pan-inline-editable-text
              class="tab-values"
              :edit-mode="editMode"
              :font-size="dataDetailsFontSize"
              param-key="longitude"
              :param-value="toParamValue(longitudeToDecimalMinutesFormatter(waypoint.longitude)).value"
              @changed="updated"
            />
          </div>
        </pan-row>

        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Course</pan-static-text>
            <div>
              <ecdis-value class="data-values"
                >{{ courseFormatter(!last ? leg?.course : undefined) }}
                <ecdis-unit>°</ecdis-unit>
              </ecdis-value>
            </div>
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Speed</pan-static-text>
            <pan-inline-editable-param
              class="tab-values"
              :edit-mode="editMode"
              :font-size="dataDetailsFontSize"
              param-key="speed"
              :param-value="toParamValue(speedFormatter(!last ? leg?.speedPlanned : undefined), speedUnit)"
              @changed="updated"
            />
          </div>
        </pan-row>

        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Offtrack stbd</pan-static-text>
            <pan-inline-editable-param
              class="tab-values"
              :edit-mode="editMode"
              :font-size="dataDetailsFontSize"
              param-key="offtrackStarboard"
              :param-value="
                toParamValue(
                  twoDecimalFormatter(!last && leg?.starboardXTD !== undefined ? leg?.starboardXTD : undefined),
                  offtrackUnit,
                )
              "
              @changed="updated"
            />
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Offtrack port</pan-static-text>
            <pan-inline-editable-param
              class="tab-values"
              :edit-mode="editMode"
              :font-size="dataDetailsFontSize"
              param-key="offtrackPort"
              :param-value="
                toParamValue(
                  twoDecimalFormatter(!last && leg?.portsideXTD !== undefined ? leg?.portsideXTD : undefined),
                  offtrackUnit,
                )
              "
              @changed="updated"
            />
          </div>
        </pan-row>

        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Leg mode</pan-static-text>
            <div v-if="last" class="tab-values">
              <ecdis-value>-</ecdis-value>
            </div>
            <pan-dropdown v-if="!last" class="tab-values" :disabled="!editMode" :drops-downwards="true">
              <ecdis-value class="data-values">{{ legModeToStringFormatter(leg?.geometryType) }}</ecdis-value>
              <template #content>
                <ecdis-value-list
                  v-slot="{ value }"
                  :values="['Rhumb line', 'Great circle']"
                  :current-value="legModeToStringFormatter(leg?.geometryType)"
                  @selected="setLegMode"
                >
                  <ecdis-value>{{ value }}</ecdis-value>
                </ecdis-value-list>
              </template>
            </pan-dropdown>
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Delay</pan-static-text>
            <pan-inline-editable-param
              class="tab-values"
              :edit-mode="editMode"
              :font-size="dataDetailsFontSize"
              param-key="delay"
              :param-value="toParamValue(timeFormatter(!last ? waypoint.delay : undefined), '')"
              @changed="updated"
            />
          </div>
        </pan-row>

        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Leg dist.</pan-static-text>
            <div>
              <ecdis-value class="tab-values data-values">
                {{ distanceFormatter(!last ? waypoint.legDistance : undefined) }}
                <ecdis-unit>NM</ecdis-unit>
              </ecdis-value>
            </div>
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Travelled time</pan-static-text>
            <div>
              <ecdis-value class="tab-values data-values">{{ timeFormatter(waypoint.travelledTime) }}</ecdis-value>
            </div>
          </div>
        </pan-row>

        <pan-row>
          <div class="tab-container">
            <pan-static-text class="tab-values">Remaining dist.</pan-static-text>
            <div>
              <ecdis-value class="tab-values data-values">
                {{ distanceFormatter(!last ? waypoint.remainingDistance : undefined) }}
                <ecdis-unit>NM</ecdis-unit>
              </ecdis-value>
            </div>
          </div>
          <div class="tab-container">
            <pan-static-text class="tab-values">Travelled dist.</pan-static-text>
            <div>
              <ecdis-value class="data-values tab-values">
                {{ distanceFormatter(waypoint.travelledDistance) }}
                <ecdis-unit>NM</ecdis-unit>
              </ecdis-value>
            </div>
          </div>
        </pan-row>
      </div>
    </template>

    <template #footer="{ open }">
      <pan-row v-if="note || (open && editMode)" class="wp-note-row">
        <icon-note-leg v-if="!open" class="wp-note-leg" />
        <icon-documentation />
        <input
          :readonly="!editMode"
          class="wp-note"
          placeholder="Add a note..."
          :value="note"
          @blur="updateNote(waypoint, $event)"
        />
      </pan-row>
    </template>
  </pan-expandable-row>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { messageCtx } from '../../../contexts/messages';
import { routesCtx } from '../../../contexts/routes';
import { routeSettingsCtx } from '../../../contexts/routeSettings';
import { vesselCtx } from '../../../contexts/vessels';
import { GeometryType, Waypoint } from '../../../generated/RouteManagement';
import { meterInNm, mpsInKnots, nmInMeter, radInDeg } from '../../../utils/conversions';
import {
  courseFormatter,
  distanceFormatter,
  latitudeToDecimalMinutesFormatter,
  legModeToStringFormatter,
  longitudeToDecimalMinutesFormatter,
  speedFormatter,
  timeFormatter,
  toParamValue,
  twoDecimalFormatter,
} from '../../../utils/formatters';
import { WaypointLegPair } from '../../../utils/routeConversions';
import { validateRadius } from '../../../utils/routeUtils';

const dataDetailsFontSize = 14;

export default defineComponent({
  props: {
    panStaticTextrightAlign: { type: Boolean, default: false },
    maxHeight: { type: String, default: '350px' },
    disabled: { type: Boolean, default: false },
    dropsDownwards: { type: Boolean, default: false },
    waypointLegPair: {
      type: Object as PropType<WaypointLegPair>,
      default: (): WaypointLegPair => {
        return { waypoint: {}, leg: undefined };
      },
    },
    first: { type: Boolean, default: false },
    last: { type: Boolean, default: false },
  },
  setup(props) {
    const {
      state,
      updateWaypointAndLeg,
      setNote,
      isActiveWaypoint: isActive,
      setActiveWaypointId,
    } = useContext(routesCtx);
    const { state: routeSettingsState } = useContext(routeSettingsCtx);
    const { getNumericVesselParam } = useContext(vesselCtx);
    const { settings: routeSettings } = toRefs(routeSettingsState);
    const message = useContext(messageCtx);

    const editMode = computed(() => state.editId === state.detailId);
    const waypoint = computed(() => props.waypointLegPair.waypoint);
    const leg = computed(() => props.waypointLegPair.leg);

    const offtrackUnit = computed(
      () => routeSettings.value.generalSettings.find((s) => s.id == 'offtrackLimit')?.value.unit ?? '-',
    );
    const radiusUnit = computed(
      () => routeSettings.value.generalSettings.find((s) => s.id == 'radius')?.value.unit ?? '-',
    );
    const speedUnit = computed(
      () => routeSettings.value.generalSettings.find((s) => s.id == 'plannedSpeed')?.value.unit ?? '-',
    );

    const isActiveWaypoint = computed(
      () => waypoint.value.waypointId !== undefined && isActive(waypoint.value.waypointId),
    );

    const note = computed(() => waypoint.value?.text ?? undefined);

    const updated = (ev: { id: string; value: string }) => {
      const wp = {
        ...(waypoint.value ?? {}),
      };
      const newLeg = {
        ...leg.value,
      };
      let invalidate = true;
      switch (ev.id) {
        case 'name':
          wp.name = ev.value;
          invalidate = false;
          break;
        case 'radius':
          wp.radius =
            validateRadius(+ev.value * nmInMeter, getNumericVesselParam('shipMinTurnRadius'), message) * meterInNm;
          break;
        case 'longitude':
          if (ev.value.match(/^(?:[0-9]{1,2}|1[0-7][0-9]|180)°? [0-5]?[0-9](\.[0-9]{1,3})?'? ([EeWw])$/)) {
            wp.longitude = degMinDecimalStringToDecimalDegreesConverter(ev.value);
          } else {
            wp.longitude = parseFloat(ev.value);
          }
          break;
        case 'latitude':
          if (ev.value.match(/^(?:[0-8]?[0-9]|90)°? [0-5]?[0-9](\.[0-9]{1,3})?'? ([NnSs])$/)) {
            wp.latitude = degMinDecimalStringToDecimalDegreesConverter(ev.value);
          } else {
            wp.latitude = parseFloat(ev.value);
          }
          break;
        case 'speed':
          if (newLeg) newLeg.speedPlanned = parseFloat(ev.value);
          break;
        case 'offtrackStarboard':
          if (newLeg) newLeg.starboardXTD = parseFloat(ev.value);
          break;
        case 'offtrackPort':
          if (newLeg) newLeg.portsideXTD = parseFloat(ev.value);
          break;
        case 'delay':
          wp.delay = parseFloat(ev.value);
          invalidate = false;
          break;
        default:
          break;
      }
      updateWaypointAndLeg({ waypoint: wp, leg: newLeg }, invalidate);
    };

    const degMinDecimalStringToDecimalDegreesConverter = (degMinSecString: string): number => {
      const [deg, min, direction] = degMinSecString.split(' ');
      return directionToSign(direction) * (parseFloat(deg) + parseFloat(min) / 60);
    };

    const directionToSign = (direction: string): number => {
      if (direction.toUpperCase() === 'N' || direction.toUpperCase() === 'E') return 1;
      return -1;
    };

    const setLegMode = (legMode: string) => {
      const newLeg = {
        ...leg.value,
      };
      if (legMode === legModeToStringFormatter(GeometryType.Orthodrome)) {
        newLeg.geometryType = GeometryType.Orthodrome;
      } else {
        newLeg.geometryType = GeometryType.Loxodrome;
      }
      updateWaypointAndLeg({ waypoint: waypoint.value, leg: newLeg });
    };

    const updateNote = (waypoint: Waypoint, event: FocusEvent) => {
      if (!waypoint.waypointId || !event.target) return;
      setNote(waypoint.waypointId, (event.target as HTMLInputElement).value);
    };

    const onToggle = () => {
      // Whenever the waypoint panel is toggled in edit mode, mark the respective waypoint as active.
      if (editMode.value) setActiveWaypointId(waypoint.value.waypointId ?? '');
    };

    return {
      toParamValue,
      courseFormatter,
      distanceFormatter,
      timeFormatter,
      speedFormatter,
      latitudeToDecimalMinutesFormatter,
      longitudeToDecimalMinutesFormatter,
      legModeToStringFormatter,
      twoDecimalFormatter,
      nmInMeter,
      radInDeg,
      mpsInKnots,
      note,
      editMode,
      updateNote,
      waypoint,
      leg,
      updated,
      setLegMode,
      GeometryType,
      offtrackUnit,
      radiusUnit,
      speedUnit,
      dataDetailsFontSize,
      onToggle,
      isActiveWaypoint,
    };
  },
});
</script>

<style scoped>
.data-values {
  font-size: 14px;
  line-height: 14px;
}

.value {
  padding: 0 0 0 8px;
}

.value.summary {
  padding: 0;
  font-size: 14px;
}

.selected {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contents {
  background: rgba(21, 25, 28, 1);
  display: flex;
  flex-direction: column;
  padding-right: 12px;
}

.tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding-left: 12px;
}

.tab-container > * {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.title {
  width: 132px;
}

.wp {
  display: flex;
  flex-direction: row;
}

.wp > * {
  display: flex;
  align-items: center;
}

.wp > :nth-of-type(1) {
  width: 172px;
  padding-left: 4px;
}

.wp > :nth-of-type(2) {
  width: 60px;
}

.wp > :nth-of-type(3) {
  width: 70px;
}

.wp > :nth-of-type(4) {
  width: 75px;
}

.wp > :nth-of-type(5) {
  width: 65px;
}

.wp-note-row {
  padding: 8px;
}

.wp-note {
  width: calc(100% - 32px);
  font-size: 14px;
  line-height: 14px;
  padding: 0 8px;
  border: 0 solid transparent;
  color: rgba(246, 246, 246, 1);
  background: rgba(21, 25, 28, 1);
  user-select: text;
}

.wp-note:active,
.wp-note:focus {
  outline: 0 solid transparent;
  border: 0 solid transparent;
}

.wp-note:empty:before {
  content: attr(placeholder);
}

.wp-note-leg {
  margin: -8px 4px 0 8px;
}

.tab-values {
  flex: 1;
}

.inactive {
  padding-left: 4px;
}

.active {
  width: 4px;
  height: 48px;
  background: var(--button_text-selected);
}
</style>
