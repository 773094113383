<template>
  <button
    v-for="value in values"
    :key="String(value)"
    :tabindex="1"
    class="listitem"
    :class="{ selected: currentValue === value }"
    @click="$emit('selected', value)"
  >
    <slot :value="value" />
  </button>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    values: {
      type: Array as PropType<(string | number | boolean)[]>,
      default: () => [],
    },
    currentValue: {
      type: [String, Number, Boolean],
      default: undefined,
    },
  },
  emits: ['selected'],
});
</script>

<style scoped>
.listitem {
  padding: 10px 8px;
  height: 36px;
  flex-shrink: 0;
  border-bottom: 1px solid black;
  color: var(--text_main);
  outline: none;
  cursor: pointer;
}
.listitem.selected {
  background: black;
}
.listitem.selected,
.listitem.selected ::v-deep(*) {
  color: var(--highlight);
}
</style>
