import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { getChartServerClient } from '../api-clients/getChartServerClient';
import { RouteValidationResult } from '../generated/ChartServer';
import { Route } from '../generated/RouteManagement';
import { toValidationRoute } from '../utils/routeConversions';

type State = {
  visible: boolean;
  showDetails: boolean;
  isValidating: boolean;
  validationResults?: RouteValidationResult[];
  activeValidationResultIdx?: number;
  highlightedFeatureId?: string;
  selectedFeatureId?: string;
  selectedShipName?: string;
  selectedShipCode?: string;
};

enum ValidationMethod {
  Unattended,
}

export const routeValidationCtx = defineContext(() => {
  const state: State = reactive({
    visible: false,
    showDetails: false,
    isValidating: false,
  });
  let controller: AbortController;

  const setVisibility = (visible: boolean) => {
    state.visible = visible;
  };
  const startValidation = async (
    route: Route,
    safetyContour: number,
    vesselName?: string | undefined,
    vesselCode?: string | undefined,
    validationMethod: ValidationMethod = ValidationMethod.Unattended,
  ) => {
    const chartServerClient = await getChartServerClient();

    state.isValidating = true;
    state.selectedShipName = vesselName ? vesselName : '';
    state.selectedShipCode = vesselCode ? vesselCode : '';
    state.showDetails = true;
    state.validationResults = [];
    state.activeValidationResultIdx = undefined;
    controller = new AbortController();

    if (validationMethod === ValidationMethod.Unattended) {
      // go over route per waypoint pair
      let startWaypoint = 0;
      const validationRoute = toValidationRoute(route, safetyContour);
      if (!validationRoute || !validationRoute.waypoints || validationRoute.waypoints.length < 2) return;

      do {
        const validationResult = await chartServerClient.validate(
          {
            route: validationRoute,
            startWaypoint,
            endWaypoint: startWaypoint + 1,
          },
          controller.signal,
        );
        state.validationResults.push(validationResult);
        state.activeValidationResultIdx = state.validationResults.length - 1;
        startWaypoint++;
      } while (startWaypoint < validationRoute.waypoints.length - 1 && !controller.signal.aborted);
    }
    state.isValidating = false;
  };

  const cancelValidation = () => {
    controller?.abort();
    state.isValidating = false;
    state.showDetails = false;
    state.activeValidationResultIdx = undefined;
  };

  const setActiveIdx = (idx: number) => {
    state.activeValidationResultIdx = Math.max(Math.min(idx, (state.validationResults?.length ?? 1) - 1), 0);
  };

  let highlightTimeoutHandler: number;
  const setHighlightedFeatureId = (featureId: string) => {
    state.highlightedFeatureId = featureId;

    window.clearTimeout(highlightTimeoutHandler);
    highlightTimeoutHandler = window.setTimeout(() => {
      state.highlightedFeatureId = undefined;
    }, 2000);
  };

  const setSelectedFeatureId = (featureId: string) => {
    state.selectedFeatureId = featureId;
  };

  return {
    state: readonly(state),
    setVisibility,
    startValidation,
    cancelValidation,
    setActiveIdx,
    setHighlightedFeatureId,
    setSelectedFeatureId,
  };
});
