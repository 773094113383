import { computed, reactive, readonly, ref } from 'vue';
import { configuration } from './configuration';
import { ApiClient, Company } from './generated/Api';
import { fetch } from './utils/http';
import { persist } from './utils/persist';

type State = {
  id?: string;
  name?: string | null;
  companies: Company[];
  company: CompanyInfo;
  sessionId?: string;
};

type Settings = {
  selectedCompany?: string;
};

type CompanyInfo = {
  id: string;
  name: string;
  valid: boolean;
  features: string[];
  customerSimulatorId: string;
};

const noCompany: CompanyInfo = {
  id: '00000000-0000-0000-0000-000000000000',
  name: '',
  valid: false,
  features: [],
  customerSimulatorId: '',
};

const apiClient = new ApiClient(configuration.baseUrls.api, {
  fetch,
});

const state: State = reactive({
  companies: [],
  company: noCompany,
  features: [],
});

const settings: Settings = persist('userSettingsState.v1', reactive({}));

const load = async (): Promise<void> => {
  const data = await apiClient.me();
  if (data) {
    state.id = data.id;
    state.name = data.name;
    state.sessionId = data.sessionId;

    if (data.companies) {
      state.companies = data.companies;
    }

    if (data.companies && data.companies.length > 0 && data.companies[0].id) {
      setCompany(settings.selectedCompany ?? data.companies[0].id);
    }
  }
};

const setCompany = (companyId: string): void => {
  const company = state.companies.find((company) => company.id === companyId);
  settings.selectedCompany = company?.id;

  state.company = {
    id: company?.id ?? noCompany.id,
    name: company?.name ?? noCompany.name,
    valid: company?.id !== undefined,
    features: company?.features ?? [],
    customerSimulatorId: company?.customerSimulatorId ?? noCompany.customerSimulatorId,
  };
};

const userError = ref<string | undefined>(undefined);
const setUserError = (errorMessage: string): void => {
  userError.value = errorMessage;
};

const resetSession = (): void => {
  state.sessionId = undefined;
};

export default {
  state: readonly(state),
  load: load,
  isAuthorized: computed(() => state.companies.some((company) => company.id === settings.selectedCompany)),
  setCompany,
  errorMessage: computed(() => userError),
  setUserError,
  resetSession,
};
