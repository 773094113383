<template>
  <div class="row">
    <slot />
  </div>
</template>

<style scoped>
.row {
  border-bottom: 1px solid #000000;
  min-height: 48px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
