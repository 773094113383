<template>
  <div class="topBar">
    <ecdis-kongsberg-logo class="logo" />
    <ecdis-planning-station-header />

    <div class="spacer" />
    <ecdis-user-profile class="profile" />
  </div>
</template>

<style scoped>
.topBar {
  background-color: #292b2e;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
}

.logo {
  margin-left: 8px;
  min-width: 24px;
}

.profile {
  margin-right: 16px;
}

.spacer {
  flex: 1;
}
</style>
