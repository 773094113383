import { MarinersCollection } from '../contexts/marinersNotes';
import { NoteType } from '../generated/Navigation';
/* eslint-disable import/no-unresolved */
import cautionNoteUrl from '../global/assets/cautionNote.svg?url';
import eventNoteUrl from '../global/assets/eventNote.svg?url';
import infoNoteUrl from '../global/assets/infoNote.svg?url';
/* eslint-enable import/no-unresolved */

export const getImagePath = (type: NoteType): string => {
  let path = '';
  switch (type) {
    case NoteType.Information:
      path = infoNoteUrl;
      break;
    case NoteType.Caution:
      path = cautionNoteUrl;
      break;
    case NoteType.Event:
      path = eventNoteUrl;
      break;
  }

  return path;
};

export const showMarinersNote = (noteType: NoteType, isPlanningStation: boolean): boolean => {
  if (noteType === NoteType.Event) {
    return !isPlanningStation;
  }
  return true;
};

export const MarinerNoteIcons = {
  [NoteType.Caution]: 'CautionOutlineIcon',
  [NoteType.Information]: 'InfoSquareIcon',
  [NoteType.DashedLine]: 'DashedLineIcon',
  [NoteType.SolidLine]: 'SolidLineIcon',
  [NoteType.SafetyContour]: 'SafetyContourIcon',
  [NoteType.UnfilledArea]: 'UnfilledAreaIcon',
  [NoteType.FilledArea]: 'FilledAreaIcon',
  [NoteType.Event]: 'EventIcon',
};

export const MarinerNoteHeadings = {
  [NoteType.Caution]: 'Caution',
  [NoteType.Information]: 'Information',
  [NoteType.DashedLine]: 'Dashed line',
  [NoteType.SolidLine]: 'Solid line',
  [NoteType.SafetyContour]: 'Saftey contour',
  [NoteType.UnfilledArea]: 'Unfilled area',
  [NoteType.FilledArea]: 'Filled area',
  [NoteType.Event]: 'Event',
};

export const pointTypeNotes = [NoteType.Event, NoteType.Caution, NoteType.Information];
export const lineTypeNotes = [
  NoteType.DashedLine,
  NoteType.SolidLine,
  NoteType.SafetyContour,
  NoteType.UnfilledArea,
  NoteType.FilledArea,
];

export const getCompatibleNoteConversionTypes = (
  noteType: NoteType | undefined,
  isPlanningStation: boolean,
): NoteType[] => {
  if (!noteType) return [];
  if (pointTypeNotes.includes(noteType)) {
    if (isPlanningStation) {
      return pointTypeNotes.filter((item) => item !== NoteType.Event);
    }
    return pointTypeNotes;
  } else if (lineTypeNotes.includes(noteType)) {
    return lineTypeNotes;
  }
  return [];
};

export const allowMarinersNoteEdit = (
  collection: MarinersCollection,
  notesLoading: boolean,
  activeCollectionId: string | undefined,
  activeMarinersNoteId: string | undefined,
) => {
  const noteUnderUpdate = collection.notes.find((item) => !item.updatedBy);
  if (!notesLoading && (noteUnderUpdate || (collection.id === activeCollectionId && activeMarinersNoteId))) {
    return true;
  }
  return false;
};
