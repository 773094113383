import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { TargetData } from '../types';
import { persist } from '../utils/persist';
import { isTargetAisOrAisCombined, isTargetRadarOrRadarCombined } from '../utils/targetUtils';

type State = {
  targets: { [key: string]: TargetData };
  showRadarTargets: boolean;
  selectedTargetId: number;
  expandedTargets: Array<number>;
  forceAwakeTargets: Array<number>;
};

export const targetDataCtx = defineContext(() => {
  const state: State = persist(
    'targetData.v1',
    reactive({
      targets: {},
      selectedTargetId: -1,
      showRadarTargets: true,
      targetSelectionModeInChart: false,
      expandedTargets: [],
      forceAwakeTargets: [],
    }),
  );

  const updateTargetData = (targets: { [key: string]: TargetData }) => {
    state.targets = {};
    for (const target of Object.values(targets)) {
      if (state.forceAwakeTargets.find((item: number) => item === target.Number)) {
        target.ForceAwake = true;
      }
      state.targets[target.Number] = target;
    }
  };

  const getTargetsCountBySource = (sourceType: string) => {
    let count = 0;
    if (sourceType == 'showAisTargets') {
      for (const target of getTargetData()) {
        if (isTargetAisOrAisCombined(target.Origin) || target.Origin === 'RadarCombined') {
          count++;
        }
      }
    } else if (sourceType == 'showRadarTargets') {
      for (const target of getTargetData()) {
        if (isTargetRadarOrRadarCombined(target.Origin) || target.Origin === 'AisCombined') {
          count++;
        }
      }
    }
    return count;
  };

  const getTargetData = () => {
    return Object.values(state.targets);
  };

  // Todo: Add a switch to use this
  const toggleRadarTargets = () => {
    state.showRadarTargets = !state.showRadarTargets;
  };

  const setSelectedTarget = (targetNumber: number) => {
    state.selectedTargetId = state.selectedTargetId === targetNumber ? -1 : targetNumber;
  };

  const isTargetSelected = (targetNumber: number): boolean => {
    return state.selectedTargetId === targetNumber;
  };

  const updateExpanded = (targetNumber: number) => {
    const found = state.expandedTargets.find((item: number) => item === targetNumber);
    if (found) {
      state.expandedTargets = state.expandedTargets.filter((item) => item !== targetNumber);
    } else {
      state.expandedTargets.push(targetNumber);
    }
  };

  const activateSleepingTarget = (targetNumber: number) => {
    const found = state.forceAwakeTargets.find((item: number) => item === targetNumber);
    if (found) {
      state.forceAwakeTargets = state.forceAwakeTargets.filter((item) => item !== targetNumber);
    } else {
      state.forceAwakeTargets.push(targetNumber);
    }
  };

  const clearactivateSleepingTarget = () => {
    state.forceAwakeTargets = [];
  };

  const isExpanded = (targetNumber: number) => {
    return state.expandedTargets.find((item: number) => item === targetNumber);
  };
  return {
    state: readonly(state),
    updateTargetData,
    getTargetsCountBySource,
    toggleRadarTargets,
    getTargetData,
    setSelectedTarget,
    isTargetSelected,
    updateExpanded,
    activateSleepingTarget,
    clearactivateSleepingTarget,
    isExpanded,
  };
});
