<template>
  <div class="flex">
    <div class="h-[12.5rem] w-1 bg-button_text-selected"></div>
    <div class="flex w-full flex-col p-2">
      <div>
        <pan-row v-if="marinersNote.type" class="borderNone flex flex-col">
          <signi-icon :name="MarinerNoteIcons[marinersNote.type]" class="mariners-note-selected" />
          <signi-select
            class="h-12 w-64 flex-none rounded-sm bg-button_text-disabled"
            :options="compatibleNoteTypes"
            :model-value="MarinerNoteHeadings[marinersNote?.type]"
            @update:model-value="emit('update:type', $event)"
          >
          </signi-select>
          <div class="flex-end flex grow justify-end">
            <button title="Delete Note" @click="emit('delete')">
              <signi-icon name="DeleteIcon"></signi-icon>
            </button>
            <button title="GoTo Note" @click="emit('goToPin', marinersNote.points[0])">
              <signi-icon name="TargetpinIcon"></signi-icon>
            </button>
          </div>
        </pan-row>
      </div>
      <pan-row v-if="marinersNote?.id" class="description borderNone">
        <textarea
          id="notesDescription"
          v-model="desc"
          placeholder="Description..."
          class="descriptionText"
          @blur="emit('update:description', desc)"
        ></textarea>
      </pan-row>
      <pan-row class="borderNone">
        <div v-show="showPositionInfo" class="latLng">
          <pan-static-text class="position">{{ latitude + ' N' }}</pan-static-text>
          <pan-static-text class="position">{{ longitude + ' E' }}</pan-static-text>
        </div>
        <div v-show="false" class="dateTime">
          <pan-static-text class="staticText">UTC: {{ time }}</pan-static-text>
          <pan-static-text class="staticText">{{ date }}</pan-static-text>
        </div>
      </pan-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatInTimeZone } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';
import { computed, ref } from 'vue';
import { formatPositionDegree } from '../../../../../lib/utils/formatters';
import { isPlanningStation } from '../../../../configuration';
import {
  MarinerNoteHeadings,
  MarinerNoteIcons,
  getCompatibleNoteConversionTypes,
  pointTypeNotes,
} from '../../../../utils/marinersNotesUtils';
import { MarinersNote } from './../../../../contexts/marinersNotes';

const props = defineProps<{ marinersNote: MarinersNote }>();
const emit = defineEmits(['goToPin', 'update:note', 'update:type', 'update:description', 'delete']);

const desc = ref(props.marinersNote.description);
const date = computed(() =>
  props.marinersNote?.updatedAt
    ? formatInTimeZone(props.marinersNote?.updatedAt, 'UTC', 'dd MMM. yyyy', { locale: enGB })
    : '--/--/----',
);
const time = computed(() =>
  props.marinersNote?.updatedAt
    ? formatInTimeZone(props.marinersNote?.updatedAt, 'UTC', 'HH:mm:ss', { locale: enGB })
    : '--:--:--',
);

const compatibleNoteTypes = computed(() =>
  getCompatibleNoteConversionTypes(props.marinersNote?.type, isPlanningStation.value).map((item) => ({
    label: MarinerNoteHeadings[item],
    id: item,
  })),
);

const showPositionInfo = ref(pointTypeNotes.includes(props.marinersNote?.type));

const latitude = computed(() =>
  props.marinersNote?.points[0].lat ? formatPositionDegree(props.marinersNote.points[0].lat) : '-',
);

const longitude = computed(() =>
  props.marinersNote?.points[0].lng ? formatPositionDegree(props.marinersNote.points[0].lng) : '-',
);
</script>

<style scoped>
.iconsGroup {
  @apply w-7;
}

.icons {
  @apply h-7 w-7;
}

.dropdown {
  @apply ml-2 w-48 bg-button_background-default p-1;
}

.icon-row {
  @apply ml-auto mr-4;
}

.description {
  @apply ml-8 mt-2 h-20 w-11/12 bg-button_background-selected pt-2 pb-2 pl-6 pr-6 text-pano_assessment_warning;
}

.staticText {
  @apply w-24;
}

.actions {
  @apply ml-52 flex;
}

.borderNone {
  @apply border-none !important;
}

.dateTime {
  @apply flex w-full justify-end;
}

.latLng {
  @apply flex w-full justify-start pl-8;
}
.position {
  @apply pr-3;
}

.descriptionText {
  @apply h-full w-full resize-none border-none bg-button_background-selected text-base text-text_static outline-none;
}

.mariners-note-selected {
  background-color: rgba(var(--mariners_notes_selected-rgb), 0.5);
  color: rgba(var(--mariners_notes_selected-rgb), 1);
  @apply m-1 rounded-sm;
}
</style>
