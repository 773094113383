<template>
  <svg viewBox="0 0 32 33" fill="none">
    <rect width="32" height="32" transform="translate(0 0.5)" fill="#1C1F24" />
    <path d="M10.375 16.125H21.625" stroke="#F6F6F6" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
