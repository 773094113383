import { ToolTipData } from '../contexts/toolTip';
import { DangerState, TargetData } from '../types';
import { meterInNm, mpsInKnots, radInDeg } from './conversions';
import {
  formatPositionDegree,
  formatSecToHHMMSS,
  formatUnitLat,
  formatUnitLng,
  twoDecimalFormatter,
} from './formatters';

export const getTargetLabel = (targetData: TargetData): string => {
  return targetData.Name && targetData.Name !== targetData.MMSI
    ? targetData.Name.length <= 25
      ? targetData.Name
      : targetData.Name.substr(0, 22) + '...'
    : 'T' + targetData.Number.toString().padStart(3, '0');
};

export const isTargetAisOrAisCombined = (targetOrigin: string) => ['AisCombined', 'Ais'].includes(targetOrigin);

export const isTargetRadarOrRadarCombined = (targetOrigin: string) => ['RadarCombined', 'Radar'].includes(targetOrigin);

export const getTargetToolTipData = (targetData: TargetData): ToolTipData[] => {
  if (isTargetAisOrAisCombined(targetData.Origin)) {
    return [
      {
        title: getTargetLabel(targetData),
        value: !targetData.IsSart ? targetData.NavigationalStatus : '',
        isHeader: true,
      },
      {
        title: 'MMSI',
        value: targetData.MMSI,
        isHeader: true,
      },
      {
        title: 'Lat',
        value: formatPositionDegree(targetData.Latitude * radInDeg, false),
        unit: formatUnitLat(targetData.Latitude),
      },
      {
        title: 'Long',
        value: formatPositionDegree(targetData.Longitude * radInDeg, true),
        unit: formatUnitLng(targetData.Longitude),
      },
      {
        title: 'CPA',
        value: twoDecimalFormatter((targetData.Cpa ?? 0) * meterInNm),
        unit: 'NM',
      },
      {
        title: 'TCPA',
        value: formatSecToHHMMSS(targetData.TCpa),
      },
      {
        title: 'RNG',
        value: twoDecimalFormatter((targetData.Range ?? 0) * meterInNm),
        unit: 'NM',
      },
      {
        title: 'BRG',
        value: twoDecimalFormatter((targetData.Bearing ?? 0) * radInDeg),
        unit: '°',
      },
      {
        title: 'COG',
        value: twoDecimalFormatter(targetData.Course * radInDeg),
        unit: '°',
      },
      {
        title: 'SOG',
        value: twoDecimalFormatter(targetData.Speed * mpsInKnots),
        unit: 'kn',
      },
      {
        title: 'Heading',
        value: twoDecimalFormatter(
          targetData.Heading * radInDeg < 0
            ? 360 - Math.abs(targetData.Heading * radInDeg)
            : targetData.Heading * radInDeg,
        ),
        unit: '°',
      },
      {
        title: 'ROT',
        value: twoDecimalFormatter(targetData.RateOfTurn * 60 * radInDeg),
        unit: '°/min',
      },
    ];
  } else {
    return [
      {
        title: 'ID: ' + String(targetData.Number).padStart(3, '0'),
        value: '',
        isHeader: true,
      },
      {
        title: '',
        value: '',
        isHeader: true,
      },
      {
        title: 'Lat',
        value: formatPositionDegree(targetData.Latitude * radInDeg, false),
        unit: formatUnitLat(targetData.Latitude),
      },
      {
        title: 'Long',
        value: formatPositionDegree(targetData.Longitude * radInDeg, true),
        unit: formatUnitLng(targetData.Longitude),
      },
      {
        title: 'CPA',
        value: twoDecimalFormatter((targetData.Cpa ?? 0) * meterInNm),
        unit: 'NM',
      },
      {
        title: 'TCPA',
        value: formatSecToHHMMSS(targetData.TCpa),
      },
      {
        title: 'RNG',
        value: twoDecimalFormatter((targetData.Range ?? 0) * meterInNm),
        unit: 'NM',
      },
      {
        title: 'BRG',
        value: twoDecimalFormatter((targetData.Bearing ?? 0) * radInDeg),
        unit: '°',
      },
      {
        title: 'COG',
        value: twoDecimalFormatter(targetData.Course * radInDeg),
        unit: '°',
      },
      {
        title: 'SOG',
        value: twoDecimalFormatter(targetData.Speed * mpsInKnots),
        unit: 'kn',
      },
    ];
  }
};

export function targetConfiguration(
  tcpaLimitInMinutes: number,
  cpaLimitInNauticalMiles: number,
  combineTargetDistanceThreshold: number,
  combineTargetCourseThreshold: number,
  combineTargetSpeedThreshold: number,
  combinedTargetsPreferAis: boolean,
  targetAssociationOn: boolean,
  showAisTargets: boolean,
  showRadarTargets: boolean,
  showDangerousTargets: boolean,
  aisFilteringOn: boolean,
  aisCpaTcpaFilterOn: boolean,
  aisRangeFilterOn: boolean,
  aisRangeFilterValue: number,
  lostTargetAlertsEnabled: boolean,
): string {
  const targetConfigData = {
    tcpaLimitInMinutes: tcpaLimitInMinutes,
    cpaLimitInNauticalMiles: cpaLimitInNauticalMiles,
    showAisTargets: showAisTargets,
    showRadarTargets: showRadarTargets,
    showDangerousTargets: showDangerousTargets,
    lostTargetAlertsEnabled: lostTargetAlertsEnabled,
    targetAssociation: {
      targetAssociationOn: targetAssociationOn,
      combinedTargetsPreferAis: combinedTargetsPreferAis,
      combineTargetCourseThreshold: combineTargetCourseThreshold,
      combineTargetDistanceThreshold: combineTargetDistanceThreshold,
      combineTargetSpeedThreshold: combineTargetSpeedThreshold,
    },
    aisFiltering: {
      aisFilteringOn: aisFilteringOn,
      aisCpaTcpaFilterOn: aisCpaTcpaFilterOn,
      aisRangeFilterOn: aisRangeFilterOn,
      aisRangeFilterValue: aisRangeFilterValue,
    },
  };
  return JSON.stringify(targetConfigData);
}

export function checkAndFilterAisTargets(targetData: TargetData[], shouldShowSleepingTargets: boolean): TargetData[] {
  if (!shouldShowSleepingTargets) {
    return targetData.filter((target: TargetData) => !target.Sleeping || (target.Sleeping && target.ForceAwake));
  }
  return targetData;
}

export function shouldAllowActivateDeactiveSleepingTarget(
  targetData: TargetData | undefined,
  checkForActivation: boolean,
): boolean {
  if (targetData === undefined) return false;
  if (checkForActivation) {
    return (
      targetData.DangerState !== DangerState.Alarm &&
      (targetData?.Sleeping ?? false) &&
      (!targetData?.ForceAwake ?? false)
    );
  }
  return (
    targetData.DangerState !== DangerState.Alarm && (targetData?.Sleeping ?? false) && (targetData?.ForceAwake ?? false)
  );
}
