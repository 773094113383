<template>
  <pan-draggable-panel v-if="isPanelVisible()" class="criticalPointsPanel">
    <template #header>
      <pan-row class="header">
        <ecdis-value id="title" class="title">CRITICAL POINTS</ecdis-value>
        <icon-close
          class="close pointer"
          @pointerdown="
            cancel();
            hidePanel();
          "
        />
      </pan-row>
    </template>
    <template #main>
      <pan-row class="header">
        <ecdis-value class="title">ROUTE NAME</ecdis-value>
        <ecdis-value class="name">{{ getRouteName() }}</ecdis-value>
      </pan-row>

      <div :class="{ 'scrollbar max-h-80 overflow-x-hidden overflow-y-scroll': criticalPoints.length > 1 }">
        <div v-for="[key, data] in criticalPointsMap" :key="key">
          <div v-if="data?.length > 0" class="mini-header">
            <span class="textColor">WPT {{ getWaypointIndex(waypoints, key) }}</span>
          </div>
          <template v-for="(criticalPoint, index) in data" :key="index">
            <ecdis-critical-points-list
              :critical-point="criticalPoint"
              :way-point-id="key"
              :way-point-index="getWaypointIndex(waypoints, key)"
              :critical-points-visible="criticalPointsVisible"
              :is-critical-point-editable="isCriticalPointEditable(criticalPoint.criticalPointId)"
              :is-panel-in-readonly-view="isPanelInReadonlyView()"
              :show-menu="showMenu"
              @updated="updated"
              @modify="modify"
              @gottocriticalpoint="goToPin(criticalPoint)"
            />
          </template>
        </div>
      </div>
      <pan-row v-if="!isPanelInReadonlyView() && criticalPoints.length > 0" class="row">
        <div class="panel-container">
          <template v-if="!(allCriticalPointPersisted(criticalPoints) && !isPanelInEditMode())">
            <pan-button id="cancelBtn" class="btn" @click="cancel"> Cancel </pan-button>
            <pan-button id="saveBtn" class="btn primary" :disabled="disableSave" @click="saveCriticalPoint">
              Save
            </pan-button>
          </template>
        </div>
      </pan-row>
      <pan-row v-if="criticalPoints.length === 0">
        <pan-static-text class="panel-container">No critical points added yet</pan-static-text>
      </pan-row>
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../configuration';
import { controlModesCtx } from '../../contexts/controlModes';
import { criticalPointsSettingCtx } from '../../contexts/criticalPointsSetting';
import { routesCtx } from '../../contexts/routes';
import { routeSettingsCtx } from '../../contexts/routeSettings';
import { viewportCtx } from '../../contexts/viewport';
import { CriticalPoint } from '../../generated/ChartServer';
import { LngLatPoint } from '../../types';
import {
  allCriticalPointPersisted,
  allowCriticalPointsSave,
  getCriticalPointPositionOnTrack,
  getCriticalPointsForRoute,
  getStructuredMapDataForCriticalPointsPanel,
  getWaypointIndex,
  updateCriticalPointMapData,
} from '../../utils/criticalPointUtils';
import { toParamValue } from '../../utils/formatters';
import { calculateBounds } from '../../utils/tools';

export default defineComponent({
  setup() {
    const {
      clearCriticalPointsInEditMode,
      hidePanel,
      isPanelVisible,
      isCriticalPointEditable,
      setCriticalPointEditable,
      getRouteId,
      getRouteName,
      isPanelInEditMode,
      isPanelInReadonlyView,
    } = useContext(criticalPointsSettingCtx);
    const { getDisplaySettingsValue, getMonitorSettingsValue } = useContext(routeSettingsCtx);
    const { findRouteById, clearUnpersistedCriticalPoints, setCriticalPoints, removeCriticalPoint } =
      useContext(routesCtx);
    const { showInViewport } = useContext(viewportCtx);
    const { setBrowse } = useContext(controlModesCtx);
    const addCriticalPointFromPanel = ref(false);
    const showMenu = ref(true);
    const isExpandable = ref(false);
    const route = computed(() => findRouteById(getRouteId()));
    const waypoints = computed(() => route.value?.waypoints);
    const criticalPointsMap = ref();
    const criticalPoints = computed(() => getCriticalPointsForRoute(route.value));
    const disableSave = computed(() => !allowCriticalPointsSave(criticalPointsMap.value, waypoints.value));
    const criticalPointsVisible = computed(
      () => getDisplaySettingsValue('criticalPoints') || getMonitorSettingsValue('criticalPoints'),
    );
    watch(
      () => criticalPoints.value,
      (criticalPointsVal) => {
        if (criticalPointsVal && waypoints.value) {
          criticalPointsMap.value = getStructuredMapDataForCriticalPointsPanel(criticalPoints.value);
        }
      },
      { immediate: true },
    );

    const modify = (modifiedField: {
      operationType: 'Edit' | 'Delete';
      criticalPointId: string;
      waypointId: string;
    }) => {
      if (modifiedField.operationType === 'Edit') {
        setCriticalPointEditable(modifiedField.criticalPointId);
        return;
      }
      removeCriticalPoint(
        criticalPointsMap.value.get(modifiedField.waypointId),
        modifiedField.criticalPointId,
        modifiedField.waypointId,
      );
    };

    const selected = () => {
      isExpandable.value = !isExpandable.value;
    };

    const openActions = () => {
      showMenu.value = !showMenu.value;
    };
    const updateCriticalPointFromPanel = () => {
      addCriticalPointFromPanel.value = !addCriticalPointFromPanel.value;
    };

    const updated = (updatedFields: {
      type: 'message' | 'triggerValue' | 'monitoringMode';
      value: { id: string; value: string };
      wpId: string;
    }) => {
      criticalPointsMap.value = updateCriticalPointMapData(
        criticalPointsMap.value,
        updatedFields?.value?.id,
        updatedFields?.value?.value,
        updatedFields?.type,
        updatedFields?.wpId,
      );
    };
    const saveCriticalPoint = () => {
      if (!disableSave.value) {
        clearCriticalPointsInEditMode();
        [...criticalPointsMap.value.keys()].flatMap((rl) => {
          setCriticalPoints(criticalPointsMap.value.get(rl), rl);
        });
      }
    };
    const cancel = () => {
      clearUnpersistedCriticalPoints();
      clearCriticalPointsInEditMode();
      if (waypoints.value) criticalPointsMap.value = getStructuredMapDataForCriticalPointsPanel(criticalPoints.value);
    };
    const goToPin = (criticalPoint: CriticalPoint) => {
      if (criticalPointsVisible.value) {
        const findPreviousLeg = route?.value?.legs?.filter((l) => l.toWaypoint === criticalPoint.waypointId)[0];
        const enrichedPrevWpt = route?.value?.calculationResult?.waypoints?.find(
          (item) => findPreviousLeg?.fromWaypoint === item.waypointId,
        );
        if (!enrichedPrevWpt?.track) return;
        const cpLatLng = getCriticalPointPositionOnTrack(
          [...enrichedPrevWpt.track].reverse(),
          criticalPoint.distanceFromWaypoint,
        );
        goToCriticalPointPos(cpLatLng);
      }
    };

    const goToCriticalPointPos = (position: LngLatPoint) => {
      if (position.lat === undefined || position.lng === undefined) return;
      if (isExtension) setBrowse(true);
      const bounds = calculateBounds(position);
      showInViewport(bounds, 0.75, false);
    };
    return {
      criticalPointsMap,
      criticalPoints,
      showMenu,
      isExpandable,
      isPanelInEditMode,
      isCriticalPointEditable,
      modify,
      openActions,
      selected,
      hidePanel,
      isPanelVisible,
      getRouteName,
      getWaypointIndex,
      allCriticalPointPersisted,
      waypoints,
      updated,
      addCriticalPointFromPanel,
      updateCriticalPointFromPanel,
      saveCriticalPoint,
      cancel,
      goToPin,
      disableSave,
      isPanelInReadonlyView,
      toParamValue,
      criticalPointsVisible,
    };
  },
});
</script>

<style scoped>
.criticalPointsPanel {
  padding: 0px !important;
  width: 360px;
  background: var(--background_layer_below);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.header .title {
  color: var(--static-text-main);
}
.header .name {
  margin-left: 78px;
  width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.close {
  margin-left: 174px;
}
.pointer {
  cursor: pointer;
}
.header {
  padding: 0 12px;
  cursor: move;
  background: #15191c;
}
.textColor {
  color: var(--text_static);
}
.mini-header {
  height: 24px;
  background: #15191c;
  padding-left: 4%;
  padding-top: 1%;
  border-bottom: 1px solid #000000;
}
.commonMargin {
  margin-left: 4%;
  font-size: 14px;
}
.align-actions {
  padding: 3%;
  justify-content: center;
}
.panel-container {
  padding: 5px;
  display: flex;
  margin: auto;
}
.btn {
  width: 105px;
  margin: 5px;
}
</style>
