import { LatLon } from '../generated/ChartServer';
import { LngLatPoint, MeterPoint } from '../types';
import { forward, inverse } from './mercator';

// Entire area that can be shown in view:
// from (0, 0) top/left to (2*halfMaxMX, 2*halfMaxMY) bottom/right

export const { mX: halfMaxMX, mY: halfMaxMY } = forward({ lng: 180, lat: 84 });

export function lngLatToMeter({ lng, lat }: LngLatPoint): MeterPoint {
  const { mX, mY } = forward({ lng, lat });
  return {
    mX: mX + halfMaxMX,
    mY: halfMaxMY - mY, // cartesian conversion
  };
}

export function meterToLngLat({ mX, mY }: MeterPoint): LngLatPoint {
  return inverse({
    mX: mX - halfMaxMX,
    mY: -(mY - halfMaxMY), // cartesian conversion
  });
}

export const latLonPointToMeter = (point: LatLon): MeterPoint => {
  return lngLatToMeter({
    lng: radInDeg * (point.longitude ?? 0),
    lat: radInDeg * halfPi(point.latitude ?? 0),
  });
};

export const latLonPointsToPoints = (points: readonly LatLon[]): number[] =>
  points.flatMap((t) => {
    const pos = latLonPointToMeter(t);
    return [pos.mX, pos.mY];
  });

export function mod2pi(angle: number): number {
  let mod = angle % (2 * Math.PI);
  if (mod < 0) mod += 2 * Math.PI;
  return mod;
}

export function pipi(angle: number): number {
  let num = angle % (2.0 * Math.PI);
  if (num < -1.0 * Math.PI) num += 2.0 * Math.PI;
  if (num >= Math.PI) num -= 2.0 * Math.PI;
  return num;
}

export function halfPi(angle: number): number {
  let num = angle % Math.PI;
  if (num < -0.5 * Math.PI) num += Math.PI;
  if (num >= Math.PI / 2) num -= Math.PI;
  return num;
}

export function pi(angle: number): number {
  let num = mod2pi(angle);
  if (num > Math.PI) num = 2 * Math.PI - num;
  return num;
}

export function calculateDistance(pos1: LngLatPoint, pos2: LngLatPoint): number {
  const x = (pos2.lng - pos1.lng) * Math.cos((pos1.lat + pos2.lat) / 2);
  const y = pos2.lat - pos1.lat;
  return Math.sqrt(x * x + y * y) * earthRadius;
}

export function calculateBearing(pos1: LngLatPoint, pos2: LngLatPoint): number {
  const y = Math.sin(pos2.lng - pos1.lng) * Math.cos(pos2.lat);
  const x =
    Math.cos(pos1.lat) * Math.sin(pos2.lat) - Math.sin(pos1.lat) * Math.cos(pos2.lat) * Math.cos(pos2.lng - pos1.lng);
  return mod2pi(Math.atan2(y, x));
}

export const nmInMeter = 1852;
export const meterInNm = 1 / nmInMeter;
export const mpsInKnots = 3600 / nmInMeter;
export const degInRad = Math.PI / 180;
export const radInDeg = 1 / degInRad;
export const earthRadius = 6378137;
