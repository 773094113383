<template>
  <template v-if="authenticated">
    <slot></slot>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import authentication from '../../authentication';

export default defineComponent({
  setup() {
    const { state: authenticationState } = authentication;

    const authenticated = computed(() => authenticationState.authenticated);

    return {
      authenticated,
    };
  },
});
</script>
