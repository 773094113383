import { checkIntersection } from 'line-intersect';
import { v4 as uuid } from 'uuid';
import { DeepReadonly } from 'vue';
import { ContextType } from 'vue-context-composition';
import { messageCtx } from '../contexts/messages';
import { EnrichedRoute } from '../contexts/routes';
import { GeneralSettings } from '../contexts/routeSettings';
import { ToolTipData } from '../contexts/toolTip';
import { EnrichedWaypoint, LatLon, RouteCalculationResult } from '../generated/ChartServer';
import { GeometryType, Leg, Route, RouteValidationStatus, Waypoint } from '../generated/RouteManagement';
import { MeterPoint, ShapeType } from '../types';
import {
  degInRad,
  halfMaxMX,
  halfPi,
  lngLatToMeter,
  meterInNm,
  meterToLngLat,
  mod2pi,
  mpsInKnots,
  pipi,
  radInDeg,
} from './conversions';
import {
  courseFormatter,
  courseToTrueNorthFormatter,
  distanceFormatter,
  speedFormatter,
  twoDecimalFormatter,
} from './formatters';
import { WaypointLegPair } from './routeConversions';
import { lessThan } from './tools';
import { vector } from './vector';

type CrossCheckResult = {
  crosses: boolean;
  direction: 'east' | 'west' | '-';
};

export const dateLineCheck = (longitude1: number, longitude2: number): CrossCheckResult => {
  const res = <CrossCheckResult>{ crosses: false, direction: '-' };
  const pipiL1 = pipi(longitude1);
  const pipiL2 = pipi(longitude2);
  if (pipiL1 >= 0 && pipiL2 < 0) {
    if (Math.abs(pipiL1) + Math.abs(pipiL2) > Math.PI) {
      res.crosses = true;
      res.direction = 'east';
    }
  } else if (pipiL1 < 0 && pipiL2 >= 0) {
    if (Math.abs(pipiL1) + Math.abs(pipiL2) > Math.PI) {
      res.crosses = true;
      res.direction = 'west';
    }
  }
  return res;
};

export function splitAtDateLine(points: readonly LatLon[]): LatLon[][] {
  const slices: LatLon[][] = [];
  let lastSlice = 0;
  points.forEach((p, index) => {
    if (index > 0) {
      const longitude = p.longitude;
      const lastLongitude = points[index - 1].longitude;
      if (longitude && lastLongitude) {
        const crossing = dateLineCheck(lastLongitude, longitude);
        if (crossing.crosses) {
          slices.push(points.slice(0, index));
          lastSlice = index;
        }
      }
    }
  });

  slices.push(points.slice(lastSlice));
  for (let i = 1; i < slices.length; i++) {
    const before = slices[i - 1];
    const after = slices[i];

    const latLonBefore = before[before.length - 1];
    const latLonAfter = after[0];
    if (
      latLonBefore.latitude == undefined ||
      latLonBefore.longitude == undefined ||
      latLonAfter.latitude == undefined ||
      latLonAfter.longitude == undefined
    )
      continue;

    const meterPointBefore = lngLatToMeter({
      lat: latLonBefore.latitude * radInDeg,
      lng: latLonBefore.longitude * radInDeg,
    });
    const meterPointAfter = lngLatToMeter({
      lat: latLonAfter.latitude * radInDeg,
      lng: latLonAfter.longitude * radInDeg,
    });
    const crossing = dateLineCheck(latLonBefore.longitude, latLonAfter.longitude);

    const crossingFactor = crossing.direction === 'east' ? 1 : -1;
    const crossAdjMx = 2 * halfMaxMX * crossingFactor;

    const dateLineNorth = lngLatToMeter({ lng: 180 * crossingFactor, lat: 84 });
    const dateLineSouth = lngLatToMeter({ lng: 180 * crossingFactor, lat: -84 });

    const intersection = checkIntersection(
      meterPointBefore.mX,
      meterPointBefore.mY,
      meterPointAfter.mX + crossAdjMx,
      meterPointAfter.mY,
      dateLineNorth.mX,
      dateLineNorth.mY,
      dateLineSouth.mX,
      dateLineSouth.mY,
    );
    if (intersection.type === 'intersecting') {
      //Offsetting longitude to just before and just after
      // the intersection point on the dateline (this creates a tiny gap)
      const longitudeOffset = crossing.direction === 'east' ? -0.0000000001 : 0.0000000001;

      const lngLatInDeg = meterToLngLat({ mX: intersection.point.x, mY: intersection.point.y });
      const latLonBefore = <LatLon>{
        latitude: lngLatInDeg.lat * degInRad,
        longitude: lngLatInDeg.lng * degInRad + longitudeOffset,
      };
      const latLonAfter = <LatLon>{
        latitude: lngLatInDeg.lat * degInRad,
        longitude: lngLatInDeg.lng * degInRad - longitudeOffset,
      };
      before.push(latLonBefore);
      after.unshift(latLonAfter);
    }
  }
  return slices;
}

export function aroundTheWorld(line: readonly LatLon[]): LatLon[][] {
  return [
    line.map((p) => <LatLon>{ latitude: p.latitude, longitude: p.longitude != null ? pipi(p.longitude) : 0 }),
    line.map(
      (p) => <LatLon>{ latitude: p.latitude, longitude: p.longitude != null ? pipi(p.longitude) + 2 * Math.PI : 0 },
    ),
    line.map(
      (p) => <LatLon>{ latitude: p.latitude, longitude: p.longitude != null ? pipi(p.longitude) - 2 * Math.PI : 0 },
    ),
  ];
}

//This can mask and in turn overwrite route data
export function sortWaypoints(route: Route): boolean {
  const waypoints = route.waypoints;
  if (!waypoints || waypoints.some((w) => w.id == undefined)) return false;
  if (!route.legs || route.legs.length !== waypoints.length - 1) return false;
  //Using reverse so that legs already in the right order will have best case scenario
  const remainingLegs = route.legs.map((l) => [l.fromWaypoint, l.toWaypoint]).reverse();
  const waypointOrder = remainingLegs.pop();
  if (waypointOrder) {
    //Limiting number of passes in case bad data would cause infinite loop
    let passes = 0;
    while (remainingLegs.length > 0) {
      passes++;
      const leg = remainingLegs.pop();
      if (leg) {
        if (leg[0] === waypointOrder[waypointOrder.length - 1]) {
          waypointOrder.push(leg[1]);
          passes = 0;
        } else if (leg[1] === waypointOrder[0]) {
          waypointOrder.unshift(leg[0]);
          passes = 0;
        } else {
          remainingLegs.unshift(leg);
          if (passes >= remainingLegs.length) break;
        }
      }
    }
    //Make sure all legs are used and that there were no duplicates
    if (remainingLegs.length === 0 && new Set(waypointOrder).size === waypointOrder.length) {
      waypoints.sort((a, b) => waypointOrder.indexOf(a.waypointId) - waypointOrder.indexOf(b.waypointId));
      //Setting sequential id for each waypoint
      waypoints.forEach((w, index) => (w.id = index + 1));
      return true;
    }
  }
  return false;
}

//TODO: use return value from sort to determine if recoverRoute is needed
export function recoverRoute(route: Route, settings: GeneralSettings[]): void {
  const waypoints = route.waypoints;
  if (!waypoints || waypoints.some((w) => w.id == undefined)) return;
  //Sorting by id
  waypoints.sort((w1, w2) => (w1.id && w2.id ? w1.id - w2.id : 0));
  //Making ids start at and increase by 1
  waypoints.forEach((w, index) => (w.id = index + 1));
  route.legs = generateLegs(waypoints, settings);
}

export function generateLegs(waypoints: Waypoint[], settings: GeneralSettings[]): Leg[] {
  const legs = [];
  const defaultOfftrack = parseFloat(settings?.find((s) => s.id === 'offtrackLimit')?.value.value ?? '0') * meterInNm;
  const plannedSpeed = parseFloat(settings?.find((s) => s.id === 'plannedSpeed')?.value.value ?? '0');

  for (let i = 1; i < waypoints.length; i++) {
    const wp1 = waypoints[i - 1];
    const wp2 = waypoints[i];
    const newLeg = <Leg>{
      legId: uuid(),
      fromWaypoint: wp1.waypointId,
      toWaypoint: wp2.waypointId,
      geometryType: GeometryType.Loxodrome,
      portsideXTD: defaultOfftrack,
      starboardXTD: defaultOfftrack,
      speedPlanned: plannedSpeed,
    };
    legs.push(newLeg);
  }
  return legs;
}

export function invalidate(route: Route): void {
  route.validationStatus = RouteValidationStatus.NotValidated;
  route.validationSignature = undefined;
  route.validationTimeStamp = undefined;
  route.validityPeriodStart = undefined;
  route.validityPeriodStop = undefined;
}

export const getTravelledTime = (waypointId: string, calculationResult: RouteCalculationResult): number => {
  const wp = calculationResult.waypoints;
  if (wp) {
    const index = wp.findIndex((w) => w.waypointId === waypointId);
    if (index === 0) return 0;
    return wp
      .slice(0, index)
      .map((w) => w.time ?? 0)
      .reduce((acc, current) => acc + current);
  }
  return 0;
};

export const getTravelledDistance = (waypointId: string, calculationResult: RouteCalculationResult): number => {
  const wp = calculationResult.waypoints;
  if (wp) {
    const index = wp.findIndex((w) => w.waypointId === waypointId);
    if (index === 0) return 0;
    return wp
      .slice(0, index)
      .map((w) => w.distance ?? 0)
      .reduce((acc, current) => acc + current);
  }
  return 0;
};

export const getRemainingDistance = (waypointId: string, calculationResult: RouteCalculationResult): number => {
  const wp = calculationResult.waypoints;
  if (wp) {
    const index = wp.findIndex((w) => w.waypointId === waypointId);
    if (index === wp.length - 1) return 0;
    return wp
      .slice(index)
      .map((w) => w.distance ?? 0)
      .reduce((acc, current) => acc + current);
  }
  return 0;
};

export type WaypointSpeedCourseLabel = {
  courseMeterPoint: MeterPoint;
  speedMeterPoint: MeterPoint;
  speed: string;
  course: string;
};

export const calculateWaypointSpeedAndCoursePositions = (
  waypoints: DeepReadonly<EnrichedWaypoint[]>,
  speedUnit: string,
  courseUnit: string,
  distance: number,
): WaypointSpeedCourseLabel[] => {
  const waypointSpeedCourseData: WaypointSpeedCourseLabel[] = [];
  waypoints.forEach((wp, currentWayPointIndex) => {
    if (currentWayPointIndex < waypoints.length - 1) {
      const currentWpm = lngLatToMeter({
        lng: radInDeg * (wp.longitude ?? 0),
        lat: radInDeg * halfPi(wp.latitude ?? 0),
      });
      const nextWpm = lngLatToMeter({
        lng:
          radInDeg *
          (currentWayPointIndex < waypoints.length - 1 ? waypoints[currentWayPointIndex + 1].longitude ?? 0 : 0),
        lat:
          radInDeg *
          (currentWayPointIndex < waypoints.length - 1 ? halfPi(waypoints[currentWayPointIndex + 1].latitude ?? 0) : 0),
      });

      const speedLabelVector = transformLabelVector(currentWpm, nextWpm, distance, 40);
      const courseLabelVector = transformLabelVector(currentWpm, nextWpm, distance, 120);
      waypointSpeedCourseData.push({
        courseMeterPoint: {
          mX: currentWpm.mX - courseLabelVector.x,
          mY: currentWpm.mY - courseLabelVector.y,
        },
        speedMeterPoint: {
          mX: currentWpm.mX - speedLabelVector.x,
          mY: currentWpm.mY - speedLabelVector.y,
        },
        speed: speedFormatter((wp.speed ?? 0) * mpsInKnots) + speedUnit.toLowerCase(),
        course: courseToTrueNorthFormatter(mod2pi(wp.course ?? 0) * radInDeg) + courseUnit,
      });
    }
  });
  return waypointSpeedCourseData;
};

export const transformLabelVector = (
  currentMeterPoint: MeterPoint,
  nextMeterPoint: MeterPoint,
  distance: number,
  transformAngleInDeg: number,
) => {
  // Create leg vector
  const legVector = vector(currentMeterPoint.mX, currentMeterPoint.mY)
    .subtract(vector(nextMeterPoint.mX, nextMeterPoint.mY))
    .setLength(distance);
  const expectedVectorAngle = mod2pi(legVector.angle() + Math.PI / 2) * radInDeg - transformAngleInDeg;
  return legVector.setAngle(expectedVectorAngle * degInRad);
};

export type WaypointLabel = { meterPoint: MeterPoint; wayPointLabel: string };

export const calculateWaypointLabelPositions = (
  waypoints: DeepReadonly<EnrichedWaypoint[]>,
  distance: number,
): WaypointLabel[] => {
  const waypointLabelData: WaypointLabel[] = [];
  waypoints?.forEach((wp, currentWayPointIndex) => {
    let currentLegVector = vector(0, 0);
    let nextLegVector = vector(0, 0);
    // iterate over the waypoints and set the current waypoint meter point & initialize the current & next leg vector to 0,0
    const currentWpm = lngLatToMeter({
      lng: radInDeg * (wp.longitude ?? 0),
      lat: radInDeg * halfPi(wp.latitude ?? 0),
    });
    // Set the last wpm & the next wpm for the waypoints
    const lastWpm = lngLatToMeter({
      lng: radInDeg * (currentWayPointIndex > 0 ? waypoints[currentWayPointIndex - 1].longitude ?? 0 : 0),
      lat: radInDeg * (currentWayPointIndex > 0 ? halfPi(waypoints[currentWayPointIndex - 1].latitude ?? 0) : 0),
    });
    const nextWpm = lngLatToMeter({
      lng:
        radInDeg *
        (currentWayPointIndex < waypoints.length - 1 ? waypoints[currentWayPointIndex + 1].longitude ?? 0 : 0),
      lat:
        radInDeg *
        (currentWayPointIndex < waypoints.length - 1 ? halfPi(waypoints[currentWayPointIndex + 1].latitude ?? 0) : 0),
    });
    // find out the resultant vector of the currentLegVector with Next leg Vector by setting up a unit vector for the current & the next leg vector points
    if (currentWayPointIndex > 0)
      currentLegVector = vector(currentWpm.mX, currentWpm.mY).subtract(vector(lastWpm.mX, lastWpm.mY)).setLength(1);
    if (currentWayPointIndex < waypoints.length - 1)
      nextLegVector = vector(currentWpm.mX, currentWpm.mY).subtract(vector(nextWpm.mX, nextWpm.mY)).setLength(1);

    const vectorSum = currentLegVector.add(nextLegVector).setLength(distance);
    waypointLabelData.push({
      meterPoint: {
        mX: currentWpm.mX + vectorSum.x,
        mY: currentWpm.mY + vectorSum.y,
      },
      wayPointLabel: 'W' + (currentWayPointIndex + 1),
    });
  });
  return waypointLabelData;
};

export const validateRadius = (
  currentRadiusInMeters: number,
  minPermissibleTurnRadius: number,
  { pushMessage }: ContextType<typeof messageCtx>,
): number => {
  // If the entered wpt is less than the min ship radius then set it to the min ship turn radius.
  const hasinvalidWptTurnRadius = lessThan(currentRadiusInMeters, minPermissibleTurnRadius);
  if (hasinvalidWptTurnRadius) {
    pushMessage({ type: 'info', text: 'Radius cannot be smaller than ship minimum turn radius.' });
    return minPermissibleTurnRadius;
  }
  return currentRadiusInMeters;
};

export const hasBadGeometryOrInvalidTurnRadius = (
  waypoint: DeepReadonly<EnrichedWaypoint>,
  route: EnrichedRoute | undefined,
  shipMinTurnRadius: number,
): boolean => {
  return waypoint.hasBadGeometry
    ? true
    : isFirst(route, waypoint) || isLast(route, waypoint)
    ? false
    : lessThan(waypoint.turnRadius ?? 0, shipMinTurnRadius);
};

//Because routes are sorted, a simple index check is sufficient to find if it's the first waypoint
export const isFirst = (route: EnrichedRoute | undefined, waypoint: DeepReadonly<EnrichedWaypoint>): boolean => {
  return route?.waypoints ? route.waypoints[0]?.waypointId === waypoint.waypointId : false;
};

//Because routes are sorted, a simple index check is sufficient to find if it's the last waypoint
export const isLast = (route: EnrichedRoute | undefined, waypoint: DeepReadonly<EnrichedWaypoint>): boolean => {
  return route?.waypoints ? route.waypoints[route.waypoints.length - 1]?.waypointId === waypoint.waypointId : false;
};

export const calculateRouteMonitoringToolTipData = (
  waypointData: WaypointLegPair | undefined,
  shapeType: ShapeType,
  generalSettings: readonly GeneralSettings[],
): ToolTipData[] => {
  if (shapeType === 'leg') {
    return [
      {
        title: 'Course',
        value: courseFormatter(waypointData?.leg?.course),
        unit: '°',
      },
      {
        title: 'Distance',
        value: distanceFormatter(waypointData?.waypoint?.legDistance),
        unit: 'NM',
      },
      {
        title: 'Speed',
        value: speedFormatter(waypointData?.leg?.speedPlanned),
        unit: generalSettings.find((s) => s.id == 'plannedSpeed')?.value.unit ?? '-',
      },
      {
        title: 'Offset PORT',
        value: twoDecimalFormatter(waypointData?.leg?.portsideXTD),
        unit: generalSettings.find((s) => s.id == 'offtrackLimit')?.value.unit ?? '-',
      },
      {
        title: 'Offset STB',
        value: twoDecimalFormatter(waypointData?.leg?.starboardXTD),
        unit: generalSettings.find((s) => s.id == 'offtrackLimit')?.value.unit ?? '-',
      },
    ];
  } else if (shapeType === 'waypoint') {
    return [
      {
        title: 'Name',
        value: waypointData?.waypoint?.name ?? '',
      },
      {
        title: 'Radius',
        value: twoDecimalFormatter(waypointData?.waypoint?.radius),
        unit: generalSettings.find((s) => s.id == 'radius')?.value.unit ?? '-',
      },
    ];
  }
  return [];
};

export const getLegEndWaypoint = (enrichedRoute: EnrichedRoute, waypointId: string): Waypoint | undefined => {
  const legAfter = enrichedRoute.legs?.find((l) => l.fromWaypoint === waypointId);
  return enrichedRoute.waypoints?.find((w) => w.waypointId === legAfter?.toWaypoint);
};

export const getWaypointTrack = (enrichedRoute: DeepReadonly<EnrichedRoute>, wayPointId: string): LatLon[] => {
  const wpt = enrichedRoute.calculationResult?.waypoints?.find((wp) => wp.waypointId === wayPointId);
  return wpt?.track?.map((l) => <LatLon>{ latitude: l.latitude, longitude: pipi(l.longitude ?? 0) }) ?? [];
};
