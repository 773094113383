import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';
import { getProductCatalogClient } from '../api-clients/getProductCatalogClient';
import { Dimensioned, Vessel } from '../generated/ProductCatalog';
import { ParamValue } from '../types';
import { persist } from '../utils/persist';

const visibleParams: VisibleParams[] = [
  { id: 'shipLength', name: 'Ship length', value: undefined, editable: false },
  { id: 'shipWidth', name: 'Ship width', value: undefined, editable: false },
  { id: 'shipDraught', name: 'Max ship draught', value: undefined, editable: false },
  { id: 'conningPositionXFromStern', name: 'CCRP from stern', value: undefined, editable: false },
  { id: 'conningPositionYFromStern', name: 'CCRP stb. of centreline', value: undefined, editable: false },
  { id: 'pivotPositionXFromStern', name: 'Pivot point from stern', value: undefined, editable: false },
  { id: 'pivotPositionYFromStern', name: 'Pivot point stb. of centre', value: undefined, editable: false },
  { id: 'shipMinTurnRadius', name: 'Min ship turn radius', value: undefined, editable: true },
  { id: 'shipMaxTurnRate', name: 'Max ship turn rate', value: undefined, editable: true },
  { id: 'shipTurnAccelerationLength', name: 'Turn acceleration length', value: undefined, editable: true },
];

type VisibleParamIdentifier =
  | 'shipLength'
  | 'shipWidth'
  | 'shipDraught'
  | 'conningPositionXFromStern'
  | 'conningPositionYFromStern'
  | 'pivotPositionXFromStern'
  | 'pivotPositionYFromStern'
  | 'shipMinTurnRadius'
  | 'shipMaxTurnRate'
  | 'shipTurnAccelerationLength';

type VisibleParams = {
  id: VisibleParamIdentifier;
  name: string;
  value: ParamValue | undefined;
  editable: boolean;
};

export type UpdateParamReq = {
  id: string;
  value: string;
};

type State = {
  isVesselSelected: boolean;
  vessels?: Vessel[];
  token?: string;
  activeVessel?: Vessel;
  selectedVessel?: Vessel;
  selectedVesselParams?: VisibleParams[];
  isViewDirty: boolean;
  refetchOwnshipInfo: boolean;
};

export const vesselCtx = defineContext(() => {
  const state: State = persist(
    'vesselParams.V1',
    reactive({
      vessels: undefined,
      token: undefined,
      isPanelActive: false,
      isVesselSelected: false,
      selectedVesselParams: visibleParams,
      isViewDirty: false,
      refetchOwnshipInfo: false,
    }),
  );

  const loadAllVessels = async () => {
    const productCatalogClient = await getProductCatalogClient();
    state.vessels = await productCatalogClient.vessel_GetAllVessels();
  };

  const loadToken = async () => {
    const productCatalogClient = await getProductCatalogClient();
    const resourceToken = await productCatalogClient.utility_GetContainerSas('resource', 120);
    state.token = resourceToken.token;
  };

  const selectVessel = (vessel: Vessel) => {
    state.isVesselSelected = true;
    state.selectedVessel = { ...vessel };
    state.selectedVesselParams?.forEach(
      (param) => (param.value = { ...(vessel[param.id as keyof Vessel] as ParamValue) }),
    );
  };

  const updateParam = (change: UpdateParamReq) => {
    state.selectedVesselParams?.every((param) => {
      if (param.id === change.id && param.value) {
        param.value.value = change.value;
        setIsViewDirty(true);
        return false;
      }
      return true;
    });
  };

  const clearUncommittedChanges = () => {
    const selected = state.vessels?.find((vessel) => vessel.shipId === state.selectedVessel?.shipId);
    if (selected) {
      state.selectedVesselParams?.forEach((param) => {
        param.value = { ...(selected[param.id as keyof Vessel] as ParamValue) };
      });
      setIsViewDirty(false);
    }
  };

  const commitChanges = (): void => {
    const index = state.vessels?.findIndex((vessel) => vessel.shipId === state.selectedVessel?.shipId);

    if (index !== undefined && state.selectedVesselParams) {
      state.selectedVesselParams.forEach((param) => {
        if (state.vessels) {
          state.vessels[index][param.id] = { ...(param.value as Dimensioned) };
        }
      });

      setIsViewDirty(false);
    }
  };

  const changeActiveVessel = (vessel: Vessel) => {
    state.activeVessel = vessel;
  };

  const resetParamValues = async () => {
    if (state.selectedVessel) {
      const productCatalogClient = await getProductCatalogClient();
      const details = await productCatalogClient.vessel_GetVesselById(state.selectedVessel.id);
      console.log(details);
      changeActiveVessel(details);
      selectVessel(details);
    }
  };

  const matchVesselAndResetIsViewDirty = () => {
    const index = state.vessels?.findIndex((vessel) => vessel.shipId === state.selectedVessel?.shipId);
    if (index !== undefined && state.selectedVesselParams) {
      setIsViewDirty(false);
    }
  };

  const getNumericVesselParam = (key: VisibleParamIdentifier) => {
    return parseFloat(state.selectedVesselParams?.find((p) => p.id === key)?.value?.value ?? '0');
  };

  const toggleRefetchShipValues = () => {
    state.refetchOwnshipInfo = !state.refetchOwnshipInfo;
  };

  const shouldRefetchShipDetails = () => {
    return state.refetchOwnshipInfo;
  };

  const setIsViewDirty = (isViewDirty: boolean) => {
    state.isViewDirty = isViewDirty;
  };

  return {
    state: readonly(state),
    loadAllVessels,
    loadToken,
    getNumericVesselParam,
    changeActiveVessel,
    selectVessel,
    updateParam,
    clearUncommittedChanges,
    commitChanges,
    resetParamValues,
    setIsViewDirty,
    matchVesselAndResetIsViewDirty,
    toggleRefetchShipValues,
    shouldRefetchShipDetails,
  };
});
