<template>
  <div class="flex h-full flex-col">
    <div class="flex flex-col overflow-y-auto">
      <signi-panel-header title="Active Alerts" class="flex items-center justify-between gap-x-2 pr-2">
        <button
          :aria-label="muted ? 'MuteIcon' : 'UnmuteIcon'"
          class="btn btn-flat btn-xs"
          :disabled="muted || activeAlerts?.length === 0"
          @click="$emit('update:mute', true)"
        >
          <signi-icon :key="muted.toString()" :name="muted ? 'MuteIcon' : 'UnmuteIcon'" />
        </button>
      </signi-panel-header>

      <div v-if="activeAlerts.length" class="grid-container">
        <template v-for="alert in activeAlerts" :key="alert.id">
          <signi-alert :alert="alert" :blink-sync="blinkSync" @acknowledge="onAcknowledge(alert.id)" />
        </template>
      </div>
      <div v-else class="p-3 text-text_main">There are no active alerts</div>
    </div>

    <div class="flex-1"></div>

    <div class="flex max-h-[50%] flex-col">
      <button aria-label="historyButton" @click="historyExpanded = !historyExpanded">
        <signi-panel-header title="Alert History" class="flex items-center justify-between">
          <signi-icon name="ChevronUpIcon" class="chevron mr-2 text-text_main" :class="{ open: historyExpanded }" />
        </signi-panel-header>
      </button>

      <div
        v-if="historyExpanded && historicalAlerts.length"
        class="grid-container"
        :class="{ 'hist-expanded-height': historyExpanded }"
      >
        <template v-for="alert in historicalAlerts" :key="alert.id">
          <signi-alert :alert="alert" :blink-sync="true" />
        </template>
      </div>
      <div v-else-if="historyExpanded && !historicalAlerts.length" class="p-3 text-text_main">
        Alert history is empty
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRef } from 'vue';
import { useActiveAlerts, useHistoricalAlerts } from '../alerts-helper';
import { Alert } from '../types';

const props = defineProps<{ alerts: Alert[]; blinkSync: boolean; muted: boolean }>();
const emit = defineEmits(['acknowledge', 'update:mute']);

const historyExpanded = ref(false);
const activeAlerts = useActiveAlerts(toRef(props, 'alerts'));
const historicalAlerts = useHistoricalAlerts(toRef(props, 'alerts'));

const onAcknowledge = (id: string) => {
  emit('acknowledge', id);
};
</script>

<style scoped>
.chevron.open {
  @apply rotate-180;
}
.grid-container {
  @apply grid grid-cols-[auto_30fr_auto_auto] items-center overflow-y-auto;
}
</style>
