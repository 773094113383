import { v4 as uuid } from 'uuid';
import { DeepReadonly, computed, reactive, readonly, watch } from 'vue';
import { defineContext } from 'vue-context-composition';
import { getChartServerClient } from '../api-clients/getChartServerClient';
import { getRouteManagementClient } from '../api-clients/getRouteManagementClient';
import { DistanceMarkerConfiguration, LatLon, RouteCalculationResult } from '../generated/ChartServer';
import {
  CriticalPoint,
  GeometryType,
  IndexLine,
  Leg,
  MonitoringMode,
  Route,
  RouteStatus,
  RouteValidationStatus,
  ValidationStatusWithShipDetails,
  Waypoint,
} from '../generated/RouteManagement';
import { LngLatPoint, MeterPoint } from '../types';
import {
  calculateDistance,
  degInRad,
  latLonPointToMeter,
  lngLatToMeter,
  meterInNm,
  mod2pi,
  pipi,
  radInDeg,
} from '../utils/conversions';
import { getDistanceEnrichedTrack, waypointCanBeDeleted } from '../utils/criticalPointUtils';
import { dateFormatter, validationAbbrFormatter } from '../utils/formatters';
import { WaypointLegPair, toValidationRoute } from '../utils/routeConversions';
import {
  getLegEndWaypoint,
  getRemainingDistance,
  getTravelledDistance,
  getTravelledTime,
  getWaypointTrack,
  invalidate,
  sortWaypoints,
} from '../utils/routeUtils';
import { vector } from '../utils/vector';
import { GeneralSettings } from './routeSettings';

export type EnrichedRoute = Route & {
  show: boolean;
  calculationResult?: RouteCalculationResult;
  transient: boolean;
  activeWaypointId?: string;
};

type State = {
  routes?: EnrichedRoute[];
  routesError: unknown;
  showDetail: boolean;
  detailId?: string;
  editId?: string;
  distanceMarkerConfiguration?: DistanceMarkerConfiguration;
  monitorId?: string;
};

export const routesCtx = defineContext(() => {
  const state: State = reactive({
    routes: undefined,
    routesError: undefined,
    showDetail: false,
    detailId: undefined,
    editMode: false,
    noteDetails: [],
    distanceMarkerConfiguration: undefined,
  });

  const loadWaypoints = async (route: EnrichedRoute) => {
    if (!route.waypoints?.length && route.routeId) {
      const routeManagementClient = await getRouteManagementClient();
      const fullRoute = await routeManagementClient.get(route.routeId);
      sortWaypoints(fullRoute);
      route.waypoints = fullRoute.waypoints;
      route.legs = fullRoute.legs;
      route.indexLines = fullRoute.indexLines;
    }
  };

  const updateDistanceMarkerConfiguration = (distanceMarkerConfiguration: DistanceMarkerConfiguration) => {
    state.distanceMarkerConfiguration = distanceMarkerConfiguration;
    state.routes?.filter((r) => r.show).forEach(callChartServer);
  };

  const reloadRoute = async (routeId: string) => {
    const route = findRouteById(routeId);
    if (route) {
      const routeManagementClient = await getRouteManagementClient();
      const fullRoute = await routeManagementClient.get(routeId);
      sortWaypoints(fullRoute);
      Object.assign(route, fullRoute);
    }
  };

  const callChartServer = async (route: EnrichedRoute) => {
    const routeToValidate = toValidationRoute(route, undefined, state.distanceMarkerConfiguration);
    if (routeToValidate !== undefined) {
      const chartServerClient = await getChartServerClient();
      route.calculationResult = await chartServerClient.calculate(routeToValidate);

      updateCalculatedRouteParameters(route);
    }
  };

  const updateCalculatedRouteParameters = (route: EnrichedRoute) => {
    route.waypoints?.forEach((w) => {
      const wpData = route.calculationResult?.waypoints?.find((c) => c.waypointId === w.waypointId);
      if (route.calculationResult && w.waypointId && wpData && wpData.longitude && wpData.latitude) {
        const leg = findLeg(w.waypointId);
        if (leg) leg.course = mod2pi(wpData.course ?? 0) * radInDeg;
        w.legDistance = (wpData.distance ?? 0) * meterInNm;
        w.travelledTime = getTravelledTime(w.waypointId, route.calculationResult);
        w.travelledDistance = getTravelledDistance(w.waypointId, route.calculationResult) * meterInNm;
        w.remainingDistance = getRemainingDistance(w.waypointId, route.calculationResult) * meterInNm;
      }
    });
    if (!state.editId) return;
    // should sync the criticalPoint specific correction from chartServer's enrichedCriticalPoint response to the Route.Waypoint[]. Required during edit route mode only.
    route.calculationResult?.criticalPoints?.forEach((cp) => {
      // find out the prevRefWp existingCpProp (routeMgnmt)
      const prevRefWp = route.waypoints?.find((wp) => {
        return wp.criticalPoints?.find((item) => item.criticalPointId === cp.criticalPointId);
      });
      // find the existing cp props (routeMgnmt)
      const existingCpProp = prevRefWp?.criticalPoints?.find((item) => {
        return item.criticalPointId === cp?.criticalPointId;
      });
      // Find the new reference wpt from the wpt list. (routeMgnmt)
      const newRefWp = route.waypoints?.find((waypoint) => {
        return waypoint.waypointId === cp.waypointId;
      });
      if (!cp || !prevRefWp || !newRefWp || !existingCpProp) return;
      // Splice the cp from the old reference Wpt.
      prevRefWp?.criticalPoints?.splice(
        prevRefWp?.criticalPoints?.findIndex((item) => item.criticalPointId === cp.criticalPointId),
        1,
      );
      if (newRefWp.criticalPoints === undefined) newRefWp.criticalPoints = [];
      // get the new distance from the new reference waypointId (chartserver) & update the cp object.
      existingCpProp.distanceFromWaypoint = cp.distanceFromWaypoint;
      // Push the criticalPoint to the new reference wpt.
      newRefWp.criticalPoints.push(existingCpProp);
    });
  };

  const enrichRoute = async (routeToEnrich: DeepReadonly<Route>) => {
    const route = findRouteById(routeToEnrich.routeId);
    if (!route) return;

    await loadWaypoints(route);
  };

  const loadRoutes = async () => {
    state.routesError = undefined;
    const routeManagementClient = await getRouteManagementClient();
    const routes = await routeManagementClient.getAll(false).catch((e) => {
      state.routesError = e;
      return [];
    });

    state.routes = routes.map((route: Route) => {
      const refRoute = reactive<EnrichedRoute>({
        ...route,
        show: false,
        transient: false,
      });

      // load route waypoints lazily on show
      watch(
        () => refRoute.show,
        (show) => {
          if (show) {
            loadWaypoints(refRoute);
          }
        },
      );

      // load calculated result on waypoint changes
      watch(
        () => routeRecalculationProperties(refRoute),
        () => callChartServer(refRoute),
        { deep: true }, // make sure waypoint coordinate changes are picked up
      );

      if (refRoute.waypoints?.length) {
        sortWaypoints(refRoute);
      }

      return refRoute;
    });
  };

  const routeRecalculationProperties = (route: EnrichedRoute) => {
    return [
      route.waypoints?.map((w) => ({
        latitude: w.latitude,
        longitude: w.longitude,
        radius: w.radius,
      })),
      route.legs?.map((l) => ({
        fromWaypoint: l.fromWaypoint,
        toWaypoint: l.toWaypoint,
        starboardXTD: l.starboardXTD,
        portsideXTD: l.portsideXTD,
        geometryType: l.geometryType,
        speedPlanned: l.speedPlanned,
      })),
    ];
  };

  const setNote = (waypointId: string, newNote: string) => {
    const waypoint = findWaypoint(waypointId);
    if (waypoint) {
      waypoint.text = newNote;
    }
  };

  const deleteNote = (waypointId: string) => {
    const waypoint = findWaypoint(waypointId);
    if (waypoint) {
      waypoint.text = undefined;
    }
  };

  const findRouteById = (routeId: string | undefined): EnrichedRoute | undefined =>
    state.routes?.find((route) => route.routeId === routeId);

  const toggleRouteVisibility = (toggleRoute: DeepReadonly<EnrichedRoute>) => {
    const route = findRouteById(toggleRoute.routeId);
    if (!route) return;
    route.show = !route.show; // triggers watch
  };

  const showDetail = (listRoute: DeepReadonly<EnrichedRoute>) => {
    state.showDetail = true;
    state.detailId = listRoute.routeId;
    if (!listRoute.show) {
      toggleRouteVisibility(listRoute); // triggers fetch
    }
  };

  const closeDetail = () => {
    state.showDetail = false;
  };

  const detailRoute = computed(() => findRouteById(state.detailId));
  const editRoute = computed(() => findRouteById(state.editId));
  const monitoredRoute = computed(() => findRouteById(state.monitorId));

  const findWaypoint = (waypointId: string) => {
    return state.routes?.flatMap((r) => r.waypoints?.filter((w) => w.waypointId === waypointId))[0];
  };

  const findLeg = (waypointId: string) => {
    return state.routes?.flatMap((r) => r.legs?.filter((l) => l.fromWaypoint === waypointId))[0];
  };
  const findPreviousLeg = (waypointId: string) => {
    return state.routes?.flatMap((r) => r.legs?.filter((l) => l.toWaypoint === waypointId))[0];
  };
  const findLegById = (legId: string) => {
    return state.routes?.flatMap((r) => r.legs?.filter((l) => l.legId === legId))[0];
  };
  const setWaypointPosition = (waypointId: string, latLon: LngLatPoint) => {
    const wp = findWaypoint(waypointId);
    if (wp === undefined) return;
    // trigger watch by changing waypoint
    wp.latitude = latLon.lat;
    wp.longitude = latLon.lng;
  };

  const calculateBounds = (route: DeepReadonly<EnrichedRoute>) => {
    // find min/max lat/lng
    const bounds = {
      minLng: 180,
      maxLng: -180,
      minLat: 90,
      maxLat: -90,
    };
    route.waypoints?.forEach((waypoint) => {
      bounds.minLng = Math.min(waypoint.longitude ?? 0, bounds.minLng);
      bounds.maxLng = Math.max(waypoint.longitude ?? 0, bounds.maxLng);
      bounds.minLat = Math.min(waypoint.latitude ?? 0, bounds.minLat);
      bounds.maxLat = Math.max(waypoint.latitude ?? 0, bounds.maxLat);
    });
    const topLeft = lngLatToMeter({ lng: bounds.minLng, lat: bounds.maxLat });
    const bottomRight = lngLatToMeter({ lng: bounds.maxLng, lat: bounds.minLat });
    return {
      topLeft,
      bottomRight,
    };
  };

  const setEditId = (routeId?: string) => {
    if (state.editId) {
      clearActiveWaypoint(state.editId);
    }
    state.editId = routeId;
  };

  const setMonitorId = (routeId?: string) => {
    if (state.monitorId) {
      clearActiveWaypoint(state.monitorId);
    }
    state.monitorId = routeId;
    state.routes
      ?.filter((route) => route.routeId === routeId)
      .forEach((route) => {
        route.show = true;
      });
  };

  const clearActiveWaypoint = (routeId: string) => {
    const route = findRouteById(routeId);
    if (route !== undefined) {
      route.activeWaypointId = undefined;
    }
  };

  const createRoute = () => {
    const newRoute = <EnrichedRoute>{
      routeId: uuid(),
      show: true,
      name: 'New route',
      validationStatus: RouteValidationStatus.NotValidated,
      waypoints: [],
      legs: [],
      transient: true,
    };
    state.detailId = newRoute.routeId;
    state.showDetail = true;
    state.routes = [[newRoute], state.routes].flatMap((rl) => rl as EnrichedRoute[]);
  };

  const insertNewCriticalpoint = async (fromWayPointId: string, meterPoint: MeterPoint) => {
    if (!editRoute.value?.waypoints) return;
    const referenceWpt = getLegEndWaypoint(editRoute.value, fromWayPointId);
    if (referenceWpt) {
      if (referenceWpt.criticalPoints === undefined) referenceWpt.criticalPoints = [];
      const newCriticalpoint: CriticalPoint = {
        criticalPointId: uuid(),
        triggerValue: 0,
        monitoringMode: MonitoringMode.Distance,
        message: '',
        persist: false,
        distanceFromWaypoint: getCriticalPointDistanceFromWp(fromWayPointId, meterPoint, false),
      };
      referenceWpt.criticalPoints.push(newCriticalpoint);
      callChartServer(editRoute.value);
    }
  };

  const insertNewWaypoint = async (
    fromWayPointId: string,
    latLon: LngLatPoint,
    routeSettings?: readonly GeneralSettings[],
  ) => {
    const route = findRouteById(state.editId);
    if (!route) return;
    if (route.waypoints == undefined) route.waypoints = [];
    if (route.legs == undefined) route.legs = [];

    const waypoints = route.waypoints;
    const legs = route.legs;
    const wpToInsertAfter = waypoints.find((wp) => wp.waypointId === fromWayPointId);
    const wptLeg = legs.find((l) => l.fromWaypoint === fromWayPointId);
    const newWaypointId = uuid();

    const defaultTurnRadius = parseFloat(routeSettings?.find((s) => s.id === 'radius')?.value.value ?? '0');

    if (wpToInsertAfter) {
      if (wptLeg) {
        //split current wpt leg into two legs
        const newLeg = {
          ...wptLeg,
          legId: uuid(),
          fromWaypoint: newWaypointId,
        };
        wptLeg.toWaypoint = newWaypointId;
        legs.push(newLeg);
      }

      //new waypoint data
      const newWaypoint = {
        waypointId: newWaypointId,
        id: waypoints.length,
        latitude: latLon.lat,
        longitude: latLon.lng,
        radius: defaultTurnRadius,
        status: RouteStatus.Draft,
      };
      waypoints.push(newWaypoint);

      //Ensures correct sequence of waypoint id numbers
      sortWaypoints(route);
    }
  };

  const removeRouteFromList = () => {
    const route = findRouteById(state.detailId);
    if (route) {
      if (route.routeId == state.editId) setEditId();
      state.routes?.forEach((stateRoute, index) => {
        if (stateRoute.routeId === route.routeId) {
          state.routes?.splice(index, 1);
          return;
        }
      });
    }
  };

  const appendNewWaypoint = async (latLon: LngLatPoint | undefined, routeSettings: readonly GeneralSettings[]) => {
    const route = findRouteById(state.editId);
    if (!route) return;
    if (route.waypoints == undefined) route.waypoints = [];
    if (route.legs == undefined) route.legs = [];

    const waypoints = route.waypoints;
    const legs = route.legs;
    //Assume waypoint list is always sorted
    const lastWp = waypoints[waypoints.length - 1] ?? undefined;
    //Do not assume leg list is sorted
    const lastLeg = legs.find((l) => l.toWaypoint == lastWp.waypointId);

    //new waypoint data
    const waypointId = uuid();
    const waypointNumbers = [0, ...waypoints.map((wp) => (wp.id != undefined ? wp.id : 0))];
    const seq = Math.max(...waypointNumbers) + 1;

    const defaultOfftrack = parseFloat(routeSettings?.find((s) => s.id === 'offtrackLimit')?.value.value ?? '0');
    const plannedSpeed = parseFloat(routeSettings?.find((s) => s.id === 'plannedSpeed')?.value.value ?? '0');
    const defaultTurnRadius = parseFloat(routeSettings?.find((s) => s.id === 'radius')?.value.value ?? '0');

    if (lastWp != undefined) {
      lastWp.radius = defaultTurnRadius;
      if (lastLeg) {
        legs.push({
          ...lastLeg,
          legId: uuid(),
          fromWaypoint: lastWp.waypointId,
          toWaypoint: waypointId,
          geometryType: lastLeg.geometryType ?? GeometryType.Loxodrome,
          portsideXTD: defaultOfftrack,
          starboardXTD: defaultOfftrack,
          speedPlanned: plannedSpeed,
        });
      } else {
        legs.push({
          legId: uuid(),
          fromWaypoint: lastWp.waypointId,
          toWaypoint: waypointId,
          geometryType: GeometryType.Loxodrome,
          portsideXTD: defaultOfftrack,
          starboardXTD: defaultOfftrack,
          speedPlanned: plannedSpeed,
        });
      }
    }

    waypoints.push({
      waypointId: waypointId,
      id: seq,
      latitude: latLon?.lat ?? lastWp?.latitude ?? 0,
      longitude: latLon?.lng ?? lastWp?.longitude ?? 0,
      radius: defaultTurnRadius,
    });

    // Calculate and add watcher the first time a waypoint is added
    if (route.waypoints.length === 1 && route.calculationResult == undefined) {
      watch(
        () => routeRecalculationProperties(route),
        () => callChartServer(route),
        { deep: true, immediate: true }, // make sure waypoint coordinate changes are picked up
      );

      await enrichRoute(route);

      showDetail(route);
    }
  };

  const updateWaypoint = (route: Route, waypointId: string, updatedWaypoint: Waypoint) => {
    if (!route || !route.waypoints) return;

    const waypointIdx = route.waypoints.findIndex((w) => w.waypointId === waypointId);
    if (waypointIdx === undefined) return;

    route.waypoints[waypointIdx] = updatedWaypoint;
  };

  const updateLeg = (route: Route, legId: string, updatedLeg: Leg) => {
    if (!route || !route.legs) return;

    const legIdx = route.legs.findIndex((l) => l.legId === legId);
    if (legIdx === undefined) return;

    route.legs[legIdx] = updatedLeg;
  };

  const updateWaypointAndLeg = (wayPointAndLeg: WaypointLegPair, revokeValidation = true) => {
    const route = findRouteById(state.editId);
    if (!route || !wayPointAndLeg.waypoint.waypointId) return;
    if (revokeValidation) invalidate(route);
    updateWaypoint(route, wayPointAndLeg.waypoint.waypointId, wayPointAndLeg.waypoint);
    if (wayPointAndLeg?.leg?.legId) {
      updateLeg(route, wayPointAndLeg.leg.legId, wayPointAndLeg.leg);
    }
  };

  const updateRadius = (radius: number, waypointId: string) => {
    const waypoint = findWaypoint(waypointId);
    const turnRadiusInNm = radius * meterInNm;
    updateWaypointAndLeg({
      waypoint: { ...waypoint, radius: turnRadiusInNm },
      leg: undefined,
    });
  };

  const removeWaypoint = (waypointId: string) => {
    const route = findRouteById(state.editId);
    if (!route) return;
    const waypoints = route.waypoints;
    if (waypoints != undefined) {
      const legs = route.legs;

      const legBefore = legs ? legs.find((l) => l.toWaypoint === waypointId) : undefined;
      const legAfter = legs ? legs.find((l) => l.fromWaypoint === waypointId) : undefined;
      const wpAfter = legAfter ? waypoints.find((w) => w.waypointId === legAfter.toWaypoint) : undefined;

      if (legs) {
        if (!wpAfter) {
          legs.splice(
            legs.findIndex((l) => l.legId === legBefore?.legId),
            1,
          );
        } else {
          if (legBefore) legBefore.toWaypoint = wpAfter.waypointId;
          legs.splice(
            legs.findIndex((l) => l.legId === legAfter?.legId),
            1,
          );
        }
      }
      waypoints.splice(
        waypoints?.findIndex((wp) => wp.waypointId === waypointId),
        1,
      );
      //This updates the waypoint id number
      sortWaypoints(route);
    }
  };

  const saveRoute = async () => {
    const route = findRouteById(state.detailId);

    if (route) {
      const routeForSaving = {
        ...route,
        calculationResult: undefined,
        userId: detailRoute.value?.userId ?? '00000000-0000-0000-0000-000000000000',
        status: detailRoute.value?.status ?? RouteStatus.Draft,
        validationStatus: detailRoute.value?.validationStatus ?? RouteValidationStatus.NotValidated,
      } as Route;
      route.transient = false;

      const routeManagementClient = await getRouteManagementClient();
      await routeManagementClient.update(routeForSaving);
    }
  };
  const validationStatusIntMap: { [key: string]: number } = {
    notValidated: 0,
    encValidatedOK: 1,
    encValidatedWithWarnings: 2,
    encValidatedWithGroundings: 3,
    encValidatedWithGroundingsAndWarnings: 4,
    nonENCValidatedOK: 5,
    nonENCValidatedWithWarnings: 6,
    nonENCValidatedWithGroundings: 7,
    nonENCValidatedWithGroundingsAndWarnings: 8,
  };

  const saveValidation = async (
    validationStatus: RouteValidationStatus,
    validationStatusWithShipDetails: ValidationStatusWithShipDetails,
  ) => {
    const route = findRouteById(state.detailId);

    if (route && route.routeId) {
      route.validationStatus = validationStatus;
      route.shipName = validationStatusWithShipDetails.shipName;
      route.shipCode = validationStatusWithShipDetails.shipCode;
      route.validationTimeStamp = new Date();
      validationStatusWithShipDetails.validationStatus = validationStatusIntMap[validationStatus];

      const routeManagementClient = await getRouteManagementClient();
      await routeManagementClient.setValidationStatusWithShipDetails(route.routeId, validationStatusWithShipDetails);
    }
  };

  const validationStatus = (route?: DeepReadonly<EnrichedRoute>) => {
    if (!route) return '';

    const validationAbbr = validationAbbrFormatter(route.validationStatus);
    if (route.validationStatus !== RouteValidationStatus.NotValidated) {
      if (route.shipName !== null)
        return `${validationAbbr} ${dateFormatter(route.validationTimeStamp)} - ${route.shipName}`;
      else return `${validationAbbr} ${dateFormatter(route.validationTimeStamp)}`;
    }

    return validationAbbr;
  };

  const valid = (route?: DeepReadonly<EnrichedRoute>) => {
    return route?.validationStatus !== RouteValidationStatus.NotValidated;
  };

  const editing = (route?: DeepReadonly<EnrichedRoute>) => {
    return state.editId !== undefined && route?.routeId === state.editId;
  };

  const monitoring = (route?: DeepReadonly<EnrichedRoute>) => {
    return state.monitorId !== undefined && route?.routeId === state.monitorId;
  };

  const findRouteByWaypointId = (waypointId: string) => {
    return state.routes?.filter((r) => {
      return r.waypoints?.some((w) => w.waypointId == waypointId);
    })[0];
  };

  const isActiveWaypoint = (waypointId: string) => {
    return findRouteByWaypointId(waypointId)?.activeWaypointId == waypointId;
  };

  const setActiveWaypointId = (activeWaypointId: string) => {
    const waypoint = findWaypoint(activeWaypointId);
    const route = findRouteByWaypointId(activeWaypointId);
    if (waypoint !== undefined && route !== undefined && route.activeWaypointId !== activeWaypointId) {
      route.activeWaypointId = activeWaypointId;
    }
  };

  const isRouteValidatedForVessel = (shipCode: string | undefined, route?: DeepReadonly<EnrichedRoute>) => {
    return valid(route) && route?.shipCode === shipCode;
  };

  const addNewIndexLine = (fromWayPointId: string) => {
    const route = findRouteByWaypointId(fromWayPointId);
    const leg = findLeg(fromWayPointId);
    if (!leg || !leg.fromWaypoint || !leg.toWaypoint) return;

    if (route?.indexLines && route?.indexLines?.filter((item) => item.legId === leg.legId).length === 10) {
      return;
    }

    const fromWayPoint = findWaypoint(leg.fromWaypoint);
    const toWayPoint = findWaypoint(leg.toWaypoint);

    const fromWayPointInMeter = latLonPointToMeter({
      latitude: (fromWayPoint?.latitude ?? 0) * degInRad,
      longitude: (fromWayPoint?.longitude ?? 0) * degInRad,
    });
    //fromWayPointInMeter with initial/default displacement or distance from the leg
    const initialPoint = {
      mX: fromWayPointInMeter.mX + Math.cos((leg?.course ?? 0) * degInRad) * 6000,
      mY: fromWayPointInMeter.mY + Math.sin((leg?.course ?? 0) * degInRad) * 3000,
    };
    //Offset vector from Leg-FromWaypoint to New index line start point
    const startPointOffset = vector(
      initialPoint.mX - (fromWayPointInMeter?.mX ?? 0),
      initialPoint.mY - (fromWayPointInMeter?.mY ?? 0),
    );
    /*Rotating the offset/distance vector with the Leg rotaion value to allign the index line as per the rotaion of the leg.
      i.e. rotated angle of Offset from Leg From-Way-point to Leg To-Way-point .
      The rotation is done to make the line position to be relative to the Leg From-Way-point position
    */
    const rotatedStartPointOffset = startPointOffset.setAngle(
      startPointOffset.angle() + (90 - (leg.course ?? 0)) * degInRad,
    );

    const indexline = <IndexLine>{
      indexLineId: uuid(),
      routeId: route?.routeId,
      legId: leg.legId,
      offsetMeterX: rotatedStartPointOffset.x,
      offsetMeterY: rotatedStartPointOffset.y,
      length: calculateDistance(
        { lng: (fromWayPoint?.longitude ?? 0) * degInRad, lat: (fromWayPoint?.latitude ?? 0) * degInRad },
        { lng: (toWayPoint?.longitude ?? 0) * degInRad, lat: (toWayPoint?.latitude ?? 0) * degInRad },
      ),
    };

    route?.indexLines?.push(indexline);
  };

  const updateIndexLine = (indexLineId: string, newStartPositionOffset: MeterPoint, newLength: number) => {
    const route = findRouteById(state.editId);
    route?.indexLines?.forEach((indexLine) => {
      if (indexLine.indexLineId === indexLineId) {
        indexLine.length = newLength;
        indexLine.offsetMeterX = newStartPositionOffset.mX;
        indexLine.offsetMeterY = newStartPositionOffset.mY;
        return false;
      }
    });
  };

  const removeIndexLine = (indexLineId: string) => {
    const route = findRouteById(state.editId);
    route?.indexLines?.splice(
      route?.indexLines?.findIndex((l) => l.indexLineId === indexLineId),
      1,
    );
  };

  const canDeleteWaypoint = (waypointId: string): boolean => {
    return waypointCanBeDeleted(waypointId, editRoute.value?.waypoints);
  };

  const removeCriticalPoint = (criticalPoints: Array<CriticalPoint>, criticalPointId: string, waypointId: string) => {
    if (editRoute.value) {
      const waypoint = editRoute.value.waypoints?.find((wp) => wp.waypointId === waypointId);
      criticalPoints?.splice(
        criticalPoints?.findIndex((l) => l.criticalPointId === criticalPointId),
        1,
      );
      if (waypoint && waypoint.criticalPoints) {
        waypoint.criticalPoints = [...criticalPoints];
        callChartServer(editRoute.value);
      }
    }
  };

  const clearUnpersistedCriticalPoints = () => {
    if (editRoute.value) {
      editRoute.value?.waypoints?.forEach((wp: Waypoint) => {
        wp.criticalPoints?.forEach((cp: CriticalPoint) => {
          if (!cp.persist && wp.criticalPoints && cp.criticalPointId && wp.waypointId) {
            removeCriticalPoint(wp.criticalPoints, cp.criticalPointId, wp.waypointId);
          }
        });
      });
    }
  };

  const setCriticalPoints = (criticalPoints: Array<CriticalPoint>, waypointId: string) => {
    if (editRoute.value) {
      criticalPoints.map((item: CriticalPoint) => (item.persist = true));
      const waypoint = editRoute.value.waypoints?.find((wp) => wp.waypointId === waypointId);
      if (waypoint && waypoint.criticalPoints) {
        waypoint.criticalPoints = [...criticalPoints];
      }
      callChartServer(editRoute.value);
    }
  };

  const getCriticalPointDistanceFromWp = (wayPointId: string, meterPoint: MeterPoint, dragging = false) => {
    let track = [];
    if (!editRoute.value) return;
    if (dragging) {
      const findPreviousLeg = editRoute.value?.legs?.filter((l) => l.toWaypoint === wayPointId)[0];
      const enrichedPrevWpt = editRoute.value?.calculationResult?.waypoints?.find(
        (wp) => findPreviousLeg?.fromWaypoint === wp.waypointId,
      );
      track =
        enrichedPrevWpt?.track?.map((l) => <LatLon>{ latitude: l.latitude, longitude: pipi(l.longitude ?? 0) }) ?? [];
    } else {
      track = getWaypointTrack(editRoute.value, wayPointId) ?? [];
    }
    const distanceEnrichedTrack = getDistanceEnrichedTrack(track, meterPoint);
    const closestLineSegment = [...distanceEnrichedTrack].sort((a, b) => a.distanceFromCp - b.distanceFromCp)[0];
    if (closestLineSegment.index > 0) {
      return (
        distanceEnrichedTrack[closestLineSegment.index - 1].distanceFromTrackStart - closestLineSegment.distanceFromCp
      );
    }
    return closestLineSegment.distanceFromCp;
  };

  const findCriticalpointFromEditRoute = (criticalPointId: string, waypointId: string) => {
    const waypoint = editRoute.value?.waypoints?.find((w) => w.waypointId === waypointId);
    return waypoint?.criticalPoints?.find((cp) => cp.criticalPointId === criticalPointId);
  };

  const setCriticalpointPosition = (
    oldReferenceWaypointId: string,
    newReferenceWaypointId: string,
    criticalPointId: string,
    newPosition: MeterPoint,
  ) => {
    if (editRoute.value) {
      if (oldReferenceWaypointId !== newReferenceWaypointId) {
        // Scenario if the refwaypoint of the existing cp is different after the dragend.
        // Pull out the existing cp properties for the while was mapped with the oldReferenceWaypointId.
        const cp = findCriticalpointFromEditRoute(criticalPointId, oldReferenceWaypointId);
        // find out the prevRefWp
        const prevRefWp = editRoute.value.waypoints?.find((waypoint) => {
          return waypoint.criticalPoints?.find(
            (cp) => cp.criticalPointId === criticalPointId && waypoint.waypointId === oldReferenceWaypointId,
          );
        });
        // Find the new reference wpt from the wpt list.
        const newRefWp = editRoute.value.waypoints?.find((waypoint) => {
          return waypoint.waypointId === newReferenceWaypointId;
        });
        if (!cp || !prevRefWp || !newRefWp) return;
        // Splice the cp from the old reference Wpt.
        prevRefWp?.criticalPoints?.splice(
          prevRefWp?.criticalPoints?.findIndex((cp) => cp.criticalPointId === criticalPointId),
          1,
        );
        if (newRefWp.criticalPoints === undefined) newRefWp.criticalPoints = [];
        // get the new distance from the new reference waypointId & update the cp object.
        cp.distanceFromWaypoint = getCriticalPointDistanceFromWp(newReferenceWaypointId, newPosition, true);
        // Push the criticalPoint to the new reference wpt.
        newRefWp.criticalPoints.push(cp);
      } else {
        // Scenario if the referenceWaypoint has been the same.
        const cp = findCriticalpointFromEditRoute(criticalPointId, newReferenceWaypointId);
        if (!cp) return;
        // Since reference wptId is the same still hence just update the distanceFromWaypoint.
        cp.distanceFromWaypoint = getCriticalPointDistanceFromWp(newReferenceWaypointId, newPosition, true);
      }
      callChartServer(editRoute.value);
    }
  };

  return {
    state: readonly(state),
    canDeleteWaypoint,
    loadRoutes,
    toggleRouteVisibility,
    showDetail,
    closeDetail,
    enrichRoute,
    detailRoute,
    editRoute,
    setWaypointPosition,
    calculateBounds,
    setEditId,
    appendNewWaypoint,
    insertNewWaypoint,
    updateWaypointAndLeg,
    findRouteByWaypointId,
    updateRadius,
    removeWaypoint,
    setNote,
    deleteNote,
    createRoute,
    saveRoute,
    saveValidation,
    removeRouteFromList,
    reloadRoute,
    validationStatus,
    valid,
    editing,
    updateDistanceMarkerConfiguration,
    isActiveWaypoint,
    setActiveWaypointId,
    isRouteValidatedForVessel,
    monitoring,
    setMonitorId,
    monitoredRoute,
    addNewIndexLine,
    findLegById,
    updateIndexLine,
    removeIndexLine,
    findWaypoint,
    findLeg,
    findPreviousLeg,
    insertNewCriticalpoint,
    findRouteById,
    setCriticalPoints,
    removeCriticalPoint,
    clearUnpersistedCriticalPoints,
    setCriticalpointPosition,
    getCriticalPointDistanceFromWp,
  };
});
