<template>
  <pan-row
    :class="{
      'background-edit-mode': isCriticalPointEditable || !criticalPoint?.persist,
    }"
  >
    <div class="tab-container">
      <pan-inline-editable-param
        :default-value="'0'"
        :edit-mode="isCriticalPointEditable || !criticalPoint?.persist"
        :font-size="14"
        :input-width="50"
        :param-key="criticalPoint?.criticalPointId ?? ''"
        :param-value="
          toParamValue(
            criticalPoint?.triggerValue !== undefined ? criticalPoint?.triggerValue?.toString() : '',
            criticalPoint?.monitoringMode === 'time' ? 'min' : 'Nm',
          )
        "
        @changed="$emit('updated', { value: $event, type: 'triggerValue', wpId: wayPointId })"
      />
    </div>
    <div class="messageWidth">
      <pan-inline-editable-text
        id="message"
        :edit-mode="isCriticalPointEditable || !criticalPoint?.persist"
        :font-size="14"
        :white-space="'normal'"
        :placeholder="'Message'"
        :param-key="criticalPoint?.criticalPointId ?? ''"
        :size="20"
        :param-value="criticalPoint?.message"
        @changed="$emit('updated', { value: $event, type: 'message', wpId: wayPointId })"
      />
    </div>
    <icon-pin
      v-if="criticalPointsVisible && !isPanelInReadonlyView"
      class="pin"
      :class="{ pointer: criticalPoint?.persist && criticalPointsVisible && !isPanelInReadonlyView }"
      :disabled="!criticalPoint?.persist"
      @click="$emit('gottocriticalpoint')"
    />
    <pan-dropdown
      v-if="showMenu && !isPanelInReadonlyView"
      class="menu"
      :show-chevron="false"
      :position="{ right: '5px', top: '40px', maxHeight: '350px' }"
    >
      <ecdis-contents>
        <div class="action-button pointer">...</div>
      </ecdis-contents>
      <template #content>
        <ecdis-value-list
          v-slot="{ value }"
          :values="['Edit', 'Delete']"
          @selected="
            $emit('modify', {
              operationType: $event,
              criticalPointId: criticalPoint?.criticalPointId,
              waypointId: wayPointId,
            })
          "
        >
          <ecdis-value>{{ value }}</ecdis-value>
        </ecdis-value-list>
      </template>
    </pan-dropdown>
  </pan-row>
  <pan-row
    v-if="isCriticalPointEditable || !criticalPoint?.persist"
    :class="{
      'background-edit-mode': isCriticalPointEditable || !criticalPoint?.persist,
    }"
  >
    <div class="cell">
      <pan-static-text>WPT</pan-static-text>
    </div>
    <div class="cell right">
      <pan-static-text>{{ wayPointIndex }}</pan-static-text>
    </div>
    <div class="cell">
      <pan-static-text>UNIT</pan-static-text>
    </div>
    <div class="cell right">
      <pan-dropdown :show-chevron="true" :position="{ right: '30px', top: '1%', maxHeight: '350px' }">
        <ecdis-contents>
          <ecdis-value>{{ criticalPoint?.monitoringMode === 'time' ? 'Time' : 'Dist' }}</ecdis-value>
        </ecdis-contents>
        <template #content>
          <ecdis-value-list
            v-slot="{ value }"
            :values="['Time', 'Dist']"
            :current-value="criticalPoint?.monitoringMode === 'time' ? 'Time' : 'Dist'"
            @selected="
              $emit('updated', {
                value: { id: criticalPoint?.criticalPointId, value: $event === 'Time' ? 'min' : 'Nm' },
                type: 'monitoringMode',
                wpId: wayPointId,
              })
            "
          >
            <ecdis-value>{{ value }}</ecdis-value>
          </ecdis-value-list>
        </template>
      </pan-dropdown>
    </div>
  </pan-row>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { CriticalPoint } from '../../generated/RouteManagement';
import { toParamValue } from '../../utils/formatters';

export default defineComponent({
  props: {
    criticalPoint: {
      type: Object as PropType<CriticalPoint>,
      default: undefined,
    },
    wayPointId: {
      type: String,
      default: undefined,
    },
    wayPointIndex: {
      type: Number,
      default: 0,
    },
    criticalPointsVisible: {
      type: Boolean,
      default: undefined,
    },
    isCriticalPointEditable: {
      type: Boolean,
      default: undefined,
    },
    isPanelInReadonlyView: {
      type: Boolean,
      default: undefined,
    },
    showMenu: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: ['updated', 'modify', 'gottocriticalpoint'],
  setup() {
    return {
      toParamValue,
    };
  },
});
</script>
<style scoped>
.menu {
  margin-left: 5%;
  padding: 4px;
}
.pointer {
  cursor: pointer;
}
.pin {
  font-size: 14px;
  margin-left: 2%;
}
.messageWidth {
  width: 172px;
  margin-top: 4px;
}
.background-edit-mode {
  background: #25282e;
}
.tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  max-width: 24%;
  padding-left: 2%;
}
.cell {
  width: 50%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
}
.right {
  padding: 0;
}
.action-button {
  transform: rotate(90deg);
  font-size: 30px;
  margin-left: 4%;
}
.action-button:hover {
  color: var(--indicator_background-active);
}
</style>
