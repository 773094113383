/* eslint-disable no-unused-vars */
export interface Alert {
  id: string;
  category: AlertCategory;
  description: string;
  level: AlertLevel;
  text: string;
  active: boolean;
  acknowledged: boolean;
  timestamp: string;
  type: AlertType;
  isSelected?: boolean;
}

export enum AlertLevel {
  Caution = 'Caution',
  Warning = 'Warning',
  Alarm = 'Alarm',
}

export enum AlertCategory {
  A = 'A',
  B = 'B',
  NotApplicable = 'NotApplicable',
}

export enum AlertType {
  None = 'None',
  PrimaryPosition = 'PrimaryPosition',
  SecondaryPosition = 'SecondaryPosition',
  PrimarySog = 'PrimarySog',
  SecondarySog = 'SecondarySog',
  PrimaryStw = 'PrimaryStw',
  SecondaryStw = 'SecondaryStw',
  PrimaryHeading = 'PrimaryHeading',
  SecondaryHeading = 'SecondaryHeading',
  PrimaryCog = 'PrimaryCog',
  SecondaryCog = 'SecondaryCog',
  PrimaryDepth = 'PrimaryDepth',
  SecondaryDepth = 'SecondaryDepth',
  CcrpDeviation = 'CcrpDeviation',
  CrossTrackLimit = 'CrossTrackLimit',
  MonitoringStopped = 'MonitoringStopped',
}
