<template>
  <div
    v-for="value in values"
    :key="value"
    :tabindex="1"
    class="listitem"
    :class="{ selected: currentValue === value, disabled: isSelectionDisabled(value) && currentValue !== value }"
    @click="!isSelectionDisabled(value) ? $emit('selected', { id: paramKey, value }) : ''"
  >
    <slot :value="value" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    paramKey: {
      type: String,
      required: true,
    },
    values: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    currentValue: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    disabledValues: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },
  emits: ['selected'],
  setup(props) {
    const isSelectionDisabled = (value: string) => props.disabledValues.includes(value);

    return {
      isSelectionDisabled,
    };
  },
});
</script>

<style scoped>
.listitem {
  padding: 10px 8px;
  height: 48px;
  flex-shrink: 0;
  border-bottom: 1px solid black;
  color: var(--text_main);
  outline: none;
  cursor: pointer;
}
.listitem.selected {
  background: black;
}
.listitem.selected,
.listitem.selected ::v-deep(*) {
  color: var(--highlight);
}
.listitem.disabled,
.listitem.disabled ::v-deep(*) {
  color: #6e7175;
  background: #33373d;
}
</style>
