<template>
  <div class="flex flex-col overflow-hidden bg-background_layer_below p-2 text-center">
    <button disabled class="btn btn-xs !py-0">NOM scale</button>
    <div class="truncate text-text_main" :title="formattedScale">1:{{ formattedScale }}</div>
  </div>
</template>

<script lang="ts" setup>
/**
 * Show the current chart scale.
 *
 * The button is disabled because changing to mode is not implemented yet
 */
import { computed } from 'vue';
import { scaleFormatter } from '../../../utils/formatters';

const props = defineProps<{ scale: number }>();

const formattedScale = computed(() => scaleFormatter(props.scale));
</script>
