import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';

export enum DisplayVector {
  True = 'True',
  Rel = 'Relative',
}

type State = {
  displayVectorType: DisplayVector;
  vectorLengthInMinutes: number;
  displayVectors: boolean;
};

export const displayVectorCtx = defineContext(() => {
  const state: State = reactive({
    displayVectorType: DisplayVector.True,
    vectorLengthInMinutes: 6,
    displayVectors: true,
  });

  const setDisplayVectorType = (vectorType: DisplayVector) => {
    state.displayVectorType = vectorType;
  };

  const setDisplayLength = (vectorLength: number) => {
    state.displayVectors = vectorLength > 0;
    state.vectorLengthInMinutes = vectorLength > 0 ? vectorLength : 0;
  };

  const isTrueVector = () => state.displayVectorType === DisplayVector.True;

  const displayVectors = () => state.displayVectors;

  return {
    state: readonly(state),
    setDisplayVectorType,
    setDisplayLength,
    isTrueVector,
    displayVectors,
  };
});
