import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';

type State = {
  loading: boolean;
  error: boolean;
};

export const imageLoadingCtx = defineContext(() => {
  const state: State = reactive({
    loading: false,
    error: false,
  });

  const setLoading = (loading: boolean) => {
    state.loading = loading;
  };

  const setError = (error: boolean) => {
    state.error = error;
  };

  return {
    state: readonly(state),
    setLoading,
    setError,
  };
});
