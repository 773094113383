<template>
  <div :class="[{ disabled: disabled }, size]" class="inline-block text-text_main">
    <div v-if="label" class="label" :class="{ '!text-left': labelAlignment === 'left' }">
      {{ label }}
    </div>
    <div class="main" :class="{ 'items-baseline': unit }">
      <div v-if="labelAlignment === 'center'" class="invisible" style="visibility: hidden">
        <div v-if="unit" class="unit invisible">{{ unit }}</div>
        <div v-else-if="unitPreset" class="unit-preset invisible">
          {{ unitPresetLabel }}
        </div>
      </div>
      <div class="value">{{ value }}</div>
      <div v-if="unit" class="unit">{{ unit }}</div>
      <div v-else-if="unitPreset" class="unit-preset">{{ unitPresetLabel }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    label?: string;
    labelAlignment?: 'left' | 'center';
    size?: 'xxs' | 'xs' | 'md' | 'lg';
    unit?: string;
    unitPreset?: 'deg' | 'degCelcius' | 'degPerMin';
    value?: string;
  }>(),
  {
    disabled: undefined,
    label: undefined,
    labelAlignment: 'center',
    size: 'md',
    unit: undefined,
    unitPreset: undefined,
    value: '',
  },
);

const unitPresetLabel = computed(
  () =>
    ({
      deg: '°',
      degCelcius: '°C',
      degPerMin: '°/min',
    }[props.unitPreset]),
);
</script>

<style scoped>
.main {
  @apply flex leading-none;
}
.label {
  @apply text-center text-text_static;
}
.unit {
  @apply ml-0.5 text-text_static;
}
.unit-preset {
  @apply text-xl leading-none text-text_static;
}

.xxxs {
  .value {
    @apply text-kpi-xxxs;
  }
  .unit-preset,
  .unit {
    @apply ml-1 text-sm leading-none;
  }
}

.xxs {
  .label {
    @apply text-xs;
  }
  .value {
    @apply text-kpi-xxs;
  }
}
.xs {
  .value {
    @apply text-kpi-xs;
  }
}
.sm {
  .value {
    @apply text-kpi-sm;
  }
}
.md {
  .label {
    @apply text-lg;
  }
  .value {
    @apply text-kpi-md;
  }
  .unit {
    @apply text-xl;
  }
  .unit-preset {
    @apply text-[2.8125rem]; /* 45px */
  }
}
.lg {
  .label {
    @apply text-lg;
  }
  .value {
    @apply text-kpi-lg;
  }
  .unit {
    @apply ml-2 text-3xl;
  }
  .unit-preset {
    @apply text-[2.8125rem]; /* 45px */
  }
}

.disabled {
  .label {
    @apply text-button_text-disabled;
  }
  .main {
    @apply invisible;
  }
}
</style>
