<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16.5" r="9.5" stroke="#E60F0F" />
    <path d="M22.7173 23.2175L9.28223 9.78247" stroke="#E60F0F" />
    <path d="M9.28223 23.2175L22.7173 9.7825" stroke="#E60F0F" />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
