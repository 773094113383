import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { ContextType } from 'vue-context-composition';
import { criticalPointsSettingCtx } from '../../../contexts/criticalPointsSetting';
import { routesCtx } from '../../../contexts/routes';
import { viewportCtx } from '../../../contexts/viewport';
import { LngLatPoint, MeterPoint } from '../../../types';
import {
  calculateDistance,
  degInRad,
  latLonPointToMeter,
  meterToLngLat,
  mod2pi,
  radInDeg,
} from '../../../utils/conversions';
import { CriticalPointsLabel, WpDist, getRefinedTrackPoints } from '../../../utils/criticalPointUtils';
import { vector } from '../../../utils/vector';

type trackPointBoundaryArtifacts = {
  previousBoundingLine: ReturnType<typeof vector> | undefined;
  nextBoundingLine: ReturnType<typeof vector> | undefined;
  closestPoint: WpDist;
  closestPointVector: ReturnType<typeof vector>;
  magnitudePreviousBoundingLine: number | undefined;
  magnitudeNextBoundingLine: number | undefined;
  angle: number;
};

export function addCriticalPoints(
  criticalPointLabel: CriticalPointsLabel,
  criticalPointsGroup: Konva.Group,
  { transformToAbsolute, transformToViewport }: ContextType<typeof viewportCtx>,
  routeCtxRef: ContextType<typeof routesCtx>,
  criticalPointsSettingCtxRef: ContextType<typeof criticalPointsSettingCtx>,
  color: string,
  isMonitored: boolean,
  routeId: string | undefined,
): void {
  if (!criticalPointLabel.wpId || !routeId) return;
  const leg = routeCtxRef.findPreviousLeg(criticalPointLabel.wpId);
  if (!leg?.course) return;
  const pxPointForCpLabel = transformToAbsolute(criticalPointLabel.criticalPointLabelMeterPoint);
  const enrichedRoute = routeCtxRef.findRouteById(routeId);
  let waypointId = '';
  const tracks = getRefinedTrackPoints(enrichedRoute);

  const visualsGroup = new Konva.Group();
  const criticalPointLine = new Konva.Line({
    points: [0, 0, 0, -6],
    stroke: color,
    strokeWidth: 1,
    hitStrokeWidth: 20,
  });
  const criticalPointCircle = new Konva.Circle({
    offsetX: 0,
    offsetY: 20,
    radius: 14,
    strokeWidth: 1,
    stroke: color,
    strokeScaleEnabled: false,
  });
  const criticalPointText = new Konva.Text({
    text: criticalPointLabel.displayText,
    fontFamily: 'Lato',
    fill: 'black',
    fontSize: 12,
  });
  const boundaryArtifacts = getBoundaryForCriticalPoints(criticalPointLabel.criticalPointLabelMeterPoint);
  visualsGroup
    .add(criticalPointLine, criticalPointText, criticalPointCircle)
    .absolutePosition(pxPointForCpLabel)
    .draggable(true)
    .rotation(boundaryArtifacts.angle)
    .dragBoundFunc((pos) => dragBoundFunctionForCP(pos));
  criticalPointsGroup.add(visualsGroup);
  alignCpText(visualsGroup, -1 * boundaryArtifacts.angle);

  const stage = criticalPointsGroup.getStage();
  if (!stage || isMonitored) return;
  visualsGroup.on('mousedown touchstart', (ev) => {
    // cancel dragging of stage
    ev.cancelBubble = true;
    if (ev.evt.button === 0) {
      const routeInfo = routeCtxRef.findRouteByWaypointId(criticalPointLabel.wpId);
      if (routeInfo?.routeId && routeInfo.name) {
        criticalPointsSettingCtxRef.setRouteInfo(routeInfo.routeId, routeInfo.name, false);
        criticalPointsSettingCtxRef.showPanel();
      }
    }
  });
  visualsGroup.on('mouseover', (ev) => {
    stage.container().style.cursor = 'move';
    // cancel unset of cursor
    ev.cancelBubble = true;
  });

  visualsGroup.on('dragend', (ev) => {
    ev.cancelBubble = true;
    const pos = visualsGroup.getAbsolutePosition();
    if (!pos) return;
    repositionCriticalPoint(transformToViewport(pos));
  });

  function repositionCriticalPoint(position: MeterPoint) {
    if (
      !criticalPointLabel.detail ||
      !criticalPointLabel.detail.criticalPointId ||
      !criticalPointLabel.detail.waypointId
    )
      return;
    routeCtxRef.setCriticalpointPosition(
      criticalPointLabel.wpId,
      waypointId,
      criticalPointLabel.detail.criticalPointId,
      position,
    );
  }

  function alignCpText(visualsGroup: Konva.Group, angleDegrees: number) {
    const text = visualsGroup.find('Text')[0];
    if (text) {
      const angleRadians = (angleDegrees * Math.PI) / 180;
      text.rotation(angleDegrees);
      const yOffset = 25 * Math.cos(angleRadians);
      text.offsetY(yOffset < -15 ? -15 : yOffset);
      text.offsetX(text.getClientRect().width / 2 + 25 * Math.sin(angleRadians));
    }
  }

  function getBoundaryForCriticalPoints(pointerPosition: MeterPoint): trackPointBoundaryArtifacts {
    const closestPoint = tracks
      .map(
        (p, index) =>
          <WpDist>{
            distance: calculateDistance(
              {
                lng: p?.position.longitude ?? 0,
                lat: p?.position.latitude ?? 0,
              },
              <LngLatPoint>{
                lng: meterToLngLat(pointerPosition).lng * degInRad,
                lat: meterToLngLat(pointerPosition).lat * degInRad,
              },
            ),
            waypoint: p?.waypoint,
            index: index,
          },
      )
      .sort((a, b) => a.distance - b.distance)[0];

    const previousLineSegmentPoint = tracks[closestPoint.index - 1]?.position;
    const closestLineSegmentPoint = tracks[closestPoint.index]?.position;
    const nextLineSegmentPoint = tracks[closestPoint.index + 1]?.position;

    const previousMeterPoint = previousLineSegmentPoint
      ? latLonPointToMeter({
          longitude: previousLineSegmentPoint.longitude,
          latitude: previousLineSegmentPoint.latitude,
        })
      : undefined;
    const previousAbsolute = previousMeterPoint ? transformToAbsolute(previousMeterPoint) : undefined;
    const previousPointVector = previousAbsolute ? vector(previousAbsolute.x, previousAbsolute.y) : undefined;

    const closestMeterPoint = latLonPointToMeter({
      longitude: closestLineSegmentPoint?.longitude,
      latitude: closestLineSegmentPoint?.latitude,
    });
    const closestAbsolute = transformToAbsolute(closestMeterPoint);
    const closestPointVector = vector(closestAbsolute.x, closestAbsolute.y);

    const nextMeterPoint = nextLineSegmentPoint
      ? latLonPointToMeter({
          longitude: nextLineSegmentPoint.longitude,
          latitude: nextLineSegmentPoint.latitude,
        })
      : undefined;
    const nextAbsolute = nextMeterPoint ? transformToAbsolute(nextMeterPoint) : undefined;
    const nextPointVector = nextAbsolute ? vector(nextAbsolute.x, nextAbsolute.y) : undefined;

    //Calculations
    const previousBoundingLine = previousPointVector?.subtract(closestPointVector);
    const nextBoundingLine = nextPointVector?.subtract(closestPointVector);
    const absPosition = transformToAbsolute(pointerPosition);
    const pointerPositionVector = vector(absPosition.x, absPosition.y);
    const closestPointToPointerVector = pointerPositionVector.subtract(closestPointVector);
    const magnitudePreviousBoundingLine = previousBoundingLine
      ? closestPointToPointerVector.dot(previousBoundingLine.setLength(1))
      : undefined;
    const magnitudeNextBoundingLine = nextBoundingLine
      ? closestPointToPointerVector.dot(nextBoundingLine.setLength(1))
      : undefined;

    //This is the current waypoint
    waypointId =
      (magnitudePreviousBoundingLine ?? -999999) > (magnitudeNextBoundingLine ?? -999999)
        ? tracks[closestPoint.index]?.waypoint ?? ''
        : tracks[closestPoint.index + 1]?.waypoint ?? '';

    return {
      previousBoundingLine: previousBoundingLine,
      nextBoundingLine: nextBoundingLine,
      angle:
        (mod2pi((previousBoundingLine ?? vector())?.subtract(nextBoundingLine ?? vector()).angle() ?? 0) - Math.PI) *
        radInDeg,
      closestPoint: closestPoint,
      closestPointVector: closestPointVector,
      magnitudePreviousBoundingLine: magnitudePreviousBoundingLine,
      magnitudeNextBoundingLine: magnitudeNextBoundingLine,
    };
  }

  function dragBoundFunctionForCP(position: Vector2d): Vector2d {
    const pointerPositionMeterPoint = transformToViewport(position);
    const boundaryArtifactsOnDrag = getBoundaryForCriticalPoints(pointerPositionMeterPoint);
    visualsGroup.rotation(boundaryArtifactsOnDrag.angle);
    alignCpText(visualsGroup, -1 * boundaryArtifactsOnDrag.angle);

    if (
      (boundaryArtifactsOnDrag.magnitudeNextBoundingLine &&
        !boundaryArtifactsOnDrag.magnitudePreviousBoundingLine &&
        boundaryArtifactsOnDrag.magnitudeNextBoundingLine < 0) ||
      (boundaryArtifactsOnDrag.magnitudePreviousBoundingLine &&
        !boundaryArtifactsOnDrag.magnitudeNextBoundingLine &&
        boundaryArtifactsOnDrag.magnitudePreviousBoundingLine < 0)
    ) {
      return { x: boundaryArtifactsOnDrag.closestPointVector.x, y: boundaryArtifactsOnDrag.closestPointVector.y };
    }

    //The magnitude will be negative along one bounding line and positive along the other
    const magnitude = Math.max(
      boundaryArtifactsOnDrag.magnitudePreviousBoundingLine ?? -99999,
      boundaryArtifactsOnDrag.magnitudeNextBoundingLine ?? -9999,
    );
    const boundingLine =
      (boundaryArtifactsOnDrag.magnitudePreviousBoundingLine ?? -9999) >
      (boundaryArtifactsOnDrag.magnitudeNextBoundingLine ?? -9999)
        ? boundaryArtifactsOnDrag.previousBoundingLine
        : boundaryArtifactsOnDrag.nextBoundingLine;
    const change = boundingLine?.setLength(magnitude);
    if (change) {
      return {
        x: boundaryArtifactsOnDrag.closestPointVector.x + change.x,
        y: boundaryArtifactsOnDrag.closestPointVector.y + change.y,
      };
    }
    return { x: boundaryArtifactsOnDrag.closestPointVector.x, y: boundaryArtifactsOnDrag.closestPointVector.y };
  }
}
