<template>
  <signi-tools-list
    :items="positionFixMarkers"
    title="Position fix"
    @update:visible="updateListVisibility"
    @add="createPositionFixMarker()"
  >
    <template #default="{ item }">
      <signi-position-fix-marker
        class="border-l-4 border-background_layer_below"
        :class="{ 'border-feedback_text': editId === item.id }"
        :model-value="item"
        @update-type="updateFixType"
        @add-position-type="addBearingRange"
        @update-show-lops="toggleShowLops"
        @start-edit="switchToEdit"
        @update-bearing-range="updateBearingRange"
        @remove-lop="removeLop"
        @cancel-edit="cancelEditing"
        @create-position-fix-on-chart="createFix"
        @update-position-fix-on-chart="updateFix"
        @delete-position-fix="deleteFix"
        @update-visibility="updatePositionFixVisibility"
        @pin="pinPositionFix"
      />
    </template>
  </signi-tools-list>
</template>

<script setup lang="ts">
import { useContext } from 'vue-context-composition';
import { viewportCtx } from '../../../contexts/viewport';
import { useToolsStore } from './useToolsStore';

const emit = defineEmits(['markersUpdated']);

const { viewport } = useContext(viewportCtx);

const {
  addBearingRangeToPositionFixMarker,
  cancelEditing,
  createPositionFix,
  createPositionFixMarker,
  deletePositionFix,
  editId,
  pinPositionFix,
  positionFixMarkers,
  removeLop,
  switchToEdit,
  toggleShowLops,
  updateBearingRange,
  updateFixType,
  updateListVisibility,
  updatePositionFix,
  updatePositionFixVisibility,
} = useToolsStore()!;

const emitUpdate = () => emit('markersUpdated', JSON.stringify(positionFixMarkers.value ?? ''));

const addBearingRange = (groupId: string, type: 'Bearing' | 'Range') => {
  addBearingRangeToPositionFixMarker(groupId, type, viewport.center);
};
const createFix = (groupId: string) => {
  const created = createPositionFix(groupId);
  if (created) emitUpdate();
};
const updateFix = (groupId: string) => {
  const updated = updatePositionFix(groupId);
  if (updated) emitUpdate();
};
const deleteFix = (groupId: string) => {
  deletePositionFix(groupId);
  emitUpdate();
};
</script>
