<template>
  <div class="title">
    <div class="name">K-Sim Planning Station</div>
    <div class="time">
      {{ time }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';

const formatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h23',
};
const timeFormat = new Intl.DateTimeFormat('default', formatOptions);

export default defineComponent({
  setup() {
    const time = ref('');
    let handle: ReturnType<typeof setInterval>;
    onMounted(() => {
      handle = setInterval(() => {
        time.value = timeFormat.format();
      });
    });
    onBeforeUnmount(() => {
      clearInterval(handle);
    });
    return {
      time,
    };
  },
});
</script>

<style scoped>
.title {
  margin: 0 16px;
}
.name {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #eaeaea;
}
.time {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #a2aab9;
}
</style>
