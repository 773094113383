/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ref, isRef, ref, watch } from 'vue';

export type AsyncFunction<T, P> = (params: P | undefined, signal: AbortSignal) => Promise<T>;

export type AsyncFunctionReturn<T> = {
  isLoading: Ref<boolean>;
  error: Ref<any>;
  data: Ref<T | undefined>;
  abort: () => void;
  retry: () => void;
};

/**
 * Async helper function that returns three reactive values:
 * * `isLoading`, a boolean that is true during pending state;
 * * `data`, contains the resolved value in the fulfilled state; and
 * * `error`, contains the exception in the rejected state.
 *
 * It returns the following functions as well:
 * * `abort`, that aborts the current promise
 * * `retry`, that retries the original promise
 *
 * @param promiseFn (optionally ref to) function that returns a Promise.
 * @param params (optionally ref to) parameters passed as first argument to the promise function.
 * @returns Object literal containing `isLoading`, `error` and `data` value wrappers and `abort` and `retry`
 * functions.
 */
export function useAsync<T, P>(
  promiseFn: AsyncFunction<T, P> | Ref<AsyncFunction<T, P>>,
  params?: P | Ref<P | undefined>,
): AsyncFunctionReturn<T> {
  // wrap arguments in ref if not provided
  const wrapPromiseFn = isRef(promiseFn) ? promiseFn : ref(promiseFn);
  const wrapParams = isRef(params) ? params : ref(params);

  // create empty return values
  const isLoading = ref<boolean>(false);
  const error = ref<any>();
  const data = ref<T>();

  // abort controller
  let controller: AbortController | undefined;

  function abort() {
    isLoading.value = false;
    if (controller !== undefined) {
      controller.abort();
      controller = undefined;
    }
  }

  function retry() {
    // unwrap the original promise as it is optionally wrapped
    const origPromiseFn = wrapPromiseFn.value;
    // create a new promise and trigger watch
    wrapPromiseFn.value = async (params, signal) => origPromiseFn(params, signal);
  }

  // watch for change in arguments, which triggers immediately initially
  const watched: [typeof wrapPromiseFn, typeof wrapParams] = [wrapPromiseFn, wrapParams];
  watch(
    watched,
    async ([newPromiseFn, newParams]) => {
      try {
        abort();
        isLoading.value = true;
        controller = new AbortController();
        const result = await newPromiseFn(newParams as P, controller.signal);
        error.value = undefined;
        data.value = result;
      } catch (e) {
        error.value = e;
        data.value = undefined;
      } finally {
        isLoading.value = false;
      }
    },
    { immediate: true },
  );

  return {
    isLoading,
    error,
    data,
    abort,
    retry,
  };
}
