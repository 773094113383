import { reactive } from 'vue';
import { defineContext } from 'vue-context-composition';
import { persist } from '../utils/persist';

export type OwnshipSettings = {
  trueScaleOutline: boolean;
  headingLine: boolean;
  showMinuteMarks: boolean;
  minuteMarksInterval: number;
};

type State = {
  settings: OwnshipSettings;
};

const defaultOwnshipSettings: OwnshipSettings = {
  trueScaleOutline: false,
  headingLine: true,
  showMinuteMarks: false,
  minuteMarksInterval: 3,
};

export const ownshipSettingsCtx = defineContext(() => {
  const state: State = persist(
    'defaultOwnshipSettings.v2',
    reactive({
      settings: defaultOwnshipSettings,
    }),
  );

  return {
    state,
  };
});
