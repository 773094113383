import Konva from 'konva';
import { watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { objectInfoCtx } from '../../contexts/objectInfo';
import { viewportCtx } from '../../contexts/viewport';

export function addChartInfoMarker(absoluteLayer: Konva.Layer): void {
  const { state } = useContext(objectInfoCtx);
  const { viewport, transformToAbsolute } = useContext(viewportCtx);
  const markerGroup = new Konva.Group();
  absoluteLayer.add(markerGroup);

  watch(
    [() => state, () => viewport.matrix],
    () => {
      markerGroup.destroyChildren();

      if (!state.visible || !state.position) {
        return;
      }
      const { x, y } = transformToAbsolute(state.position);
      for (let i = 0; i < 4; i++) {
        const corner = new Konva.Line({
          points: [0, 6, 0, 0, 6, 0],
          absolutePosition: { x, y },
          offset: { x: 9, y: 9 },
          stroke: 'orange',
          strokeWidth: 3,
          rotation: i * 90,
        });
        markerGroup.add(corner);
      }
    },
    { deep: true },
  );
}
