<template>
  <input
    v-model="value"
    class="search-box"
    type="text"
    :placeholder="placeholderText"
    @pointerdown.stop
    @keyup="changed"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    model: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const value = ref(props.model);
    const placeholderText = ref(props.placeholder);

    const changed = () => emit('changed', value.value);

    return {
      placeholderText,
      value,
      changed,
    };
  },
});
</script>

<style scoped>
.search-box {
  background-color: var(--input_background-default);
  color: var(--text_static);
  height: 100%;
  font-family: Lato;
  font-size: 16px;
  border: none;
  outline: none;
  padding-left: 12px;
}
</style>
