<template>
  <SwitchGroup>
    <div class="contents">
      <SwitchLabel v-if="label">{{ label }}</SwitchLabel>
      <Switch
        v-bind="$attrs"
        :model-value="modelValue"
        :class="modelValue && !disabled ? 'bg-button_text-selected' : 'bg-button_background-disabled'"
        class="relative inline-flex h-2 w-6 items-center rounded-full text-text_main shadow-below-sm transition-colors"
        :disabled="disabled"
        @update:model-value="$emit('update:modelValue', $event)"
      >
        <span
          :class="modelValue ? 'translate-x-2.5' : '-translate-x-px'"
          class="inline-block h-4 w-4 transform rounded-full bg-button_background-default shadow-sm transition-transform"
        />
      </Switch>
    </div>
  </SwitchGroup>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

withDefaults(defineProps<{ disabled?: boolean; label?: string; modelValue: boolean }>(), {
  label: undefined,
  modelValue: false,
});
defineEmits(['update:modelValue']);
</script>
