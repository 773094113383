<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M8.80761 18.1924L20.6399 11.6399L13.7574 23.1421L11.2825 20.6673L8.80761 18.1924Z"
      stroke="#A2AAB9"
      stroke-linejoin="round"
    />
  </svg>
</template>
<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
