import { App } from 'vue';
import { createContext } from 'vue-context-composition';
import { appsCtx } from './contexts/apps';
import { contextMenuCtx } from './contexts/contextMenu';
import { controlModesCtx } from './contexts/controlModes';
import { criticalPointsSettingCtx } from './contexts/criticalPointsSetting';
import { cursorCtx } from './contexts/cursor';
import { displayGroupsCtx } from './contexts/displayGroups';
import { displayVectorCtx } from './contexts/displayVector';
import { eblVrmCtx } from './contexts/eblVrm';
import { graticuleCtx } from './contexts/graticule';
import { imageLoadingCtx } from './contexts/imageLoading';
import { marinersNotesCtx } from './contexts/marinersNotes';
import { messageCtx } from './contexts/messages';
import { monitoringCtx } from './contexts/monitoring';
import { objectInfoCtx } from './contexts/objectInfo';
import { ownshipSettingsCtx } from './contexts/ownshipSettings';
import { routesCtx } from './contexts/routes';
import { routeSettingsCtx } from './contexts/routeSettings';
import { routeValidationCtx } from './contexts/routeValidation';
import { safetySettingCtx } from './contexts/safetySetting';
import { targetDataCtx } from './contexts/targetData';
import { targetSettingsCtx } from './contexts/targetSettings';
import { toolTipCtx } from './contexts/toolTip';
import { vesselCtx } from './contexts/vessels';
import { viewportCtx } from './contexts/viewport';

export function provideContexts(app: App<Element>): void {
  app
    .provide(...createContext(graticuleCtx))
    .provide(...createContext(controlModesCtx))
    .provide(...createContext(displayGroupsCtx))
    .provide(...createContext(viewportCtx))
    .provide(...createContext(targetDataCtx))
    .provide(...createContext(cursorCtx))
    .provide(...createContext(routesCtx))
    .provide(...createContext(appsCtx))
    .provide(...createContext(imageLoadingCtx))
    .provide(...createContext(routeValidationCtx))
    .provide(...createContext(objectInfoCtx))
    .provide(...createContext(vesselCtx))
    .provide(...createContext(contextMenuCtx))
    .provide(...createContext(safetySettingCtx))
    .provide(...createContext(routeSettingsCtx))
    .provide(...createContext(messageCtx))
    .provide(...createContext(eblVrmCtx))
    .provide(...createContext(toolTipCtx))
    .provide(...createContext(displayVectorCtx))
    .provide(...createContext(ownshipSettingsCtx))
    .provide(...createContext(targetSettingsCtx))
    .provide(...createContext(monitoringCtx))
    .provide(...createContext(criticalPointsSettingCtx))
    .provide(...createContext(marinersNotesCtx));
}
