<template>
  <div class="flex grow flex-col">
    <div class="flex">
      <signi-icon :name="MarinerNoteIcons[note.type]" class="mariners-note" />
      <div class="grow">
        <div class="flex items-center">
          <h3 class="grow text-text_static">
            {{ MarinerNoteHeadings[note.type] }}
          </h3>
          <button title="Select Note for Edit Mode" class="text-text_main" @click="emit('select:note', note.id)">
            <signi-icon name="EditIcon" class="h-8 w-10"></signi-icon>
          </button>
          <button title="GoTo Note" class="text-text_main" @click="emit('goToPin', note.points[0])">
            <signi-icon name="TargetpinIcon"></signi-icon>
          </button>
        </div>
        <div class="font-normal">
          <p class="text-text_main">{{ note.description ? note.description : '-' }}</p>
          <div v-show="showPositionInfo" class="latLng">
            <pan-static-text class="position">{{ latitude + ' N' }}</pan-static-text>
            <pan-static-text class="position">{{ longitude + ' E' }}</pan-static-text>
          </div>
          <div v-show="false" class="flex justify-between">
            <p class="ml-auto text-text_static">
              <span>UTC: {{ time }}</span> <span class="ml-2">{{ date }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatInTimeZone } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';
import { computed, ref } from 'vue';
import { MarinersNote } from '../../../../contexts/marinersNotes';
import { formatPositionDegree } from '../../../../utils/formatters';
import { MarinerNoteHeadings, MarinerNoteIcons, pointTypeNotes } from '../../../../utils/marinersNotesUtils';

const props = defineProps<{ note: MarinersNote }>();
const emit = defineEmits(['select:note', 'goToPin']);

const date = computed(() =>
  props.note?.updatedAt
    ? formatInTimeZone(props.note?.updatedAt, 'UTC', 'dd MMM. yyyy', { locale: enGB })
    : '--/--/----',
);
const time = computed(() =>
  props.note?.updatedAt ? formatInTimeZone(props.note?.updatedAt, 'UTC', 'HH:mm:ss', { locale: enGB }) : '--:--:--',
);
const showPositionInfo = ref(pointTypeNotes.includes(props.note?.type));

const latitude = computed(() => (props.note.points[0].lat ? formatPositionDegree(props.note?.points[0].lat) : '-'));

const longitude = computed(() => (props.note.points[0].lng ? formatPositionDegree(props.note?.points[0].lng) : '-'));
</script>

<style scoped>
.mariners-note {
  color: rgba(var(--mariners_notes_default-rgb), 1);
  @apply m-1 mr-2;
}
.latLng {
  @apply flex w-full justify-start pt-2;
}
.position {
  @apply pr-3;
}
</style>
