import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { watch, watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension, isPlanningStation } from '../../../configuration';
import { MarinersCollection, MarinersNote, marinersNotesCtx } from '../../../contexts/marinersNotes';
import { viewportCtx } from '../../../contexts/viewport';
import { NoteType } from '../../../generated/Navigation';
import { halfPi, lngLatToMeter, meterToLngLat, radInDeg } from '../../../utils/conversions';
import { getImagePath, pointTypeNotes } from '../../../utils/marinersNotesUtils';

export async function addMarinersNotes(absoluteLayer: Konva.Layer): Promise<void> {
  const { updatePointPosition, visibleCollectionNotes, selectMarinersNote } = useContext(marinersNotesCtx);
  const colors = {
    unselectedNodeOrLine: '#C66A31',
    selectedNodeOrLine: '#FF7A00',
    unselectedfilledArea: 'rgba(255, 122, 0, 0.2)',
    selectedFilledArea: 'rgba(255, 122, 0, 0.3)',
    selectedLineOrNode: 'rgba(255, 122, 0, 0.5)',
  };
  const { transformToAbsolute, transformToViewport } = useContext(viewportCtx);
  const marinersNotesGroup = new Konva.Group();
  const selectMarinerNoteOnChart = (note: MarinersNote, event: KonvaEventObject<MouseEvent>, collectionId?: string) => {
    event.cancelBubble = true;
    if (!note.id || note.selected) return;
    // clicking on the line of the chart note sets the node to active state.
    selectMarinersNote(note.id, collectionId);
  };

  const createImageNote = (type: NoteType) => {
    return new Promise<Konva.Image>((resolve) =>
      Konva.Image.fromURL(getImagePath(type), (imageNode: Konva.Image) => {
        resolve(imageNode);
      }),
    );
  };

  const cautionNoteImage: Konva.Image = await createImageNote(NoteType.Caution);
  const eventNoteImage: Konva.Image = await createImageNote(NoteType.Event);
  const informationNoteImage: Konva.Image = await createImageNote(NoteType.Information);

  absoluteLayer.add(marinersNotesGroup);

  const drawPointNotes = (note: MarinersNote, collectionId?: string) => {
    if (!note?.points || !note.id) return;
    const mp = lngLatToMeter({
      lng: radInDeg * (note.points[0].lng ?? 0),
      lat: radInDeg * halfPi(note.points[0].lat ?? 0),
    });
    const { x, y } = transformToAbsolute(mp);

    const stage = marinersNotesGroup.getStage();
    const iconSize = 32;

    const pointNote = marinersNotesGroup.children?.find((image) => image.getAttr('id') === note.id);
    if (pointNote) {
      pointNote.destroy();
    }

    let imageNode: Konva.Image;

    switch (note.type) {
      case NoteType.Caution:
        imageNode = cautionNoteImage.clone();
        break;
      case NoteType.Information:
        imageNode = informationNoteImage.clone();
        break;
      case NoteType.Event:
        imageNode = eventNoteImage.clone();
        break;
      default:
        return;
    }

    imageNode.setAttrs({
      x,
      y,
      width: iconSize,
      height: iconSize,
      fill: note.selected ? colors.selectedLineOrNode : '',
      draggable: true,
    });

    marinersNotesGroup.add(imageNode);

    imageNode.on('mousedown touchstart', (ev: KonvaEventObject<MouseEvent>) =>
      selectMarinerNoteOnChart(note, ev, collectionId),
    );

    if (!stage) return;
    imageNode.on('dragend', () => {
      const pos = stage.getPointerPosition();
      if (!pos || !note.id) return;
      const meterPoint = transformToViewport(pos);
      updatePointPosition(note.id, meterToLngLat(meterPoint), 0, collectionId);
    });
  };

  const drawLineNotes = (note: MarinersNote, collectionId?: string) => {
    if (!note?.points || !note.id) return;
    const flattendPoints = note.points.flatMap((item) => {
      const mp = lngLatToMeter({
        lng: radInDeg * (item.lng ?? 0),
        lat: radInDeg * halfPi(item.lat ?? 0),
      });
      const linePixelPoints = transformToAbsolute(mp);
      return [linePixelPoints.x, linePixelPoints.y] ?? [];
    });
    const shadowLine = new Konva.Line({
      points: flattendPoints,
      stroke: colors.selectedLineOrNode,
      strokeWidth: note.type === NoteType.SafetyContour ? 14 : 12,
      closed: note.type === NoteType.UnfilledArea || note.type === NoteType.FilledArea,
      strokeScaleEnabled: false,
      visible: note.selected,
    });
    const marinersLine = new Konva.Line({
      points: flattendPoints,
      fill:
        note.type === NoteType.FilledArea
          ? note.selected
            ? colors.selectedFilledArea
            : colors.unselectedfilledArea
          : '',
      stroke: note.selected ? colors.selectedLineOrNode : colors.unselectedNodeOrLine,
      strokeWidth: note.type === NoteType.SafetyContour ? 5 : 2,
      closed: note.type === NoteType.UnfilledArea || note.type === NoteType.FilledArea,
      dash: note.type === NoteType.DashedLine ? [33, 10] : [],
      strokeScaleEnabled: false,
      hitStrokeWidth: 10,
    });
    marinersNotesGroup.add(shadowLine, marinersLine);
    note.points.forEach((point, index) => {
      if (!note?.points || !note.id) return;
      const circleGroup = new Konva.Group();
      const isActiveNote = note.selected;
      const mp = lngLatToMeter({
        lng: radInDeg * (point.lng ?? 0),
        lat: radInDeg * halfPi(point.lat ?? 0),
      });
      const { x, y } = transformToAbsolute(mp);

      const selectedNode = new Konva.Circle({
        x,
        y,
        radius: 10,
        fill: colors.selectedLineOrNode,
        stroke: colors.selectedLineOrNode,
        draggable: true,
        visible: isActiveNote && note.points.length - 1 === index,
      });

      let pseudoLine: Konva.Line;
      if (isActiveNote && note.points.length - 1 === index) {
        pseudoLine = new Konva.Line({
          stroke: colors.selectedNodeOrLine,
          listening: false,
          strokeWidth: 2,
        });
        marinersNotesGroup.add(pseudoLine);
        selectedNode.on('mousedown touchstart', (ev) => {
          ev.cancelBubble = true;
          if (pseudoLine) {
            pseudoLine.destroy();
          }
        });
      }

      const marinersNode = new Konva.Circle({
        x,
        y,
        radius: 3,
        fill: isActiveNote ? colors.selectedLineOrNode : colors.unselectedNodeOrLine,
        stroke: isActiveNote ? colors.selectedLineOrNode : colors.unselectedNodeOrLine,
        draggable: true,
        visible: isActiveNote,
      });
      circleGroup.add(marinersNode, selectedNode);
      marinersNotesGroup.add(circleGroup);

      const stage = marinersNotesGroup.getStage();
      if (!stage) return;

      const allNodes = stage.find('Circle');
      allNodes?.forEach((item) => {
        // Whenever user mouseovers over the nodes, hide the pseudoLine
        item.on('mouseover', (ev) => {
          ev.cancelBubble = true;
          pseudoLine?.visible(false);
        });
        item.on('mouseout', (ev) => {
          ev.cancelBubble = true;
          pseudoLine?.visible(true);
        });
      });

      marinersLine.on('mousedown touchstart ', (ev: KonvaEventObject<MouseEvent>) =>
        selectMarinerNoteOnChart(note, ev, collectionId),
      );

      marinersNode.on('mouseover', (ev: KonvaEventObject<MouseEvent>) => {
        marinersNode.radius(6);
        ev.cancelBubble = true;
      });

      circleGroup.on('mouseout', (ev: KonvaEventObject<MouseEvent>) => {
        ev.cancelBubble = true;
        marinersNode.radius(3);
      });

      circleGroup.on('mousedown touchstart ', (ev) => selectMarinerNoteOnChart(note, ev, collectionId));

      stage.on('mousemove', (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        const pos = stage.getPointerPosition();
        if (!pseudoLine || !pos) {
          return;
        }
        pseudoLine.points([selectedNode.x(), selectedNode.y(), pos.x, pos.y]);
      });

      circleGroup.on('dragend', () => {
        const pos = stage.getPointerPosition();
        if (!pos || !note.id) return;
        const meterPoint = transformToViewport(pos);
        updatePointPosition(note.id, meterToLngLat(meterPoint), index, collectionId);
      });
    });
  };

  const drawNotes = () => {
    watchEffect(() => {
      marinersNotesGroup.destroyChildren();
      visibleCollectionNotes().forEach((collection: MarinersCollection) => {
        collection.notes.forEach((note: MarinersNote) => {
          if (pointTypeNotes.includes(note.type)) {
            if ((isPlanningStation.value && note.type != NoteType.Event) || isExtension.value) {
              drawPointNotes(note, collection.id);
            }
          } else {
            drawLineNotes(note, collection.id);
          }
        });
      });
    });
  };
  watch([() => visibleCollectionNotes()], drawNotes, { deep: true, immediate: true });
}
