import Konva from 'konva';
import { ContextType } from 'vue-context-composition';
import { viewportCtx } from '../../../contexts/viewport';
import { WaypointSpeedCourseLabel } from '../../../utils/routeUtils';
import { colors } from './addRoutes';

export function addWaypointCourseLabel(
  { courseMeterPoint, course }: WaypointSpeedCourseLabel,
  waypointCourseLabelGroup: Konva.Group,
  { transformToAbsolute }: ContextType<typeof viewportCtx>,
): void {
  const { x, y } = transformToAbsolute(courseMeterPoint);
  // add text in large enough area so it can be horizontally/vertically centered
  const wptCourseText = new Konva.Text({
    x,
    y,
    width: 50,
    height: 50,
    offsetX: 25,
    offsetY: 25,
    text: course,
    fontFamily: 'Lato',
    fill: colors.editTrack,
    align: 'center',
    verticalAlign: 'middle',
    fontSize: 14,
  });
  waypointCourseLabelGroup.add(wptCourseText);
}
