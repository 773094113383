<template>
  <pan-draggable-panel v-if="visible" class="routeSettingsPanel" :class="{ 'ecdis-width': isExtension }">
    <template #header>
      <pan-row class="header">
        <ecdis-value class="title">Default route settings</ecdis-value>
        <icon-grab class="grab" />
        <icon-close class="close" @pointerdown="toggleVisibility" />
      </pan-row>
    </template>

    <template #main>
      <pan-row v-for="(setting, index) in settings.generalSettings" :key="index">
        <div class="cell left">
          <pan-static-text>{{ setting.name }}</pan-static-text>
        </div>
        <div class="cell right">
          <pan-inline-editable-param
            :param-key="setting.id"
            :param-value="setGeneralSettingsParamValue(setting)"
            @changed="validateGeneralSettings($event, setting)"
          />
        </div>
      </pan-row>

      <pan-row class="header">
        <ecdis-value class="title">Display</ecdis-value>
      </pan-row>

      <ecdis-display-settings />

      <pan-row v-for="(setting, index) in settings.tagSettings" :key="index">
        <div class="cell left">
          <pan-static-text>{{ setting.name }}</pan-static-text>
        </div>
        <div class="cell right">
          <pan-dropdown v-if="setting.isSelectable" class="dropdown" :drops-downwards="true">
            <ecdis-value>{{ setting.value.value }}</ecdis-value>
            <template #content>
              <ecdis-option-list
                v-slot="{ value }"
                :param-key="setting.id"
                :values="['Travelled', 'Remaining']"
                :current-value="setting.value.value"
                @selected="updateTagSettings"
              >
                <ecdis-value>{{ value }}</ecdis-value>
              </ecdis-option-list>
            </template>
          </pan-dropdown>

          <pan-inline-editable-param
            v-else
            :param-key="setting.id"
            :param-value="setting.value"
            @changed="updateTagSettings"
          />
        </div>
      </pan-row>
    </template>
  </pan-draggable-panel>
</template>

<script lang="ts">
import { defineComponent, toRefs, watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { isExtension } from '../../configuration';
import { messageCtx } from '../../contexts/messages';
import { routesCtx } from '../../contexts/routes';
import { GeneralSettings, routeSettingsCtx } from '../../contexts/routeSettings';
import { vesselCtx } from '../../contexts/vessels';
import { meterInNm, nmInMeter } from '../../utils/conversions';
import { toParamValue, twoDecimalFormatter } from '../../utils/formatters';
import { validateRadius } from '../../utils/routeUtils';

export default defineComponent({
  setup() {
    const {
      state: routeSettingsState,
      toggleVisibility,
      updateGeneralSettings,
      updateTagSettings,
      getDistanceMarkerConfiguration,
    } = useContext(routeSettingsCtx);
    const { state: vesselState, getNumericVesselParam } = useContext(vesselCtx);
    const message = useContext(messageCtx);
    const { updateDistanceMarkerConfiguration } = useContext(routesCtx);
    const { visible, settings } = toRefs(routeSettingsState);

    watch(
      () => vesselState.selectedVessel,
      (updatedVessel) => {
        if (updatedVessel) {
          updateGeneralSettings({
            id: 'radius',
            value:
              updatedVessel.shipMinTurnRadius && updatedVessel?.shipMinTurnRadius.value
                ? ((+updatedVessel?.shipMinTurnRadius?.value ?? 0) * meterInNm).toString()
                : '',
          });
          updateGeneralSettings({ id: 'plannedSpeed', value: updatedVessel.shipCruiseSpeed?.value ?? '' });
        }
      },
    );

    //When the minShipRadius/width under vessel params get updated (on click of apply button) then data should be updated under Route Settings panel as well.
    watch(
      () => !vesselState.isViewDirty,
      (isViewClean) => {
        if (isViewClean) {
          const updatedMinTurnRadiusParam = vesselState?.selectedVesselParams?.find(
            (param) => param.id === 'shipMinTurnRadius',
          )?.value?.value;
          const updatedShipWidthParam = vesselState?.selectedVesselParams?.find((param) => param.id === 'shipWidth')
            ?.value?.value;
          if (updatedMinTurnRadiusParam) {
            updateGeneralSettings({
              id: 'radius',
              value: ((+updatedMinTurnRadiusParam ?? 0) * meterInNm).toString(),
            });
          }
          if (updatedShipWidthParam) {
            updateGeneralSettings({
              id: 'offtrackLimit',
              value: ((+updatedShipWidthParam ?? 0) * meterInNm * 0.5).toString(),
            });
          }
        }
      },
    );

    watch(
      () => getDistanceMarkerConfiguration(),
      (config) => updateDistanceMarkerConfiguration(config ?? { distance: 0, reversed: false }),
      { immediate: true },
    );

    const validateGeneralSettings = (ev: { id: string; value: string }, setting: GeneralSettings) => {
      if (setting.id === 'radius') {
        ev.value = (
          validateRadius(+ev.value * nmInMeter, getNumericVesselParam('shipMinTurnRadius'), message) * meterInNm
        ).toString();
      }
      updateGeneralSettings(ev);
    };

    const setGeneralSettingsParamValue = (setting: GeneralSettings) => {
      if (setting.id === 'radius' || setting.id === 'offtrackLimit')
        return toParamValue(twoDecimalFormatter(+setting.value.value), 'NM');
      else return setting.value;
    };

    return {
      visible,
      settings,
      toggleVisibility,
      updateTagSettings,
      validateGeneralSettings,
      setGeneralSettingsParamValue,
      isExtension,
    };
  },
});
</script>

<style scoped>
.routeSettingsPanel {
  width: 320px;
  background: var(--background_layer_below);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.ecdis-width {
  width: 336px;
}

.header {
  padding: 0 12px;
  background: var(--background_tile);
  cursor: move;
}

.title {
  flex: 1;
  font-size: 16px;
  text-transform: uppercase;
}

.grab {
  margin-right: 8px;
}

.close {
  cursor: pointer;
}

.cell {
  width: 50%;
}

.left {
  padding: 13px;
}

.right:hover {
  background-color: var(--input_background-default);
}

.dropdown {
  flex: 1 1 100%;
  padding: 8px;
}
</style>
