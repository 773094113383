import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { ContextType } from 'vue-context-composition';
import { viewportCtx } from '../../contexts/viewport';
import { blinkKonvaSymbol } from '../../utils/tools';

export function addMinuteMark(
  minuteMarkVector: Vector2d,
  isMinuteMarkVisible: boolean,
  minuteMarkGroup: Konva.Group,
  rotationAngle: number,
  { transformToAbsolute }: ContextType<typeof viewportCtx>,
  binkMode: boolean,
  stroke = 'black',
): void {
  const { x, y } = transformToAbsolute({ mX: minuteMarkVector.x, mY: minuteMarkVector.y });
  const minuteMarkSubGroup = new Konva.Group({
    rotation: rotationAngle,
    visible: isMinuteMarkVisible,
    position: { x, y },
  });
  const minuteMarkLine = new Konva.Line({
    points: [-8, 0, 0, 0, 8, 0],
    stroke: stroke,
    strokeWidth: 1,
    strokeScaleEnabled: false,
  });
  blinkKonvaSymbol(minuteMarkLine, binkMode);
  minuteMarkSubGroup.add(minuteMarkLine);
  minuteMarkGroup.add(minuteMarkSubGroup);
}
