<template>
  <div class="static-text">
    <slot />
  </div>
</template>

<style scoped>
.static-text {
  color: var(--text_static);
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
