import Konva from 'konva';
import { watchEffect } from 'vue';
import { useContext } from 'vue-context-composition';
import { viewportCtx } from '../../contexts/viewport';

export function watchViewportTransforms(layer: Konva.Layer): void {
  const { viewport, pxToMeter, setMatrix } = useContext(viewportCtx);

  // transform stage to scale, translate and rotate to viewport properties
  watchEffect(() => {
    const {
      dimension: { width, height },
      center,
      rotation: { angle },
    } = viewport;

    // calculate scaling that turns meters back into pixels, given viewport size and scaling params
    const widthMeters = pxToMeter(width);
    const heightMeters = pxToMeter(height);
    const scaleX = width / widthMeters;
    const scaleY = height / heightMeters;

    // scale back to pixels
    layer.scale({
      x: scaleX,
      y: scaleY,
    });

    // set stage offset to center of viewport, this is the rotation pivot point
    const offset = {
      x: center.mX,
      y: center.mY,
    };
    layer.offset(offset);

    // rotate using degrees
    layer.rotation(angle);

    // compensate position of viewport center in pixels
    layer.position({
      x: width / 2,
      y: height / 2,
    });

    // update matrix to trigger reactive watchers
    setMatrix(layer.getTransform().getMatrix());
  });
}
