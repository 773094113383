import { reactive, readonly } from 'vue';
import { defineContext } from 'vue-context-composition';

type State = {
  showPanel: boolean;
  criticalPointsInEditMode: Array<string>;
  routeId: string;
  routeName: string;
  isReadOnlyView: boolean;
};

export const criticalPointsSettingCtx = defineContext(() => {
  const criticalPointsSettingState: State = reactive({
    showPanel: false,
    criticalPointsInEditMode: [],
    routeId: '',
    routeName: '',
    isReadOnlyView: false,
  });

  const hidePanel = () => {
    criticalPointsSettingState.showPanel = false;
  };
  const showPanel = () => {
    criticalPointsSettingState.showPanel = true;
  };
  const isPanelVisible = () => criticalPointsSettingState.showPanel;

  const isCriticalPointEditable = (criticalPointId: string | undefined) => {
    if (criticalPointsSettingState.criticalPointsInEditMode.find((item: string) => item === criticalPointId)) {
      return true;
    }
    return false;
  };

  const setCriticalPointEditable = (criticalPointId: string) => {
    const found = criticalPointsSettingState.criticalPointsInEditMode.find((item: string) => item === criticalPointId);
    if (!found) {
      criticalPointsSettingState.criticalPointsInEditMode.push(criticalPointId);
    }
  };

  const getRouteId = () => criticalPointsSettingState.routeId;
  const getRouteName = () => criticalPointsSettingState.routeName;

  const setRouteInfo = (routeId: string, routeName: string, isReadonly: boolean) => {
    criticalPointsSettingState.routeId = routeId;
    criticalPointsSettingState.routeName = routeName;
    criticalPointsSettingState.isReadOnlyView = isReadonly;
  };

  const isPanelInEditMode = () => criticalPointsSettingState.criticalPointsInEditMode.length > 0;

  const clearCriticalPointsInEditMode = () => {
    criticalPointsSettingState.criticalPointsInEditMode = [];
  };

  const isPanelInReadonlyView = () => criticalPointsSettingState.isReadOnlyView;

  return {
    criticalPointsSettingState: readonly(criticalPointsSettingState),
    isPanelInEditMode,
    isCriticalPointEditable,
    clearCriticalPointsInEditMode,
    setCriticalPointEditable,
    hidePanel,
    showPanel,
    isPanelVisible,
    getRouteName,
    getRouteId,
    setRouteInfo,
    isPanelInReadonlyView,
  };
});
