<template>
  <label class="flex items-center gap-x-2 whitespace-pre text-sm text-text_main">
    <input
      type="checkbox"
      :checked="modelValue"
      :indeterminate.prop="indeterminate"
      v-bind="$attrs"
      @click="$emit('update:modelValue', ($event.target as HTMLInputElement).checked)"
    />{{ label }}
  </label>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
withDefaults(
  defineProps<{
    indeterminate?: boolean;
    label?: string;
    modelValue?: boolean;
  }>(),
  { label: undefined, modelValue: false },
);
defineEmits(['update:modelValue']);
</script>

<style scoped>
input[type='checkbox'] {
  @apply m-0 grid h-4 w-4 flex-shrink-0 appearance-none place-content-center rounded-sm border border-button_border-default bg-input_background-default hover:border-button_text-disabled disabled:border-button_background-disabled disabled:bg-button_background-disabled;
}
input[type='checkbox']:not(:disabled, :indeterminate)::before {
  @apply scale-0 transform bg-cover content-[''];
  width: 0.65rem;
  height: 0.455rem;
  transition: 120ms transform ease-in-out;

  /* TODO: get better SVG file: fill instead of stroke so that we can use clip-path and currentColor */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMy4xMjg0NUwzLjYyMTMyIDUuNzQ5NzdMOC43NSAwLjYyMTA5NCIgc3Ryb2tlPSIjNEE5MEUyIiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvc3ZnPg==');
  /* clip-path: polygon(10% 45%, 36% 82%, 88% 9%); clip path based on above SVG that is not good */

  /* Fallback implementation with different check mark */
  /* box-shadow: inset 1em 1em var(--button_text-selected); */
  /* clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); */
}
input[type='checkbox']:indeterminate::before {
  @apply text-button_text-selected content-['•'];
}
input[type='checkbox']:checked::before {
  @apply scale-100;
}
</style>
