<template>
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M19.5583 19.3388C20.7611 18.0818 21.5 16.3772 21.5 14.5C21.5 10.634 18.366 7.5 14.5 7.5C10.634 7.5 7.5 10.634 7.5 14.5C7.5 18.366 10.634 21.5 14.5 21.5C16.4888 21.5 18.2839 20.6706 19.5583 19.3388ZM19.5583 19.3388L25 25"
      stroke="#F6F6F6"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 32px;
  height: 32px;
}
</style>
