import Konva from 'konva';
import { computed } from 'vue';
import { useContext } from 'vue-context-composition';
import { contextMenuCtx } from '../../contexts/contextMenu';
import { controlModesCtx } from '../../contexts/controlModes';
import { cursorCtx } from '../../contexts/cursor';
import { marinersNotesCtx } from '../../contexts/marinersNotes';
import { routesCtx } from '../../contexts/routes';
import { routeSettingsCtx } from '../../contexts/routeSettings';
import { toolTipCtx } from '../../contexts/toolTip';
import { viewportCtx } from '../../contexts/viewport';
import { PixelPoint } from '../../types';
import user from '../../user';
import { meterToLngLat } from '../../utils/conversions';
import { lineTypeNotes } from '../../utils/marinersNotesUtils';
import { toWaypointLegPairs } from '../../utils/routeConversions';
import { calculateRouteMonitoringToolTipData } from '../../utils/routeUtils';
import { getTargetToolTipData } from '../../utils/targetUtils';

export function addEventListeners(stage: Konva.Stage): void {
  const { viewport, setCenter, setZoom, transformToViewport } = useContext(viewportCtx);
  const { state: controlModesState } = useContext(controlModesCtx);
  const { setAbsoluteMeterPoint } = useContext(cursorCtx);
  const { showContextMenu, hideContextMenu } = useContext(contextMenuCtx);
  const routesContext = useContext(routesCtx);
  const { insertNewWaypoint, appendNewWaypoint, monitoredRoute } = routesContext;
  const tooltip = useContext(toolTipCtx);
  const { updateMarinersNotesPoints, unselectedAllMarinersNotes, getSelectedMarinersNote } =
    useContext(marinersNotesCtx);

  const { state: routeSettings } = useContext(routeSettingsCtx);
  const monitoredWaypointLegPairs = computed(() =>
    monitoredRoute.value ? toWaypointLegPairs(monitoredRoute.value) : [],
  );

  // last relative dragging position
  let lastDraggingPos: PixelPoint | undefined;

  const container = stage.container();
  const onKeyBoardEvt = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter' && container?.tabIndex) {
      ev.preventDefault();
      // while adding nodes for mariners note, enter button event should exit the marinersNote edit mode.
      container.tabIndex = -1;
      unselectedAllMarinersNotes();
      container.removeEventListener('keydown', onKeyBoardEvt);
    }
  };

  stage.on('mousedown touchstart', (ev) => {
    const pos = stage.getPointerPosition();
    if (ev.evt.button === 0) {
      hideContextMenu();
      if (pos === null) {
        return;
      }
      const meterPoint = transformToViewport(pos);
      const { note, collectionId } = getSelectedMarinersNote();
      if ((ev.evt as MouseEvent) && ev.evt.ctrlKey) {
        const data = ev.target.getAttr('data');
        if (data && data.type === 'leg') {
          const fromWaypointId = data.uuid;
          if (fromWaypointId) {
            void insertNewWaypoint(fromWaypointId, meterToLngLat(meterPoint), routeSettings.settings.generalSettings);
          }
        } else {
          void appendNewWaypoint(meterToLngLat(meterPoint), routeSettings.settings.generalSettings);
        }
      } else if ((ev.evt as MouseEvent) && note && lineTypeNotes.includes(note.type)) {
        const pointerPositionMeterPoint = transformToViewport(pos);
        updateMarinersNotesPoints(meterToLngLat(pointerPositionMeterPoint), note.id, collectionId);
        if (container?.tabIndex < 0) {
          container.tabIndex = 1;
          container.addEventListener('keydown', onKeyBoardEvt);
        }
      } else {
        lastDraggingPos = pos;
      }
    }
  });

  stage.on('click', (ev) => {
    const pos = stage.getPointerPosition();
    if (ev.evt.button === 2 && pos) {
      ev.cancelBubble = true;
      showContextMenu(
        pos,
        ev.target?.getAttr('data'),
        routesContext,
        user.state.company?.features.map((item) => item),
      );
    }
  });

  stage.on('dblclick', (ev) => {
    const pos = stage.getPointerPosition();
    ev.cancelBubble = true;
    if (pos) {
      container.tabIndex = -1;
      container.removeEventListener('keydown', onKeyBoardEvt);
      unselectedAllMarinersNotes();
    }
  });

  stage.on('mousemove touchmove', () => {
    if (lastDraggingPos === undefined) {
      return;
    }
    const pos = stage.getPointerPosition();
    if (pos === null) {
      return;
    }

    const draggingMeterPoint = transformToViewport(pos);
    const lastDraggingMeterPoint = transformToViewport(lastDraggingPos);
    const deltaMeterPoint = {
      mX: lastDraggingMeterPoint.mX - draggingMeterPoint.mX,
      mY: lastDraggingMeterPoint.mY - draggingMeterPoint.mY,
    };

    // update center of viewport directly, constraints will be handled by other watchers
    setCenter({
      mX: viewport.center.mX + deltaMeterPoint.mX,
      mY: viewport.center.mY + deltaMeterPoint.mY,
    });

    lastDraggingPos = pos;
  });

  stage.on('mouseup touchend', () => {
    lastDraggingPos = undefined;
  });

  stage.on('mousemove touchmove', () => {
    const pos = stage.getPointerPosition();
    if (pos === null) {
      return;
    }
    const absoluteMeterPoint = transformToViewport(pos);
    setAbsoluteMeterPoint(absoluteMeterPoint);
  });

  stage.on('wheel', (options) => {
    const e = options.evt as WheelEvent;
    hideContextMenu();
    const pos = stage.getPointerPosition();
    setZoom(e.deltaY < 0, pos as PixelPoint, e.deltaY, controlModesState.browse);
  });

  let toolTipTimeoutRef = -1;
  stage.on('mousemove touchmove', (ev) => {
    const data = ev.target.getAttr('data');
    if (data && data.type) {
      // During monitoring mode, the tooltip to be shown should have the meta data.
      const position = stage.getPointerPosition();
      if (!position) return;
      if (
        monitoredWaypointLegPairs.value &&
        (data.type === 'leg' || data.type === 'waypoint') &&
        data.showMonitoringToolTipMetaData
      ) {
        tooltip.hide();
        window.clearTimeout(toolTipTimeoutRef);
        const wptMonitoredData = monitoredWaypointLegPairs.value.find((wp) => wp.waypoint.waypointId === data.uuid);
        toolTipTimeoutRef = window.setTimeout(() => {
          tooltip.show(
            position,
            calculateRouteMonitoringToolTipData(wptMonitoredData, data.type, routeSettings.settings.generalSettings),
          );
          toolTipTimeoutRef = -1;
        }, 300);
        // tool-tip should only be shown after hovering for 0.3 secs at a position.
      } else if (data.type === 'target' && data.target) {
        tooltip.hide();
        window.clearTimeout(toolTipTimeoutRef);
        toolTipTimeoutRef = window.setTimeout(() => {
          tooltip.show(position, getTargetToolTipData(data.target), true);
          toolTipTimeoutRef = -1;
        }, 300);
      }
    } else if (toolTipTimeoutRef > -1) {
      window.clearTimeout(toolTipTimeoutRef);
      tooltip.hide();
    }
  });
}
