import { Circle } from 'konva/lib/shapes/Circle';
import { Line } from 'konva/lib/shapes/Line';
import { Text } from 'konva/lib/shapes/Text';
import { Bounds } from '../contexts/viewport';
import { LngLatPoint, MeterPoint } from '../types';
import { lngLatToMeter, radInDeg } from './conversions';

export function angle({ mX: x1, mY: y1 }: MeterPoint, { mX: x2, mY: y2 }: MeterPoint): number {
  const dy = y2 - y1;
  const dx = x2 - x1;
  let theta = Math.atan2(dy, dx) + Math.PI / 2; // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
}

// this one is only useful for distance calculations where meterpoints are quite nearby.
export function distance({ mX: x1, mY: y1 }: MeterPoint, { mX: x2, mY: y2 }: MeterPoint): number {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

/**
 * Check if num1 is less than num2, with a given margin of error
 * @param num1 the number which we want to know if is smaller than num 2
 * @param num2 the number we are comparing against
 * @param precision the difference where we should say that the numbers are equal, default 1E-4
 */
export function lessThan(num1: number, num2: number, precision = 1e-4): boolean {
  return num1 < num2 - precision;
}

/**
 * Limit a given input number within a range of values
 * @param min Minimum value of the range
 * @param max Maximum value of the range
 * @param num The number to be limited
 * @returns The limited value
 */
export function limit(min: number, max: number, num: number): number {
  return Math.min(Math.max(num, min), max);
}

/**
 * Returns the list of multiples for the number less than the provided boundary range.
 * @param num The number whose multiples are required
 * @param range The boundary range value within which the multiples are required
 * @returns the list of multiples for the number.
 */
export function multiples(num: number, range: number): Array<number> {
  const multiplesArr = [];
  for (let i = 1; i < range / num; i++) multiplesArr.push(num * i);
  return multiplesArr;
}

/**
 * Allows to blink the konva symbol.
 * @param konvaShape The konvaShape which is required to blink.
 * @param blinkMode The boolean value to notify if the blinkMode is active.
 */
export function blinkKonvaSymbol(konvaShape: Line | Circle | Text, blinkMode: boolean) {
  if (blinkMode) {
    window.setInterval(() => {
      if (konvaShape.isVisible()) {
        konvaShape.visible(false);
      } else {
        konvaShape.visible(true);
      }
    }, 500);
  }
}
/**
 * Allows to calculate the bounds for the lat & lng
 * @param latLng Lat Lng points
 * @returns Bounds returns the caluclated bounds based on the min max lat & lng
 */

export function calculateBounds(latLng: LngLatPoint): Bounds {
  const bounds = { minLng: 180, maxLng: -180, minLat: 90, maxLat: -90 };
  bounds.minLng = Math.min(latLng.lng * radInDeg, bounds.minLng);
  bounds.maxLng = Math.max(latLng.lng * radInDeg, bounds.maxLng);
  bounds.minLat = Math.min(latLng.lat * radInDeg, bounds.minLat);
  bounds.maxLat = Math.max(latLng.lat * radInDeg, bounds.maxLat);
  const topLeft = lngLatToMeter({ lng: bounds.minLng, lat: bounds.maxLat });
  const bottomRight = lngLatToMeter({ lng: bounds.maxLng, lat: bounds.minLat });
  return { topLeft, bottomRight };
}

export function createTargetMarker(x: number, y: number, offset: number, targetColor: string, padding = 2) {
  const group = [];
  const normalizedOffset = offset + padding;

  for (let i = 0; i < 4; i++) {
    const corner = {
      points: [0, 10, 0, 0, 10, 0],
      x,
      y,
      absolutePosition: { x: x + offset, y: y + offset },
      offset: { x: normalizedOffset, y: normalizedOffset },
      stroke: targetColor,
      strokeWidth: 3,
      rotation: i * 90,
    };

    group.push(corner);
  }

  return group;
}
