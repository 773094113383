<template>
  <div v-if="activeVessel">
    <pan-row class="row-head">{{ activeVessel.shipName }}</pan-row>

    <div class="row-image">
      <img :src="imgUrl" class="image" alt="vessel-image" />
    </div>

    <ecdis-ship-param
      param-name="Ship length"
      :param-value="activeVessel.shipLength?.value"
      :param-unit="activeVessel.shipLength?.unit"
    />
    <ecdis-ship-param
      param-name="Ship width"
      :param-value="activeVessel.shipWidth?.value"
      :param-unit="activeVessel.shipWidth?.unit"
    />
    <ecdis-ship-param
      param-name="Ship draught"
      :param-value="activeVessel.shipDraught?.value"
      :param-unit="activeVessel.shipDraught?.unit"
    />
    <ecdis-ship-param
      param-name="Min ship turn radius"
      :param-value="
        toParamValue(
          twoDecimalFormatter(
            activeVessel?.shipMinTurnRadius && activeVessel?.shipMinTurnRadius.value !== undefined
              ? (+activeVessel.shipMinTurnRadius.value ?? 0) * meterInNm
              : undefined,
          ),
          'NM',
        )?.value
      "
      :param-unit="'NM'"
    />
    <ecdis-ship-param
      param-name="Max ship turn rate"
      :param-value="activeVessel.shipMaxTurnRate?.value"
      :param-unit="activeVessel.shipMaxTurnRate?.unit"
    />
    <ecdis-ship-param
      param-name="Turn acceleration length"
      :param-value="activeVessel.shipTurnAccelerationLength?.value"
      :param-unit="activeVessel.shipTurnAccelerationLength?.unit"
    />

    <div class="selector">
      <pan-button class="btn-selector" data-test-id="selectVessel" @click="select">Select vessel</pan-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRef } from 'vue';
import { useContext } from 'vue-context-composition';
import { configuration } from '../../configuration';
import { routeSettingsCtx } from '../../contexts/routeSettings';
import { vesselCtx } from '../../contexts/vessels';
import { meterInNm } from '../../utils/conversions';
import { toParamValue, twoDecimalFormatter } from '../../utils/formatters';

const emit = defineEmits(['close']);

const { state, selectVessel } = useContext(vesselCtx);
const { updateGeneralSettings } = useContext(routeSettingsCtx);
const activeVessel = toRef(state, 'activeVessel');

const imgUrl = computed(
  () => `${configuration.baseUrls.blobStorage}/${activeVessel.value?.imageUrl}${state.token ? state.token : ''}`,
);

const select = () => {
  if (activeVessel.value) {
    selectVessel(activeVessel.value);
    if (activeVessel.value.shipWidth?.value !== undefined) {
      // Once a vessel is selected from vessel details panel, the offtrack limit is set for the route settings panel.
      updateGeneralSettings({
        id: 'offtrackLimit',
        value: (+activeVessel.value.shipWidth.value * meterInNm * 0.5).toString(),
      });
    }
    emit('close');
  }
};
</script>

<style scoped>
.row-head {
  width: 100%;
  font-size: 16px;
  padding: 16px;
}

.row-image {
  width: 100%;
  height: 230px;
  padding: 8px 16px 0 16px;
  border-bottom: 0;
  font-size: 16px;
}

.image {
  width: 100%;
  max-height: 214px;
}

.selector {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.btn-selector {
  background-color: var(--feedback_indicator_background) !important;
}
</style>
