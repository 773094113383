<template>
  <div
    id="tool-tip"
    :class="splitInColumns ? 'col-data' : 'default-style'"
    :style="{ left: state.x + 'px', top: state.y + 'px' }"
  >
    <pan-row
      v-for="tooltipData in state.data"
      :key="tooltipData.title"
      class="item-pan-row"
      :class="{ 'split-width': splitInColumns, 'heading-background': tooltipData.isHeader }"
    >
      <template v-if="tooltipData.isHeader">
        <div>
          <pan-static-text class="heading-text heading-key">{{ tooltipData.title }}</pan-static-text>
          <pan-static-text class="heading-text heading-value">{{ tooltipData.value }}</pan-static-text>
        </div>
      </template>
      <template v-else>
        <ecdis-value :class="splitInColumns ? 'multi-col-item-title' : 'item-title'"
          >{{ tooltipData.title }}:</ecdis-value
        >
        <ecdis-value class="item-value">{{ tooltipData.value }}</ecdis-value>
        <pan-static-text class="value-unit">{{ tooltipData.unit }}</pan-static-text>
      </template>
    </pan-row>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useContext } from 'vue-context-composition';
import { toolTipCtx } from '../../contexts/toolTip';

export default defineComponent({
  setup() {
    const { state } = useContext(toolTipCtx);
    const { splitInColumns } = toRefs(state);
    return { state, splitInColumns };
  },
});
</script>
<style scoped>
#tool-tip {
  position: absolute;
  background-color: var(--background_layer_below);
  margin: 8px;
  opacity: 0.75;
}
.heading-background {
  background-color: var(--input_background-default) !important;
}
.default-style {
  padding: 0 8px;
  border-radius: 5px;
}
.col-data {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}
.item-pan-row {
  display: flex;
  min-height: 30px !important;
  border-bottom: none !important;
}
.split-width {
  width: 50%;
}
.item-title {
  padding: 0 5px;
}
.multi-col-item-title {
  padding: 0 8px;
}
.item-value {
  padding: 0 3px;
  font-size: 16px !important;
  color: var(--feedback_indicator_background) !important;
}
.value-unit {
  font-size: 16px !important;
}
.heading-key {
  padding: 4px 12px 0 12px;
}
.heading-value {
  padding: 0 4px 4px 12px;
}
.heading-text {
  font-size: 16px !important;
  white-space: normal !important;
}
</style>
