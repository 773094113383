<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 18L18 12M18 12L12 6M18 12L6 12" stroke="#F6F6F6" stroke-linejoin="round" />
  </svg>
</template>

<style scoped>
svg {
  width: 24px;
  height: 24px;
}
</style>
