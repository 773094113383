import Konva from 'konva';
import { colors } from './addScaleBar';

export function createLegendBottom(): Konva.Text {
  return new Konva.Text({
    x: 7,
    offsetY: -2,
    fontFamily: 'Lato',
    fontSize: 12,
    fontStyle: 'bold',
    fill: colors.black,
  });
}
