<template>
  <component :is="icon" aria-hidden="true" focusable="false" class="inline-block" />
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import * as icons from './';

/**
 * Generic icon component that renders a Vue SVG component (using svg-loader)
 */
export default defineComponent({
  props: {
    /** Name of the component, exported in @signi/common/src/lib/icon/index.ts */
    name: { type: String as PropType<keyof typeof icons>, required: true },
  },
  setup(props) {
    return {
      icon: icons[props.name] ?? 'span',
    };
  },
});
</script>
