import { isReactive, watch } from 'vue';

type Reactive = Record<string, unknown>;

function getState(storage: Storage, key: string): Reactive | null {
  const stateJSON = storage.getItem(key);
  if (stateJSON === null) {
    return null;
  }

  try {
    return JSON.parse(stateJSON);
  } catch {
    // something is wrong with session storage item, clear it
    storage.removeItem(key);
    return null;
  }
}

function setState(storage: Storage, key: string, state: Reactive): void {
  storage.setItem(key, JSON.stringify(state));
}

/**
 * Make any Vue reactive object persistent. The initial state and any subsequent changes are persisted using the Web Storage API
 *
 * @param {string} key The key under which the state is persisted.
 * @param {object} state The state object that will be persisted.
 * @param {Storage} storage The storage object that will be used to persist, defaults to `sessionStorage`.
 * @returns {object} The state that was passed in.
 */
export function persist<T extends Reactive>(key: string, state: T, storage: Storage = sessionStorage): T {
  if (!isReactive(state)) {
    throw Error('persist() only works on a reactive object');
  }

  const sessionState = getState(storage, key);
  if (sessionState !== null) {
    Object.entries(sessionState).forEach(([prop, value]) => {
      (state as Reactive)[prop] = value;
    });
  }

  watch(state, () => setState(storage, key, state), { immediate: true });

  return state;
}
