<template>
  <signi-panel-select
    heading="Motion"
    :options="options"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #option="{ value }">{{ optionLabelsMap[value] }}</template>
    <template #selected="{ value }">{{ optionLabelsMap[value] }}</template>
  </signi-panel-select>
</template>

<script setup lang="ts">
import { SelectOption } from '../select/types';

const optionLabelsMap = {
  // Radar
  TrueMotion: 'True',
  RelativeMotion: 'Relative',

  // ECDIS
  TM: 'True',
  RM: 'Relative',
};

defineProps<{ modelValue?: keyof typeof optionLabelsMap; options: SelectOption<string>[] }>();
defineEmits(['update:modelValue']);
</script>
