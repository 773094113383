<template>
  <svg width="24" height="24" viewBox="0 0 24 24" title="Logout" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 4V17H11V18H5C4.44772 18 4 17.5203 4 16.9286V4.07143C4 3.4797 4.44772 3 5 3H11V4H5ZM17.9181 12H10V11H17.9181L15 7.76556L15.9401 7L20 11.5L15.9401 16L15 15.2344L17.9181 12Z"
      fill="white"
    />
  </svg>
</template>

<style scoped>
svg {
  cursor: pointer;
}
</style>
