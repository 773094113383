<template>
  <div class="inlineEditableParam" :class="{ 'cursor-pointer': editMode }" @click="startEdit">
    <div v-show="isEditable" class="textbox">
      <input
        ref="textbox"
        v-model="edited"
        :style="{ fontSize: fontSize + 'px', width: inputWidth + '%' }"
        type="number"
        @change="changed"
        @wheel="$event.preventDefault()"
        @blur="stopEdit"
        @keyup.enter="stopEdit"
      />
      <ecdis-unit class="unit-text" :style="{ 'font-size': fontSize + 'px' }">{{ unit }}</ecdis-unit>
    </div>

    <ecdis-value v-show="!isEditable" class="param" :style="{ 'font-size': fontSize + 'px' }">
      {{ value }} <ecdis-unit>{{ unit }}</ecdis-unit>
    </ecdis-value>
  </div>
</template>

<script lang="ts">
import { PropType, Ref, computed, defineComponent, ref } from 'vue';
import { ParamValue } from '../../types';

export default defineComponent({
  props: {
    paramKey: {
      type: String,
      required: true,
    },
    paramValue: {
      type: Object as PropType<ParamValue> | undefined,
      default: undefined,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: Number,
      default: 16,
      required: false,
    },
    inputWidth: {
      type: Number,
      default: 70,
      required: false,
    },
    defaultValue: {
      type: String,
      default: '-',
      required: false,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const textbox: Ref<HTMLInputElement | undefined> = ref();
    const isEditable = ref(false);
    const unit = computed(() => props.paramValue?.unit || '');
    const value = computed(() => props.paramValue?.value || props.defaultValue);
    const edited = ref();

    const startEdit = () => {
      edited.value = value.value;
      isEditable.value = props.editMode;
      window.setTimeout(() => {
        textbox.value?.focus();
      }, 0);
    };
    const stopEdit = () => (isEditable.value = false);
    const changed = () => emit('changed', { id: props.paramKey, value: `${edited.value}` });
    return { isEditable, unit, value, edited, startEdit, stopEdit, changed, textbox };
  },
});
</script>

<style scoped>
.inlineEditableParam {
  display: flex;
  flex: 1;
  align-items: center;
  height: 48px;
}

.cursor-pointer {
  cursor: pointer;
}

.unit-text {
  line-height: 24px;
  margin-left: 5px;
}

.param {
  padding: 0 8px;
}

input {
  height: 47px;
  background-color: var(--input_background-default);
  color: var(--text_main);
  border: transparent;
  outline: 1px solid #000000;
  padding-left: 8px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  user-select: text;
}
</style>
