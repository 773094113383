import { watch } from 'vue';
import { useContext } from 'vue-context-composition';
import { viewportCtx } from '../../contexts/viewport';

export function watchViewportScaling(): void {
  const { viewport, setScaling } = useContext(viewportCtx);

  // update dpm based on viewport width
  watch(
    () => viewport.dimension.width,
    () => {
      setScaling({
        ...viewport.scaling,
        //TODO: find a way around hard coding DPM for C-Map
        // dpm: (viewport.dimension.width / 1920) * 3780,
        dpm: 3780,
      });
    },
    { immediate: true },
  );
}
